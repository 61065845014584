/**
 * @generated SignedSource<<5de3f657af1570cf5cebc10fb7b78e73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accessPlanUids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bannerImg"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverImg"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverImgDesc"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "learningObjectives"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sections"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "synopsis"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "originalFileName",
    "storageKey": null
  },
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "accessPlanUids",
            "variableName": "accessPlanUids"
          },
          {
            "kind": "Variable",
            "name": "bannerImg",
            "variableName": "bannerImg"
          },
          {
            "kind": "Variable",
            "name": "coverImg",
            "variableName": "coverImg"
          },
          {
            "kind": "Variable",
            "name": "coverImgDesc",
            "variableName": "coverImgDesc"
          },
          {
            "kind": "Variable",
            "name": "description",
            "variableName": "description"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "learningObjectives",
            "variableName": "learningObjectives"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "sections",
            "variableName": "sections"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          },
          {
            "kind": "Variable",
            "name": "synopsis",
            "variableName": "synopsis"
          },
          {
            "kind": "Variable",
            "name": "tags",
            "variableName": "tags"
          }
        ],
        "kind": "ObjectValue",
        "name": "data"
      }
    ],
    "concreteType": "Course",
    "kind": "LinkedField",
    "name": "updateCourse",
    "plural": false,
    "selections": [
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "synopsis",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LearningObjective",
        "kind": "LinkedField",
        "name": "learningObjectives",
        "plural": true,
        "selections": [
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UploadedFile",
        "kind": "LinkedField",
        "name": "bannerImg",
        "plural": false,
        "selections": (v17/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UploadedFile",
        "kind": "LinkedField",
        "name": "coverImg",
        "plural": false,
        "selections": (v17/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coverImgDesc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastUpdatedTs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          (v13/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseSection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rank",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseChapter",
                "kind": "LinkedField",
                "name": "chapters",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleVersion",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Module",
                        "kind": "LinkedField",
                        "name": "module",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccessPlan",
        "kind": "LinkedField",
        "name": "accessPlans",
        "plural": true,
        "selections": [
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          (v12/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addToHomepage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseDetailsDialog_UpdateMutation",
    "selections": (v20/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v11/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "CourseDetailsDialog_UpdateMutation",
    "selections": (v20/*: any*/)
  },
  "params": {
    "cacheID": "18711a7cfc7bd70dc54e8a6741bed29a",
    "id": null,
    "metadata": {},
    "name": "CourseDetailsDialog_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CourseDetailsDialog_UpdateMutation(\n  $id: GlobalID!\n  $name: String = null\n  $description: String = null\n  $synopsis: String = null\n  $status: String = null\n  $learningObjectives: [String!] = null\n  $bannerImg: String = null\n  $coverImg: String = null\n  $coverImgDesc: String = null\n  $tags: [String!] = null\n  $accessPlanUids: [Uid!] = null\n  $sections: [CourseSectionInputPartial!] = null\n) {\n  updateCourse(data: {id: $id, name: $name, description: $description, synopsis: $synopsis, status: $status, learningObjectives: $learningObjectives, bannerImg: $bannerImg, coverImg: $coverImg, coverImgDesc: $coverImgDesc, tags: $tags, accessPlanUids: $accessPlanUids, sections: $sections}) {\n    id\n    uid\n    name\n    description\n    synopsis\n    learningObjectives {\n      text\n    }\n    bannerImg {\n      originalFileName\n      name\n      url\n      size\n    }\n    coverImg {\n      originalFileName\n      name\n      url\n      size\n    }\n    coverImgDesc\n    lastUpdatedTs\n    versions {\n      uid\n      version\n      status\n      author {\n        uid\n        firstName\n        lastName\n        email\n      }\n      sections {\n        uid\n        name\n        description\n        rank\n        chapters {\n          uid\n          name\n          description\n          modules {\n            uid\n            version\n            status\n            module {\n              name\n            }\n          }\n        }\n      }\n    }\n    accessPlans {\n      uid\n      name\n    }\n    tags {\n      id\n      text\n    }\n    addToHomepage\n  }\n}\n"
  }
};
})();

node.hash = "87e6a9540af780bf54f997dc33d51a20";

export default node;
