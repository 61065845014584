import React from 'react';
import Box from '@mui/material/Box';
import {
  pageContentPaddingX,
  pageContentPaddingY,
  scrollableXContainerPosition,
  sectionDescriptionFont,
  sectionHeaderMb,
  sectionNameFont,
  sectionNameMb
} from '../../../style/constants/homepage';
import Typography from '@mui/material/Typography';
import ScrollableXContainer from '../../common/ScrollableXContainer';
import {courseSectionDescription} from '../../homepage/Courses';
import Skeleton from '@mui/material/Skeleton';
import {CURR_SITE, SITE_STANFORD} from '../../../constants';

const site = CURR_SITE.name;
const backgroundImage = site === SITE_STANFORD
  ? ''
  : 'url(/static/ns/img/background_homepage_courses.svg)';

const indexes = [0, 1]
const CoursesSkeleton = () => {

  const coursesElement = (
    indexes
      .map(index => (
        <Box
          key={index}
          sx={{
            width: '276px',
            height: '100%',
            boxShadow: '5px 5px 5px 0px rgba(0, 0, 0, 0.3)',
            zIndex: '100',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            mr: '32px',
          }}
        >
          <Skeleton
            key={index}
            variant='rectangular'
            width={'276px'}
            height={'207px'}
            sx={{
              mb: {xs: '16px'},
            }}
          />
          <Box sx={{px: '16px'}}>
            <Skeleton
              sx={{
                width: '85%',
              }}
            />
            <Skeleton
              sx={{
                width: '75%',
              }}
            />
            <Skeleton
              sx={{
                width: '90%',
                mb: '60px',
              }}
            />

            <Skeleton
              sx={{
                height: '40px',
                width: '100%',
                mb: '16px',
              }}
            />
          </Box>
        </Box>
      ))
  );

  return (
    <Box sx={{
      backgroundColor: 'background.main',
      backgroundImage: backgroundImage,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      position: 'relative',
      px: {...pageContentPaddingX},
      py: {...pageContentPaddingY}
    }}>
      <Typography sx={{
        mb: {...sectionNameMb},
        fontSize: {...sectionNameFont},
      }}>
        Courses
      </Typography>

      <Typography sx={{
        mb: {...sectionHeaderMb},
        fontSize: {...sectionDescriptionFont},
      }}>
        {courseSectionDescription}
      </Typography>

      <ScrollableXContainer
        containerSx={{
          pb: {xs: '20px'},
        }}
        navigationSx={{
          bottom: {xs: '10px', lg: '30px'},
          ...scrollableXContainerPosition,
        }}
        elements={coursesElement}
      />
    </Box>
  );
};

export default CoursesSkeleton;