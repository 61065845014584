import React from 'react';
import AccessError from '../components/common/AccessError';
import NavBar from '@/components/common/navbar/NavBar';
import Dashboard from '../components/dashboard/Dashboard'
import PropTypes from 'prop-types';
import {theme} from '../themes/dashboard';
import {ThemeProvider} from '@mui/material/styles';
import {isAdmin} from '../utils/auth';

const DashboardPage = (props) => {
  const { user } = props;

  return isAdmin(user)
    ?
      <ThemeProvider theme={theme}>
        <NavBar
          showLinks={false}
          showWelcomeText={false}
          theme={theme}
        />
        <Dashboard user={user} />
      </ThemeProvider>
    :
      <AccessError />
}

DashboardPage.propTypes = {
  user: PropTypes.object,
}

DashboardPage.defaultProps = {
  user: null,
}

export default DashboardPage;
