/**
 * @generated SignedSource<<9255a4d376f71943b967e4667ad222f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accessPlanUid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "autoRenew"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isAdmin"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriptionDuration"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "university"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accessPlanUid",
        "variableName": "accessPlanUid"
      },
      {
        "kind": "Variable",
        "name": "autoRenew",
        "variableName": "autoRenew"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "isAdmin",
        "variableName": "isAdmin"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "subscriptionDuration",
        "variableName": "subscriptionDuration"
      },
      {
        "kind": "Variable",
        "name": "university",
        "variableName": "university"
      }
    ],
    "kind": "ObjectValue",
    "name": "data"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "University",
  "kind": "LinkedField",
  "name": "university",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "joinedTs",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allQuestionsAnswered",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "UserGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v14/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessPlan",
      "kind": "LinkedField",
      "name": "accessPlan",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Invitation",
  "kind": "LinkedField",
  "name": "invitation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdTs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastAttemptedSendDatetime",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msg",
  "storageKey": null
},
v24 = [
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInvitationDialog_InviteSingleUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UserInvitationResult",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "invitation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "recipient",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserProfile_UserAnswers"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserInvitationDialog_InviteSingleUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "UserInvitationResult",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "invitation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "recipient",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileQuestion",
                        "kind": "LinkedField",
                        "name": "question",
                        "plural": false,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileQuestionOption",
                        "kind": "LinkedField",
                        "name": "chosenOption",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowCustomAnswer",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileQuestionOption",
                        "kind": "LinkedField",
                        "name": "chosenOptions",
                        "plural": true,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customAnswer",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/),
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4588d2b8843efe735ae294c7391228d8",
    "id": null,
    "metadata": {},
    "name": "UserInvitationDialog_InviteSingleUserMutation",
    "operationKind": "mutation",
    "text": "mutation UserInvitationDialog_InviteSingleUserMutation(\n  $firstName: String!\n  $lastName: String!\n  $email: String!\n  $accessPlanUid: Uid\n  $university: String!\n  $isAdmin: Boolean!\n  $autoRenew: Boolean\n  $subscriptionDuration: Int\n) {\n  inviteUser(data: {firstName: $firstName, lastName: $lastName, email: $email, accessPlanUid: $accessPlanUid, university: $university, isAdmin: $isAdmin, autoRenew: $autoRenew, subscriptionDuration: $subscriptionDuration}) {\n    invitation {\n      recipient {\n        id\n        uid\n        firstName\n        lastName\n        email\n        university {\n          id\n          uid\n          name\n        }\n        isActive\n        joinedTs\n        allQuestionsAnswered\n        groups {\n          name\n        }\n        subscription {\n          type\n          validFrom\n          validTo\n          accessPlan {\n            uid\n            name\n          }\n        }\n        invitation {\n          createdTs\n          lastAttemptedSendDatetime\n        }\n        answers {\n          ...UserProfile_UserAnswers\n        }\n      }\n    }\n    status\n    msg\n  }\n}\n\nfragment UserProfile_UserAnswers on UserAnswer {\n  uid\n  question {\n    uid\n  }\n  chosenOption {\n    uid\n    allowCustomAnswer\n  }\n  chosenOptions {\n    uid\n  }\n  customAnswer\n}\n"
  }
};
})();

node.hash = "48767b00455b8f1fe5277d83174358f9";

export default node;
