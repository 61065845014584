import * as yup from "yup";
import {DURATION_UNITS, MAX_CSV_SIZE} from "./constants";
import {formatFileSize} from '@/utils/common';

const baseSchema = yup.object({
  firstName: yup
    .string()
    .default('')
    .required('First name is required'),
  lastName: yup
    .string()
    .default('')
    .required('Last name is required'),
  email: yup
    .string()
    .default('')
    .email('A valid email address is required')
    .required('Email address is required'),
  university: yup
    .object({
      uid: yup.string(),
      name: yup.string().required('Institution is required'),
    })
    .default(null)
    .nullable()
    .required('Institution is required'),
  accessPlan: yup
    .object({
      uid: yup.string(),
      name: yup.string().required('Access Plan is required'),
    })
    .default(null)
    .required('Access Plan is required'),
});

export const userQuestionnaireSchema = yup.object({
  answers: yup
    .array().of(
      yup.object({
        chosenOption: yup
          .mixed()
          .default(null)
          .nullable(),
        customAnswer: yup
          .string()
          .when('chosenOption',
            ([chosenOption], schema) => {
              return (
                chosenOption?.allowCustomAnswer === true
                  ? schema.nonNullable().required('Please enter your answer')
                    .test(
                      'check-whitespace-stripped-value',
                      (d) => `Please enter a valid answer`,
                      (value, context) => {
                        const stripped = (value ?? '').trim();
                        return Boolean(stripped);
                      }
                    )
                  : schema.nullable().default(null)
              )
            }
          ),
      })
   )
});

export const userSchema = baseSchema.concat(yup.object({
  subscriptionType: yup
    .object({
      id: yup.number(),
      name: yup.string().required('Subscription Type is required'),
    })
    .default(null)
    .nullable(),
})).concat(userQuestionnaireSchema);

export const invitationSchemaSingleUser = baseSchema.concat(yup.object({
  accessPlan: yup
    .object({})
    .when('isAdmin',
      ([isAdmin], schema) => (
        isAdmin === false // 'null/undefined' mean no selection has been made
        ? schema.concat(yup.object({
            uid: yup.string(),
            name: yup.string().required('Access Plan is required'),
          })
          .default(null)
          .required('Access Plan is required'))
        : schema.nullable().default(null)
      )
    ),
  isAdmin: yup
    .bool()
    .default(false),
  autoRenew: yup
    .bool()
    .default(null)
    .nullable(),
  subscriptionDuration: yup
    .object({})
    .when('autoRenew',
      ([autoRenew], schema) => (
        autoRenew === false // 'null/undefined' mean no selection has been made
        ? schema.concat(yup.object({
            unit: yup
              .string()
              .oneOf(DURATION_UNITS.map((unit) => unit.value)),
            duration: yup.number()
              .integer('Duration must be a positive integer')
              .positive('Duration must be a positive integer')
              .required('Duration is required')
              .typeError('Duration must be a positive integer')
              .default(null)
              .when('unit',
                ([unit], schema) => {
                  const unitInfo = DURATION_UNITS.find((elem) => elem.value === unit);
                  return (unitInfo
                    ? schema.max(unitInfo.max, `Maximum is ${unitInfo.max} when specifying ${unitInfo.name}`)
                    : schema.required());
              })
          }))
        : schema
      )
    )
}));

export const invitationSchemaMultiUser = yup.object({
  csvFile: yup
    .mixed()
    .required('CSV file is required')
    .test(
      'is-too-large',
      (d) => `Maximum file size is ${formatFileSize(MAX_CSV_SIZE)}`,
      (value, context) => (value?.size ?? 0) <= MAX_CSV_SIZE
    )
});

export const changePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .default('')
    .required('Please enter current password'),
  newPassword: yup
    .string()
    .default('')
    .required('Please enter new password')
    .min(8, 'The minimum password length is 8')
    .test('password-changed', 'New password can\'t be the same as the current password', function(value){
      return this.parent.currentPassword !== value
    })
    .test('uppercase', 'At least one uppercase letter', (value) => /[A-Z]/.test(value))
    .test('digit', 'At least one digit', (value) => /[0-9]/.test(value)),

  newPassword2: yup
    .string()
    .default('')
    .required('Please confirm the password')
    .test('passwords-match', 'Passwords must match', function(value){
      return this.parent.newPassword === value
    })
});
