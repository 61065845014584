import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

import PropTypes from 'prop-types';

const ReadonlyTextfield = (props) => {
  const {
    text,
    label,
    ...textFieldProps
  } = props;
  return (
    <Stack direction='column' spacing={0}>
      {label &&
        <Typography
          variant='body2'
        >
          {label}
        </Typography>
      }
      <Typography
        variant='body1'
        {...textFieldProps}
        sx={{
          mb: '5px',
          backgroundColor: grey[200],
          p: '5px',
          borderRadius: 1,
        }}
      >
        {text}
      </Typography>
    </Stack>
  )
}

ReadonlyTextfield.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  textFieldProps: PropTypes.object,
}

export default ReadonlyTextfield;
