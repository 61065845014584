import {isEmpty} from 'lodash';
import React from 'react';
import {ConnectionHandler, graphql, useLazyLoadQuery} from 'react-relay';

const UniversitiesQuery = graphql`
  query useUniversities_UniversitiesQuery {
      universities(
          first: 100,
      )
      @connection(key: "useUniversities_universities")
      {
          edges {
              node {
                  id
                  uid
                  name
              }
          }
      }
  }
`;
export const useUniversities = () => {
  const universitiesData = useLazyLoadQuery(UniversitiesQuery);
  const universities = React.useMemo(() => {
     return universitiesData.universities.edges.map((edge) => edge.node)
  }, [universitiesData.universities.edges]);

  const updateUniversities = (store, univProxy) => {
    const existingUniversity = universities.find((univ) => univ.id === univProxy.getValue('id'));
    if (isEmpty(existingUniversity)) {
      console.log('useUniversities::updateUniversities: newUniversity=', univProxy);
      const univConnection = ConnectionHandler.getConnection(
        store.getRoot(),
        'useUniversities_universities',
      );
      if (univConnection) {
        const edge = ConnectionHandler.createEdge(
          store,
          univConnection,
          univProxy,
          'University'
        )
        ConnectionHandler.insertEdgeAfter(univConnection, edge);
      }
    }
  }

  const deleteUniversity = (store, univToDelete) => {
    if (!isEmpty(univToDelete)) {
      console.log('useUniversities::deleteUniversities: univsToDelete=', univToDelete);
      const univConnection = ConnectionHandler.getConnection(
        store.getRoot(),
        'useUniversities_universities',
      );
      if (univConnection) {
        ConnectionHandler.deleteNode(univConnection, univToDelete.id);
      }
      const userConnection = ConnectionHandler.getConnection(
        store.getRoot(),
        'UsersTableFragment_users',
        {filters: {forCurrentSite: true}},
      );
      if (userConnection) {
        const edges = userConnection.getLinkedRecords('edges');
        edges.forEach((edge) => {
          const node = edge.getLinkedRecord('node');
          const univ = node.getLinkedRecord('university');
          if (univ && univ.getValue('id') === univToDelete.id) {
            node.invalidateRecord();
          }
        })
      }
    }
  }

  return {
    universities,
    updateUniversities,
    deleteUniversity,
  };
}
