/**
 * @generated SignedSource<<1456d4e4e8cb0f8bc785c0f1d4248769>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModulesSectionsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "sections",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SectionFragment_section"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Chapter",
            "kind": "LinkedField",
            "name": "chapters",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ModuleChapterFragment_chapter"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModulesSectionsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "sections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frontendId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Chapter",
            "kind": "LinkedField",
            "name": "chapters",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "defaultModules",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverImgDesc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadedFile",
                    "kind": "LinkedField",
                    "name": "coverImg",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleVersion",
                    "kind": "LinkedField",
                    "name": "latestVersion",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionUidOfNextModule",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModuleVersionProgress",
                        "kind": "LinkedField",
                        "name": "progress",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "progressPercent",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3410b76e3c7e442db04dfa0b17c84570",
    "id": null,
    "metadata": {},
    "name": "ModulesSectionsListQuery",
    "operationKind": "query",
    "text": "query ModulesSectionsListQuery {\n  sections {\n    ...SectionFragment_section\n    chapters {\n      id\n      uid\n      ...ModuleChapterFragment_chapter\n    }\n  }\n}\n\nfragment ModuleChapterFragment_chapter on Chapter {\n  uid\n  name\n  defaultModules {\n    id\n    uid\n    ...ModuleDataWrapperFragment_defaultModules\n  }\n}\n\nfragment ModuleDataWrapperFragment_defaultModules on Module {\n  id\n  uid\n  name\n  description\n  coverImgDesc\n  coverImg {\n    url\n  }\n  tags {\n    text\n  }\n  latestVersion {\n    id\n    uid\n    versionUidOfNextModule\n    ...ModuleDataWrapperProgressFragment_progress\n  }\n}\n\nfragment ModuleDataWrapperProgressFragment_progress on ModuleVersion {\n  progress {\n    progressPercent\n  }\n  id\n}\n\nfragment SectionFragment_section on Section {\n  id\n  uid\n  name\n  description\n  frontendId\n}\n"
  }
};
})();

node.hash = "b9c7d82f6c4682f6ec204a984f8a8d70";

export default node;
