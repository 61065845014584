import {join, keys, pickBy, reduce, some} from 'lodash';
import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

const UserReportFieldsQuery = graphql`
  query UserReportDownloadDialog_FieldsQuery {
    userReportFields {
        id
        title
    }
  }
`

const REPORT_URL = '/api/reports/users/';


export default function UserReportDownloadDialog(props) {
  const {
    isOpen,
    onClose
  } = props;

  const {userReportFields} = useLazyLoadQuery(UserReportFieldsQuery, {});
  const [fieldSelected, setFieldSelected] = React.useState(
    reduce(
      userReportFields,
      (result, field) => {
        result[field.id] = true;
        return result;
      },
      {}
    )
  )
  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  const handleCheckboxChange = (event) => {
    setFieldSelected({
      ...fieldSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDownloadClick = (evt) => {
    const selectedFields = keys(pickBy(fieldSelected, (isSelected) => isSelected));
    evt.target.href = `${REPORT_URL}?fields=${join(selectedFields, ',')}`
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>Download User Report</DialogTitle>
      <DialogContent sx={{p: 2}}>
        <Paper sx={{ p: 2 }} elevation={2}>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Select fields to include in the report:</FormLabel>
            <FormGroup>
              {
                userReportFields.map((field) => (
                  <FormControlLabel
                    key={field.id}
                    control={
                      <Checkbox
                        checked={fieldSelected[field.id]}
                        name={field.id}
                        size='small'
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={field.title}
                  />

                ))
              }
            </FormGroup>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button aria-label='Cancel' onClick={handleClose} size='small'>Cancel</Button>
        <Button
          aria-label='Download'
          href={REPORT_URL}
          variant='contained'
          size='small'
          disabled={!some(fieldSelected, (isSelected) => isSelected)}
          onClick={handleDownloadClick}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserReportDownloadDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

UserReportDownloadDialog.defaultProps = {
  isOpen: false,
}
