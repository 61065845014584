import React, {useCallback, useRef, useState} from 'react';
import {disableScrollBar, setTargetSectionScrollTo} from '../../utils/common';

export const useTabNavigation = () => {
  const stickyPartRef = useRef();
  const headerPartRef = useRef();
  const sectionsRefMap = useRef({});
  const [sectionValue, setSectionValue] = useState(null);

  const handleSectionChanged = useCallback((value) => {
    setSectionValue(value);
  }, []);

  const handleSectionTabChanged = (value) => {
    if (stickyPartRef.current && headerPartRef.current) {
      const sectionRef = sectionsRefMap.current[value];
      if (sectionRef) {
        disableScrollBar();
        const headerHeight = headerPartRef.current.getBoundingClientRect().height;
        const headerHiddenPart = Math.min(window.scrollY, headerHeight);
        const headerVisiblePart = headerHeight - headerHiddenPart;
        const sectionPosition = Math.round(
          sectionRef.getBoundingClientRect().top
        );
        const positionOffset = Math.round(
          stickyPartRef.current.getBoundingClientRect().bottom
        );
        const currentScroll = Math.round(window.scrollY);
        const navigateTo = (
          currentScroll + sectionPosition - positionOffset + headerVisiblePart
        );
        setTargetSectionScrollTo(value);
        window.scroll({top: navigateTo, behavior: 'smooth'});
        handleSectionChanged(value);
      }
    }
  }

  return [
    stickyPartRef,
    headerPartRef,
    sectionsRefMap,
    sectionValue,
    handleSectionChanged,
    handleSectionTabChanged,
  ];
};