import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes, {shape} from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import ScrollableXContainer from '../common/ScrollableXContainer';
import VideoCard from './VideoCard';
import ModuleCardNoContent from '../self-guided/ModuleCardNoContent';
import {videoParentPropTypes} from '../../constants/propTypesShapes';
import VideoDataWrapper from '../common/data-wrappers/VideoDataWrapper';

const VideoChapterFragment = graphql`
  fragment VideoChapterFragment_chapter on Chapter {
    uid
    name
    defaultModules(latestVersionOnlyWithVideos: true) {
      id
      uid
      name
      coverImg {
        url
      }
      tags {
        text
      }
      latestVideoVersion {
        uid
        videos {
          uid
          ...VideoDataWrapperFragment_defaultVersionedModulesVideo
        }
      }
    }
  }
`;

const VideosChapter = (
  {
    chapter,
    sectionColor,
    childrenProps,
  }
) => {

  const {
    selectedVideo,
    handleSelectVideoChanged,
    handleNextVideoChanged,
    handlePreviousVideoChanged,
    handleOpenDialog,
  } = childrenProps;

  const chapterData = useFragment(
    VideoChapterFragment,
    chapter,
  );

  const modules = chapterData.defaultModules;
  const filteredModules = modules.filter(module => !!module.latestVideoVersion.videos);
  const videos = filteredModules.flatMap(module => module.latestVideoVersion.videos);
  const videoModuleMap = filteredModules.reduce((map, module) => {
    const { latestVideoVersion, ...moduleWithoutLatestVersion } = module;
    module.latestVideoVersion.videos.forEach(video => {
      map[video.uid] = moduleWithoutLatestVersion;
    });
    return map;
  }, {});

  const renderVideoCard = (
    {
      videoData,
      progressValue,
      progressBarVariant,
      onClick,
    }) => {

    return (
      <VideoCard
        key={videoData.uid}
        videoData={videoData}
        progressValue={progressValue}
        progressBarVariant={progressBarVariant}
        onClick={onClick}
      />
    )
  }

  const videosElement = (
    videos.length === 0
      ? [
        <ModuleCardNoContent
          name={'No videos found'}
          key={'no-videos-found-card'}
          sectionColor={sectionColor}
          cardSx={{
            width: '276px',
          }}
        />
      ]
      :
      videos.map((video) => {
        return (
          <VideoDataWrapper
            key={video.uid}
            video={video}
            module={videoModuleMap[video.uid]}
            selectedVideo={selectedVideo}
            handleSelectVideoChanged={handleSelectVideoChanged}
            handleNextVideoChanged={handleNextVideoChanged}
            handlePreviousVideoChanged={handlePreviousVideoChanged}
            handleOpenDialog={handleOpenDialog}
            renderChildren={renderVideoCard}
          />
        );
      })
  );

  return (
    <Box sx={{
      mb: {xs: '13px'},
      '&:last-child': { mb: 0 },
    }}>

      {/* Chapter Name */}
      <Typography
        color={`${sectionColor}.contrastText`}
        fontWeight='bold'
        fontSize={{ xs: 14, md: 20 }}
        sx={{
          mb: { xs: '18px', md: '24px'},
        }}
      >
        {chapterData.name}
      </Typography>

      {/* Chapter Content */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}>
        {/* Videos */}
        <ScrollableXContainer
          containerSx={{
            pb: {xs: '20px'},
          }}
          navigationSx={{
            top: '-15%',
            right: '0',
          }}
          elements={videosElement}
        />
      </Box>
    </Box>
  );
};

VideosChapter.propTypes = {
  chapter: PropTypes.object.isRequired,
  sectionColor: PropTypes.string.isRequired,
  childrenProps: shape(videoParentPropTypes),
}

export default VideosChapter;
