import {round} from 'lodash';

export const generateBreakpointThemeProps = (theme, propName, breakpointsValues) => {
  return Object.entries(breakpointsValues).reduce(
    (styles, [breakpoint, breakpointValue]) => ({
      ...styles,
      [theme.breakpoints.up(breakpoint)]: {
        [propName]: breakpointValue,
      },
    }),
    {}
  )
};

const dummyScrollId = 'dummy-scroll';
let scrollBarLength;
export let isScrollDisabled = false;
export let targetSectionScrollTo = null;

export const setTargetSectionScrollTo = (value) => {
  targetSectionScrollTo = value;
}

export const createDummyScroll = () => {
  const dummyScroll = document.getElementById(dummyScrollId);
  scrollBarLength = window.innerWidth - document.body.clientWidth;
  if (!dummyScroll && scrollBarLength && scrollBarLength > 0) {
    const textarea = document.createElement('textarea');
    textarea.id = dummyScrollId;
    textarea.style.overflowY = 'scroll';
    textarea.style.overflowX = 'hidden';
    textarea.style.padding = '0px';
    textarea.style.width = `${scrollBarLength}px`;
    textarea.style.outline = 'none';
    textarea.style.resize = 'none';
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.right = '0';
    textarea.style.bottom = '0';
    textarea.style.border = '0';
    textarea.style.zIndex = '9999';
    document.body.appendChild(textarea);
  }
}

export const removeDummyScroll = () => {
  const dummyScroll = document.getElementById(dummyScrollId);
  if (dummyScroll) {
    dummyScroll.remove();
  }
}

let scrollDisabledTimer = null;

export const disableScrollBar = () => {
  if (isScrollDisabled === false) {
    let marginRight = 0;
    if (window.getComputedStyle) {
      const bodyStyle = window.getComputedStyle(document.body);
      if (bodyStyle) {
        marginRight = parseInt(bodyStyle.marginRight, 10);
      }
    }
    scrollBarLength = window.innerWidth - document.body.clientWidth;
    createDummyScroll();
    Object.assign(document.body.style, {
      overflow: 'hidden',
      marginRight: `${marginRight + scrollBarLength}px`
    });
    isScrollDisabled = true;
  }
  // It was found during the test
  // that if the user scrolls very fast and chaotically (not typical user behavior),
  // the window.scroll event might not finish as expected.
  // Therefore, we added the ability to cancel the disabled state of scrolling as a precaution.
  if (scrollDisabledTimer !== null) {
    clearTimeout(scrollDisabledTimer);
  }
  scrollDisabledTimer = setTimeout(function() {
    enableScrollBar()
  }, 1500);
}

export const enableScrollBar = () => {
  if (isScrollDisabled === true) {
    let marginRight = 0;
    if (window.getComputedStyle) {
      const bodyStyle = window.getComputedStyle(document.body);
      if (bodyStyle) {
        marginRight = parseInt(bodyStyle.marginRight, 10);
      }
    }
    removeDummyScroll()
    Object.assign(document.body.style, {
      overflow: 'auto',
      marginRight: `${marginRight - scrollBarLength}px`
    });
    isScrollDisabled = false;
  }
}

const SIZE_ABBR = ['B', 'KB', 'MB', 'GB'];

export const formatFileSize = (sizeBytes, precision) => {
  let size = sizeBytes;
  let i = 0;
  while (i < SIZE_ABBR.length) {
    if (size >= 1024) {
      size = round(size / 1024, precision);
      i += 1;
    }
    else {
      break;
    }
  }
  return `${size}${SIZE_ABBR[i]}`;
}
