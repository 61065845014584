/**
 * @generated SignedSource<<d3002a8428a2e138a0b07b52b421990a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Section",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Chapter",
        "kind": "LinkedField",
        "name": "chapters",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleDetailsDialogQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModuleDetailsDialogQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c52929627a897724a7045041c1878de3",
    "id": null,
    "metadata": {},
    "name": "ModuleDetailsDialogQuery",
    "operationKind": "query",
    "text": "query ModuleDetailsDialogQuery {\n  sections {\n    uid\n    name\n    chapters {\n      uid\n      name\n    }\n  }\n}\n"
  }
};
})();

node.hash = "013295bef370d099fc3d279edd661701";

export default node;
