import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {alpha, LinearProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PropTypes from 'prop-types';
import DefaultModuleCover from '/static/common/img/default_module_cover.png';

import {CURR_SITE, SITE_STANFORD} from '../../constants';
import {
  moduleBoxStaticStylesNs,
  moduleBoxStaticStylesStanford
} from '../../style/constants/modules';


const site = CURR_SITE.name;

export const moduleBoxStaticStyles = site === SITE_STANFORD
  ? moduleBoxStaticStylesStanford
  : moduleBoxStaticStylesNs;

const ModuleCard = (
  {
    moduleData,
    sectionColor,
    progressValue,
    progressBarVariant,
    isProgressLoading,
    onClick,
  }
) => {

  const imgUrl = moduleData?.coverImg?.url || DefaultModuleCover;
  const coverImgDesc = moduleData?.coverImgDesc ?? '';

  const moduleBoxDynamicStyles = site === SITE_STANFORD
    ? {
      background: `linear-gradient(to bottom, #000000B0, #000000B0), url(${imgUrl})`,
      '&:hover': {
        background: (theme) => {
          const color = alpha(theme.palette[sectionColor].dark, 0.8);
          return `linear-gradient(0deg, ${color}, ${color}), url(${imgUrl})`;
        },
        borderRadius: '0px',
        border: (theme) => {
          const color = theme.palette[sectionColor].contrastText;
          return `4px solid ${color}`
        },
      },
    }
    : {
      background: `url(${imgUrl})`,
    }

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      className='module-card'
      key={moduleData.uid}
      title={coverImgDesc}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundSize: 'cover',
      }}
      sx={{
        ...moduleBoxStaticStyles,
        ...moduleBoxDynamicStyles,
      }}
    >
      {/* Box with module name and optional progress icon */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        {/* Module Name */}
        <Typography
          className="module-name"
          align='center'
          fontWeight='bold'
          fontSize={{xs: 14, md: 16}}
          sx={{
            mx: {xs: '4px', md: '12px'},
            mt: {xs: '4px', md: '12px'},
            mb: {xs: '4px', md: '12px'},
          }}
        >
          {moduleData.name}
        </Typography>

        {/* Module Progress */}
        {isHovered && !isProgressLoading &&
          <>
            <Box
              direction='row'
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{
                mb: {xs: '4px', md: '12px'},
              }}
            >
              <PlayCircleOutlineIcon
                fontSize='large'
                sx={{
                  fontSize: {xs: '18px', sm: '20px', md: '22px'},
                  mr: {xs: 1},
                }}
              />
              <Typography
                align='center'
                fontWeight='bold'
                fontSize={{xs: 12, md: 14}}
              >
                {progressValue}% Completed
              </Typography>
            </Box>
          </>
        }
      </Box>

      {/* Module Progress Bar */}
      <LinearProgress
        aria-label='Module completion progress of user'
        sx={{
          width: '80%',
          marginTop: 'auto',
          position: 'absolute',
          bottom: 0,
          mb: {xs: 1, md: 2},
        }}
        variant={progressBarVariant}
        value={progressValue}
      />
    </Box>
  );
};

ModuleCard.propTypes = {
  moduleData: PropTypes.object.isRequired,
  sectionColor: PropTypes.string.isRequired,
  progressValue: PropTypes.number.isRequired,
  progressBarVariant: PropTypes.string.isRequired,
  isProgressLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ModuleCard;