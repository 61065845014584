import React from 'react';
import {sectionsColors} from './SectionsNavigation';
import PropTypes from 'prop-types';
import ProjectTabs from '../ui/ProjectTabs';
import ProjectTab from '../ui/ProjectTab';
import {sections} from '../../constants/pages/sections';
const SectionsTabs = (
  {
    sectionValue,
    handleSectionTabChanged,
    getSectionRank,
    isDataLoaded,
  }
) => {

  return (
    <ProjectTabs
      tabValue={sectionValue}
      changeTabValue={handleSectionTabChanged}
    >
      {
        sections.map((section) => {
          const sectionRank = getSectionRank(section);
          return (
            <ProjectTab
              key={sectionRank}
              value={sectionRank}
              isSelected={sectionRank === sectionValue}
              tabName={section.name}
              indicatorColor={`${sectionsColors[sectionRank]}.tabInactive`}
              selectedIndicatorColor={`${sectionsColors[sectionRank]}.contrastText`}
              tabBgColor={`${sectionsColors[sectionRank]}.main`}
              tabColor={`${sectionsColors[sectionRank]}.contrastText`}
              isDataLoaded={isDataLoaded}
            />
          )
        })
      }
    </ProjectTabs>
  )
};

SectionsTabs.propTypes = {
  sectionValue: PropTypes.number,
  handleSectionTabChanged: PropTypes.func.isRequired,
  getSectionRank: PropTypes.func.isRequired,
  isDataLoaded: PropTypes.bool,
};

SectionsTabs.defaultProps = {
  isDataLoaded: true,
}

export default SectionsTabs;