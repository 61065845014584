import {isEmpty} from 'lodash';
import React, { useState } from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay';
import { useParams, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {postLoginPage} from '@/utils/auth';
import {usePasswordRules} from '../hooks/usePasswordRules';


const InvitedUserInfoQuery = graphql`
  query ConfirmInvitation_InvitedUserInfoQuery(
    $uid: String!,
    $token: String!,
  ) {
    invitedUser(
      userUid64b: $uid,
      token: $token,
    ) {
        user {
           firstName
           lastName
           email
           groups {
             name
           }
        }
        msg
    }
  }
`;

const ConfirmInvitationMutation = graphql`
  mutation ConfirmInvitationMutation(
    $uid: String!,
    $token: String!,
    $password: String!,
    $password2: String!
  ) {
    confirmInvitation(
      userUid64b: $uid,
      token: $token,
      password: $password,
      password2: $password2,
    ) {
         status
         msg
      }
  }
`;

const ConfirmInvitation = () => {
  const { uid, token } = useParams();
  const { invitedUser } = useLazyLoadQuery(InvitedUserInfoQuery, {uid, token});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    password2: '',
  });
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [commitMutation, isMutationInFlight] = useMutation(ConfirmInvitationMutation);

  const setFormFieldError = (field, errMsg) => {
    setFieldErrors((fe) => ({
      ...fe,
        [field]: errMsg,
    }))
  }

  const checkFormField = (field, errMsg) => {
    let valid = true;
    const val = formData[field];
    if (isEmpty(val)) {
      setFormFieldError(field, errMsg);
      valid = false;
    }
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldErrors({});
    let valid = true;
    valid = checkFormField('password', 'Please enter a password') && valid;
    valid = checkFormField('password2', 'Please confirm the password') && valid;
    if (!valid) {
      return;
    }

    setError(null);

    commitMutation({
      variables: {
        uid: uid,
        token: token,
        password: formData.password,
        password2: formData.password2,
      },
      onCompleted: (data) => {
        console.log('In ConfirmInvite::onCompleted:', data);
        const res = data.confirmInvitation;
        if (res?.status !== true) {
          setError(res.msg);
        }
        else {
          const dest = postLoginPage(invitedUser?.user);
          navigate(dest, {replace: true});
        }
      },
      onError: (error) => {
        const err = error.source.errors[0].message;
        setError(err);
      }
    })
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError('')
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [
    allConditionsMet,
    renderRulesMessages,
  ] = usePasswordRules(formData.password, formData.password2);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {invitedUser?.user === null
          ?
          <Typography color='error' variant='body2' sx={{ mb: 2 }}>
            {invitedUser?.msg ?? 'Error processing invitation link'}
          </Typography>
          :
          <>
            <Avatar
              sx={{
                backgroundColor: 'primary.avatar',
                marginBottom: '15px'
              }}
            >
              {(invitedUser?.user?.firstName?.[0] ?? '').toUpperCase()}
              {(invitedUser?.user?.lastName?.[0] ?? '').toUpperCase()}
            </Avatar>
            <Typography variant='body1' style={{textAlign: 'center'}}>
              Please create your account
            </Typography>

            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                disabled
                fullWidth
                name='email'
                label='E-mail'
                type='email'
                id='email'
                value={invitedUser?.user?.email ?? ''}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                value={formData.password}
                error={!isEmpty(fieldErrors?.password)}
                helperText={fieldErrors?.password}
                onChange={handleInputChange}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password2'
                label='Confirm password'
                type='password'
                id='password2'
                value={formData.password2}
                error={!isEmpty(fieldErrors?.password2)}
                helperText={fieldErrors?.password2}
                onChange={handleInputChange}
              />
              {renderRulesMessages()}
              {error && (
                <Typography color='error' variant='body2' sx={{ mb: 2 }}>
                  {error}
                </Typography>
              )}
              <Button
                aria-label='Complete Registration'
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                disabled={isMutationInFlight || !allConditionsMet}
              >
                {isMutationInFlight ? 'Loading...' : 'Complete Registration'}
              </Button>
            </Box>
          </>
        }
      </Box>
    </Container>
  );
};

export default ConfirmInvitation;
