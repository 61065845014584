import React from 'react';
import {isEmpty, isEqual} from 'lodash';
import {graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import ModuleDetailsDialog from './ModuleDetailsDialog';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import {useTags} from '../hooks/useTags';


const UpdateModuleMutation = graphql`
  mutation UpdateModuleDialogMutation(
    $id: GlobalID!,
    $name: String = null,
    $description: String = null,
    $status: String = null,
    $inSelfStudy: Boolean = null,
    $chapterUid: Uid = null,
    $cmi5Zip: String = null,
    $coverImg: String = null,
    $coverImgDesc: String = null,
    $tags: [String!] = null,
    $accessPlanUids: [Uid!] = null,
  ) {
    updateModule(
      data: {
        id: $id,
        name: $name,
        description: $description,
        status: $status,
        inSelfStudy: $inSelfStudy,
        chapterUid: $chapterUid,
        cmi5Zip: $cmi5Zip,
        tags: $tags,
        coverImg: $coverImg,
        coverImgDesc: $coverImgDesc,
        accessPlanUids: $accessPlanUids
      }
    ) {
      ... on Module {
        id
        ...ModulesTable_ModuleFragment
      }
    }
  }
`;


export default function UpdateModuleDialog(props) {
  const {
    module,
    onClose
  } = props;

  const [commitMutation, isMutationInFlight] = useMutation(UpdateModuleMutation);
  const displayMessage = React.useContext(DisplayMessageContext);
  const {updateTags} = useTags();

  const mutate = (data) => {
    const variables = {
      id: module.id,
      uid: module.uid,
    };
    ['name', 'description', 'inSelfStudy', 'status', 'coverImgDesc'].forEach(
      (field) => {
        if (!isEqual(data[field], module[field])) {
          variables[field] = data[field];
        }
      }
    );
    const tags = module.tags.map((t) => t.text);
    if (!isEqual(data?.tags, tags)) {
      variables.tags = data.tags;
    }
    const accessPlanUids = module.accessPlans.map((ap) => ap.uid);
    if (!isEqual(data?.accessPlanUids, accessPlanUids)) {
      variables.accessPlanUids = data.accessPlanUids;
    }
    if (!isEqual(data?.chapterUid, module.defaultChapter?.uid)) {
      variables.chapterUid = data.chapterUid;
    }
    if (!isEqual(data?.coverImg?.name, module?.coverImg?.name)) {
      variables.coverImg = data.coverImg.name;
    }
    if (!isEmpty(data?.cmi5Zip?.name)) {
      variables.cmi5Zip = data.cmi5Zip.name;
    }
    console.log('In UpdateModule::mutate. vars=', JSON.stringify(variables));
    commitMutation({
      variables,
      onCompleted: (data) => {
        displayMessage({info: 'Module has been saved.'});
        onClose(data.updateModule);
      },
      onError: (errData) => {
        console.error("Error performing mutation updateModule:", errData);
        const err = errData.source?.errors?.[0]?.message
          ?? 'Error updating module. Please try again later.'
        displayMessage({error: err});
      },
      updater: (store, data) => {
        console.log('UpdateModule::CommitMutation::updater: data=', data);
        const updatedModule = store.getRootField('updateModule');
        const updatedModuleTags = updatedModule.getLinkedRecords('tags');
        updateTags(store, updatedModuleTags);
      },
    })
  }

  return (
    <ModuleDetailsDialog
      title='Edit Module'
      isMutationInProgress={isMutationInFlight}
      mutate={mutate}
      {...props}
    />
  );
}

UpdateModuleDialog.propTypes = {
  isOpen: PropTypes.bool,
  module: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

UpdateModuleDialog.defaultProps = {
  isOpen: false,
}
