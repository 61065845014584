/**
 * @generated SignedSource<<f084c8e94085329cdd0358001046c202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accessPlanUids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chapterUid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cmi5Zip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverImg"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverImgDesc"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inSelfStudy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v11 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accessPlanUids",
        "variableName": "accessPlanUids"
      },
      {
        "kind": "Variable",
        "name": "chapterUid",
        "variableName": "chapterUid"
      },
      {
        "kind": "Variable",
        "name": "cmi5Zip",
        "variableName": "cmi5Zip"
      },
      {
        "kind": "Variable",
        "name": "coverImg",
        "variableName": "coverImg"
      },
      {
        "kind": "Variable",
        "name": "coverImgDesc",
        "variableName": "coverImgDesc"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "inSelfStudy",
        "variableName": "inSelfStudy"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "tags",
        "variableName": "tags"
      }
    ],
    "kind": "ObjectValue",
    "name": "data"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalFileName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateModuleDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "updateModule",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModulesTable_ModuleFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v10/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateModuleDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "updateModule",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inSelfStudy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Chapter",
            "kind": "LinkedField",
            "name": "defaultChapter",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Section",
                "kind": "LinkedField",
                "name": "section",
                "plural": false,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "coverImg",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImgDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedTs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CMI5Package",
                "kind": "LinkedField",
                "name": "cmi5",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadedFile",
                    "kind": "LinkedField",
                    "name": "zipPath",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v14/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessPlan",
            "kind": "LinkedField",
            "name": "accessPlans",
            "plural": true,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76f25ea8b705c8d6b87ca5c388c1fde1",
    "id": null,
    "metadata": {},
    "name": "UpdateModuleDialogMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateModuleDialogMutation(\n  $id: GlobalID!\n  $name: String = null\n  $description: String = null\n  $status: String = null\n  $inSelfStudy: Boolean = null\n  $chapterUid: Uid = null\n  $cmi5Zip: String = null\n  $coverImg: String = null\n  $coverImgDesc: String = null\n  $tags: [String!] = null\n  $accessPlanUids: [Uid!] = null\n) {\n  updateModule(data: {id: $id, name: $name, description: $description, status: $status, inSelfStudy: $inSelfStudy, chapterUid: $chapterUid, cmi5Zip: $cmi5Zip, tags: $tags, coverImg: $coverImg, coverImgDesc: $coverImgDesc, accessPlanUids: $accessPlanUids}) {\n    id\n    ...ModulesTable_ModuleFragment\n  }\n}\n\nfragment ModulesTable_ModuleFragment on Module {\n  uid\n  name\n  description\n  inSelfStudy\n  defaultChapter {\n    uid\n    name\n    section {\n      uid\n      name\n    }\n  }\n  coverImg {\n    originalFileName\n    name\n    url\n    size\n  }\n  coverImgDesc\n  lastUpdatedTs\n  versions {\n    uid\n    version\n    status\n    author {\n      uid\n      firstName\n      lastName\n      email\n    }\n    cmi5 {\n      uid\n      status\n      zipPath {\n        originalFileName\n        name\n        size\n      }\n    }\n    module {\n      uid\n      name\n    }\n  }\n  accessPlans {\n    uid\n    name\n  }\n  tags {\n    id\n    text\n  }\n}\n"
  }
};
})();

node.hash = "9ed7e68579884c3120e2754771451869";

export default node;
