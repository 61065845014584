import React from 'react';
import Box from '@mui/material/Box';
import {sections} from '../../../constants/pages/sections';
import {pageContentPadding} from '../../../style/constants/self-guided';
import Skeleton from '@mui/material/Skeleton';
import {useMediaQuery} from '@mui/material';
import Typography from '@mui/material/Typography';
import {sectionsColors} from '../../content/SectionsNavigation';
import {sectionDescriptions} from '../../content/Section';
import {moduleBoxStaticStylesStanford} from '../../../style/constants/modules';
import PropTypes from 'prop-types';

const ModulesSectionsListSkeleton = (props) => {
  const {
    isVideo,
  } = props

  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const videoCardStyle = {
    flexShrink: '0',
    width: '276px',
    mr: '32px',
    height: {
      xs: '143px', md: '220px',
    }
  }
  const cardStyle = isVideo ? videoCardStyle : moduleBoxStaticStylesStanford;

  return (
    <Box>
      {sections.map((section, index) => (
        <Box key={index}>
          {/* Section Header Skeleton */}
          <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            alignItems: {xs: 'flex-start', md: 'center'},
            px: {...pageContentPadding},
            py: {xs: '8px', md: '12px'},
            backgroundColor: `${sectionsColors[section.frontendId]}.header`,
          }}>
            {
              isMd && (
                <Typography sx={{
                  color: `${sectionsColors[section.frontendId]}.contrastText`,
                  fontSize: {xs: 19, md: 28},
                  mr: '24px',
                  mb: {xs: '8px', md: '0px'},
                }}
                >
                  {section.name}
                </Typography>
              )
            }

            <Typography sx={{
              color: `${sectionsColors[section.frontendId]}.contrastText`,
              fontSize: {xs: 14},
            }}
            >
              {sectionDescriptions[section.frontendId]}
            </Typography>
          </Box>

          {/* All Chapters Skeleton */}
          <Box
            sx={{
              px: {...pageContentPadding},
              pt: {xs: '18px', md: '24px'},
              pb: {xs: '20px', md: '40px'},
              backgroundColor: `${sectionsColors[section.frontendId]}.main`,
              minHeight: '250px',
            }}
          >
            {/* Section Chapters Skeleton */}
            {[...Array(section.chaptersAmount)].map((_, chapterIndex) => (
              <Box
                key={chapterIndex}
                sx={{
                  mb: {xs: '13px'},
                  '&:last-child': {mb: 0},
                }}
              >

                {/* Chapter Name */}
                <Skeleton
                  variant="text"
                  fontSize={{
                    xs: 14, md: 20
                  }}
                  sx={{
                    width: '200px',
                    mb: {xs: '13px'},
                    fontSize: {
                      xs: '20px', md: '24px',
                    },
                  }}
                />

                {/* Chapter Content */}
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  {
                    [...Array(10)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant='rectangular'
                        sx={{ ...cardStyle }}
                      />
                    ))
                  }
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

ModulesSectionsListSkeleton.propTypes = {
  isVideo: PropTypes.bool,
}

ModulesSectionsListSkeleton.defaultProps = {
  isVideo: false,
}

export default ModulesSectionsListSkeleton;
