import {concat, isArray, sortBy} from 'lodash';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {iconCheckBox, iconCheckBoxChecked} from './icons';
import {MAX_TAG_LENGTH} from '@/constants';
import {useTags} from '../hooks/useTags';


const TagSelect = (props) => {
  const {
    checkboxes,
    error,
    helperText,
    label,
    disableNewOptionCreation,
    multiple,
    name,
    refCallback,
    value,
    onChange,
    onBlur,
    ...autoselectFieldProps
  } = props;
  const {tags} = useTags();
  const [tagsData, setTagsData] = React.useState(tags);
  const sortedTags = React.useMemo(() => {
    return sortBy(tagsData, (t) => t.text.toLowerCase());
  }, [tagsData]);

  return (
    <React.Suspense fallback={<Skeleton variant="rounded" />}>
      <Autocomplete
        id="ac-topics"
        ref={refCallback}
        multiple={multiple}
        freeSolo={!disableNewOptionCreation}
        fullWidth
        name={name}
        options={sortedTags}
        disableCloseOnSelect={multiple}
        size='small'
        value={value}
        getOptionLabel={(option) => option.text}
        isOptionEqualToValue={(option, value) => {
          const opt_text = option?.text || (typeof option === 'string' ? option : '');
          const val_text = value?.text || (typeof value === 'string' ? value : '');
          return opt_text.toLowerCase() === val_text.toLowerCase();
        }}
        renderOption={checkboxes
          ? ((props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={iconCheckBox}
                  checkedIcon={iconCheckBoxChecked}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.text}
              </li>
            ))
          : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? 'Tags'}
            error={error}
            helperText={helperText ?? ' '}
          />
        )}
        onChange={(event, newValue) => {
          const valuesArray = isArray(newValue) ? newValue : [newValue];
          const selTags = valuesArray.map((item) => {
            let newTag = null;
            if (typeof item === 'string') {
              newTag = { text: item.substring(0, MAX_TAG_LENGTH) };
            } else if (item && item.inputValue) {
              // Create a new value from the user input
              newTag = {
                text: item.inputValue.substring(0, MAX_TAG_LENGTH),
                isNew: true,
              };
            }
            if (newTag !== null) {
              const tag = tagsData.find(
                (elem) => elem.text.toLowerCase() === newTag.text.toLowerCase()
              );
              if (tag === undefined) {
                setTagsData((prevTagsData) => concat(prevTagsData, newTag));
              }
              return newTag;
            }
            return item;
          });
          onChange(selTags);
        }}
        onBlur={onBlur}
        {...autoselectFieldProps}
      />
    </React.Suspense>
  )
}

TagSelect.propTypes = {
  checkboxes: PropTypes.bool,
  disableNewOptionCreation: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  refCallback: PropTypes.func,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  autoselectFieldProps: PropTypes.object,
}

TagSelect.defaultProps = {
  checkboxes: false,
  disableNewOptionCreation: false,
  error: false,
  helperText: null,
  label: null,
  multiple: false,
  value: null,
}

export default TagSelect;
