import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

const FeaturedTopicsChipsSkeleton = () => {
  return (
    <Grid container spacing={1} sx={{
      mb: '32px',
    }}>
      {
        indexes.map(index => (
          <Grid item key={index}>
            <Skeleton
              variant='rectangular'
              height={32}
              width={80}
              sx={{
                borderRadius: 16,
              }}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default FeaturedTopicsChipsSkeleton;