import * as yup from 'yup';
import {FEATURED_TOPIC_HDR} from '../../constants';

export const validationSchema = yup.object({
  topics: yup.array().of(
    yup.object({
      name: yup.string().required(`${FEATURED_TOPIC_HDR} is required`),
      synopsis: yup.string().required('Synopsis is required'),
      modules: yup.array().min(1, 'At least one module is required')
    })
  )
  .min(1, `At least one ${FEATURED_TOPIC_HDR} is required`)
  .test({
    name: 'no-topic-duplicates',
    test(value, ctx) {
      const names = {}
      value.forEach((topic) => {
        const key = (topic.name || '').trim().toLowerCase();
        names[key] = (names?.[key] ?? 0) + 1;
      });
      for (let i=0; i < value.length; ++i) {
        const key = (value[i].name || '').trim().toLowerCase();
        if (names[key] > 1) {
          return ctx.createError({message: `Duplicate ${FEATURED_TOPIC_HDR} found: ${value[i].name}`});
        }
      }
      return true;
    }}
  ),
});

export const initialValues = {
  topics: [{
    name: null,
    synopsis: '',
    modules: [],
  }],
}
