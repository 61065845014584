import { Outlet, Navigate } from 'react-router-dom'
import PropTypes from "prop-types";


const PrivateRoutes = ({user}) => {
  return(
    user ? <Outlet/> : <Navigate to="/login/"/>
  )
}

PrivateRoutes.propTypes = {
  user: PropTypes.object,
}

PrivateRoutes.defaultProps = {
  user: null,
}

export default PrivateRoutes;
