import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {iconCheckBox, iconCheckBoxChecked} from './icons';

const AccessPlanFragment = graphql`
  fragment AccessPlanSelect_accessPlan on AccessPlan {
    uid
    name
  }
`;

const DataQuery = graphql`
  query AccessPlanSelectQuery {
    accessPlans(filters: {isPublic: true, site: {current: true}}) {
      ...AccessPlanSelect_accessPlan @relay(mask: false)
    }
  }
`;



const AccessPlanSelect = (props) => {
  const {
    checkboxes,
    error,
    helperText,
    label,
    multiple,
    name,
    refCallback,
    value,
    onChange,
    onBlur,
    ...autoselectFieldProps
  } = props;
  const data = useLazyLoadQuery(DataQuery, {});

  return (
    <React.Suspense fallback={<Skeleton variant="rounded" />}>
      <Autocomplete
        ref={refCallback}
        blurOnSelect={!multiple}
        multiple={multiple}
        id="ac-access-plan"
        name={name}
        options={data.accessPlans}
        disableCloseOnSelect={multiple}
        value={value}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option?.name === value?.name;
        }}
        renderOption={checkboxes
          ? ((props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={iconCheckBox}
                  checkedIcon={iconCheckBoxChecked}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            ))
          : undefined
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? 'Groups with Access'}
            error={error}
            helperText={helperText ?? ' '}
          />
        )}
        size='small'
        onChange={onChange}
        onBlur={onBlur}
        {...autoselectFieldProps}
      />
    </React.Suspense>
  )
}

AccessPlanSelect.propTypes = {
  checkboxes: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  refCallback: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  autoselectFieldProps: PropTypes.object,
}

AccessPlanSelect.defaultProps = {
  checkboxes: false,
  error: false,
  helperText: null,
  label: null,
  multiple: false,
  value: null,
}

export default AccessPlanSelect;
