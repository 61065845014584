import {isEmpty, trim} from 'lodash';
import React, { useState } from "react";
import {graphql, useMutation} from "react-relay";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";



const RequestPasswordResetMutation = graphql`
  mutation RequestPasswordResetMutation(
    $email: String!,
  ) {
    requestPasswordReset(
      email: $email,
    )
  }
`;


const RequestPasswordReset = () => {
  const [fieldErrors, setFieldErrors] = useState({});
  const [email, setEmail] = useState(null);
  const [linkSent, setLinkSent] = useState(false);
  const [commitMutation, isMutationInFlight] = useMutation(RequestPasswordResetMutation);

  const setFormFieldError = (field, errMsg) => {
    setFieldErrors((fe) => ({
      ...fe,
        [field]: errMsg,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldErrors({});
    if (isEmpty(trim(email))) {
      setFormFieldError('email', 'Please enter an email address');
      return;
    }

    commitMutation({
      variables: {
        email,
      },
      onCompleted: (data) => {
        console.log('In RequestPasswordReset::onCompleted:', data);
        setLinkSent(true);
      },
      onError: (error) => {
        console.log('In RequestPasswordReset::onError:', error);
        setLinkSent(true);
      }
    })
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {linkSent ?
          <Typography variant='body1' style={{textAlign: 'center'}}>
            Password reset link has been sent to specified email address.
            Please check your email and follow the instructions.
          </Typography>
          :
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography variant='body1'>
              Please enter your e-mail address below. A password reset link will be sent to your email.
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              name='email'
              label='Email'
              type='email'
              id='email'
              value={email}
              error={!isEmpty(fieldErrors?.email)}
              helperText={fieldErrors?.email ?? ' '}
              onChange={handleInputChange}
            />
            <Button
              aria-label='Reset Password'
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={isMutationInFlight}
            >
              {isMutationInFlight ? 'Loading...' : 'Reset Password'}
            </Button>
          </Box>
        }
      </Box>
    </Container>
  );
};

export default RequestPasswordReset;
