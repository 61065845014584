import {concat, take, takeRight, without} from 'lodash';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {createStyles, makeStyles} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ModuleSelectionDialog from '../content/ModuleSelectionDialog';
import TextFieldWithLengthDisplay from '../content/TextFieldWithLengthDisplay';
import PropTypes from 'prop-types';
import {FEATURED_TOPIC_HDR} from '@/constants';
import TagSelect from '../common/TagSelect';


export const useAccordionStyles = makeStyles(() => createStyles({
  accordionSummaryContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const FeaturedTopicAccordion = (props) => {
  const {
    data,
    index,
    expanded,
    formik,
    showRemoveButton,
    onChangeField,
    onExpanded,
    onRemoveTopic,
  } = props;
  const [isModuleDialogOpen, setIsModuleDialogOpen] = React.useState(false);
  const [draggingModule, setDraggingModule] = React.useState(null);
  const topicName = data?.name ?? '';
  const synopsis = data?.synopsis ?? '';
  const modules = data?.modules ?? [];
  const accordionSummaryContentClasses = useAccordionStyles();

  const onAddModule = (evt) => {
    setIsModuleDialogOpen(true);
  }
  const onRemoveModule = (uid) => {
    onChangeField('modules', modules.filter((m) => m.uid !== uid));
  }
  const onModuleSelectionDialogClose = (selectedModules) => {
    setIsModuleDialogOpen(false);
    if (selectedModules === null) {
      return;
    }
    onChangeField('modules', selectedModules.map((mv)=> mv.module));
  }
  const handleRemoveTopic = (evt) => {
    evt.preventDefault();
    onRemoveTopic(index);
  }
  const handleAccordionExpanded = (evt, isExpanded) => {
    if (!evt.isDefaultPrevented()) {
      onExpanded(index, isExpanded);
    }
  }

  const handleDragStart = (evt, idx) => {
    evt.target.style.cursor = 'grabbing'
    setDraggingModule(idx);
  }
  const handleDragEnd = (evt) => {
    evt.target.style.boxShadow = 'none';
    evt.target.style.cursor = 'auto'
  }
  const handleDrop = (evt, idx) => {
    evt.preventDefault();
    if (idx === draggingModule) return;
    const moduleToDrop = modules[draggingModule];
    const withoutDropped = without(modules, moduleToDrop);
    onChangeField('modules',
      concat(
        take(withoutDropped, idx),
        moduleToDrop,
        takeRight(withoutDropped, withoutDropped.length-idx)
      )
    )

  }
  const handleDragOver = (evt) => {
    evt.preventDefault();
    if (evt.target.className === 'module') {
      evt.target.style.boxShadow = '0 4px 3px grey';
    }
  }
  const handleDragLeave = (evt) => {
    evt.target.style.boxShadow = 'none';
  }


  return (
    <>
      <Box>
        <Accordion
          expanded={expanded}
          onChange={handleAccordionExpanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`topic${index}-content`}
            id={`topic${index}-header`}
            classes={{
              'content': accordionSummaryContentClasses.accordionSummaryContent,
            }}
            sx={{
              backgroundColor: '#ECEFF1'
            }}
          >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <DragIndicatorIcon  sx={{ fontSize: 15, marginRight: '10px' }} />
              <Typography>{FEATURED_TOPIC_HDR} {index+1}</Typography>
            </Box>
            {showRemoveButton &&
              <Tooltip
                title={showRemoveButton ? "Remove" : "Can't remove this topic"}
                arrow
              >
                <IconButton
                  aria-label='Remove Topic'
                  disabled={!showRemoveButton}
                  onClick={handleRemoveTopic}
                  sx={{my: '-5px'}}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            }
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction='column'
              gap={1}
            >
              <TagSelect
                id={`topic${index}-name`}
                name={`topics[${index}].name`}
                label={FEATURED_TOPIC_HDR}
                disableNewOptionCreation
                error={formik.touched?.topics?.[index]?.name && Boolean(formik.errors?.topics?.[index]?.name)}
                helperText={(formik.touched?.topics?.[index]?.name && formik.errors?.topics?.[index]?.name) || ' '}
                value={{text: topicName}}
                sx={{mt: 2}}
                onBlur={(evt) => formik.setFieldTouched(`topics[${index}].name`, true, true)}
                onChange={(selTags) => {
                  const selTag = selTags[0]?.text ?? '';
                  onChangeField('name', selTag);
                }}
              />
              <TextFieldWithLengthDisplay
                maxLength={250}
                id="synopsis"
                name={`topics[${index}].synopsis`}
                error={
                  formik.touched?.topics?.[index]?.synopsis &&
                  Boolean(formik.errors?.topics?.[index]?.synopsis)
                }
                helperText={(
                    formik.touched?.topics?.[index]?.synopsis &&
                    formik.errors?.topics?.[index]?.synopsis
                  ) || ' '
                }
                fullWidth
                label="Synopsis"
                multiline
                rows={2}
                size='small'
                value={synopsis}
                sx={{mt: 2}}
                onChange={(evt) => {
                  onChangeField('synopsis', evt.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <Box className='container-modules' sx={{mt: 3}}>
                {modules.map((module, idx) => (
                  <Paper
                    key={`module-${idx}`}
                    draggable
                    elevation={0}
                    className='module'
                    sx={{backgroundColor: '#ECEFF1', py: '4px', my: '4px'}}
                    onDragStart={(evt) => handleDragStart(evt, idx)}
                    onDragEnd={(evt) => handleDragEnd(evt, idx)}
                    onDrop={(evt) => handleDrop(evt, idx)}
                    onDragOver={(evt) => handleDragOver(evt, idx)}
                    onDragLeave={(evt) => handleDragLeave(evt, idx)}
                  >
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                    >
                      <Stack
                        direction='row'
                        gap={1}
                        alignItems='center'
                        className='moduleTitle'
                        sx={{ml: 2}}
                      >
                        <DragIndicatorIcon sx={{ fontSize: 15 }} />
                        <Typography>
                          {module?.name ?? (module?.module?.name ?? `Module ${idx}`)}
                        </Typography>
                      </Stack>
                      <IconButton
                        aria-label='Remove Module'
                        onClick={(evt) => onRemoveModule(module.uid)}
                      >
                        <Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>
                      </IconButton>
                    </Stack>
                  </Paper>
                ))}
                {
                  formik.touched?.topics?.[index]?.modules &&
                  Boolean(formik.errors?.topics?.[index]?.modules) &&
                  <Typography color='error' variant='body2'>
                    {formik.errors?.topics?.[index]?.modules}
                  </Typography>
                 }
              </Box>
              <Button
                aria-label='Add module'
                variant='text'
                onClick={onAddModule}
                sx={{alignSelf: 'start'}}
              >
                + Add Module
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      {isModuleDialogOpen &&
        <ModuleSelectionDialog
          isOpen={isModuleDialogOpen}
          initialSelection={modules.map((m) => m.uid)}
          initialSearchText={topicName}
          title='Modules'
          isRowSelectable={
            (row) => (row?.status === 'published' &&
              row?.tags.find((tag) => tag.toLowerCase() === topicName.toLowerCase()))
          }
          onClose={onModuleSelectionDialogClose}
        />
      }
    </>
  )
}

FeaturedTopicAccordion.propTypes = {
  data: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  index: PropTypes.number.isRequired,
  formik: PropTypes.object.isRequired,
  showRemoveButton: PropTypes.bool,
  onChangeField: PropTypes.func.isRequired,
  onExpanded: PropTypes.func.isRequired,
  onRemoveTopic: PropTypes.func.isRequired,
}

FeaturedTopicAccordion.defaultProps = {
  expanded: false,
  showRemoveButton: false,
}

export default FeaturedTopicAccordion;
