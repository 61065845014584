export const sectionsColors = {
  0: 'sectionFirstOption',
  1: 'sectionSecondOption',
  2: 'sectionThirdOption',
}

export const sectionsColorsByName = {
  'discover': 'sectionFirstOption',
  'develop': 'sectionSecondOption',
  'deliver': 'sectionThirdOption',
}
