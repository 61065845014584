import React from 'react';
import {graphql, useMutation} from 'react-relay';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import TextFieldWithLengthDisplay from '../content/TextFieldWithLengthDisplay';
import {changePasswordSchema} from './validation';
import {useFormik} from 'formik';


const ChangePasswordMutation = graphql`
  mutation ChangePasswordDialogMutation(
    $currentPassword: String!,
    $password: String!,
    $password2: String!
  ) {
    changePassword(
      currentPassword: $currentPassword,
      password: $password,
      password2: $password2,
    ) {
         status
         msg
      }
  }
`;

export default function ChangePasswordDialog(props) {
  const {
    isOpen,
    displayMessage,
    onClose,
  } = props;

  const [commitMutation, isMutationInFlight] = useMutation(ChangePasswordMutation);
  const [error, setError] = React.useState(null);

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      handleSubmit(values);
      formik.setTouched({});
    },
  });

  const onSubmit = (evt) => {
    formik.handleSubmit();
    formik.setSubmitting(false);
  }

  const handleSubmit = (formData) => {
    commitMutation({
      variables: {
        currentPassword: formData.currentPassword,
        password: formData.newPassword,
        password2: formData.newPassword2,
      },
      onCompleted: (data) => {
        console.log('In ChangePassword::onCompleted:', data);
        const res = data.changePassword;
        if (res.status) {
          displayMessage({info: res.msg});
          onClose();
        }
        else {
          setError(res.msg);
        }
      },
      onError: (error) => {
        console.log('In ChangePassword::onError:', error);
        const err = error.source.errors[0].message;
        setError(err);
      }
    })

  }
  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent sx={{p: 2}}>
        <Stack direction='column' gap={2} alignContent='start' width='100%'>
          <TextFieldWithLengthDisplay
            type="password"
            maxLength={50}
            error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
            helperText={(formik.touched.currentPassword && formik.errors.currentPassword) ?? ' '}
            fullWidth
            label="Current Password"
            margin="dense"
            variant="outlined"
            size='small'
            {...formik.getFieldProps('currentPassword')}
          />
          <TextFieldWithLengthDisplay
            type="password"
            maxLength={50}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={(formik.touched.newPassword && formik.errors.newPassword) ?? ' '}
            fullWidth
            label="New Password"
            margin="dense"
            variant="outlined"
            size='small'
            {...formik.getFieldProps('newPassword')}
          />
          <TextFieldWithLengthDisplay
            type="password"
            maxLength={50}
            error={formik.touched.newPassword2 && Boolean(formik.errors.newPassword2)}
            helperText={(formik.touched.newPassword2 && formik.errors.newPassword2) ?? ' '}
            fullWidth
            label="Confirm Password"
            margin="dense"
            variant="outlined"
            size='small'
            {...formik.getFieldProps('newPassword2')}
          />
          <Typography color='error' variant='body2' sx={{ mb: 2 }}>
            {error ?? ' '}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label='Cancel'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          aria-label='Submit'
          disabled={formik.isSubmitting || isMutationInFlight}
          variant='contained'
          onClick={onSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChangePasswordDialog.propTypes = {
  isOpen: PropTypes.bool,
  displayMessage: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

ChangePasswordDialog.defaultProps = {
  isOpen: false,
}
