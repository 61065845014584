import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import {CHAPTER, MODULE} from './constants';
import {getButtonPerProgress, getStatusPerProgress} from './utils';


const getBackgroundColorPerProgress = (progress) => (
  progress === 100 ? '#F7F7F7' : 'white'
)

const isModulePublished = (mv) => mv?.status === 'published';

const ChapterAccordion = (props) => {
  const {
    chapter,
    modulesProgress,
    onLaunchChapter,
    onLaunchModule,
  } = props;
  const [expanded, setExpanded] = React.useState(true);
  const getModuleVersionProgress = React.useCallback((mvUid) => {
    const progressInfo = modulesProgress.find((mvp) => mvp.moduleVersion.uid === mvUid);
    return progressInfo !== undefined ? progressInfo.progressPercent : undefined;
  }, [modulesProgress])
  const chapterProgress = React.useMemo(() => {
      const modules = (chapter?.modules ?? []).filter(isModulePublished);
      if ((modules.length) === 0) return 0;
      let sum = 0;
      modules.forEach((mv) => {
        const mvProgress = getModuleVersionProgress(mv.uid);
        sum += mvProgress ?? 0;
      })
      return Math.floor(sum / modules.length);

    }, [chapter, getModuleVersionProgress]
  )

  const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));


  const handleChapterActionButtonClick = (evt) => {
    evt.preventDefault();
    onLaunchChapter(chapter.uid);
  }
  const handleModuleActionButtonClick = (evt, moduleVersionUid) => {
    evt.preventDefault();
    onLaunchModule(moduleVersionUid);
  }

  const handleAccordionExpanded = (evt, isExpanded) => {
    if (evt.defaultPrevented) return;
    setExpanded(isExpanded);
  }

  return (
    <>
      <Stack direction='column' spacing={1} sx={{border: '1px solid #585858'}}>
        <Accordion
          expanded={expanded}
          onChange={handleAccordionExpanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`chapter-content`}
            sx={{
              backgroundColor: `${getBackgroundColorPerProgress(chapterProgress)}`
            }}
          >
            <Stack direction='column' spacing={1}>
              <Typography variant='h6'>{chapter.name}</Typography>
              <Stack direction='row' spacing={3} justifyContent='center' alignSelf='start' alignItems='center'>
                {getButtonPerProgress(chapterProgress,
                  isSm ? CHAPTER : '',
                  {
                    onClick: (evt) => handleChapterActionButtonClick(evt)
                  }
                )}
                {getStatusPerProgress(chapterProgress)}
              </Stack>
              <Typography variant='body2'>{chapter.description}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: `${getBackgroundColorPerProgress(chapterProgress)}`
            }}
          >
            <Stack direction='column' spacing={0}>
              {chapter.modules
                .filter(isModulePublished)
                .map((mv, idx) => {
                  const moduleProgress = getModuleVersionProgress(mv.uid);
                  return (
                    <Grid
                      container
                      key={mv.module.name}
                      sx={{
                        backgroundColor: `${getBackgroundColorPerProgress(moduleProgress)}`,
                        borderBottom: '1px solid #e0e0e0',
                        pt: 3,
                        pb: 2,
                        px: {xs: 0.5, sm: 1, md: 2},
                      }}
                    >
                      <Grid
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        sx={{px: 0}}
                      >
                        <Typography variant='body2'>{idx+1}. {mv.module.name}</Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        sx={{px: 0}}
                      >
                        <Stack direction='row' spacing={1}>
                          {getStatusPerProgress(moduleProgress)}
                          {getButtonPerProgress(moduleProgress,
                            isSm ? MODULE : '',
                            {
                              onClick: (evt) => handleModuleActionButtonClick(evt, mv.uid)
                            }
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  )
                }
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </>
  )
}

ChapterAccordion.propTypes = {
  chapter: PropTypes.object.isRequired,
  modulesProgress: PropTypes.arrayOf(PropTypes.shape({
    moduleVersion: PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }),
    progressPercent: PropTypes.number.isRequired,
  })),
  onLaunchChapter: PropTypes.func.isRequired,
  onLaunchModule: PropTypes.func.isRequired,
}

export default ChapterAccordion;
