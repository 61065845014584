/**
 * @generated SignedSource<<44d69fb25eb8450d1b9a83e2b43fb1da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filters",
  "value": {
    "site": {
      "current": true
    }
  }
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TagEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTags_TagsQuery",
    "selections": [
      {
        "alias": "tags",
        "args": [
          (v0/*: any*/)
        ],
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "__useTags_tags_connection",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "__useTags_tags_connection(filters:{\"site\":{\"current\":true}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useTags_TagsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "tags",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "tags(filters:{\"site\":{\"current\":true}},first:100)"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "useTags_tags",
        "kind": "LinkedHandle",
        "name": "tags"
      }
    ]
  },
  "params": {
    "cacheID": "fbf7d729c824288614e507342fa525f0",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "tags"
          ]
        }
      ]
    },
    "name": "useTags_TagsQuery",
    "operationKind": "query",
    "text": "query useTags_TagsQuery {\n  tags(first: 100, filters: {site: {current: true}}) {\n    edges {\n      node {\n        id\n        text\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "85ffb02960a32b64250b07be019f5667";

export default node;
