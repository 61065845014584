import {isEmpty} from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import {moduleSearchCardSx} from '@/style/constants/search';
import {CardMedia, LinearProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import {contentElementNameFont} from '@/style/constants/homepage';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {getButtonPerProgress} from '../course-details/utils';
import PropTypes from 'prop-types';

const SearchEntityCard = (props) => {
  const {
    url,
    name,
    description,
    progressValue,
    handleClick,
  } = props;

  return (
    <Box
      className="search-entity-card"
      sx={{
        ...moduleSearchCardSx,
        height: '100%',
        minHeight: '250px',
        maxHeight: '750px',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
        zIndex: '100',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box sx={{
        position: 'relative',
        pb: '65%',
        mb: {xs: '16px'},
      }}>
        <CardMedia
          image={url}
          component='img'
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        px: '16px',
      }}>
        <Box>
          <Typography
            className="search-entity-name"
            sx={{
              fontWeight: 'bold',
              fontSize: {...contentElementNameFont},
              mb: '15px',
            }}
          >
            {name}
          </Typography>
          {!isEmpty(description) &&
            <Typography
              className="search-entity-description"
              sx={{
                fontSize: {
                  xs: '14px', md: '16px',
                },
                mb: '8px',
              }}
            >
              {description}
            </Typography>
          }
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {
            progressValue > 0
              ? <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: {xs: '16px'},
                width: '100%',
              }}>
                <LinearProgress
                  aria-label='Completion progress'
                  sx={{
                    width: '80%',
                  }}
                  variant={'determinate'}
                  value={progressValue}
                />
                <Typography
                  variant='body2'
                >
                  {`${progressValue} %`}
                </Typography>
              </Box>
              : null
          }
          {
            getButtonPerProgress(progressValue, '', {
              variant: 'outlined',
              onClick: handleClick,
              startIcon: <PlayArrowIcon/>,
              size: 'small',
              sx: {
                width: '100%',
                mb: '16px',
                borderColor: '#bebebe',
              },
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

SearchEntityCard.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  progressValue: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default SearchEntityCard;