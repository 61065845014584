import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const MainLinks = (props) => {
  const {
    pages,
    smallScreensSx,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{
      ...smallScreensSx,
      justifyContent: {xs: 'space-between', lg: 'flex-start'},
      flexGrow: 2,
      mr: {xs: 1, md: 2}
    }}>
      {pages.map((page) => {
        const isActive = location?.pathname === page.link;
        return (
            <Link
              onClick={
                (e) => navigate(page.link)
              }
              underline='none'
              key={page.label}
              component='button'
              variant='body1'
              sx={{
                padding: '10px',
                mr: {xs: 0, lg: 4},
                '&:last-child': {mr: 0},
                color: 'primary.main',
                textTransform: 'none',
                backgroundColor: isActive ? 'text.menuItemBackground' : '',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: isActive ? 'text.menuItemSelected' : 'text.menuItem',
                }}
              >
                {page.label}
              </Typography>
            </Link>
        )}
      )}
    </Box>
  )
}

MainLinks.propTypes = {
  pages: PropTypes.array.isRequired,
  smallScreensSx: PropTypes.object,
}

MainLinks.defaultProps = {
  smallScreensSx: {},
}

export default MainLinks;
