import React from 'react';
import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

const style = {
  position: 'absolute',
  outline: 'none',
  borderRadius: '5px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: '40px',
  minWidth: '300px',
  maxWidth: '80%',
  minHeight: '400px',
  maxHeight: '80vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
}
const DeleteUniversity = (props) => {
  const {
    isDeleteModalOpen,
    onModalClose,
    universityToDelete,
    universityReplaceWith,
    onValueReplaceWithChanged,
    universities,
    onSubmitClick,
    isLoading,
    disabled,
  } = props;

  const universitiesOptions = universities.filter(
    u => u?.uid !== universityToDelete?.uid).map(u => u.name);

  return (
    <Modal
      disableEnforceFocus
      open={isDeleteModalOpen}
      onClose={onModalClose}
      aria-labelledby='delete-institution-modal-title'
      aria-describedby='delete-institution-modal-discription'
    >
      <Box sx={style}>
        <Typography>
          {
            `To delete the institution '${universityToDelete?.name}',
            please choose another institution to transfer the current users to.`
          }
        </Typography>
        <Autocomplete
          value={universityReplaceWith?.name || null}
          onChange={onValueReplaceWithChanged}
          disablePortal
          id='The university replace with'
          options={universitiesOptions}
          sx={{
            width: '300px',
          }}
          ListboxProps={{
            sx: {
              maxHeight: '200px',
              overflowY: 'scroll',
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='The institution'
            />
          )}
        />
        <LoadingButton
          onClick={onSubmitClick}
          loading={isLoading}
          disabled={disabled}
          variant='contained'
          sx={{
            width: '100px',
            textTransform: 'none',
            bgcolor: 'primary.action',
            '&:hover': {
              bgcolor: 'primary.actionDark',
            },
            mt: 'auto',
            ml: 'auto',
          }}
        >
          {'Delete'}
        </LoadingButton>
      </Box>
    </Modal>
  );
};

DeleteUniversity.propTypes = {
  isDeleteModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  universityToDelete: PropTypes.object,
  universityReplaceWith: PropTypes.object,
  onValueReplaceWithChanged: PropTypes.func.isRequired,
  universities: PropTypes.array,
  onSubmitClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default DeleteUniversity;