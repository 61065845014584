import React from 'react';
import Box from '@mui/material/Box';
import {CardMedia, LinearProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  contentElementDescriptionFont,
  contentElementNameFont,
} from '../../style/constants/homepage';
import PropTypes from 'prop-types';
import {getButtonPerProgress} from '../course-details/utils';


const CourseCard = (props) => {
  const {
    versionedCourseData,
    progressValue,
    onNameClick,
    onButtonClick,
  } = props;

  const {course} = versionedCourseData;

  return (
    <Box
      className="course-card"
      sx={{
        key: course.name,
        width: '276px',
        height: '100%',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
        zIndex: '100',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        mr: '32px',
      }}
    >
      <CardMedia
        image={course.coverImg.url}
        component='img'
        alt={course?.coverImgDesc ?? ''}
        title={course?.coverImgDesc ?? ''}
        sx={{
          mb: {xs: '16px'},
          width: '276px',
          height: '207px',
        }}
      />
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        px: '16px',
      }}>
        <Box>
          <Typography
            className="course-title"
            onClick={onNameClick}
            sx={{
              fontWeight: 'bold',
              fontSize: {...contentElementNameFont},
              mb: '8px',
              cursor: 'pointer',
            }}
          >
            {course.name}
          </Typography>
          <Typography
            className="course-synopsys"
            sx={{
              fontSize: {...contentElementDescriptionFont},
              mb: '8px'
            }}
          >
            {course.synopsis}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {
            progressValue > 0
              ? <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: {xs: '16px'},
                width: '100%',
              }}>
                <LinearProgress
                  aria-label='Course completion progress of user'
                  sx={{
                    width: '80%',
                  }}
                  variant={'determinate'}
                  value={progressValue}
                />
                <Typography
                  variant='body2'
                >
                  {`${progressValue} %`}
                </Typography>
              </Box>
              : null
          }
          {
            getButtonPerProgress(progressValue, '', {
              onClick: onButtonClick,
              sx: {
                width: '100%',
                mb: '16px',
                borderColor: '#bebebe',
                fontWeight: 'bold',
              }
            })
          }
        </Box>
      </Box>
    </Box>
  );
};

CourseCard.propTypes = {
  versionedCourseData: PropTypes.object.isRequired,
  progressValue: PropTypes.number.isRequired,
  onNameClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

export default CourseCard;
