import {CURR_SITE, SITE_NS} from '@/constants';

const SITE_PREFIX = CURR_SITE.name === SITE_NS
  ? "SPARK NS Learning Center"
  : "SPARK Stanford Edu Platform";


const TITLES = {
  'self-guided-learning': 'Self-Guided Learning',
  'directed-learning': 'Directed Learning',
  '': 'Home',
  'video-library': 'Video Library',
  'course': 'Course Details',
  'dashboard': {
    'reports': 'Dashboard : Reports',
    'users': 'Dashboard : Users',
    'courses': 'Dashboard : Courses',
    'modules': 'Dashboard : Modules',
    'landing-page': 'Dashboard : Landing Page Manager',
    'self-guided-manager': 'Dashboard : Self-Guided Learning Page Manager',
    'tags': 'Dashboard : Tags',
    'institutions': 'Dashboard : Institutions',
  },
  'login': 'Log In',
  'request-password-reset': 'Request Password Reset',
  'reset-password': 'Reset Password',
  'confirm-invitation': 'Confirm Invitation',
  'privacy-policy': 'Privacy Policy',
  'terms': 'Terms',
  'faqs': 'FAQs',
  'about-us': 'About Us',
  'contact-us': 'Contact Us',
};

export function getPageTitle(path, elementPosition = 1, titles = null) {
  titles = titles || TITLES;
  const name = path.split('/')[elementPosition];
  const title = titles[name] || SITE_PREFIX;
  if (typeof title === 'object') {
    return getPageTitle(path, elementPosition+1, title);
  }
  return `${SITE_PREFIX} : ${title}`;
}
