/**
 * @generated SignedSource<<0e5fa5277d054c2e8b2e1914033e7a21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topics"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "topics",
            "variableName": "topics"
          }
        ],
        "kind": "ObjectValue",
        "name": "data"
      }
    ],
    "concreteType": "FeaturedTopics",
    "kind": "LinkedField",
    "name": "setFeaturedTopics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeaturedTopic",
        "kind": "LinkedField",
        "name": "topics",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "synopsis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "modules",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedTopics_SetFeaturedTopicsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturedTopics_SetFeaturedTopicsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "99b6c6992f161b14f33bebbaac075d37",
    "id": null,
    "metadata": {},
    "name": "FeaturedTopics_SetFeaturedTopicsMutation",
    "operationKind": "mutation",
    "text": "mutation FeaturedTopics_SetFeaturedTopicsMutation(\n  $topics: [FeaturedTopicInputPartial!]!\n) {\n  setFeaturedTopics(data: {topics: $topics}) {\n    topics {\n      uid\n      name\n      synopsis\n      modules {\n        uid\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "60d5f4d38687622923b94c0c5ec5b31c";

export default node;
