import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

const SelfGuidedModulesSkeleton = () => {
  return (
    <Box
      sx={{
        mt: '5px',
        minHeight: '90vh',
      }}>
      <Tabs
        value='Discover'
        aria-label='section-skeleton'
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
      >
        <Tab
          disabled
          key='Discover'
          label='Discover'
          value='Discover'
        />
        <Tab
          disabled
          key='Develop'
          label='Develop'
          value='Develop'
        />
        <Tab
          disabled
          key='Deliver'
          label='Deliver'
          value='Deliver'
        />
      </Tabs>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '70vh',
        }}
      >
        <CircularProgress
          aria-label='Data loading progress'
          color='inherit'
        />
      </Box>
    </Box>
  );
};

export default SelfGuidedModulesSkeleton;