import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {GROUP_ADMINS} from './constants';
import UserProfile from './UserProfile';


export default function UserDetailsDialog(props) {
  const {
    isOpen,
    user,
    showProgress,
    showChangePasswordLink,
    displayMessage,
    fieldParams,
    onClose
  } = props;

  const isAdmin = React.useMemo(() => (
     Boolean((user.groups ?? []).find((g) => g.name === GROUP_ADMINS))
  ), [user]);


  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );



  return (
    <Dialog
      fullWidth
      maxWidth={isAdmin || !showProgress ? 'md' : 'lg'}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>{isAdmin ? 'Admin' : 'Learner'} Profile</DialogTitle>
      <DialogContent sx={{p: 2, pb: 0}}>
        <UserProfile
          user={user}
          displayMessage={displayMessage}
          showProgress={showProgress}
          showChangePasswordLink={showChangePasswordLink}
          fieldParams={fieldParams}
          extraButtons={
            <Button
              aria-label='Close'
              variant='outlined'
              onClick={handleClose}
            >
              Close
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
}

UserDetailsDialog.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.object,
  showProgress: PropTypes.bool,
  showChangePasswordLink: PropTypes.bool,
  fieldParams: PropTypes.object,
  displayMessage: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

UserDetailsDialog.defaultProps = {
  isOpen: false,
  user: null,
  showProgress: false,
  showChangePasswordLink: false,
  fieldParams: {},
}
