import React, {useEffect, useState} from 'react';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const tabIndicatorHeight = {xs: '4px'};

const tabsShadow = `
  0px 3px 5px -1px rgba(0, 0, 0, 0.20), 
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 18px 0px rgba(0, 0, 0, 0.12)
`

const ProjectTab = (props) => {
  const {
    value,
    isSelected,
    tabName,
    indicatorColor,
    selectedIndicatorColor,
    tabBgColor,
    tabColor,
    isDataLoaded,
    ...parentProps
  } = props;

  return (
    <Tab
      disabled={!isDataLoaded}
      key={value}
      value={value}
      {...parentProps}
      label={
        <>
          <Typography
            sx={{
              mb: tabIndicatorHeight,
            }}
            fontSize={{xs: 16}}
            fontWeight={isSelected ? 'bold' : 'normal'}
          >
            {tabName}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              height: tabIndicatorHeight,
              width: '100%',
              backgroundColor: (
                isSelected
                  ? selectedIndicatorColor
                  : indicatorColor
              ),
              zIndex: 1000,
              transition: 'background-color 500ms linear',
            }}
          />
        </>
      }
      sx={{
        height: {xs: '38px'},
        justifyContent: 'center',
        backgroundColor: tabBgColor,
        color: tabColor,
        opacity: 1,
        textTransform: 'none',
        fontWeight: isSelected ? 'bold' : 'normal',
        boxShadow: isSelected ? tabsShadow : 'none',
      }}
    >
    </Tab>
  );
};

ProjectTab.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isSelected: PropTypes.bool.isRequired,
  tabName: PropTypes.string.isRequired,
  indicatorColor: PropTypes.string.isRequired,
  selectedIndicatorColor: PropTypes.string.isRequired,
  tabBgColor: PropTypes.string.isRequired,
  tabColor: PropTypes.string.isRequired,
  parentProps: PropTypes.object,
  isDataLoaded: PropTypes.bool,
}

ProjectTab.defaultProps = {
  isDataLoaded: true,
}

export default ProjectTab;