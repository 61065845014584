import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CourseDetails from '../components/course-details/CourseDetails';
import {theme} from '../themes/userUi';
import {ErrorBoundary} from '../ErrorBoundary';
import NavBar from '@/components/common/navbar/NavBar';
import Footer from '../components/common/Footer';

const CourseDetailsPage = (props) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar/>
        <ErrorBoundary>
          <CourseDetails/>
        </ErrorBoundary>
        <Footer/>
      </ThemeProvider>
    </>
  )
}

export default CourseDetailsPage;
