import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const usePasswordRules = (password, password2) => {
  const checkPasswordStrength = (password) => {
    const conditions = [
      { regex: /.{8,}/, message: 'At least 8 characters' },
      { regex: /[A-Z]/, message: 'At least one uppercase letter' },
      { regex: /[0-9]/, message: 'At least one digit' },
    ];
    return conditions.map((condition) => ({
      valid: condition.regex.test(password),
      message: condition.message,
    }));
  };

  const passwordStrength = checkPasswordStrength(password);
  const passwordsMatch = password === password2;
  const allConditionsMet = passwordStrength.every(
    condition => condition.valid) && passwordsMatch;

  const renderRulesMessages = () => (
    <Box sx={{ mt: 2 }}>
      {passwordStrength.map((condition, index) => (
        <Typography
          key={index}
          variant='body2'
          color={condition.valid ? 'success.main' : 'error'}
        >
          {condition.message}
        </Typography>
      ))}
      <Typography
        variant="body2"
        color={passwordsMatch ? 'success.main' : 'error'}
      >
        {passwordsMatch ? 'Passwords match' : 'Passwords do not match'}
      </Typography>
    </Box>
  );

  return [allConditionsMet, renderRulesMessages];
};
