import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {
  moduleSearchCardsContainerSx,
  moduleSearchCardSx,
} from '../../../style/constants/search';

const indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const SearchResultSkeletonsList = () => {
  return (
    <Box
      sx={{
        ...moduleSearchCardsContainerSx,
      }}
    >
      {indexes.map(index => (
        <Box
          key={index}
          sx={{
            ...moduleSearchCardSx,
          }}
        >
          <Skeleton
            key={index}
            variant='rectangular'
            height={'250px'}
          />
          <Skeleton
            sx={{
              width: '100%',
            }}
          />
          <Skeleton
            sx={{
              width: '50%',
            }}
          />
          <Skeleton
            sx={{
              width: '100%',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SearchResultSkeletonsList;