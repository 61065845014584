/**
 * @generated SignedSource<<b429010f6974f64cb434027c19032c2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserProfile_UserAnswers",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileQuestion",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileQuestionOption",
      "kind": "LinkedField",
      "name": "chosenOption",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowCustomAnswer",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileQuestionOption",
      "kind": "LinkedField",
      "name": "chosenOptions",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAnswer",
      "storageKey": null
    }
  ],
  "type": "UserAnswer",
  "abstractKey": null
};
})();

node.hash = "bb8826d89adc63308714b4e79a104aef";

export default node;
