import React, {useRef} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import {SEARCH_PLACEHOLDER} from "../../../constants";

const tabsText = `
Self-Guided Learning is designed with the academic researcher in mind. All modules are available on demand, 
organized by stage of development, so you can find relevant information on your journey. Select a module to start learning.
`

const tabsVideoText = `
The Video Library is a centralized location for all educational videos available in the Learning Center.
 It includes short animations, case studies, and knowledge from subject matter experts.
 Each video is less than 10 minutes. Select a video to start learning.
`

const FocusPageSearchField = (props) => {
  const {
    onSearchFocused,
    isVideo,
  } = props;

  const inputRef = useRef(null);

  const onIconClick = () => {
    inputRef.current?.focus();
  }

  const handleFocus = (event) => {
    inputRef.current?.blur();
    onSearchFocused();
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', lg: 'row'},
      justifyContent: 'flex-start',
      mb: {xs: '16px'},
    }}
    >
      <Box sx={{
        flex: 1,
        mr: {xs: '0px', lg: '20px'},
        mb: {xs: '8px', lg: '0px'},
      }}>
        <Typography
          fontSize={{xs: '14px'}}
        >
          {!!isVideo ? tabsVideoText : tabsText}
        </Typography>
      </Box>

      <TextField
        onFocus={handleFocus}
        inputRef={inputRef}
        sx={{
          '& .MuiInput-underline': {
            '&:after': {
              borderBottom: 'initial',
            },
          },
          '& .MuiInputBase-input': {
            pb: '8px',
            fontSize: '16px',
          },
          width: {xs: '100%', lg: '300px'},
        }}
        InputProps={{
          maxLength: 20,
          startAdornment: (
            <IconButton
              aria-label='search'
              disableRipple
              onClick={onIconClick}
              sx={{
                pl: '0px',
                pr: '8px',
                pt: '0px',
                pb: '8px',
              }}
            >
              <SearchIcon
                sx={{
                  fontSize: '26px',
                  stroke: '#ffffff',
                  strokeWidth: '1px',
                }}
              />
            </IconButton>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={SEARCH_PLACEHOLDER}
        variant='standard'
      />
    </Box>
  );
};

FocusPageSearchField.propTypes = {
  onSearchFocused: PropTypes.func.isRequired,
  isVideo: PropTypes.bool,
}

export default FocusPageSearchField;