import React from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


const CONFIRMATION_TEXT = 'DELETE';

const DeleteUserMutation = graphql`
  mutation DeleteUserDialogMutation(
    $id: GlobalID!,
  ) {
      deleteUser(
         userId: $id,
      ) {
          ... on ActionResult {
            status
            msg
          }
        }
  }
`;


export default function DeleteUserDialog(props) {
  const {
    user,
    isOpen,
    displayMessage,
    onClose
  } = props;

  const [error, setError] = React.useState(null);
  const [commitMutation, isMutationInFlight] = useMutation(DeleteUserMutation);
  const [confirmText, setConfirmText] = React.useState('');

  const onCloseDeleteDialog = (confirmDelete) => {
    if (confirmDelete === true) {
      if (confirmText !== CONFIRMATION_TEXT) {
        setError('Please enter the confirmation text above');
        return;
      }
      const variables = {id: user.id};
      commitMutation({
        variables,
        onCompleted: (data) => {
          const {status, msg} = data.deleteUser;
          console.log('DeleteUser::CommitMutation::onCompleted: data=', data);
          if (status) {
            displayMessage({info: `User "${user.firstName} ${user.lastName}" has been deleted`});
            onClose(true);
          }
          else {
            displayMessage({error: msg});
          }
        },
        onError: (err) => {
          console.error("Error performing mutation deleteUser:", err);
          setError('Error deleting user. Please try again later.');
        },
        updater: (store, data) => {
          console.log('DeleteUser::CommitMutation::updater: data=', data);
          const {status} = data.deleteUser;
          if (!status) return;
          const usersConnection = ConnectionHandler.getConnection(
            store.getRoot(),
            'UsersTableFragment_users',
            {filters: {forCurrentSite: true}}
          );
          ConnectionHandler.deleteNode(usersConnection, user.id);
          console.log('DeleteUser::CommitMutation::updater: deleted node:', user.id);
        },
      })
    }
    else {
      onClose();
    }
  }
  return (
    <>
    {isOpen &&
      <Dialog
        open={isOpen}
        onClose={onCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Removing a user will also remove all related data, such as course and module progress.
          </DialogContentText>
          <DialogContentText id="alert-delete-dialog-instruction">
            Please enter <b>{CONFIRMATION_TEXT}</b> in the textbox to confirm.
          </DialogContentText>
          <TextField
            margin='dense'
            id='confirm_delete'
            size='small'
            type='text'
            value={confirmText}
            variant='outlined'
            onChange={(evt) => {setConfirmText(evt.target.value)}}
          />
          {error && (
            <Typography color='error' variant='body2'>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Delete'
            color='error'
            disabled={isMutationInFlight}
            variant='contained'
            onClick={() => onCloseDeleteDialog(true)}
          >
            Delete
          </Button>
          <Button
            aria-label='Cancel'
            onClick={onCloseDeleteDialog}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    }
    </>
  )
}

DeleteUserDialog.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  displayMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteUserDialog.defaultProps = {
  isOpen: false,
}
