import {findKey, intersectionWith, isEmpty, map} from 'lodash';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CoursesTable from '../course-builder/CoursesTable';
import LandingPageManager from '../landing-page-manager/LandingPageManager';
import {DEFAULT_PAGE, PAGES, PAGE_INFO} from './constants';
import PropTypes from 'prop-types';
import SelfGuidedModulesManager from '../modules-manager/SelfGuidedModulesManager';
import SelfGuidedModulesSkeleton from '../skeletons/modules-manager/SelfGuidedModulesSkeleton';
import ManageModules from './ManageModules';
import ManageUsers from './ManageUsers';
import Reports from './Reports';
import Tags from './Tags';
import Universities from './Universities';
import {ErrorBoundary} from '@/ErrorBoundary';


const Dashboard = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const {page = null} = useParams();
  const pageKey = findKey(PAGES, (p) => p === page);
  const [activePage, setActivePage] = React.useState(PAGES?.[pageKey] ?? DEFAULT_PAGE);

  React.useEffect(() => {
    if (pageKey === undefined) {
      navigate(`/dashboard/${DEFAULT_PAGE}/`);
    }
  }, [pageKey, navigate]);

  const handleListItemClicked = (evt, page) => {
    setActivePage(page);
    navigate(`/dashboard/${page}/`);
  }

  let component = <Alert severity="error">Sorry, you do not have access to this page.</Alert>;
  if (isEmpty(PAGE_INFO[activePage]?.allowedGroups) ||
      !isEmpty(intersectionWith(PAGE_INFO[activePage].allowedGroups, user?.groups ?? [],
        (pageGroup, userGroup) => pageGroup === userGroup.name))
  ) {
    switch (activePage) {
      case PAGES.REPORTS:
        component = <Reports />;
        break;
      case PAGES.MANAGE_USERS:
        component = <ManageUsers />;
        break;
      case PAGES.MANAGE_COURSES:
        component = <CoursesTable />;
        break;
      case PAGES.MANAGE_MODULES:
        component = <ManageModules />;
        break;
      case PAGES.MANAGE_LANDING_PAGE:
        component = <LandingPageManager />;
        break;
      case PAGES.MANAGE_SELF_GUIDED_MODULES:
        component = (
          <React.Suspense fallback={<SelfGuidedModulesSkeleton/>}>
            <SelfGuidedModulesManager/>
          </React.Suspense>
        );
        break;
      case PAGES.MANAGE_TAGS:
        component = <Tags />;
        break;
      case PAGES.MANAGE_INSTITUTIONS:
        component = <Universities/>;
        break;
    }
  }
  return (
    <>
      <Grid
        container
        sx={{height: '100vh'}}
      >
        <Grid
          item
          xs={4}
          sm={3}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems="start"
        >
          <List component="nav" aria-label="main">
          {
            map(PAGE_INFO, (link, idx) => (
              <ListItemButton
                aria-label={link.title}
                key={link.title}
                disabled={link?.enabled !== true}
                selected={activePage === idx}
                onClick={(evt) => handleListItemClicked(evt, idx)}
              >
                <ListItemText primary={link?.menuItemTitle ?? link.title}/>
              </ListItemButton>
            ))
          }
          </List>
        </Grid>
        <Grid
          item
          xs={8}
          sm={9}
          md={10}
          display="flex"
          justifyContent="center"
          sx={{
            backgroundColor: '#F5F8F9',
            alignItems: "start"
          }}
        >
          <Box sx={{px: 2, mt: 3, width: '100%', height: '100%'}}>
            <Stack direction='column' spacing={2}>
              <Typography variant='h5'>{PAGE_INFO[activePage].title}</Typography>
              <Paper
                elevation={4}
                square
                sx={{
                  padding: "10px",
                }}
              >
                <ErrorBoundary>
                  {component}
                </ErrorBoundary>
              </Paper>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

Dashboard.propTypes = {
  user: PropTypes.object,
}

Dashboard.defaultProps = {
  user: null,
}

export default Dashboard;
