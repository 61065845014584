import React from 'react';
import Box from '@mui/material/Box';
import NavBarSkeleton from './components/skeletons/navbar/NavBarSkeleton';
import CircularProgress from '@mui/material/CircularProgress';
import {theme as navbarTheme} from './themes/navbar';
import {ThemeProvider} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

export default function PageLoader() {
  const { pathname } = useLocation();
  const hideHeader = pathname.startsWith('/module-exit/')

  return (
    <ThemeProvider theme={navbarTheme}>
      <Box sx={{
        height: '100vh',
        position: 'relative'
      }}>
        {
          !hideHeader && (
            <NavBarSkeleton/>
          )
        }
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress color="primary" />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
