import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {theme as navbarTheme} from '../../../themes/navbar';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {navBarHeight} from '../../../style/constants/main';
import {pageContentPadding} from '../../../style/constants/self-guided';
import {CURR_SITE, HOMEPAGE_URL, SITE_STANFORD} from '../../../constants';
import StanfordLogo from '../../../../static/stanford/img/logo.svg';
import NsLogo from '../../../../static/ns/img/logo.svg';

const site = CURR_SITE.name;
const Logo = site === SITE_STANFORD ? StanfordLogo : NsLogo;

const NavBarSkeleton = () => {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={navbarTheme}>
      <AppBar position='sticky' sx={{backgroundColor: 'panelBackground.main'}}>
        <Box
          sx={{
            minHeight: navBarHeight,
            maxHeight: '120px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: {...pageContentPadding},
          }}
        >
          <Box
            component='img'
            onClick={
              (e) => navigate(HOMEPAGE_URL)
            }
            sx={{
              height: 48,
              width: 108,
              maxHeight: {xs: 233, md: 167},
              maxWidth: {xs: 350, md: 250},
              mr: {xs: 2, md: 4, lr: 10},
              cursor: 'pointer',
            }}
            src={Logo}
            alt='Logo of SPARK at Stanford'
          />

          <Skeleton
            variant='circular'
            sx={{
              display: {xs: 'none', md: 'flex'},
            }}
          >
            <Avatar />
          </Skeleton>

          <Skeleton
            key={'Hamburger Menu skeleton'}
            variant='rectangular'
            sx={{
              display: {xs: 'flex', md: 'none'},
              padding: '4px',
              width: '30px',
            }}
          />
        </Box>
      </AppBar>
    </ThemeProvider>
  );
};

export default NavBarSkeleton;