import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import SectionContentCard from './SectionContentCard';
import {sectionsColors} from './SectionsNavigation';
import PropTypes from 'prop-types';

// Sort the array based on the 'section' field of each object.
// Ensure that objects with the same 'section' ID are consecutive
const sectionsCards = [
  {
    section: 0,
    text: 'Clinical Need Assessment',
    iconPath: '/static/common/img/modules_icons/clinical_need_assessment.svg',
  },
  {
    section: 0,
    text: 'Target ID & Validation',
    iconPath: '/static/common/img/modules_icons/target_id.svg',
  },
  {
    section: 0,
    text: 'Screening',
    iconPath: '/static/common/img/modules_icons/screening.svg',
  },
  {
    section: 0,
    text: 'Optimization',
    iconPath: '/static/common/img/modules_icons/optimization.svg',
  },
  {
    section: 1,
    text: 'Animal Studies',
    iconPath: '/static/common/img/modules_icons/animal_studies.svg',
  },
  {
    section: 1,
    text: 'Preclinical Studies',
    iconPath: '/static/common/img/modules_icons/preclinical_studies.svg',
  },
  {
    section: 2,
    text: 'Clinical Trial Design',
    iconPath: '/static/common/img/modules_icons/clinical_trial_design.svg',
  },
  {
    section: 2,
    text: 'Phase 1',
    iconPath: '/static/common/img/modules_icons/phase1.svg',
  },
  {
    section: 2,
    text: 'Phase 2',
    iconPath: '/static/common/img/modules_icons/phase2.svg',
  },
  {
    section: 2,
    text: 'Phase 3',
    iconPath: '/static/common/img/modules_icons/phase3.svg',
  },
];

const sectionCardsCount = sectionsCards.reduce((acc, card) => {
  const {section} = card;
  acc[section] = (acc[section] || 0) + 1;
  return acc;
}, {});

const SectionContentCards = ({sectionValue, handleSectionTabChanged, isDataLoaded}) => {

  const [cardRange, setCardRange] = useState({
    start: -1,
    end: -1,
  });

  useEffect(() => {
    const getCardsRange = (startTabValue) => {
      let total = 0;
      while (startTabValue >= 0) {
        total += sectionCardsCount[startTabValue]
        startTabValue -= 1
      }
      return total
    }
    setCardRange(prev => {
      return {
        ...prev,
        start: getCardsRange(sectionValue - 1),
        end: getCardsRange(sectionValue) - 1,
      }
    })
  }, [sectionValue]);

  const isTabCard = (cardIndex) => {
    return cardIndex >= cardRange.start && cardIndex <= cardRange.end
  };

  return (
    <Box
      role='img'
      aria-label='Graphic showing the progression through the Discover, Develop, and Deliver pipeline, highlighting ten key steps'
      title='Graphic showing the progression through the Discover, Develop, and Deliver pipeline, highlighting ten key steps'
      sx={{
        display: {xs: 'none', lg: 'flex'},
        justifyContent: 'flex-start',
        '&:last-child': {mr: 0},
      }}
    >
      {sectionsCards.map((card, index) => {
        const inSelectedSection = isTabCard(index);
        return (
          <SectionContentCard
            key={index}
            sectionContentCard={card}
            sectionContentCardColor={inSelectedSection
              ? `${sectionsColors[sectionValue]}.contrastText`
              : '#767676'
            }
            handleSectionTabChanged={handleSectionTabChanged}
            isSelected={inSelectedSection}
            isDataLoaded={isDataLoaded}
          />
        )
      })}
    </Box>
  );
};

SectionContentCards.propTypes = {
  sectionValue: PropTypes.number,
  handleSectionTabChanged: PropTypes.func.isRequired,
  isDataLoaded: PropTypes.bool,
};

SectionContentCards.defaultProps = {
  isDataLoaded: true,
}

export default SectionContentCards;