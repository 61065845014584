import {pick} from 'lodash';
import React from 'react';
import AccessPlanSelect from '../common/AccessPlanSelect';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TextFieldWithLengthDisplay from '../content/TextFieldWithLengthDisplay';
import UserProgressPanel from './UserProgressPanel';
import UserQuestionnairePanel from './UserQuestionnairePanel';
import {SUBSCRIPTION_TYPES} from './constants';
import {QuestionsProvider} from '../questionnaire/QuestionsContext';
import ReadonlyTextfield from '../common/ReadonlyTextfield';
import UniversitySelect from '../common/UniversitySelect';


export default function LearnerProfilePanel(props) {
  const {
    formik,
    showProgress,
    editMode,
    fieldParams,
    user,
  } = props;

  return (
    <Stack direction='row' gap={1} alignContent='start'>
      <Stack direction='column' gap={2} alignContent='start' width='100%'>
        {editMode ?
          <TextFieldWithLengthDisplay
            maxLength={50}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={(formik.touched.firstName && formik.errors.firstName) ?? ' '}
            fullWidth
            id="firstName"
            label="First Name"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('firstName')}
          />
          :
          <ReadonlyTextfield label="First Name" text={formik.values.firstName} />
        }
        {editMode ?
          <TextFieldWithLengthDisplay
            maxLength={50}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={(formik.touched.lastName && formik.errors.lastName) ?? ' '}
            fullWidth
            id="lastName"
            label="Last Name"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('lastName')}
          />
          :
          <ReadonlyTextfield label="Last Name" text={formik.values.lastName} />
        }
        {editMode ?
          <TextFieldWithLengthDisplay
            maxLength={100}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) ?? ' '}
            fullWidth
            id="email"
            label="Email"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('email')}
          />
          :
          <ReadonlyTextfield label="Email" text={formik.values.email} />
        }
        {editMode && fieldParams?.university?.readOnly !== true ?
          <UniversitySelect
            readOnly={!editMode}
            name='university'
            value={formik.values.university}
            label="Institution"
            disableClearable
            error={formik.touched.university && Boolean(formik.errors.university)}
            helperText={(formik.touched.university && formik.errors.university) ?? ' '}
            onChange={(event, newValue) => {
              formik.setFieldValue('university', newValue);
            }}
            onBlur={(evt) => {
              formik.setFieldTouched('university', true, true)
            }}
          />
          :
          <ReadonlyTextfield label="Institution" text={formik.values.university?.name ?? ''} />
        }
        {fieldParams?.accessPlan?.hidden !== true &&
          (
            editMode ?
            <AccessPlanSelect
              readOnly={!editMode}
              name='accessPlan'
              value={formik.values.accessPlan}
              label="Access Plan Group"
              disableClearable
              error={formik.touched.accessPlan && Boolean(formik.errors.accessPlan)}
              helperText={(formik.touched.accessPlan && formik.errors.accessPlan) ?? ' '}
              onChange={(event, newValue) => {
                formik.setFieldValue('accessPlan', pick(newValue, ['uid', 'name']));
              }}
              onBlur={(evt) => formik.setFieldTouched('accessPlan', true, true)}
            />
            :
            <ReadonlyTextfield label="Access Plan Group" text={formik.values.accessPlan.name} />
          )
        }
        {fieldParams?.subscriptionType?.hidden !== true &&
          (
            editMode ?
            <Autocomplete
              readOnly={!editMode}
              blurOnSelect
              disableClearable
              multiple={false}
              id="ac-subscription-type"
              name='subscriptionType'
              options={SUBSCRIPTION_TYPES}
              value={formik.values.subscriptionType}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                return option?.name === value?.name;
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Subscription Type'
                  error={formik.touched.subscriptionType && Boolean(formik.errors.subscriptionType)}
                  helperText={(formik.touched.subscriptionType && formik.errors.subscriptionType) ?? ' '}
                />
              )}
              size='small'
              onChange={(event, newValue) => {
                formik.setFieldValue('subscriptionType', newValue);
              }}
              onBlur={(evt) => formik.setFieldTouched('subscriptionType', true, true)}
            />
            :
            <ReadonlyTextfield label="Subscription Type" text={formik.values.subscriptionType.name} />
          )
        }
        <Divider />
        <QuestionsProvider>
          <UserQuestionnairePanel
            formik={formik}
            disabled={!editMode}
          />
        </QuestionsProvider>
      </Stack>
      {showProgress &&
        <>
          <Divider orientation="vertical" flexItem />
          <Box sx={{width: '60%'}}>
            <UserProgressPanel user={user} />
          </Box>
        </>
      }
    </Stack>
  );
}

LearnerProfilePanel.propTypes = {
  editMode: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  user: PropTypes.object,
  showProgress: PropTypes.bool,
  fieldParams: PropTypes.object,
}

LearnerProfilePanel.defaultProps = {
  editMode: false,
  showProgress: false,
  fieldParams: {},
}
