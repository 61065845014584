import {createTheme} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';
import {theme as baseTheme} from './dashboard';
import {CURR_SITE, SITE_NS, SITE_STANFORD} from '../constants';

const params = {
  [SITE_NS]: {
    'color-avatar': '#0A4A63',
    'color-primary': '#F0532A',
    'color-dark': '#DC4C25',
    'color-accent': '#197F89',
    'color-background': '#F5F5F5',
  },
  [SITE_STANFORD]: {
    'color-avatar': '#4C6F8D',
    'color-primary': '#197F89',
    'color-dark': '#156e77',
    'color-accent': '#0066CC',
    'color-background': '#F5F8F9',
  }
}

const site_params = params[CURR_SITE.name] ?? params[SITE_STANFORD];

export const theme = createTheme(
  deepmerge(
    baseTheme,
    {
      palette: {
        primary: baseTheme.palette.augmentColor({
          color: {
            avatar: site_params['color-avatar'],
            main: site_params['color-primary'],
            dark: site_params['color-dark'],
            accent: site_params['color-accent'],
            background: site_params['color-background'],
          },
          name: 'primary',
        }),
      }
    }
  )
);