import React, {useCallback, useMemo} from 'react';
import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';

const style = {
  position: 'absolute',
  outline: 'none',
  borderRadius: '5px',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -20%)',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  width: {
    xs: '100%',
    md: '80%',
  },
  height: {
    xs: 'calc(56.25vw)', // 16:9 ratio
    md: 'calc(45vw)', // 16:9 ratio from 80% of the base value
  },
  backgroundColor: 'transparent', // Hide small differences in calculation div size
};

const btnWidth = {
  lg: '20%',
  md: '30%',
  sm: '50%',
  xs: '70%',
}


const HomepageVideoModal = (props) => {
  const {
    open,
    handleModalClose,
    videoSrc,
    title,
    videoIdx,
    getNextVideo,
  } = props;
  const iframeRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [currVideoSrc, setCurrVideoSrc] = React.useState(videoSrc);
  const [currVideoTitle, setCurrVideoTitle] = React.useState(title);
  const [currVideoIdx, setCurrVideoIdx] = React.useState(videoIdx);

  const handleIFrameLoaded = useCallback(() => {
    playerRef.current = new Player(iframeRef.current);
    playerRef.current.on('ended', () => setVideoEnded(true));
    playerRef.current.play();
  }, [setVideoEnded]);

  const handlePlayNextVideo = () => {
    if (typeof (getNextVideo) !== 'function') return;
    const nextVideoInfo = getNextVideo(currVideoIdx);
    if (nextVideoInfo !== null) {
      setCurrVideoIdx(nextVideoInfo.videoIdx);
      setCurrVideoSrc(nextVideoInfo.videoSrc);
      setCurrVideoTitle(nextVideoInfo.videoTitle);
      setVideoEnded(false);
    }
  };

  const handleVideoClose = useCallback(() => {
      setVideoEnded(false);
      handleModalClose();
    },
    [handleModalClose]
  );

  const handleVideoReplay = useCallback(() => {
    const player = playerRef.current;
      if (player) {
        player.setCurrentTime(0).then(
          function(ts) {
            player.play().then(function() {
            }).catch(function(error) {
              console.error("Error replaying video:", error);
            });
          }
        ).catch(function(error) {
          console.error("Error resetting video to the beginning:", error);
        });
        setVideoEnded(false);
      }
    },
    []
  );

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={handleModalClose}
      aria-label='Welcome video'
    >
      <Box sx={style}>
        {videoEnded
        ? (
          <>
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap={3}
              sx={{
                height: '100%',
                width: '100%',
                alignSelf: 'center',
                backgroundColor: 'white',
              }}
            >
              <Button
                aria-label='Close video'
                variant='contained'
                onClick={handleVideoClose}
                sx={{width: btnWidth}}
              >
                CLOSE
              </Button>
              <Button
                aria-label='Restart this video'
                variant='contained'
                onClick={handleVideoReplay}
                sx={{width: btnWidth}}
              >
                REPLAY
              </Button>
              {typeof(getNextVideo) === 'function' &&
                <Button
                  aria-label='Play next video'
                  disabled={getNextVideo(currVideoIdx) === null}
                  variant='contained'
                  onClick={handlePlayNextVideo}
                  sx={{width: btnWidth}}
                >
                  NEXT
                </Button>
              }
            </Stack>
          </>
          )
        :
          <>
            <IconButton
              aria-label="close"
              onClick={handleVideoClose}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 101,
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                display: videoEnded ? 'none' : 'block'
              }}
            >
            <iframe
              ref={iframeRef}
              src={currVideoSrc}
              allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
              style={{
                position: 'absolute',
                border: '0px',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
              }}
              title={currVideoTitle}
              onLoad={handleIFrameLoaded}
            />
            </div>
          </>
        }
      </Box>
    </Modal>
  );
};

HomepageVideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  videoSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoIdx: PropTypes.number,
  getNextVideo: PropTypes.func,
}

export default HomepageVideoModal;