import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  pageContentPaddingX,
  sectionDescriptionFont,
  sectionHeaderMb,
  sectionNameFont,
  sectionNameMb,
} from '../../style/constants/homepage';
import FeaturedTopicModules from './FeaturedTopicModules';
import FeaturedTopicModulesSkeleton from '../skeletons/homepage/FeaturedTopicModulesSkeleton';
import {CURR_SITE, FEATURED_TOPIC_HDR, SITE_NS} from '../../constants';
import FeaturedTopicsChips from './FeaturedTopicsChips';
import Skeleton from '@mui/material/Skeleton';
import FeaturedTopicsChipsSkeleton from '../skeletons/homepage/FeaturedTopicsChipsSkeleton';
import LoadingSpinner from '../../LoadingSpinner';
import Cmi5ViewerDialog from '../cmi5-viewer/Cmi5ViewerDialog';
import {useModuleLaunch} from '../hooks/useModuleLaunch';

const description = CURR_SITE.name === SITE_NS
  ? 'Tour groups of 3 or 4 related modules on a specific topic.'
  : 'Tour groups of 3-4 modules, each with interrelated themes.';

const FeaturedTopics = (props) => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    isViewDialogOpen,
    handleCloseDialog,
    nextVersionedModule,
    handleNextVersionedModuleChanged,
    nextModuleVersionCalculator,
    handleNextModuleVersionCalculatorChanged,
  ] = useModuleLaunch();

  const onSelectedTopicChanged = (topic) => {
    setSelectedTopic(topic);
  };

  return (
    <Box sx={{
      position: 'relative',
      px: {...pageContentPaddingX},
      py: {xs: '40px', lg: '80px'},
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Typography sx={{
        fontSize: {...sectionNameFont},
        mb: {...sectionNameMb},
      }}>
        {`${FEATURED_TOPIC_HDR}s`}
      </Typography>

      <Typography sx={{
        fontSize: {...sectionDescriptionFont},
        mb: {...sectionHeaderMb},
      }}>
        {description}
      </Typography>

      <React.Suspense fallback={<FeaturedTopicsChipsSkeleton/>}>
        <FeaturedTopicsChips
          selectedTopic={selectedTopic}
          onSelectedTopicChanged={onSelectedTopicChanged}
        />
      </React.Suspense>

      <Typography sx={{
        fontSize: '16px',
        fontWeight: 'bold',
        mb: '16px'
      }}>
        {'Synopsis'}
      </Typography>

      {
        !!selectedTopic
          ? (
            <Typography sx={{
              mb: '32px'
            }}>
              {selectedTopic?.synopsis}
            </Typography>
          )
          : (
            <Skeleton
              key={'FAQ Skeleton'}
              variant='text'
              sx={{
                mb: '32px',
                fontSize: '20px',
                width: '300px',
              }}
            />
          )
      }

      {/* The Topic Modules */}
      {
        !!selectedTopic
          ? (
            <React.Suspense fallback={<FeaturedTopicModulesSkeleton/>}>
              <FeaturedTopicModules
                featuredTopicId={selectedTopic.id}
                selectedVersionedModule={selectedVersionedModule}
                handleNextModuleVersionCalculatorChanged={handleNextModuleVersionCalculatorChanged}
                handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
                handleNextVersionedModuleChanged={handleNextVersionedModuleChanged}
              />
            </React.Suspense>
          )
          : <FeaturedTopicModulesSkeleton/>
      }
      <React.Suspense fallback={<LoadingSpinner/>}>
        {selectedVersionedModule &&
          <Cmi5ViewerDialog
            isOpen={isViewDialogOpen}
            moduleVersion={selectedVersionedModule}
            getNextModule={(moduleVersion) => {
              if (nextModuleVersionCalculator && moduleVersion) {
                return nextModuleVersionCalculator(moduleVersion);
              }
              return null;
            }}
            onClose={handleCloseDialog}
            handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
          />
        }
      </React.Suspense>
    </Box>
  );
};

export default FeaturedTopics;
