import React, {useEffect} from 'react';
import {graphql, useFragment} from 'react-relay';
import {useRefetchProgress} from '../../hooks/useRefetchProgress';
import VideoDataWrapperProgressQuery
  from './__generated__/VideoDataWrapperProgressQuery.graphql';
import PropTypes from 'prop-types';

const VideoDataWrapperProgressFragment = graphql`
  fragment VideoDataWrapperProgressFragment_progress on Video 
  @refetchable(queryName: "VideoDataWrapperProgressQuery")
  {
    progress {
      progressPercent
    }
  }
`;

const VideoDataWrapperFragment = graphql`
  fragment VideoDataWrapperFragment_defaultVersionedModulesVideo on Video {
    id
    uid
    title
    description
    fullUrl
    thumbnailUrl
    nextVideoUid
    previousVideoUid
    ...VideoDataWrapperProgressFragment_progress
  }
`;


const VideoDataWrapper = (props) => {
  const {
    video,
    module,
    selectedVideo,
    handleSelectVideoChanged,
    handleNextVideoChanged,
    handlePreviousVideoChanged,
    handleOpenDialog,
    renderChildren,
  } = props;

  const videoFragmentData = useFragment(
    VideoDataWrapperFragment,
    video,
  );

  const videoData = {
    ...videoFragmentData,
    module: {...module},
  }

  const [
    videoProgress,
    progressBarVariant,
    isProgressLoading,
  ] = useRefetchProgress(
    VideoDataWrapperProgressQuery,
    VideoDataWrapperProgressFragment,
    videoData,
    selectedVideo?.uid,
  );

  const progressValue = parseInt(
    videoProgress?.progress?.progressPercent || 0
  );

  useEffect(() => {
    if(
      !!selectedVideo &&
      !!videoData &&
      selectedVideo?.nextVideoUid === videoData?.uid
    ) {
      handleNextVideoChanged({
        ...videoData,
        progress: progressValue,
      });
    }
    if(
      !!selectedVideo &&
      !!videoData &&
      selectedVideo?.previousVideoUid === videoData?.uid
    ) {
      handlePreviousVideoChanged({
        ...videoData,
        progress: progressValue,
      });
    }
  }, [selectedVideo, progressValue]);

  const onClick = () => {
    handleSelectVideoChanged({
      ...videoData,
      progress: progressValue,
    });
    handleOpenDialog();
  }

  const renderChildrenProps = {
    videoData,
    progressValue,
    progressBarVariant,
    isProgressLoading,
    onClick,
  }

  return renderChildren(renderChildrenProps);
};

VideoDataWrapper.propTypes = {
  video: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  selectedVideo: PropTypes.object,
  handleSelectVideoChanged: PropTypes.func.isRequired,
  handleNextVideoChanged: PropTypes.func.isRequired,
  handlePreviousVideoChanged: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  renderChildren: PropTypes.func.isRequired,
}

export default VideoDataWrapper;