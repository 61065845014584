import Box from '@mui/material/Box';
import React, {useCallback, useEffect } from 'react';
import PropTypes, {shape} from 'prop-types';
import {enableScrollBar, isScrollDisabled, targetSectionScrollTo} from '../../utils/common';
import Section from './Section';
import {moduleParentPropTypes, videoParentPropTypes} from '../../constants/propTypesShapes';

const SectionsList = (
  {
    sections,
    isDataLoaded,
    handleSectionChanged,
    stickyPartRef,
    sectionsRefMap,
    handleCardsHidden,
    entityType,
    childrenProps,
    handleScroll,
  }
) => {

  const sectionsRefMapCb = useCallback((index, node) => {
    sectionsRefMap.current[index] = node;
  }, [sectionsRefMap]);

  const hideCards = () => {
    if (handleCardsHidden) {
      if (!isScrollDisabled && window.scrollY > 120) {
        handleCardsHidden(true);
      }
      if (window.scrollY <= 10) {
        handleCardsHidden(false);
      }
    }
  }

  const onScroll = (e) => {
    if (stickyPartRef.current) {
      hideCards();
      const stickyPartRefBottom = Math.round(
        stickyPartRef.current.getBoundingClientRect().bottom
      );

      const sections = Object.keys(sectionsRefMap.current)
        .sort((a, b) => b - a);

      const scrollSum = (
        Math.round(window.scrollY) + Math.round(window.innerHeight)
      );
      const isTheScreenEnd = scrollSum >= document.documentElement.scrollHeight;


      for (let sectionToCheck of sections) {
        sectionToCheck = parseInt(sectionToCheck, 10);
        const sectionTop = Math.round(
          sectionsRefMap.current[sectionToCheck].getBoundingClientRect().top
        );
        if (
          (sectionTop === stickyPartRefBottom || isTheScreenEnd) &&
          isScrollDisabled &&
          targetSectionScrollTo === sectionToCheck
        ) {
          enableScrollBar();
          break;
        }
        else if (
          (sectionTop <= stickyPartRefBottom || isTheScreenEnd) &&
          !isScrollDisabled
        ) {
          handleSectionChanged(parseInt(sectionToCheck))
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (isDataLoaded && sectionsRefMap.current && stickyPartRef.current) {
      onScroll()
    }
  }, [isDataLoaded, sectionsRefMap, stickyPartRef]);

  useEffect(() => {
    if (handleScroll === true) {
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, {passive: true});
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [stickyPartRef, sectionsRefMap, handleSectionChanged]);

  return (
    <Box>
      {sections.map((section, index) => (
        <Section
          key={index}
          section={section}
          sectionsRefMapCb={handleScroll === true ? sectionsRefMapCb : () => {}}
          entityType={entityType}
          childrenProps={childrenProps}
        />
      ))}
    </Box>
  );
};

SectionsList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  isDataLoaded: PropTypes.bool,
  handleSectionChanged: PropTypes.func,
  stickyPartRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  sectionsRefMap: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }),
  handleCardsHidden: PropTypes.func,
  entityType: PropTypes.string.isRequired,
  childrenProps: PropTypes.oneOfType([
    shape(moduleParentPropTypes),
    shape(videoParentPropTypes),
  ]).isRequired,
  handleScroll: PropTypes.bool,
}

SectionsList.defaultProps = {
  handleScroll: true,
  isDataLoaded: true,
}

export default SectionsList;
