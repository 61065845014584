import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  buttonFontSize,
  buttonHeight,
  pageContentPaddingX,
} from '../../style/constants/homepage';
import HomepageVideoModal from './HomepageVideoModal';
import {CURR_SITE, SITE_STANFORD} from '../../constants';

const site = CURR_SITE.name;

const nsCover = {
  backgroundImage: `url(/static/${site}/img/landing_cover.webp)`,
  backgroundColor: 'background.dark',
};

const stanfordCover = {
  backgroundImage: {
    xs: `
      linear-gradient(
        to right, 
        rgba(0, 0, 0, 0.75), 
        rgba(0, 0, 0, 0.75)
      ), 
      url(/static/${site}/img/landing_cover.webp)
    `,
    lg: `
      linear-gradient(
        to right, 
        rgba(0, 0, 0, 0.0), 
        rgba(0, 0, 0, 1)
      ), 
      url(/static/${site}/img/landing_cover.webp)
    `,
  },
};

const cover = site === SITE_STANFORD ? stanfordCover : nsCover;
const heroImgTitle = site === SITE_STANFORD
  ? "Graphic showing various drug development icons within hexagons fading across the screen"
  : "A montage of drug development icons and a SPARK NS logo design element";

const welcomingPartStyle = {
  px: {...pageContentPaddingX},
  boxSizing: 'border-box',
  height: {xs: '400px', sm: '500px'},
  ...cover,
  backgroundSize: 'cover',
  backgroundPosition: 'top left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: {xs: 'flex-start', lg: 'flex-end'},
  color: 'white',
};

const welcomingText = site === SITE_STANFORD
  ? `Welcome to SPARK’s Educational Platform`
  : 'Welcome to the SPARK NS Learning Center';

const welcomingDescription = site === SITE_STANFORD
  ? `Teaching academic researchers the best practices of translational research and drug development`
  : `The Learning Center is an online platform that supports educational content and learning in translational research, therapeutic discovery and development, and commercialization and serves as a repository for educational content.`;

const videoSrc = site === SITE_STANFORD
  ? 'https://player.vimeo.com/video/926386581?h=7ac45e46a1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  : 'https://player.vimeo.com/video/998338383?h=6a8cac2657&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

const videoTitle = site === SITE_STANFORD
  ? 'Welcome_KevinG_LMS'
  : 'Welcome to the SPARK NS Learning Center';

const Welcome = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  return (
    <Box
      title={heroImgTitle}
      sx={{
        ...welcomingPartStyle,
      }}
    >
      <Box sx={{
        height: {xs: '100%'},
        width: {xs: '100%', sm: '80%', md: '586px'},
        flex: 1,
        display: 'flex',
        justifyContent: {
          xs: 'center',
        },
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}>
        <Typography sx={{
          fontSize: {xs: '32px', sm: '40px', md: '48px'},
          mb: {xs: '24px'},
        }}>
          {welcomingText}
        </Typography>
        <Typography sx={{
          fontSize: {xs: '16px', sm: '20px', md: '24px'},
          mb: {xs: '24px'},
        }}>
          {welcomingDescription}
        </Typography>

        <Button
          aria-label='Watch Welcome Video'
          onClick={handleButtonClick}
          variant='contained'
          color='primary'
          sx={{
            '&:hover': {
              backgroundColor: 'primary.main',
            },
            height: {...buttonHeight},
            width: {xs: '214px'},
            textTransform: 'none',
          }}
        >
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: {...buttonFontSize},
          }}>
            Watch Welcome Video
          </Typography>
        </Button>
        <HomepageVideoModal
          open={isModalOpen}
          handleModalClose={handleModalClose}
          videoSrc={videoSrc}
          title={videoTitle}
        />
      </Box>
    </Box>
  );
};

export default Welcome;