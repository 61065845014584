export const moduleBoxHeight = {
  xs: '143px', md: '160px',
}

export const moduleBoxWidth = {
  xs: '190px', md: '240px',
}

export const moduleBoxStaticStylesCommon = {
  display: 'flex',
  position: 'relative',
  flexShrink: '0',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundRepeat: 'no-repeat',
  color: 'white',
  cursor: 'pointer',
}

export const moduleBoxStaticStylesStanford = {
  mr: 1,
  height: {...moduleBoxHeight},
  width: {...moduleBoxWidth},
  ...moduleBoxStaticStylesCommon,
  borderRadius: '0',
};

export const moduleBoxStaticStylesNs = {
  mr: 1,
  height: {...moduleBoxHeight},
  width: {...moduleBoxWidth},
  ...moduleBoxStaticStylesCommon,
  borderRadius: '14px',
};