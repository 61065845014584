import React from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import UserAvatar from './UserAvatar';
import {CURR_SITE, SITE_STANFORD} from '@/constants';


const NavBarNameTypography = styled(Typography)(({ theme }) => ({
  pl: 'auto',
  ...(theme.breakpoints.down('md') && {
    typography: {
      md: 'subtitle1',
      lr: 'subtitle2',
    },
  }),
  color: 'rgba(0, 0, 0, 0.87)',
}));

const formatWelcomeText = (user) => (
  CURR_SITE.name === SITE_STANFORD
    ? `Welcome back,  ${user?.firstName} ${user?.lastName}`
    : `Welcome ${user?.firstName}!`
);


const UserSection = (props) => {
  const {
    user,
    showLinks,
    showWelcomeText,
    smallScreensSx,
    onUserAvatarClick,
  } = props;
  const navigate = useNavigate();

  return (
    <Stack
      direction='row'
      gap={2}
      sx={{
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'end',
      }}
    >
      {/* FAQ Link */}
      {showLinks &&
        <Link
          underline='none'
          key='FAQ'
          component='button'
          variant='body1'
          onClick={
            (e) => navigate('/faqs/')
          }
          sx={{
            ...smallScreensSx,
            p: 0,
            mr: 1,
            padding: '10px',
            backgroundColor: location?.pathname === '/faqs/' ? 'text.menuItemBackground' : '',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: location?.pathname === '/faqs/' ? 'text.menuItemSelected' : 'text.menuItem',
            }}
          >
            FAQ
          </Typography>
        </Link>
      }

      {/* Notification Icon -- to be enabled post-MVP
        <NotificationsIcon
          sx={{
            ...showOnlySmallSx,
            color: 'rgba(0, 0, 0, 0.56)'
          }}
        />
        */}

      {/* Username */}
      {showWelcomeText &&
        <Box sx={{
          display: {xs: 'none', lg: 'inline'},
          flexShrink: 0,
        }}>
          {
            !user
              ?
              <Skeleton
                variant='text'
                sx={{
                  width: '100px',
                  fontSize: '2rem'
                }}
              />
              :
              <NavBarNameTypography>
                {formatWelcomeText(user)}
              </NavBarNameTypography>
          }
        </Box>
      }

      {/* Avatar */}
      <UserAvatar
        user={user}
        iconProps={smallScreensSx}
        onClick={onUserAvatarClick}
      />
    </Stack>
  )
}

UserSection.propTypes = {
  user: PropTypes.object.isRequired,
  showLinks: PropTypes.bool,
  showWelcomeText: PropTypes.bool,
  smallScreensSx: PropTypes.object,
  onUserAvatarClick: PropTypes.func.isRequired,
}

UserSection.defaultProps = {
  showLinks: false,
  showWelcomeText: false,
}

export default UserSection;
