import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {pageContentPadding} from '../../style/constants/self-guided';
import {navBarHeight} from '../../style/constants/main';

const StickyHeader = React.forwardRef(function component(props, ref) {
  const {
    children,
    sx,
  } = props;
  return (
    <Box
      ref={ref}
      sx={{
        p: {
          ...pageContentPadding,
        },
        position: 'sticky',
        top: navBarHeight,
        zIndex: 100,
        backgroundColor: 'background.light',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
})

StickyHeader.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}

StickyHeader.defaultProps = {
  sx: {},
}

export default StickyHeader;
