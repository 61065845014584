import React from 'react';
import {isEmpty, filter, find, upperFirst} from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { graphql, useLazyLoadQuery } from 'react-relay';
import CourseDetailsDialog from './CourseDetailsDialog';
import DeleteCourseDialog from './DeleteCourseDialog';
import LoadingSpinner from '../../LoadingSpinner';
import TagList, {compareTagLists} from '../content/TagList';
import PropTypes from 'prop-types';
import { PublishStatusColors } from '@/style/constants/main'
import { latestCourseVersion } from '@/utils/courses';
import {formatDate} from '@/utils/date';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import renderCellExpand from '../common/GridCellExpand';

const CourseVersionFragment = graphql`
  fragment CoursesTable_courseVersion on CourseVersion {
    uid
    version
    status
    author {
      uid
      firstName
      lastName
      email
    }
    sections {
      uid
      name
      description
      rank
      chapters {
        uid
        name
        description
        modules {
          uid
          version
          status
          module {
            name
          }
        }
      }
    }
  }
`;

const CourseFragment = graphql`
  fragment CoursesTable_course on Course {
    uid
    name
    description
    synopsis
    learningObjectives {
      text
    }
    bannerImg {
      originalFileName
      name
      url
      size
    }
    coverImg {
      originalFileName
      name
      url
      size
    }
    coverImgDesc
    lastUpdatedTs
    versions {
      ...CoursesTable_courseVersion @relay(mask: false)
    }
    accessPlans {
      uid
      name
    }
    tags {
      id
      text
    }
    addToHomepage
  }
`;

const CoursesQuery = graphql`
  query CoursesTableQuery {
    courses(
      first: 10,
      filters: {accessPlans: {site: {current: true}}}
    )
    @connection(key: "CoursesTable_courses")
    {
      edges {
        node {
          id
          ...CoursesTable_course @relay(mask: false)
        }
      }
    }
  }
`;


const CoursesTable = (props) => {
  const {
    initialSelection,
    initialSearchText,
    editable,
    selectable,
    isRowSelectable,
    onCoursesSelected,
  } = props;
  const data = useLazyLoadQuery(
    CoursesQuery,
    {},
  );
  const displayMessage = React.useContext(DisplayMessageContext);
  const [isCourseDialogOpen, setIsCourseDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [searchText, setSearchText] = React.useState(initialSearchText ?? '');
  const [rowSelectionModel, setRowSelectionModel] = React.useState(initialSelection ?? []);

  const editCourse = React.useCallback(
    (course) => () => {
      setSelectedCourse(course);
      setIsCourseDialogOpen(true);
    },
  [],
  );
  const deleteCourse = React.useCallback(
    (course) => () => {
      setSelectedCourse(course);
      setIsDeleteDialogOpen(true);
    },
    [],
  );
  const handleCourseDialogClosed = React.useCallback(
    (msg) => {
      setIsCourseDialogOpen(false);
      setSelectedCourse(null);
      if (!isEmpty(msg) && displayMessage) {
        displayMessage(msg);
      }
    },
    [displayMessage],
  );
  const onCloseDeleteDialog = React.useCallback(
    () => {
      setIsDeleteDialogOpen(false);
      setSelectedCourse(null);
    },
    [],
  );
  const onSearchTextChanged = React.useCallback(
    (evt) => {
      const val = evt.target.value;
      setSearchText(val);
    },
    []
  )
  const onRowSelectionChanged = React.useCallback(
    (newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
      const selectedCourses = filter(data.courses.edges, (item) => (
        find(newRowSelectionModel, (uid) => uid === item.node.uid)
      ))
      .map((item) => item.node);
      onCoursesSelected(selectedCourses);
    },
    [data.courses.edges, onCoursesSelected]
  )

  const columns = React.useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        width: 220,
        renderCell: ({value}) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value}
          </Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        width: 100,
        renderCell: (params) => (
          <Chip
            label={upperFirst(params.value)}
            size="small"
            sx={{
              bgcolor: PublishStatusColors[params.value] ?? 'default',
              color: 'white',
            }}
          />
        )
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 200,
        width: 220,
        renderCell: (params) => (
          <TagList tags={params.value} maxTagsToDisplay={4} />
        ),
        sortComparator: compareTagLists,
      },
      { field: 'accessPlans',
        renderHeader: (params) => (
          <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
            Groups with Access
          </Typography>
        ),
        minWidth: 220,
        width: 220,
        sortable: false,
        renderCell: (params) => renderCellExpand(params, (value) => (value || []).join(', ')),
      },
      {
        field: 'author',
        headerName: 'Author',
        minWidth: 120,
        width: 120,
        valueGetter: ({value}) => {
          if (!value) return '';
          let n = value.firstName;
          if (value.lastName) {
            n += ` ${value.lastName[0]}.`;
          }
          return n;
        },
        renderCell: ({value}) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value}
          </Typography>
        ),
      },
      {
        field: 'version',
        headerAlign: 'center',
        headerName: 'Version',
        align: 'center',
        minWidth: 40,
        valueFormatter: (params) => {
          return params.value ? `v${params.value}` : '';
        },
      },
      {
        field: 'last_updated',
        renderHeader: (params) => (
          <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
            Last Updated
          </Typography>
        ),
        type: 'dateTime',
        valueGetter: ({ value }) => value && new Date(value),
        minWidth: 90,
        width: 135,
        renderCell: ({ value }) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value ? formatDate(value) : ''}
          </Typography>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 30,
        getActions: (params) => {
          const actions = [];
          if (editable) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Edit" arrow><EditIcon/></Tooltip>}
                key='action_edit_course'
                label="Edit"
                onClick={editCourse(params.row.course)}
              />
            )
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>}
                key='action_delete_course'
                label="Remove"
                onClick={deleteCourse(params.row.course)}
              />
            )
          }
          return actions;
        }
      }
    ],
     [deleteCourse, editable, editCourse],
  );

  const rows = data.courses.edges.map((item) => {
    const course = item.node;
    const version = latestCourseVersion(course);
    return {
      id: course.uid,
      name: course.name,
      status: version.status,
      tags: course.tags
        .map((tag) => tag.text)
        .sort((t1, t2) => t1.toLowerCase().localeCompare(t2.toLowerCase())),
      accessPlans: course.accessPlans.map((ap) => ap.name),
      author: version.author,
      version: version.version,
      last_updated: course.lastUpdatedTs,
      course,
    }
  }).filter((item) => {
    if (!searchText) return true;
    const st = searchText.toLowerCase();
    return item.name.toLowerCase().includes(st) || (
      item.tags.filter((tag) => (tag.toLowerCase().includes((st))))
    ).length > 0;
  })
  return (
    <Box sx={{mt: '5px'}}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            size='small'
            placeholder='Search topic or name'
            onChange={onSearchTextChanged}
          />
          <Button
            aria-label='Create course'
            variant="outlined"
            size='small'
            onClick={() => {setIsCourseDialogOpen(true)}}
          >
            CREATE COURSE
          </Button>
        </Stack>
        <Box sx={{width: '100%'}}>
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            rowHeight={70}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
              sorting: {
                sortModel: [
                  {
                    field: 'name',
                    sort: 'asc',
                  },
                ],
              },
            }}
            sortingOrder={['asc', 'desc']}
            pageSizeOptions={[10, 20]}
            checkboxSelection={selectable}
            isRowSelectable={(params) => selectable && isRowSelectable(params.row)}
            onRowSelectionModelChange={onRowSelectionChanged}
            rowSelectionModel={rowSelectionModel}
            sx={{
              minHeight: '60vh'
            }}
          />
        </Box>
      </Stack>
      {isCourseDialogOpen &&
        <React.Suspense fallback={<LoadingSpinner />}>
          <CourseDetailsDialog
            isOpen={isCourseDialogOpen}
            course={selectedCourse}
            displayMessage={displayMessage}
            onClose={handleCourseDialogClosed}
          />
        </React.Suspense>
      }
      {isDeleteDialogOpen && (
        <DeleteCourseDialog
          course={selectedCourse}
          isOpen={isDeleteDialogOpen}
          displayMessage={displayMessage}
          onClose={onCloseDeleteDialog}
        />
      )}
    </Box>
  );
}

CoursesTable.propTypes = {
  initialSelection: PropTypes.array,
  initialSearchText: PropTypes.string,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  onCoursesSelected: PropTypes.func,
}

CoursesTable.defaultProps = {
  initialSelection: null,
  initialSearchText: null,
  editable: true,
  selectable: false,
  isRowSelectable: (row) => true,
}

export default CoursesTable;
