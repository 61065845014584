import {isEmpty} from 'lodash';
import React from 'react';
import {ConnectionHandler, graphql, useLazyLoadQuery} from 'react-relay';

const TagsQuery = graphql`
  query useTags_TagsQuery {
      tags(
          first: 100,
          filters: {site: {current: true}}
      )
      @connection(key: "useTags_tags")
      {
          edges {
              node {
                  id
                  text
              }
          }
      }
  }
`;
export const useTags = () => {
  const tagsData = useLazyLoadQuery(TagsQuery);
  const tags = React.useMemo(() => {
     return tagsData.tags.edges.map((edge) => edge.node)
  }, [tagsData.tags.edges]);

  const updateTags = (store, tagProxyList) => {
    const newTags = tagProxyList.filter(
      (tagProxy) => tags.find((tag) => tag.id === tagProxy.getValue('id')) === undefined
    );
    if (!isEmpty(newTags)) {
      console.log('useTags::updateTags: newTags=', newTags);
      const tagsConnection = ConnectionHandler.getConnection(
        store.getRoot(),
        'useTags_tags',
        {filters: {site: {current: true}}}
      );
      if (tagsConnection) {
        newTags.forEach((newTag) => {
          const edge = ConnectionHandler.createEdge(
            store,
            tagsConnection,
            newTag,
            'Tag'
          )
          ConnectionHandler.insertEdgeAfter(tagsConnection, edge);
        })
      }
    }
  }

  const deleteTags = (store, tagsToDelete) => {
    if (!isEmpty(tagsToDelete)) {
      console.log('useTags::deleteTags: tagsToDelete=', tagsToDelete);
      const tagsConnection = ConnectionHandler.getConnection(
        store.getRoot(),
        'useTags_tags',
        {filters: {site: {current: true}}}
      );
      if (tagsConnection) {
        tagsToDelete.forEach((tag) => {
          console.log('useTags::deleteTags: tag=', tag);
          ConnectionHandler.deleteNode(tagsConnection, tag.id);
        })
      }
    }
  }

  return {
    tags,
    updateTags,
    deleteTags,
  };
}
