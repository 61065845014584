import React from 'react';
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

const TabsOverflowVisible = styled(Tabs)(({theme}) => ({
  '&.MuiTabs-root': {
    overflow: 'visible',
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
  },
  '& .MuiButtonBase-root': {
    padding: '8px',
    marginRight: '4px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
}));

const ProjectTabs = (props) => {
  const {
    tabValue,
    changeTabValue,
    children,
  } = props;

  const onTabValueChanged = (event, value) => {
    event.preventDefault();
    changeTabValue(value);
  }

  return (
    <TabsOverflowVisible
      textColor='inherit'
      value={tabValue || 0}
      variant='fullWidth'
      onChange={onTabValueChanged}
      TabIndicatorProps={{
        sx: {
          display: 'none',
        }
      }}
    >
      {children}
    </TabsOverflowVisible>
  );
};

ProjectTabs.propTypes = {
  tabValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  changeTabValue: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default ProjectTabs;