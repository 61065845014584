import React from 'react';
import Box from '@mui/material/Box';
import NsLogo from '/static/ns/img/logo_footer.svg';
import StanfordLogo from '/static/stanford/img/logo.svg';
import {Link as MuiLink} from '@mui/material';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {pageContentPaddingX} from '../../style/constants/homepage';
import {CURR_SITE, HOMEPAGE_URL, SITE_STANFORD} from '../../constants';

const site = CURR_SITE.name;
const Logo = site === SITE_STANFORD ? StanfordLogo : NsLogo;
const logoSize = site === SITE_STANFORD
  ? {xs: '46px'}
  : {xs: '24px', sm: '46px'};
const org = site === SITE_STANFORD ? 'SPARK' : 'SPARK NS';

const pages = [
  {label: site === SITE_STANFORD ? 'Contact Us' : 'Contact', link: '/contact-us'},
  {label: 'Privacy', link: '/privacy-policy'},
  {label: 'Terms', link: '/terms'},
];

const externalLinksStanford = [
  {label: 'About SPARK at Stanford', link: 'https://sparkmed.stanford.edu/'},
  {label: 'X/Twitter', link: 'https://twitter.com/SPARK_Stanford'},
  {label: 'LinkedIn', link: 'https://www.linkedin.com/company/spark-stanford'},
];

const externalLinksNs = [
  {label: 'About SPARK NS', link: 'https://sparkns.org/'},
  {
    img: '/static/common/img/linkedin_Light_Grey.svg',
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/company/sparkns/',
    sx: {height: "20px", width: "20px"}
  },
];

const externalLinks =
  site === SITE_STANFORD
    ? externalLinksStanford
    : externalLinksNs;

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      py: {xs: '40px', lg: '0px'},
      px: {...pageContentPaddingX},
      minHeight: '136px',
      backgroundColor: 'background.footer',
      display: 'flex',
      flexDirection: {xs: 'column', lg: 'row'},
      alignItems: {xs: 'flex-start', lg: 'center'},
    }}>
      <Box
        component='img'
        sx={{
          pb: '10px',
          height: logoSize,
          maxHeight: {xs: '167px', md: '233px'},
          maxWidth: {xs: '250px', md: '350px'},
          mr: {md: '170px', lg: '225px'},
          mb: {xs: '50px', lg: '0px'},
          cursor: 'pointer',
        }}
        src={Logo}
        alt='Logo of SPARK at Stanford'
        onClick={
          (e) => navigate(HOMEPAGE_URL)
        }
      />
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column', lg: 'row'},
        alignItems: {xs: 'flex-start', lg: 'center'},
      }}>
        {
          pages.map(({label, link}) => {
            return (
              <MuiLink
                key={label}
                component={RouterLink}
                underline='none'
                to={link}
                sx={{
                  mr: {xs: 0, lg: 4},
                  mb: {xs: 4, lg: 0},
                  color: 'text.footerItem',
                  textTransform: 'none',
                }}
              >
                <Typography
                  sx={{
                    fontSize: {xs: '14px', sm: '16px'},
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}
                </Typography>
              </MuiLink>
            )
          })
        }

        {
          externalLinks.map((item) => {
            return (
              <MuiLink
                href={item.link}
                key={item.link}
                target='_blank'
                rel='noopener'
                underline='none'
                sx={{
                  mr: {xs: 0, lg: 4},
                  mb: {xs: 4, lg: 0},
                  color: 'text.footerItem',
                  textTransform: 'none',
                }}
              >
                {item.img
                ?
                  <Box
                    component='img'
                    src={item.img}
                    alt={item.label}
                    sx={item?.sx ?? {}}
                  />
                :
                  <Typography sx={{whiteSpace: 'nowrap'}}>
                    {item.label}
                  </Typography>
                }
              </MuiLink>
            )
          })
        }

        <Typography sx={{
          color: 'text.copyright',
          fontSize: '14px',
          whiteSpace: 'nowrap',
        }}>
          &copy; 2024 {org}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;