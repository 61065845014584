import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const CourseHeader = (props) => {
  const {
    course
  } = props;
  const navigate = useNavigate();
  const coverImgDesc = course?.coverImgDesc ?? '';
  return (
    <Box
      title={coverImgDesc}
      role='banner'
      aria-label={coverImgDesc}
      sx={{
        height: 260,
        backgroundImage: `url(${course?.bannerImg?.url ?? course?.coverImg?.url ?? ''})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundColor: 'gray',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#0005',
          backgroundBlendMode: 'darken',
          width: '100%',
          py: 1,
        }}
      >
        <Typography
          variant='body2'
          onClick={() => {navigate('/directed-learning/')}}
          sx={{
            ml: {xs: 2.5, md: 5},
            cursor: 'pointer',
          }}
        >
          {'<'} Return to Home
        </Typography>
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        {course && course.name
          ?
            <Typography
              variant='h2'
              component='p'
              sx={{
                height: '100%',
                alignContent: 'center',
                ml: {xs: 2.5, md: 5},
                fontSize: {xs: '2.25rem', sm: '2.5rem', md: '3rem'},
              }}
            >
              {course?.name ?? ''}
            </Typography>
          :
            <Alert severity="error" variant="filled">Requested course not found.</Alert>
        }
      </Box>
    </Box>
  )
}

CourseHeader.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string.isRequired,
    bannerImg: PropTypes.shape({
      url: PropTypes.string,
    }),
    coverImg: PropTypes.shape({
      url: PropTypes.string,
    }),
    coverImgDesc: PropTypes.string,
  }).isRequired
}

export default CourseHeader;
