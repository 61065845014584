import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ChapterAccordion from './ChapterAccordion';
import {useDragAndDrop} from '../hooks/useDragAndDrop';
import {dummyBorderStyleChapter} from '@/constants/dragAndDrop';
import PropTypes from 'prop-types';
import CircularProgress from "@mui/material/CircularProgress";

export const ChaptersManagerQuery = graphql`
  query ChaptersManagerQuery($sectionId: GlobalID!)  {
    node(id: $sectionId) {
      ... on Section {
        uid  
        chapters {
          uid
          name
          defaultModules {
            uid
            name
          }
        }
      }
    }
  }
`;

const ChaptersManagerMutation = graphql`
  mutation ChaptersManagerMutation(
    $data: UpdateModulesOrderInput!,
  ) {
    updateModulesOrder(data: $data)
  }
`;

const ChaptersManager = (props) => {
  const {
    sectionId,
  } = props;

  const {node} = useLazyLoadQuery(
    ChaptersManagerQuery,
    {sectionId}
  );

  const [updateMutation, isMutationInProgress] = useMutation(ChaptersManagerMutation);
  const [isDirtyCombined, setIsDirtyCombined] = useState(false);

  const getInitialSectionState = () => {
    return {
      uid: node.uid,
      isDirty: false,
      chapters: node.chapters.map(chapter => chapter.uid),
    }
  };

  const getInitialChaptersState = () => {
    const chapters = node.chapters.reduce((acc, chapter) => {
      acc[chapter.uid] = {
        modules: chapter.defaultModules.map(module => module.uid),
        isDirty: false,
      };
      return acc;
    }, {});
    return {
      sectionUid: node.uid,
      chapters: chapters,
    }
  };

  const [sectionState, setSectionState] = useState(
    getInitialSectionState()
  );

  const [initialSectionState, setInitialSectionState] = useState(
    getInitialSectionState()
  )

  const [chaptersState, setChaptersState] = useState(
    getInitialChaptersState()
  );

  const [initialChaptersState, setInitialChaptersState] = useState(
    getInitialChaptersState()
  );

  const handleSectionStateChanged = (chapters) => {
    setSectionState(prev => ({
      ...prev,
      isDirty: true,
      chapters: chapters,
    }));
    setIsDirtyCombined(true);
  };

  const handleChapterStateChanged = (chapterUid, chapterModules) => {
    setChaptersState(prev => ({
      ...prev,
      chapters: {
        ...prev.chapters,
        [chapterUid]: {
          isDirty: true,
          modules: chapterModules,
        }
      }
    }));
    setIsDirtyCombined(true);
  };

  useEffect(() => {
    setSectionState((prev) => getInitialSectionState());
    setInitialSectionState(getInitialSectionState())
    setChaptersState((prev) => getInitialChaptersState());
    setInitialChaptersState(getInitialChaptersState())
    setIsDirtyCombined(false);
  }, [node]);

  const displayMessage = useContext(DisplayMessageContext);

  const [chapterExpanded, setChapterExpanded] = useState(
    node.chapters.reduce((acc, chapter) => {
      acc[chapter.uid] = true;
      return acc;
    }, {}),
  );

  const handleChapterExpanded = (chapterUid, isExpanded) => {
    setChapterExpanded((prev) => {
      return {
        ...prev,
        [chapterUid]: isExpanded,
      }
    });
  }

  const chaptersRefMap = useRef({});

  const chaptersRefMapCb = useCallback((chapterUid, node) => {
    chaptersRefMap.current[chapterUid] = node;
   }, [chaptersRefMap]);

  const [
    handleDragStart,
    handleDragEnd,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  ] = useDragAndDrop(
    sectionState.chapters,
    handleSectionStateChanged,
    chaptersRefMap,
    dummyBorderStyleChapter,
  );

  const getChapterData = (uid) => {
    return node.chapters.find((ch) => ch.uid === uid );
  };

  const renderDraggableContainer = (chapterUid, children) => {
    return (
      <div
        ref={(node) => chaptersRefMapCb(chapterUid, node)}
        draggable="true"
        style={{
          border: dummyBorderStyleChapter,
        }}
        key={chapterUid}
        onDragStart={(evt) => handleDragStart(evt, chapterUid)}
        onDragEnd={(evt) => handleDragEnd(evt, chapterUid)}
        onDrop={(evt) => handleDrop(evt, chapterUid)}
        onDragOver={(evt) => handleDragOver(evt, chapterUid)}
        onDragLeave={(evt) => handleDragLeave(evt, chapterUid)}
      >
        {children}
      </div>
    )
  };

  const onSubmit = () => {
    const data = {}
    const onlyDirtyChapters = Object.entries(chaptersState.chapters)
      .filter(([uid, chapter]) => chapter.isDirty)
      .map(([uid, chapter]) => ({
        uid,
        modules: chapter.modules,
      }));
    if (onlyDirtyChapters.length > 0) {
      data.chapters = onlyDirtyChapters
    }
    if (sectionState.isDirty) {
      data.section = {
        uid: node.uid,
        chapters: sectionState.chapters
      }
    }
    const variables = {data}
    updateMutation({
      variables,
      onCompleted: (data) => {
        setInitialSectionState(sectionState);
        setInitialChaptersState(chaptersState);
        setIsDirtyCombined(false);
        console.log('In Modules::onSubmit::onCompleted. data=', JSON.stringify(data));
        displayMessage({info: 'Modules have been updated.'});
      },
      onError: (err) => {
        console.error("Error performing mutation updateModulesOrder:", err);
        displayMessage({error: 'Error updating Modules. Please try again later.'});
      },
    })
  }

  const onReset = () => {
    setSectionState(initialSectionState);
    setChaptersState(initialChaptersState);
    setIsDirtyCombined(false);
  }

  return node.uid === sectionState.uid && node.uid === chaptersState.sectionUid && (
    <Box
      sx={{
        px: 3,
        mt: 2,
      }}
    >
      <Stack
        direction='column'
        gap={3}
        alignContent='start'
      >
        {sectionState.chapters.map((chapterUid) => (
          <ChapterAccordion
            key={chapterUid}
            chapterData={getChapterData(chapterUid)}
            expanded={chapterExpanded[chapterUid]}
            onExpanded={handleChapterExpanded}
            chapterState={chaptersState.chapters[chapterUid]}
            handleChapterStateChanged={handleChapterStateChanged}
            renderDraggableContainer={renderDraggableContainer}
          />
        ))}
        <Stack
          direction='row'
          gap={1}
          alignSelf='flex-end'
        >
          {isDirtyCombined &&
            <Button
              variant='outlined'
              onClick={() => onReset()}
            >
              Reset
            </Button>
          }
          <Button
            variant='contained'
            disabled={!isDirtyCombined || isMutationInProgress}
            onClick={() => onSubmit()}
          >
            {
              isMutationInProgress
                ? (
                  <CircularProgress
                    size='16px'
                    aria-label='Saving progress circle'
                    color='inherit'
                  />
                )
                : 'Save'
            }
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
};

ChaptersManager.propTypes = {
  sectionId: PropTypes.string.isRequired,
}

export default ChaptersManager;