import {cloneDeep, concat, take, takeRight, without} from 'lodash';
import React from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import Paper from '@mui/material/Paper';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseSelectionDialog from '../course-builder/CourseSelectionDialog';


const CourseList_CourseCatalogItemFragment = graphql`
  fragment CourseList_CourseCatalogItem on CourseCatalogItem {
    id
    rank
    course {
      ...CoursesTable_course @relay(mask: false)
    }
  }
`;

export const CourseListQuery = graphql`
  query CourseListQuery {
    courseCatalog(
      first: 100,
      filters: {
        course: {accessPlans: {site: {current: true}}}
      }
    )
    @connection(key: "CourseList_courseCatalog")
    {
      edges {
        node {
          id
          ...CourseList_CourseCatalogItem @relay(mask: false)
        }
      }
    }
  }
`;

const SetCourseListMutation = graphql`
  mutation CourseList_SetCourseCatalogItemsMutation(
    $courseUids: [Uid!]!,
  ) {
    setCourseCatalogItems(
        items: $courseUids,
    ) {
        ...CourseList_CourseCatalogItem @relay(mask: false)
    }
  }
`;

const CourseList = (props) => {
  const data = useLazyLoadQuery(CourseListQuery, {});
  const [updateMutation, isMutationInProgress] = useMutation(SetCourseListMutation);
  const [isCourseDialogOpen, setIsCourseDialogOpen] = React.useState(false);

  const displayMessage = React.useContext(DisplayMessageContext);
  const [isDirty, setIsDirty] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(data.courseCatalog.edges.map((item) => item.node.course));
  const [courses, setCourses] = React.useState(initialValues);

  const [draggingCourseIdx, setDraggingCourseIdx] = React.useState(null);



  const onSave = () => {
    onSubmit(courses);
  }
  const onReset = () => {
    setCourses(cloneDeep(initialValues));
    setIsDirty(false);
  }
  const onSubmit = (courses) => {
    const courseUids = courses.map((c) => c.uid);
    console.log('In CourseList::onSubmit. vars=', JSON.stringify(courseUids));
    const variables = {courseUids};
    updateMutation({
      variables,
      onCompleted: (data) => {
        console.log('In CourseList::onSubmit::onCompleted. data=', JSON.stringify(data));
        setIsDirty(false);
        const updatedCourseList = data.setCourseCatalogItems;
        setInitialValues(updatedCourseList);
        displayMessage({info: 'Course list has been updated.'});
      },
      onError: (err) => {
        console.error("Error performing mutation setCourseCatalogItems:", err);
        displayMessage({error: 'Error updating Course List. Please try again later.'});
      },
    })

  }

  const onAddCourse = (evt) => {
    setIsCourseDialogOpen(true);
  }
  const onRemoveCourse = (courseUid) => {
    setIsDirty(true);
    setCourses((courses) =>
      courses.filter((course) => course.uid !== courseUid)
    );
  }
  const onCourseSelectionDialogClose = (selectedCourses) => {
    setIsCourseDialogOpen(false);
    if (selectedCourses === null) {
      return;
    }
    setIsDirty(true);
    setCourses(selectedCourses);
  }
  const handleDragStart = (evt, idx) => {
    setDraggingCourseIdx(idx);
  }
  const handleDragEnd = (evt) => {
    evt.target.style.boxShadow = 'none';
  }
  const handleDrop = (evt, idx) => {
    evt.preventDefault();
    if (idx === draggingCourseIdx) return;
    const courseToDrop = courses[draggingCourseIdx];
    const withoutDropped = without(courses, courseToDrop);
    setCourses(
      concat(
        take(withoutDropped, idx),
        courseToDrop,
        takeRight(withoutDropped, withoutDropped.length-idx)
      )
    )
    setIsDirty(true);
  }
  const handleDragOver = (evt) => {
    evt.preventDefault();
    if (evt.target.className === 'course') {
      evt.target.style.boxShadow = '0 4px 3px grey';
    }
  }
  const handleDragLeave = (evt) => {
    evt.target.style.boxShadow = 'none';
  }

  return (
    <>
      <Box
        id='panel-course-list'
        role='tabpanel'
        aria-labelledby='tab-course-list'
        sx={{ px: 3 }}
      >
        <Stack direction='column' gap={3} alignContent='start'>
          <Box className='container-courses' sx={{mt: 1}}>
            {courses.map((course, idx) => (
              <Paper
                key={`course-${idx}`}
                draggable
                elevation={0}
                className='course'
                sx={{backgroundColor: '#ECEFF1', py: '4px', my: '4px'}}
                onDragStart={(evt) => handleDragStart(evt, idx)}
                onDragEnd={(evt) => handleDragEnd(evt, idx)}
                onDrop={(evt) => handleDrop(evt, idx)}
                onDragOver={(evt) => handleDragOver(evt, idx)}
                onDragLeave={(evt) => handleDragLeave(evt, idx)}
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                >
                  <Stack
                    direction='row'
                    gap={1}
                    alignItems='center'
                    className='courseTitle'
                    sx={{ml: 2}}
                  >
                    <DragIndicatorIcon sx={{ fontSize: 15 }} />
                    <Typography>
                      {course?.name ?? (course?.course?.name ?? `Course ${idx}`)}
                    </Typography>
                  </Stack>
                  <IconButton
                    aria-label='Remove Course'
                    onClick={(evt) => onRemoveCourse(course.uid)}
                  >
                    <Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>
                  </IconButton>
                </Stack>
              </Paper>
            ))}
          </Box>
          <Button
            aria-label='Add course'
            variant='text'
            onClick={onAddCourse}
            sx={{alignSelf: 'start'}}
          >
            + Add Course
          </Button>
          <Stack
            direction='row'
            gap={1}
            alignSelf='flex-end'
          >
            {isDirty &&
              <Button
                aria-label='Reset'
                variant='outlined'
                onClick={onReset}
              >
                Reset
              </Button>
            }
            <Button
              aria-label='Save'
              variant='contained'
              disabled={!isDirty || isMutationInProgress}
              onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
      {isCourseDialogOpen &&
        <CourseSelectionDialog
          isOpen={isCourseDialogOpen}
          initialSelection={courses.map((c) => c.uid)}
          title='Courses'
          isRowSelectable={(row) => row?.status === 'published'}
          onClose={onCourseSelectionDialogClose}
        />
      }
    </>
  )
}

export default CourseList;
