import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {STUDY_UNIT_COURSE, STUDY_UNIT_MODULE} from './constants';
import {getButtonPerProgress, getStatusPerProgress} from '../course-details/utils';
import {formatDate} from '@/utils/date';

const UserProgressQuery = graphql`
  query UserProgressDialogQuery {
    me {
      id
      modulesProgress {
        ...UserProgressPanel_ModulesProgress @relay(mask: false)
      }
      coursesProgress {
        ...UserProgressPanel_CoursesProgress @relay(mask: false)
      }
    }
  }
`;


export default function UserProgressDialog(props) {
  const {
    isOpen,
    onClose,
  } = props;
  const {me} = useLazyLoadQuery(UserProgressQuery, {});
  const navigate = useNavigate();
  const [studyUnitType, setStudyUnitType] = React.useState(STUDY_UNIT_MODULE);
  const modulesProgress = me.modulesProgress;
  const coursesProgress = me.coursesProgress;
  const moduleRows = modulesProgress.map((item) => {
    return {
      type: STUDY_UNIT_MODULE,
      id: item.moduleVersion.uid,
      name: item.moduleVersion.module.name,
      startTs: item?.startTs ?? 0,
      progress: item.progressPercent,
    }
  })
  const courseRows = coursesProgress.map((item) => {
    return {
      type: STUDY_UNIT_COURSE,
      id: item.courseVersion.course.uid,
      name: item.courseVersion.course.name,
      startTs: item?.startTs ?? 0,
      progress: item.progressPercent,
    }
  })

  const handleStudyUnitTypeChange = React.useCallback(
    (evt, newValue) => {
      setStudyUnitType(newValue);
    },
    [],
  )
  const handleActionClick = React.useCallback(
    (evt, row) => {
      evt.preventDefault();
      onClose();
      if (row.type === STUDY_UNIT_MODULE) {
        navigate(`/self-guided-learning/?launchMvUid=${row.id}`);
      }
      else if (row.type === STUDY_UNIT_COURSE) {
        navigate(`/course/${row.id}/`)
      }
    },
    [navigate, onClose],
  )
  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  const columns = React.useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.1,
        minWidth: 180,
        width: 200,
        renderCell: ({value}) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value}
          </Typography>
        ),
      },
      {
        field: 'startTs',
        renderHeader: (params) => (
          <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
            Start Date
          </Typography>
        ),
        type: 'dateTime',
        valueGetter: ({value}) => value && new Date(value),
        minWidth: 140,
        width: 140,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value ? formatDate(value, true) : ''}
          </Typography>
        ),
      },
      {
        field: 'progress',
        headerName: 'Progress',
        minWidth: 120,
        width: 120,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => getStatusPerProgress(value, (progress) => `${progress}%`)
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 30,
        getActions: (params) => {
          const actions = [];
          actions.push(
            getButtonPerProgress(params.row.progress, '', {
              onClick: (evt) => handleActionClick(evt, params.row)
            })
          )
          return actions;
        }
      }
    ],
     [handleActionClick],
  );

  const rows = studyUnitType === STUDY_UNIT_MODULE ? moduleRows : courseRows;

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>My Progress</DialogTitle>
      <DialogContent sx={{p: 2}}>
        <Box>
          <Tabs
            value={studyUnitType}
            onChange={handleStudyUnitTypeChange}
            aria-label="Study Unit Types"
          >
            <Tab label="Modules" value={STUDY_UNIT_MODULE} />
            <Tab label="Courses" value={STUDY_UNIT_COURSE} />
          </Tabs>
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            rowHeight={70}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
            sx={{
              minHeight: '60vh'
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button aria-label='Close' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

UserProgressDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

UserProgressDialog.defaultProps = {
  isOpen: false,
}
