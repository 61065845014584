import React from 'react';
import {useModuleLaunch} from '../hooks/useModuleLaunch';
import Box from '@mui/material/Box';
import {moduleSearchCardsContainerSx} from '../../style/constants/search';
import SearchModuleCard from './SearchModuleCard';
import Cmi5ViewerDialog from '../cmi5-viewer/Cmi5ViewerDialog';
import ModuleDataWrapper from '../common/data-wrappers/ModuleDataWrapper';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../LoadingSpinner';


const SearchModulesList = (props) => {
  const { searchResults: defaultModules } = props;
  const [
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    isViewDialogOpen,
    handleCloseDialog,
    nextVersionedModule,
    handleNextVersionedModuleChanged,
  ] = useModuleLaunch();

  const renderModuleCard = React.useCallback((
    {
      moduleData,
      progressValue,
      progressBarVariant,
      isProgressLoading,
      onClick,
    }
  ) => (
      <SearchModuleCard
        key={moduleData.uid}
        moduleData={moduleData}
        progressValue={progressValue}
        progressBarVariant={progressBarVariant}
        isProgressLoading={isProgressLoading}
        onClick={onClick}
      />
    ), []);

  const moduleCards = React.useMemo(() => (
    <>
      {
        defaultModules.map(module => {
          return (
            <ModuleDataWrapper
              key={module.uid}
              module={module}
              selectedVersionedModule={selectedVersionedModule}
              handleNextVersionedModuleChanged={handleNextVersionedModuleChanged}
              handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
              renderChildren={renderModuleCard}
            />
          );
        })
      }
    </>
    ),
    [defaultModules, selectedVersionedModule?.uid, handleNextVersionedModuleChanged,
      handleSelectedVersionedModuleChanged, renderModuleCard]
  );


  return (
    <Box
      sx={{
        ...moduleSearchCardsContainerSx,
        mb: 5,
      }}
    >
      {moduleCards}
      <React.Suspense fallback={<LoadingSpinner/>}>
        {selectedVersionedModule &&
          <Cmi5ViewerDialog
            isOpen={isViewDialogOpen}
            moduleVersion={selectedVersionedModule}
            getNextModule={() => nextVersionedModule}
            onClose={handleCloseDialog}
            handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
          />
        }
      </React.Suspense>
    </Box>
  );
};

SearchModulesList.propTypes = {
  searchResults: PropTypes.array,
}

export default SearchModulesList;