import React from 'react';
import Box from '@mui/material/Box';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

const PlayIcon = () => {
  return (
    <Box sx={{
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'background.light',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      zIndex: '100',
    }}>
      <PlayArrowRoundedIcon sx={{
        fontSize: '64px'
      }}/>
    </Box>
  );
};

export default PlayIcon;