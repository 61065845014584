import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SearchTextField from '../common/search/SearchTextField';
import {pageContentPaddingX} from '../../style/constants/homepage';

const SearchContainer = (props) => {
  const {
    filterText,
    updateFilterText,
  } = props;

  return (
    <Box sx={{
      px: {...pageContentPaddingX},
      py: {xs: '24px'},
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      justifyContent: {xs: 'flex-start', md: 'center'} ,
      alignItems: {xs: 'flex-start', md: 'center'} ,
    }}>

      <Typography sx={{
        fontSize: {xs: '20px', md: '24px'},
        mb: {xs: '24px', md: '0px'},
        mr: {xs: '0px', md: '24px'}
      }}>
        {'Looking for specific content?'}
      </Typography>

      <Box
        sx={{
          width: {xs: '100%', md: 'auto'}
        }}
      >
        <SearchTextField
          filterText={filterText}
          updateFilterText={updateFilterText}
          placeholder='Search Content'
        />
      </Box>
    </Box>
  );
};

SearchContainer.propTypes = {
  filterText: PropTypes.string.isRequired,
  updateFilterText: PropTypes.func.isRequired,
}

export default SearchContainer;