import React from 'react';
import Box from '@mui/material/Box';
import {navBarHeight} from '../../style/constants/main';
import PropTypes from 'prop-types';
import {pageContentPadding} from '../../style/constants/self-guided';
import SectionsTabs from './SectionsTabs';
import SectionContentCards from './SectionContentCards';
import {contentPb, contentPt} from '../../style/constants/main';
import FocusPageSearchField from '../common/search/FocusPageSearchField';

export const sectionsColors = {
  0: 'sectionFirstOption',
  1: 'sectionSecondOption',
  2: 'sectionThirdOption',
}

const SectionsNavigation = (
  {
    sectionValue,
    handleSectionTabChanged,
    stickyPartRef,
    areCardsHidden,
    onSearchFocused,
    isDataLoaded,
  }
) => {

  return (
    <Box
      ref={stickyPartRef}
      sx={{
        px: {
          ...pageContentPadding,
        },
        pt: {...contentPt},
        pb: {...contentPb},
        position: 'sticky',
        top: navBarHeight,
        zIndex: 100,
        backgroundColor: 'background.light',
      }}
    >
      {/* Search Section */}
      <FocusPageSearchField
        onSearchFocused={onSearchFocused}
      />

      {/* Tabs Section */}
      <Box>
        <SectionsTabs
          sectionValue={sectionValue}
          handleSectionTabChanged={handleSectionTabChanged}
          getSectionRank={(section) => section.frontendId}
          isDataLoaded={isDataLoaded}
        />
      </Box>

      {/* Cards Section */}
      <Box sx={{
        transition: 'max-height 0.8s ease',
        maxHeight: areCardsHidden ? '0px' : '600px',
        overflow: 'hidden',
      }}>
        <Box sx={{
          mt: '8px',
        }}>
          <SectionContentCards
            sectionValue={sectionValue}
            handleSectionTabChanged={handleSectionTabChanged}
            isDataLoaded={isDataLoaded}
          />
        </Box>
      </Box>
  </Box>
);};

SectionsNavigation.propTypes = {
  sectionValue: PropTypes.number,
  handleSectionTabChanged: PropTypes.func.isRequired,
  stickyPartRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  areCardsHidden: PropTypes.bool.isRequired,
  onSearchFocused: PropTypes.func.isRequired,
  isDataLoaded: PropTypes.bool.isRequired,
}

export default SectionsNavigation;
