/**
 * @generated SignedSource<<c9349c8837c9dfb0f10b43d342da8edd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserReportField",
    "kind": "LinkedField",
    "name": "userReportFields",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserReportDownloadDialog_FieldsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserReportDownloadDialog_FieldsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c9655f0d760561a7510bf63ff70974f1",
    "id": null,
    "metadata": {},
    "name": "UserReportDownloadDialog_FieldsQuery",
    "operationKind": "query",
    "text": "query UserReportDownloadDialog_FieldsQuery {\n  userReportFields {\n    id\n    title\n  }\n}\n"
  }
};
})();

node.hash = "9f40be531476f6759507933df75f20c2";

export default node;
