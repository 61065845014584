import {concat} from 'lodash';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {formatDate} from '@/utils/date';


const BASE_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.2,
    minWidth: 180,
    width: 200,
    renderCell: ({value}) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {value}
      </Typography>
    ),
  },
  {
    field: 'invited',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Invited
      </Typography>
    ),
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    flex: 0.2,
    minWidth: 130,
    width: 135,
    renderCell: ({ value }) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {value ? formatDate(value) : ''}
      </Typography>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 0.2,
    minWidth: 130,
    width: 140,
  },
];


const PendingUsersTable = (props) => {
  const {
    rows,
    editable,
    loading,
    paginationModel,
    rowSelectionModel,
    handlePaginationModelChange,
    handleUserSelectionChange,
    handleUserDelete,
  } = props;
  const handleRowSelectionModelChange = React.useCallback(
    (newRowSelectionModel) => {
      handleUserSelectionChange(newRowSelectionModel);
    },
    [handleUserSelectionChange]
  );

  const columns = React.useMemo(
    () => concat(
      BASE_COLUMNS,
      {
        field: 'actions',
        type: 'actions',
        minWidth: 30,
        getActions: (params) => {
          const actions = [];
          if (editable) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>}
                key='action_delete_user'
                label="Delete"
                onClick={handleUserDelete(params.id)}
              />
            )
          }
          return actions;
        }
      }
    ),
     [handleUserDelete, editable],
  );

  return (
    <>
      <DataGrid
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        rowHeight={70}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'name',
                sort: 'asc',
              },
            ],
          },
        }}
        loading={loading}
        sortingOrder={['asc', 'desc']}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 20]}
        rowSelectionModel={rowSelectionModel}
        sx={{
          minHeight: '60vh'
        }}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </>
  );
}

PendingUsersTable.propTypes = {
  editable: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  paginationModel: PropTypes.object,
  rowSelectionModel: PropTypes.array,
  handlePaginationModelChange: PropTypes.func.isRequired,
  handleUserDelete: PropTypes.func.isRequired,
  handleUserSelectionChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

PendingUsersTable.defaultProps = {
  editable: true,
}

export default PendingUsersTable;
