import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {getButtonPerProgress, getStatusPerProgress} from './utils';
import {COURSE} from './constants';


const CourseSummary = (props) => {
  const {
    course,
    courseProgress,
    onLaunchCourse,
  } = props;
  const progress = courseProgress?.progressPercent;
  const handleCourseAction = (evt) => {
    onLaunchCourse();
  }

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Stack direction='column' spacing={3}>
        <Box
          sx={{
            py: 2,
            backgroundColor: 'white',
          }}
        >
          <Stack direction='column' spacing={2}>
            {getButtonPerProgress(progress, COURSE,{
              variant: 'contained',
              fullWidth: true,
              sx: {
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#4c6e8c',
                }
              },
              onClick: (evt) => { handleCourseAction() }
            })}
            <Box alignSelf='center'>
              <Typography variant='subtitle2' textAlign='center'>
                {getStatusPerProgress(progress)}
              </Typography>
            </Box>
            <Typography variant='body2'>{course.description}</Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            p: 2,
            border: 'solid 1px #585858',
            backgroundColor: '#F5F8F9',
          }}
        >
          <Typography variant='h6'>Learning Objectives</Typography>
            <List dense component='ol' sx={{ listStyle: "decimal", pl: 3 }}>
              {course.learningObjectives.map((lo) => (
                <ListItem
                  key={lo.text}
                  sx={{
                    display: "list-item",
                    pl: '5px',
                    '&::marker': {
                      fontSize: '0.875rem',
                      fontFamily: 'Open Sans',
                    }
                  }}
                 >
                  <ListItemText
                    primary={lo.text}
                  />
                </ListItem>
              ))}
            </List>
        </Box>
      </Stack>
    </Box>
  )
}

CourseSummary.propTypes = {
  course: PropTypes.shape({
    description: PropTypes.string,
    learningObjectives: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  courseProgress: PropTypes.shape({
    progressPercent: PropTypes.number.isRequired,
  }),
  onLaunchCourse: PropTypes.func.isRequired,
}

export default CourseSummary;
