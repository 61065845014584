import {useMutation} from 'react-relay';
import {redirectToSignInIfNeeded} from '../../api';

export const useMutationWithAuthCheck = (mutation) => {
  const [commitMutation, isMutationInProgress] = useMutation(mutation);

  const commitMutationAndCheckResponse = (params) => {
    commitMutation({
      variables: params.variables,
      onCompleted: (data) => {
        console.log('In commitMutationAndCheckResponse::onCompleted. data=', JSON.stringify(data));
        if (typeof (params.onCompleted) === 'function') {
          params.onCompleted(data);
        }
      },
      onError: (errData) => {
        console.log('In commitMutationAndCheckResponse::onError. data=', JSON.stringify(errData));
        redirectToSignInIfNeeded(errData);
        if (typeof(params.onError) === 'function') {
          params.onError(errData);
        }
      }
    });
  }
  return [commitMutationAndCheckResponse, isMutationInProgress];
}
