import {isEmpty} from 'lodash';
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {CardMedia} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {
  buttonFontSize,
  buttonHeight,
  contentElementDescriptionFont,
  contentElementNameFont
} from '@/style/constants/homepage';
import PlayIcon from './PlayIcon';
import HomepageVideoModal from './HomepageVideoModal';
import {CURR_SITE} from '@/constants';

const site = CURR_SITE.name;


const SectionDescription = ({selectedSection}) => {
  const [constants, setConstants] = React.useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedSectionData = constants?.sections?.[selectedSection] ?? {};

  React.useEffect(() => {
    import(`../../constants/pages/home/sections/${site}.jsx`)
    .then(constantsModule => {
      setConstants(constantsModule.SECTIONS);
    });
  }, []);

  const getNextVideo = (videoIdx) => {
    const nextIdx = videoIdx + 1;
    if (nextIdx >= (constants?.sections ?? []).length) return null;
    return {
      videoSrc: constants.sections[nextIdx].videoSrc,
      videoTitle: constants.sections[nextIdx].videoTitle,
      videoIdx: nextIdx,
    }
  }
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      alignItems: {xs: 'center', md: 'flex-start'},
      px: {xs: '0px', lg: '67px'}
    }}>

      <Box
        sx={{
          position: 'relative',
          mb: {xs: '24px', md: '0px'},
          mr: {xs: '0px', md: '24px'},
        }}
        onClick={handleButtonClick}
      >
        <CardMedia
          sx={{
            width: '276px',
            height: '207px',
          }}
          image={selectedSectionData.source}
          alt={selectedSectionData.imgDescription}
          component='img'
        />
        <PlayIcon/>
      </Box>

      <Box sx={{
        flex: 1,
      }}>
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {...contentElementNameFont},
          mb: {xs: '10px', md: '16px'},
        }}
        >
          {`Learn About ${selectedSectionData.name}`}
        </Typography>

        <Typography sx={{
          fontSize: {...contentElementDescriptionFont},
          mb: {xs: '16px'},
        }}
        >
          {selectedSectionData.description}
        </Typography>

        <Button
          aria-label='Watch Video'
          variant='outlined'
          sx={{
            height: {...buttonHeight},
            width: {xs: '100%', md: 'auto'},
            textTransform: 'none',
            '&.MuiButtonBase-root': {
              borderWidth: '3px',
            }
          }}
          onClick={handleButtonClick}
        >
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: {...buttonFontSize}
          }}>
            Watch Video
          </Typography>
        </Button>
      </Box>
      {!isEmpty(selectedSectionData.videoSrc) && isModalOpen &&
        <HomepageVideoModal
          open={isModalOpen}
          handleModalClose={handleModalClose}
          videoSrc={selectedSectionData.videoSrc}
          title={selectedSectionData.videoTitle}
          videoIdx={selectedSection}
          getNextVideo={getNextVideo}
        />
      }
    </Box>
  );
};

SectionDescription.propTypes = {
  selectedSection: PropTypes.number,
}

export default SectionDescription;