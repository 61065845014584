import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {graphql, useLazyLoadQuery} from 'react-relay';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChaptersManager from './ChaptersManager';

export const SelfGuidedModulesManagerQuery = graphql`
  query SelfGuidedModulesManagerQuery {
    sections {
      id
      uid
      name
      description
      frontendId
    }
  }
`;

const SelfGuidedModulesManager = () => {
  const {sections} = useLazyLoadQuery(SelfGuidedModulesManagerQuery);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabValueChanged = (event, value) => {
    event.preventDefault();
    setActiveTab(value);
  }

  return (
    <Box
      sx={{
        mt: '5px',
        minHeight: '80vh',
      }}>
      <Tabs
        value={activeTab}
        onChange={handleTabValueChanged}
        aria-label="Section"
      >
        {
          sections.map((section, idx) => {
            return (
              <Tab
                key={section.name}
                label={section.name}
                value={idx}
              />
            )
          })
        }
      </Tabs>
      {
        sections?.[activeTab] && (
          <ChaptersManager sectionId={sections[activeTab].id}/>
        )
      }
    </Box>
  )
}

export default SelfGuidedModulesManager;
