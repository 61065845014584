import React, {useEffect} from 'react';
import {graphql, useFragment} from 'react-relay';
import {useRefetchProgress} from '../../hooks/useRefetchProgress';
import ModuleDataWrapperProgressQuery
  from './__generated__/ModuleDataWrapperProgressQuery.graphql';
import {useUpdateNextModule} from '../../hooks/useUpdateNextModule';
import PropTypes from 'prop-types';


const ModuleDataWrapperProgressFragment = graphql`
  fragment ModuleDataWrapperProgressFragment_progress on ModuleVersion 
  @refetchable(queryName: "ModuleDataWrapperProgressQuery")
  {
    progress {
      progressPercent
    }
  }
`;

const ModuleDataWrapperFragment = graphql`
  fragment ModuleDataWrapperFragment_defaultModules on Module {
    id
    uid
    name
    description
    coverImgDesc
    coverImg {
      url
    }
    tags {
      text
    }
    latestVersion {
      id
      uid
      versionUidOfNextModule
      ...ModuleDataWrapperProgressFragment_progress
    }
  }
`;


const ModuleDataWrapper = (props) => {
  const {
    module,
    selectedVersionedModule,
    handleNextVersionedModuleChanged,
    handleSelectedVersionedModuleChanged,
    handleModuleVersionDataLoaded,
    renderChildren,
    launchMvUid,
  } = props;

  const moduleData = useFragment(
    ModuleDataWrapperFragment,
    module,
  );

  const versionedModuleData = {...moduleData.latestVersion, moduleUid: module.uid};
  useEffect(() => {
    if (typeof(handleModuleVersionDataLoaded) === 'function') {
      handleModuleVersionDataLoaded(module.uid, versionedModuleData);
    }
  }, []);

  useEffect(() => {
    if (!!launchMvUid && launchMvUid === versionedModuleData.uid) {
      handleSelectedVersionedModuleChanged(versionedModuleData);
    }
  }, [launchMvUid, versionedModuleData.uid])

  const [
    versionedModuleProgress,
    progressBarVariant,
    isProgressLoading,
  ] = useRefetchProgress(
    ModuleDataWrapperProgressQuery,
    ModuleDataWrapperProgressFragment,
    versionedModuleData,
    selectedVersionedModule?.uid,
  );

  useUpdateNextModule(
    selectedVersionedModule,
    versionedModuleData,
    handleNextVersionedModuleChanged,
  );

  const progressValue = parseInt(
    versionedModuleProgress?.progress?.progressPercent || 0
  );

  const onClick = () => {
    handleSelectedVersionedModuleChanged(versionedModuleData);
  };

  const renderChildrenProps = {
    moduleData,
    progressValue,
    progressBarVariant,
    isProgressLoading,
    onClick,
  }

  return renderChildren(renderChildrenProps);
};

ModuleDataWrapper.propTypes = {
  module: PropTypes.object.isRequired,
  selectedVersionedModule: PropTypes.object,
  handleNextVersionedModuleChanged: PropTypes.func.isRequired,
  handleSelectedVersionedModuleChanged: PropTypes.func.isRequired,
  handleModuleVersionDataLoaded: PropTypes.func,
  renderChildren: PropTypes.func.isRequired,
  launchMvUid:  PropTypes.string,
}

export default ModuleDataWrapper;