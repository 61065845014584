export const sections = [
  {
    name: 'Discover',
    frontendId: 0,
    chaptersAmount: 4,
  },
  {
    name: 'Develop',
    frontendId: 1,
    chaptersAmount: 4,
  },
  {
    name: 'Deliver',
    frontendId: 2,
    chaptersAmount: 3,
  },
]