import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import {
  buttonFontSize,
  buttonHeight,
  pageContentPaddingX,
} from '../../style/constants/homepage';

const FullAccessVideoBanner = () => {
  return (
    <Box sx={{
      py: {xs: '40px', lg: '31px'},
      px: {...pageContentPaddingX},
      display: 'grid',
      gridTemplateColumns: 'repeat(24, 1fr)',
      gridTemplateRows: 'auto',
      alignItems: {xs: 'flex-start', md: 'center'},
      backgroundColor: 'accent.main',
    }}>
      <Box sx={{
        gridColumn: {
          xs: '24 span',
          md: '4 / 20',
          lg: '5 / 21',
          xlg: '6 / 20',
          xxl: '9 / 17',
        },
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        justifyContent: 'space-between',
      }}
      >
        <Box sx={{
          mb: {xs: '20px', md: '0px'},
          mr: {md: '24px'},
        }}>
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: {xs: '20px', md: '24px'},
            color: 'background.light',
          }}>
            {'Access Video Library'}
          </Typography>
          <Typography sx={{
            fontSize: {xs: '16px', md: '18px'},
            color: 'background.light',
          }}>
            A centralized location for all videos in the Learning Center.
          </Typography>
        </Box>

        <Button
          component={Link}
          to='/video-library/'
          variant='outlined'
          sx={{
            alignSelf: 'center',
            '&:hover': {
              backgroundColor: 'primary.main',
            },
            color: 'background.light',
            borderColor: 'background.light',
            height: {...buttonHeight},
            width: {xs: '100%', md: '214px'},
            textTransform: 'none',
          }}
        >
          <Typography sx={{
            fontWeight: {xs: 700},
            fontSize: {...buttonFontSize},
          }}>
            {'Go to Video Library'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FullAccessVideoBanner;