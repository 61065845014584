import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {Modal} from '@mui/material';
import NavBar from '@/components/common/navbar/NavBar';
import PropTypes from 'prop-types';
import SearchTextField from './SearchTextField';
import {pageContentPadding} from '@/style/constants/self-guided';

const ModalSearch = (props) => {
  const {
    open,
    onClose,
    filterText,
    changeFilterText,
    placeholder,
  } = props;

  const [paddingR, setPaddingR] = useState(
    window.innerWidth - document.body.clientWidth,
  );

  useEffect(() => {
    setPaddingR(window.innerWidth - document.body.clientWidth);
  }, [window.innerWidth])

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box sx={{
        backgroundColor: 'background.light',
      }}>
        <Box
          sx={{
            outline: 'none',
            pr: `${paddingR}px`,
          }}
        >
          <NavBar/>
          <Box sx={{
            px: {...pageContentPadding},
            py: {xs: '60px'},
            backgroundColor: 'background.light',
            position: 'relative',
          }}>
            <Box sx={{
              mb: '10px',
            }}>
              <SearchTextField
                filterText={filterText}
                updateFilterText={changeFilterText}
                placeholder={placeholder}
                initiallyFocused={true}
              />
            </Box>
            <IconButton
              aria-label='close'
              disableRipple
              onClick={onClose}
              sx={{
                cursor: 'pointer',
                p: 0,
                position: 'absolute',
                top: {xs: '10px'},
                right: {xs: '10px', md: '20px'},
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: {xs: '28px', md: '38px'},
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

ModalSearch.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  changeFilterText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default ModalSearch;