import React from 'react';
import {isEmpty, trim} from 'lodash';
import {graphql, useLazyLoadQuery} from 'react-relay';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import FileUpload, {UploadFileTypes} from './FileUpload';
import TextFieldWithLengthDisplay from './TextFieldWithLengthDisplay';
import { latestModuleVersion } from '@/utils/modules';
import AccessPlanSelect from '../common/AccessPlanSelect';
import TagSelect from '../common/TagSelect';

const DataQuery = graphql`
  query ModuleDetailsDialogQuery {
    sections {
      uid
      name
      chapters {
        uid
        name
      }
    }
  }
`;


const PublishStatuses = [
  {name: 'Draft', value: 'draft'},
  {name: 'Published', value: 'published'},
]

export default function ModuleDetailsDialog(props) {
  const {
    error,
    isOpen,
    isMutationInProgress,
    module,
    title,
    mutate,
    onClose
  } = props;

  const data = useLazyLoadQuery(
    DataQuery,
    {},
  );
  const [moduleName, setModuleName] = React.useState(module?.name ?? '');
  const [description, setDescription] = React.useState(module?.description ?? '');
  const [selectedSection, setSelectedSection] = React.useState(module?.defaultChapter?.section ?? null);
  const [selectedChapter, setSelectedChapter] = React.useState(module?.defaultChapter ?? null);
  const [selectedAccessPlans, setSelectedAccessPlans] = React.useState(module?.accessPlans ?? []);
  const [selectedTags, setSelectedTags] = React.useState(module?.tags ?? []);
  const [moduleCover, setModuleCover] = React.useState(module?.coverImg ?? null);
  const [coverImgDesc, setCoverImgDesc] = React.useState(module?.coverImgDesc ?? null);
  const moduleVersion = latestModuleVersion(module);
  const [selectedStatus, setSelectedStatus] = React.useState(
    PublishStatuses.find((item) => item.value === moduleVersion?.status)
    ?? PublishStatuses[0]
  )
  const [cmi5Zip, setCmi5Zip] = React.useState(moduleVersion?.cmi5?.zipPath ?? null);
  const [addToSelfStudy, setAddToSelfStudy] = React.useState(module?.inSelfStudy ?? true);
  const [fieldErrors, setFieldErrors] = React.useState({});
  const controlsRef = React.useRef({});

  const sections = data.sections;
  const chapters = selectedSection === null ? [] : (selectedSection?.chapters || []);

  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  const clearFieldError = (field) => {
    setFieldErrors((fe) => ({
      ...fe,
      [field]: null,
    }))
  }

  const scrollToControl = (control) => {
    if (!control) return;
    control.scrollIntoView(true, {behavior: "smooth"});
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const errors = {}
    let scrolledToErrElem = false;

    if (isEmpty(trim(moduleName))) {
      errors['moduleName'] = 'Please enter a module name';
      setModuleName('');
      scrollToControl(controlsRef.current['moduleName']);
      scrolledToErrElem = true;
    }
    if (isEmpty(moduleCover)) {
      errors['moduleCover'] = 'Please upload a cover image';
      if (!scrolledToErrElem) {
        scrollToControl(controlsRef.current['moduleCover']);
        scrolledToErrElem = true;
      }
    }
    if (isEmpty(coverImgDesc)) {
      errors['coverImgDesc'] = 'Please enter cover image description';
      if (!scrolledToErrElem) {
        scrollToControl(controlsRef.current['coverImgDesc']);
        scrolledToErrElem = true;
      }
    }
    if (isEmpty(selectedAccessPlans)) {
      errors['accessGroups'] = 'Please select one or more groups';
      if (!scrolledToErrElem) {
        scrollToControl(controlsRef.current['accessGroups']);
        scrolledToErrElem = true;
      }
    }
    if (isEmpty(cmi5Zip)) {
      errors['cmi5Zip'] = 'Please upload a CMI5 zip file';
      if (!scrolledToErrElem) {
        scrollToControl(controlsRef.current['cmi5Zip']);
        scrolledToErrElem = true;
      }
    }
    if (!isEmpty(errors)) {
      console.log('In handleSubmit. Errors=', JSON.stringify(errors));
      setFieldErrors(errors);
      return;
    }
    mutate({
      name: trim(moduleName),
      description: trim(description),
      status: selectedStatus.value,
      inSelfStudy: addToSelfStudy,
      chapterUid: selectedChapter?.uid,
      cmi5Zip: cmi5Zip,
      coverImg: moduleCover,
      coverImgDesc: coverImgDesc,
      accessPlanUids: selectedAccessPlans.map((ap) => ap.uid),
      tags: selectedTags.map((tag) => tag.text)
    });
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction='column' gap={1}>
          <TextFieldWithLengthDisplay
            refCallback={(node) => { controlsRef.current['moduleName'] = node }}
            maxLength={65}
            error={!isEmpty(fieldErrors?.moduleName)}
            helperText={fieldErrors?.moduleName ?? ' '}
            fullWidth
            id="name"
            label="Module Name"
            margin="dense"
            value={moduleName}
            variant="outlined"
            size='small'
            onChange={(evt) => {
              setModuleName(evt.target.value);
              if (!isEmpty(trim(evt.target.value))) {
                clearFieldError('moduleName');
              }
            }}
          />
          <FileUpload
            refCallback={(node) => { controlsRef.current['moduleCover'] = node }}
            btnLabel='UPLOAD COVER IMAGE'
            helperText={<div>To be used as a card image for modules and videos.<br/>PNG file format and 276x184 dimension (3:2 ratio) recommended. 1MB file size limit.</div>}
            acceptFileTypes='.png,.jpg,.jpeg'
            fileType={UploadFileTypes.COVER_IMG}
            maxFileSize={1024*1024}
            initial={moduleCover}
            showPreview
            validationError={fieldErrors?.moduleCover}
            onUploadCleared={() => setModuleCover(null)}
            onUploadSuccess={(uploadedPath) => setModuleCover({name: uploadedPath})}
          />
          <TextFieldWithLengthDisplay
            refCallback={(node) => { controlsRef.current['coverImgDesc'] = node }}
            maxLength={250}
            rows={2}
            error={!isEmpty(fieldErrors?.coverImgDesc)}
            helperText={fieldErrors?.coverImgDesc ?? ' '}
            fullWidth
            id="cover_img_desc"
            label="Cover Image Description"
            placeholder="Please describe scene in photo for screen readers, ie: Person in Blue labcoat with beaker"
            margin="dense"
            value={coverImgDesc}
            variant="outlined"
            size='small'
            onChange={(evt) => {
              setCoverImgDesc(evt.target.value);
              if (!isEmpty(trim(evt.target.value))) {
                clearFieldError('coverImgDesc');
              }
            }}
          />
          <Autocomplete
            id="ac-section-name"
            fullWidth
            options={sections}
            size='small'
            value={selectedSection}
            isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
            onChange={(event, newValue) => {
              setSelectedSection(newValue);
              setSelectedChapter(null);
              if (!isEmpty(newValue)) {
                clearFieldError('section')
              }
            }}
            getOptionKey={(option) => (option.uid)}
            getOptionLabel={(option) => (option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Section Name"
                error={!isEmpty(fieldErrors?.section)}
                helperText={fieldErrors?.section ?? ' '}
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="ac-chapter-name"
            disabled={selectedSection === null}
            options={chapters}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chapter Name"
                error={!isEmpty(fieldErrors?.chapter)}
                helperText={fieldErrors?.chapter ?? ' '}

              />
            )}
            size='small'
            value={selectedChapter}
            getOptionKey={(option) => (option.uid)}
            getOptionLabel={(option) => (option.name)}
            onChange={(event, newValue) => {
              setSelectedChapter(newValue);
              if (!isEmpty(newValue)) {
                clearFieldError('chapter')
              }
            }}
          />
          <AccessPlanSelect
            refCallback={(node) => { controlsRef.current['accessGroups'] = node }}
            id="ac-groups-with-access"
            multiple
            name='accessPlans'
            value={selectedAccessPlans}
            label="Groups with Access"
            error={!isEmpty(fieldErrors?.accessGroups)}
            helperText={fieldErrors?.accessGroups ?? ' '}
            onChange={(event, newValue) => {
              setSelectedAccessPlans(newValue);
              if (!isEmpty(newValue)) {
                clearFieldError('accessGroups')
              }
            }}
          />
          <TagSelect
            id="ac-topics"
            name="tags"
            error={!isEmpty(fieldErrors?.tags)}
            helperText={fieldErrors?.tags ?? ' '}
            multiple
            checkboxes
            label="Topics"
            clearOnBlur
            selectOnFocus
            value={selectedTags}
            onChange={(selTags) => {
              setSelectedTags(selTags);
              if (!isEmpty(selTags)) {
                clearFieldError('tags')
              }
            }}
          />
          <TextFieldWithLengthDisplay
            refCallback={(node) => { controlsRef.current['description'] = node }}
            maxLength={250}
            id="description"
            error={!isEmpty(fieldErrors?.description)}
            helperText={fieldErrors?.description || ' '}
            fullWidth
            label="Description"
            multiline
            rows={2}
            size='small'
            value={description}
            onChange={(evt) => {
              setDescription(evt.target.value);
              if (!isEmpty(trim(evt.target.value))) {
                clearFieldError('description');
              }
            }}
          />
          <Autocomplete
            id="ac-status"
            disableClearable
            fullWidth
            options={PublishStatuses}
            size='small'
            value={selectedStatus}
            sx={{my: 1}}
            onChange={(event, newValue) => {
              setSelectedStatus(newValue);
            }}
            getOptionLabel={(option) => (option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
              />
            )}
          />
          <FormGroup sx={{marginBottom: '25px'}}>
            <FormControlLabel
              control={
                <Switch
                  checked={addToSelfStudy}
                  onChange={(evt) => {setAddToSelfStudy(evt.target.checked)}}
                />
              }
              label="Add to Self-Guided Learning page"
            />
          </FormGroup>
          <FileUpload
            refCallback={(node) => { controlsRef.current['cmi5Zip'] = node }}
            btnLabel='UPLOAD CMI5 FILE'
            initial={cmi5Zip}
            acceptFileTypes='.zip'
            fileType={UploadFileTypes.CMI5_ZIP}
            maxFileSize={50*1024*1024}
            validationError={fieldErrors?.cmi5Zip ?? ''}
            onUploadCleared={() => setCmi5Zip(null)}
            onUploadSuccess={(uploadedPath) => setCmi5Zip({name: uploadedPath})}
          />
          {error && (
            <Typography color='error' variant='body2'>
              {error}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label='Cancel'
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          aria-label='Submit'
          disabled={isMutationInProgress}
          type="button"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
      {isMutationInProgress && (
        <Backdrop
          open
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress aria-label='Data transfer progress' color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
}

ModuleDetailsDialog.propTypes = {
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  isMutationInProgress: PropTypes.bool,
  module: PropTypes.object,
  title: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ModuleDetailsDialog.defaultProps = {
  error: null,
  isOpen: false,
  isMutationInProgress: false,
  module: null,
}
