import {find, isEmpty} from 'lodash';
import React from 'react';
import { createPortal } from 'react-dom';
import {graphql, usePaginationFragment, usePreloadedQuery} from 'react-relay';
import PropTypes from 'prop-types';
import {ThemeProvider} from '@mui/material';
import Button from '@mui/material/Button';
import LoadingSpinner from '../../LoadingSpinner';
import DeleteUserDialog from './DeleteUserDialog';
import UserDetailsDialog from './UserDetailsDialog';
import {GROUP_PENDING_USERS, GROUP_LEARNERS} from './constants';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import ActiveUsersTable from './ActiveUsersTable';
import PendingUsersTable from './PendingUsersTable';
import ResendInvitationsDialog from "./ResendInvitationsDialog";
import {theme as uiTheme} from '../../themes/userUi';

const PAGE_SIZE = 10;

const UserFragment = graphql`
  fragment UsersTable_user on User {
    uid
    firstName
    lastName
    email
    university {
      id
      uid
      name
    }
    isActive
    joinedTs
    allQuestionsAnswered
    groups {
      name
    }
    subscription {
      type
      validFrom
      validTo
      accessPlan {
        uid
        name
      }
    }
    invitation {
      createdTs
      lastAttemptedSendDatetime
    }
    answers {
      ...UserProfile_UserAnswers
    }
  }
`;

const UsersTableFragment = graphql`
  fragment UsersTable_users on Query
    @refetchable(queryName: "UsersTablePaginationQuery")
    @argumentDefinitions(
      cursor: { type: "String" }
      count: { type: "Int", defaultValue: 100 }
    )
  {
    users(first: $count, after: $cursor, filters: {forCurrentSite: true})
    @connection(key: "UsersTableFragment_users")
    {
      edges {
        node {
          id
          ...UsersTable_user @relay(mask: false)
          modulesProgress {
            ...UserProgressPanel_ModulesProgress @relay(mask: false)
          }
          coursesProgress {
            ...UserProgressPanel_CoursesProgress @relay(mask: false)
          }
        }
      }
      totalCount
    }
  }
`;

export const UsersQuery = graphql`
  query UsersTableQuery {
    ...UsersTable_users
  }
`;


const UsersTable = (props) => {
  const {
    searchText,
    userType,
    queryReference,
    extraButtonsContainerRef,
    setIsDataLoading,
  } = props;
  const displayMessage = React.useContext(DisplayMessageContext);
  const fragmentRef = usePreloadedQuery(UsersQuery, queryReference);
  const {data, loadNext, hasNext, isLoadingNext} = usePaginationFragment(UsersTableFragment, fragmentRef);
  const [isUserDialogOpen, setIsUserDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedPendingUsers, setSelectedPendingUsers] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [allDataLoaded, setAllDataLoaded] = React.useState(!hasNext);
  const [isResendInviteDialogOpen, setIsResendInviteDialogOpen] = React.useState(false);

  const loadAllData = () => {
    if (!allDataLoaded) {
      const totalRowCount = data.users.totalCount;
      loadNext(totalRowCount - data.users.edges.length, {
        onComplete: (data) => {
          setAllDataLoaded(true);
        }
      });
    }
  }

  React.useEffect(() => {
    loadAllData();
  }, []);

  React.useEffect(() => {
    setIsDataLoading(isLoadingNext || !allDataLoaded);
  }, [isLoadingNext, allDataLoaded, setIsDataLoading]);

  const userFilter = React.useCallback(
    (user) => {
      if (userType === GROUP_PENDING_USERS) {
        return user?.isActive === false;
      }
      else if (user?.isActive !== true) {
        return false;
      }
      return find(user?.groups ?? [], (g) => g === userType)
    },
    [userType]
  );

  const handlePaginationModelChange = React.useCallback(
    (newPaginationModel) => {
      setPaginationModel(newPaginationModel);
    },
    []
  );
  const editUser = React.useCallback(
    (uid) => () => {
      const user = find(data.users.edges,
        (item) => item.node.uid === uid);
      setSelectedUser(user.node);
      setIsUserDialogOpen(true);
    },
  [data.users.edges],
  );
  const deleteUser = React.useCallback(
    (uid) => () => {
      const user = find(data.users.edges,
        (item) => item.node.uid === uid);
      setSelectedUser(user.node);
      setIsDeleteDialogOpen(true);
    },
    [data.users.edges],
  );
  const handleUserDialogClosed = React.useCallback(
    (msg) => {
      setIsUserDialogOpen(false);
      setSelectedUser(null);
      if (!isEmpty(msg) && displayMessage) {
        displayMessage(msg);
      }
    },
    [displayMessage],
  );
  const onCloseDeleteDialog = React.useCallback(
    () => {
      setIsDeleteDialogOpen(false);
      setSelectedUser(null);
    },
    [],
  );
  const handlePendingUserSelectionChange = React.useCallback(
    (selection) => {
      setSelectedPendingUsers(selection);
    },
    [],
  );
  const onCloseResendInvitationsDialog = React.useCallback(
    () => {
      setIsResendInviteDialogOpen(false);
    },
    [],
  );

  const filteredRows = React.useMemo(() =>
    data.users.edges.map((item) => {
      const user = item.node;
      return {
        id: user.uid,
        name: `${user.firstName} ${user.lastName}`,
        signedUp: user.joinedTs,
        invited: user.invitation?.lastAttemptedSendDatetime ?? user.invitation?.createdTs,
        email: user.email,
        isActive: user.isActive,
        groups: user.groups.map((g) => g.name),
        modulesCompleted: (user?.modulesProgress ?? []).filter((mp) => mp.progressPercent === 100).length,
        coursesCompleted: (user?.coursesProgress ?? []).filter((cp) => cp.progressPercent === 100).length,
        accessPlan: user.subscription?.accessPlan,
        subscriptionType: user.subscription?.type,
        invitationsSent: user?.invitation?.sentTs,
      }
    })
    .filter((item) => userFilter(item))
    .filter((item) => {
      if (!searchText) return true;
      const st = searchText.toLowerCase();
      return (
        item.name.toLowerCase().includes(st) ||
        item.email.toLowerCase().includes(st)
      );
    }), [data.users.edges, userFilter, searchText]
  );

  return (
    <>
      {userType === GROUP_PENDING_USERS
        ? <PendingUsersTable
            rows={filteredRows}
            loading={isLoadingNext || !allDataLoaded}
            paginationModel={paginationModel}
            rowSelectionModel={selectedPendingUsers}
            handlePaginationModelChange={handlePaginationModelChange}
            handleUserDelete={deleteUser}
            handleUserSelectionChange={handlePendingUserSelectionChange}
          />
        : <ActiveUsersTable
            rows={filteredRows}
            loading={isLoadingNext || !allDataLoaded}
            paginationModel={paginationModel}
            handlePaginationModelChange={handlePaginationModelChange}
            handleUserEdit={editUser}
            handleUserDelete={deleteUser}
          />
      }
      {userType === GROUP_PENDING_USERS && !isEmpty(selectedPendingUsers) && !isEmpty(extraButtonsContainerRef.current) &&
        createPortal(
          <ThemeProvider theme={uiTheme}>
            <Button
              aria-label='Resend Invite'
              disabled={isLoadingNext || !allDataLoaded}
              variant='contained'
              size='small'
              sx={{
                height: '100%',
              }}
              onClick={() => {setIsResendInviteDialogOpen(true)}}
            >
              RESEND INVITES
            </Button>
          </ThemeProvider>,
          extraButtonsContainerRef.current
        )
      }
      {isUserDialogOpen &&
        <React.Suspense fallback={<LoadingSpinner />}>
          <UserDetailsDialog
            isOpen={isUserDialogOpen}
            user={selectedUser}
            showProgress
            displayMessage={displayMessage}
            onClose={handleUserDialogClosed}
          />
        </React.Suspense>
      }
      {isDeleteDialogOpen &&
        <DeleteUserDialog
          user={selectedUser}
          isOpen={isDeleteDialogOpen}
          displayMessage={displayMessage}
          onClose={onCloseDeleteDialog}
        />
      }
      {isResendInviteDialogOpen &&
        <ResendInvitationsDialog
          users={selectedPendingUsers}
          isOpen={isResendInviteDialogOpen}
          displayMessage={displayMessage}
          onClose={onCloseResendInvitationsDialog}
        />
      }
    </>
  );
}

UsersTable.propTypes = {
  editable: PropTypes.bool,
  searchText: PropTypes.string,
  userType: PropTypes.string,
  queryReference: PropTypes.object,
  extraButtonsContainerRef: PropTypes.object,
  setIsDataLoading: PropTypes.func,
}

UsersTable.defaultProps = {
  editable: true,
  searchText: '',
  userType: GROUP_LEARNERS,
}

export default UsersTable;
