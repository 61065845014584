import { deepmerge } from '@mui/utils';
import {createTheme, alpha} from "@mui/material/styles";
import {theme as baseTheme} from './main';
import {CURR_SITE, SITE_NS, SITE_STANFORD} from '../constants';

const site = CURR_SITE.name;

const primaryMainStanford = '#B13050';
const primaryDarkStanford = '#7b2138';

const primaryMainNs = '#F0532A';
const primaryDarkNs = '#dc4c25';

const navbarPanelBackgroundColor = '#F5F8F9';
const primaryMain = site === SITE_STANFORD ? primaryMainStanford : primaryMainNs;
const primaryDark = site === SITE_STANFORD ? primaryDarkStanford : primaryDarkNs;

const extraPalette = {
  background: {
    [SITE_NS]: {
      light: '#ffffff',
      accent: '#F5F5F5',
      dark: '#3C434E',
      footer: '#40474F',
    },
    [SITE_STANFORD]: {
      light: '#ffffff',
      accent: '#F5F8F9',
      dark: '#000000',
      footer: '#F5F8F9',
    }
  },

  text: {
    [SITE_NS]: {
      menuItem: '#40474F',
      menuItemSelected: '#F0532A',
      menuItemBackground: '#F5F8F9',
    },
    [SITE_STANFORD]: {
      menuItem: '#B13050',
      menuItemSelected: '#B13050',
      menuItemBackground: '#2196F314',
    }
  }
}

export const theme = createTheme(
  deepmerge(
    baseTheme,
    {
      palette: {
        primary: {
          main: primaryMain,
          light: alpha(primaryMain, 0.7),
          dark: primaryDark,
          contrastText: '#fff',
        },
        ...extraPalette,
        background: {...extraPalette.background[site]},
        text: {...extraPalette.text[site]},
        panelBackground: baseTheme.palette.augmentColor({
          color: {
            main: navbarPanelBackgroundColor,
          },
          name: 'panelBackground',
        }),
      },
    }
  )
);
