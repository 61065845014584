import {useEffect, useState} from 'react';
import {fetchQuery, useRefetchableFragment, useRelayEnvironment} from 'react-relay';

export const useRefetchProgress = (
  progressQueryRef,
  progressFragment,
  entityData,
  entityUidToUpdate,
) => {

  const [progress, refetchProgress] = useRefetchableFragment(
    progressFragment,
    entityData,
  );
  const [isProgressLoading, setIsProgressLoading] = useState(false);
  const progressBarVariant = isProgressLoading ? 'indeterminate' : 'determinate';
  const environment = useRelayEnvironment();
  const [wasEntitySelected, setWasEntitySelected] = useState(false);

  // Update the progress on entity switching
  // or on closing the content modal (entityUidToUpdate === null)
  useEffect(() => {
    if (
      !!entityData?.uid &&
      !!entityUidToUpdate &&
      entityUidToUpdate === entityData?.uid &&
      wasEntitySelected === false
    ) {
      setWasEntitySelected(true);
    }
    else if (
      entityData?.uid &&
      wasEntitySelected === true
    ) {
      fetchQuery(
        environment, progressQueryRef, {id: entityData.id}
      )
      .subscribe({
        start: () => {
          setIsProgressLoading(true);
        },
        complete: () => {
          refetchProgress({ fetchPolicy: 'store-only' });
          setIsProgressLoading(false);
          setWasEntitySelected(false);
        },
      });
    }
  },[entityData?.uid, entityUidToUpdate])

  return [
    progress,
    progressBarVariant,
    isProgressLoading,
  ]
}