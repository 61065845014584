import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/react';


export function captureException(err, extra) {
  if (Sentry) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      Sentry.captureException(err);
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(err, extra);
  }
}

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, extra) { // eslint-disable-line class-methods-use-this
    console.error(err, extra);
    captureException(err, extra);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{padding: "1rem", height: "70vh"}}>
          <h4>An internal error has occurred.</h4>
          <div>The developers have been notified and will deal with it ASAP.</div>
        </div>);
    }
    return this.props.children;
  }
}
