import React from 'react';
import {RelayEnvironmentProvider} from 'relay-hooks';
import {ThemeProvider} from '@mui/material/styles';
import AppRoutes from './AppRoutes';
import {theme} from './themes/main';
import './style/sass/main.scss';
import {environment} from './api';
import {ErrorBoundary} from './ErrorBoundary';
import PageLoader from './PageLoader';
import SmartRouter from './SmartRouter';

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SmartRouter>
          <RelayEnvironmentProvider environment={environment}>
            <React.Suspense fallback={<PageLoader/>}>
              <AppRoutes />
            </React.Suspense>
          </RelayEnvironmentProvider>
        </SmartRouter>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
