import React from "react";
import {slice} from "lodash";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

export const compareTagLists = (tagList1, tagList2) => {
  for (let j=0; j<Math.max(tagList1.length, tagList2.length); ++j) {
    const t1 = (tagList1?.[j] ?? '').toLowerCase();
    const t2 = (tagList2?.[j] ?? '').toLowerCase();
    if (t1 !== t2) {
      return t1.localeCompare(t2);
    }
  }
  return 0;
}

const TagList = (props) => {
  const {
    tags,
    tagsPerRow,
    maxTagsToDisplay,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const displayMoreTags = (evt) => {
    evt.preventDefault();
    setAnchorEl(evt.currentTarget);
  }
  const showMore = tags.length > maxTagsToDisplay;
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        gridTemplateColumns: `repeat(${tagsPerRow}, 1fr)` + (showMore ? ' 20px' : ''),
      }}
    >
      {slice(tags, 0, maxTagsToDisplay).map((tag, i) => {
        const chip = <Chip label={tag} size="small" sx={{paddingTop: '2px'}} />
        return (
          <React.Fragment key={tag}>
            {
              showMore && i > 0 && i % tagsPerRow === 0
              ? <><span/>{chip}</>
              : <>{chip}</>
            }
          </React.Fragment>
        )})
      }
      {showMore &&
        <Link
          href='#'
          underline='none'
          onClick={displayMoreTags}
        >
          {`+${tags.length - maxTagsToDisplay}`}
        </Link>
      }
      {showMore &&
        <Popover
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={(e) => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List dense>
            {slice(tags, maxTagsToDisplay).map((tag) =>
              <ListItem key={tag}>
                <Chip label={tag} size="small" sx={{paddingTop: '2px'}} />
              </ListItem>
            )}
          </List>
        </Popover>
      }
    </Box>
  )

}

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  tagsPerRow: PropTypes.number,
  maxTagsToDisplay: PropTypes.number,
}

TagList.defaultProps = {
  tagsPerRow: 2,
  maxTagsToDisplay: 4,
}

export default TagList;
