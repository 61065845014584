/**
 * @generated SignedSource<<6edf01b0845290e74d39ac6546b932b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagsToDelete"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tagsToDelete",
        "variableName": "tagsToDelete"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "deleteTags",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tags_DeleteTagsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tags_DeleteTagsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ddbbafdcacd2ee6ace6f5d19a37d7d7",
    "id": null,
    "metadata": {},
    "name": "Tags_DeleteTagsMutation",
    "operationKind": "mutation",
    "text": "mutation Tags_DeleteTagsMutation(\n  $tagsToDelete: [String!]!\n) {\n  deleteTags(tagsToDelete: $tagsToDelete) {\n    id\n    text\n  }\n}\n"
  }
};
})();

node.hash = "4288226a52155e5db1798b28bdd06da2";

export default node;
