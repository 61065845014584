import React from 'react';
import {graphql} from "react-relay";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types'
import {STUDY_UNIT_COURSE, STUDY_UNIT_MODULE} from './constants';

const IN_PROGRESS = 'in_progress';
const COMPLETED = 'completed';

const CoursesProgressFragment = graphql`
  fragment UserProgressPanel_CoursesProgress on CourseVersionProgress @relay(plural: true) {
    startTs
    progressPercent
    courseVersion {
      uid
      version
      course {
        uid
        name
      }
    }
  }
`;

const ModulesProgressFragment = graphql`
  fragment UserProgressPanel_ModulesProgress on ModuleVersionProgress @relay(plural: true) {
    startTs
    progressPercent
    moduleVersion {
      uid
      version
      module {
        name
      }
    }
  }
`;

const UserProgressPanel = (props) => {
  const {
    user
  } = props;
  const coursesProgress = user.coursesProgress;
  const modulesProgress = user.modulesProgress;
  const [studyUnitType, setStudyUnitType] = React.useState(STUDY_UNIT_MODULE);
  const [progressStatus, setProgressStatus] = React.useState(IN_PROGRESS);

  const handleStudyUnitTypeChange = React.useCallback(
    (evt, newValue) => {
      setStudyUnitType(newValue);
    },
    [],
  )
  const dataSrc = studyUnitType === STUDY_UNIT_MODULE ? modulesProgress : coursesProgress;
  return (
    <Box>
      <Tabs
        value={studyUnitType}
        centered
        onChange={handleStudyUnitTypeChange}
        aria-label="Study Unit Types"
      >
        <Tab label="Modules" value={STUDY_UNIT_MODULE} />
        <Tab label="Courses" value={STUDY_UNIT_COURSE} />
      </Tabs>
      <Tabs
        value={progressStatus}
        onChange={(evt, newVal) => setProgressStatus(newVal)}
        aria-label="Completion statuses"
      >
        <Tab label="In Progress" value={IN_PROGRESS} />
        <Tab label="Completed" value={COMPLETED} />
      </Tabs>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableBody>
            {dataSrc.filter(
              (pi) => (
                progressStatus === COMPLETED ? pi.progressPercent === 100 : pi.progressPercent < 100
              )).map((pi) => (
              <TableRow
                key={studyUnitType === STUDY_UNIT_MODULE ? pi.moduleVersion.uid : pi.courseVersion.uid}
              >
                <TableCell component='th' scope='row' width='100%'>
                  {studyUnitType === STUDY_UNIT_MODULE ? pi.moduleVersion.module.name : pi.courseVersion.course.name}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {pi.progressPercent}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

UserProgressPanel.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserProgressPanel;
