import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import NavBar from '@/components/common/navbar/NavBar';
import SectionsNavigation from '../components/content/SectionsNavigation';
import SectionsList from '../components/content/SectionsList';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../themes/userUi';
import Footer from '../components/common/Footer';
import Box from '@mui/material/Box';
import {pageContentPadding} from '../style/constants/self-guided';
import {contentPt} from '../style/constants/main';
import Typography from '@mui/material/Typography';
import {useTabNavigation} from '../components/hooks/useTabNavigation';
import {MODULE_CHAPTER_TYPE, MODULE_SEARCH_TYPE, SEARCH_PLACEHOLDER} from '../constants';
import {useModuleLaunch} from '../components/hooks/useModuleLaunch';
import ModalSearch from '../components/common/search/ModalSearch';
import {useModalSearch} from '../components/hooks/useModalSearch';
import Cmi5ViewerDialog from '../components/cmi5-viewer/Cmi5ViewerDialog';
import {ErrorBoundary} from '../ErrorBoundary';
import LoadingSpinner from '../LoadingSpinner';
import ModulesSectionsList from '../components/content/ModulesSectionsList';
import ModulesSectionsListSkeleton
  from '../components/skeletons/content/ModulesSectionsListSkeleton';

const SelfGuidedModulesPage = () => {

  const [
    stickyPartRef,
    headerPartRef,
    sectionsRefMap,
    sectionValue,
    handleSectionChanged,
    handleSectionTabChanged,
  ] = useTabNavigation();

  const [filterText, setFilterText] = useState('')

  const changeFilterText = (value) => {
    setFilterText(value);
  };

  const navigate = useNavigate();

  const [
    isModalSearchOpen,
    onModalSearchClose,
    onSearchFocused,
  ] = useModalSearch();

  useEffect(() => {
    if (isModalSearchOpen && !!filterText) {
      onModalSearchClose();
      navigate(`/search?query=${filterText}&type=${MODULE_SEARCH_TYPE}`);
    }
  }, [filterText, isModalSearchOpen, navigate, onModalSearchClose])

  const [
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    isViewDialogOpen,
    handleCloseDialog,
    nextVersionedModule,
    handleNextVersionedModuleChanged,
  ] = useModuleLaunch();

  const [searchParams, setSearchParams] = useSearchParams();
  const launchMvUid = searchParams.get('launchMvUid');

  const [areCardsHidden, setAreCardsHidden] = useState(false);
  const handleCardsHidden = (value) => {
    setAreCardsHidden(value);
  }

  const childrenProps = {
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    handleNextVersionedModuleChanged,
    launchMvUid,
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dataLoadedCb = () => {
    setIsDataLoaded(true)
  }

  const renderSectionsList = (sections) => {
    return sections && sections.length > 0 && (
      <SectionsList
        key={'modules'}
        sections={sections}
        isDataLoaded={isDataLoaded}
        handleSectionChanged={handleSectionChanged}
        stickyPartRef={stickyPartRef}
        sectionsRefMap={sectionsRefMap}
        handleCardsHidden={handleCardsHidden}
        entityType={MODULE_CHAPTER_TYPE}
        childrenProps={childrenProps}
      />
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar/>
        <ErrorBoundary>
          <ModalSearch
            open={isModalSearchOpen}
            onClose={onModalSearchClose}
            filterText={filterText}
            changeFilterText={changeFilterText}
            placeholder={SEARCH_PLACEHOLDER}
          />

          <Box
            className="page-header"
            ref={headerPartRef}
            sx={{
              px: {...pageContentPadding},
              pt: {...contentPt},
            }}>
            <Typography sx={{
              fontSize: {xs: '24px', md: '32px'},
              fontWeight: 'bold',
            }}>
              Self-Guided Learning
            </Typography>

            <Box sx={{
              backgroundColor: 'primary.main',
              width: '100%',
              height: '4px',
            }}/>
          </Box>
          <SectionsNavigation
            sectionValue={sectionValue}
            handleSectionTabChanged={handleSectionTabChanged}
            stickyPartRef={stickyPartRef}
            areCardsHidden={areCardsHidden}
            onSearchFocused={onSearchFocused}
            isDataLoaded={isDataLoaded}
          />
          <React.Suspense fallback={<ModulesSectionsListSkeleton isVideo={false}/>}>
            <ModulesSectionsList
              dataLoadedCb={dataLoadedCb}
              renderSectionsList={renderSectionsList}
            />
          </React.Suspense>
          <React.Suspense fallback={<LoadingSpinner/>}>
            {selectedVersionedModule &&
              <Cmi5ViewerDialog
                isOpen={isViewDialogOpen}
                moduleVersion={selectedVersionedModule}
                getNextModule={() => nextVersionedModule}
                onClose={handleCloseDialog}
                handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
              />
            }
          </React.Suspense>
        </ErrorBoundary>
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default SelfGuidedModulesPage;
