import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {ThemeProvider} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {theme} from '../themes/auth';
import Login from '../components/auth/Login';
import RequestPasswordReset from '../components/auth/RequestPasswordReset';
import ResetPassword from '../components/auth/ResetPassword';
import ConfirmInvitation from '../components/auth/ConfirmInvitation';
import {CURR_SITE} from '../constants';


const site = CURR_SITE.name;


const AuthPage = (props) => {
  const { children } = props;
  const [constants, setConstants] = React.useState({});

  React.useEffect(() => {
    import(`../constants/pages/auth/${site}.js`)
    .then(constantsModule => {
      setConstants(constantsModule.CONSTANTS);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: {
            xs: 'column', md: 'row',
          }
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            px: {xs: '10%'},
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: 'primary.background',
            width: {xs: '100%', md: '50%'},
            height: {xs: '50%', md: '100%'},
            minHeight: '400px',
            backgroundImage: constants?.backgroundImage ?? '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
            <Box
              component='img'
              src={constants?.logoUrl ?? ''}
              alt={constants?.logoAltText ?? ''}
              sx={{
                mb: 3,
                width: '190px',
                fontWeight: 'bold',
              }}
            >

            </Box>

            <Typography
              variant='body1'
              sx={{
                mb: 3,
                fontWeight: 'bold',
              }}
            >
              {constants?.header ?? ''}
            </Typography>

            <Typography variant='body1'>
              {constants?.description ?? ''}
            </Typography>

        </Box>

        <Box sx={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: {xs: '10%'},
          width: {xs: '100%', md: '50%'},
          height: {xs: '50%', md: '100%'},
          minHeight: '400px',
        }}>
          {children}
        </Box>

      </Box>
    </ThemeProvider>
  );
};

AuthPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
}

export const ConfirmInvitationPage = () => (<AuthPage><ConfirmInvitation /></AuthPage>);
export const LoginPage = () => (<AuthPage><Login /></AuthPage>);
export const ResetPasswordPage = () => (<AuthPage><ResetPassword /></AuthPage>);
export const RequestPasswordResetPage = () => (<AuthPage><RequestPasswordReset /></AuthPage>);
