import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import TopicModuleCard from './TopicModuleCard';
import PropTypes from 'prop-types';
import {moduleParentPropTypes} from '../../constants/propTypesShapes';
import ScrollableXContainer from '../common/ScrollableXContainer';
import {scrollableXContainerPosition} from '../../style/constants/homepage';
import ModuleDataWrapper from '../common/data-wrappers/ModuleDataWrapper';

const FeaturedTopicModulesQuery = graphql`
  query FeaturedTopicModulesQuery($featuredTopicId: GlobalID!) {
    node(id: $featuredTopicId) {
      ... on FeaturedTopic {
        modules {
          uid
          ...ModuleDataWrapperFragment_defaultModules
        }
      }
    }
  }
`;

const FeaturedTopicModules = (props) => {
  const {
    featuredTopicId,
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    handleNextVersionedModuleChanged,
    handleNextModuleVersionCalculatorChanged,
  } = props;

  const {node} = useLazyLoadQuery(
    FeaturedTopicModulesQuery,
    {featuredTopicId},
    {fetchPolicy: 'network-only'},
  );

  const [moduleLatestVersions, setModuleLatestVersions] = React.useState({});
  const modules = node.modules;
  const getNextModuleVersion = React.useCallback((moduleVersion) => {
    const idx = modules.findIndex((m) => m.uid === moduleVersion.moduleUid);
    if (idx >= 0 && idx + 1 < modules.length) {
      const nextModule = modules[idx+1];
      const nextModuleVersion = moduleLatestVersions[nextModule.uid];
      if (nextModuleVersion) {
        return nextModuleVersion;
      }
    }
    return null;
  }, [modules, moduleLatestVersions]);

  React.useEffect(() => {
    if (handleNextModuleVersionCalculatorChanged) {
      handleNextModuleVersionCalculatorChanged(getNextModuleVersion);
    }
  }, [handleNextModuleVersionCalculatorChanged, getNextModuleVersion]);

  const handleModuleVersionDataLoaded = (moduleUid, moduleLatestVersion) => {
    setModuleLatestVersions((prev) => ({
      ...prev,
      [moduleUid]: moduleLatestVersion,
    }))
  }
  const renderModuleCard = (props) => {
    const {
      moduleData,
      onClick,
    } = props;

    return (
      <TopicModuleCard
        key={moduleData.uid}
        moduleData={moduleData}
        onClick={onClick}
      />
    )
  }

  const modulesElement = (
    modules.map((module) => {
      return (
        <ModuleDataWrapper
          key={module.uid}
          module={module}
          selectedVersionedModule={selectedVersionedModule}
          handleNextVersionedModuleChanged={handleNextVersionedModuleChanged}
          handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
          handleModuleVersionDataLoaded={handleModuleVersionDataLoaded}
          renderChildren={renderModuleCard}
        />
      )
    })
  );

  return (
    <>
      <ScrollableXContainer
        navigationSx={{
          bottom: {xs: '-5px', lg: '20px'},
          ...scrollableXContainerPosition,
        }}
        elements={modulesElement}
      />
    </>
  );
};

FeaturedTopicModules.propTypes = {
  featuredTopicId: PropTypes.string.isRequired,
  handleNextModuleVersionCalculatorChanged: PropTypes.func,
  ...moduleParentPropTypes,
}

export default FeaturedTopicModules;
