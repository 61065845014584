import React from 'react';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const getActionPerProgress = (progress) => (
  progress === 100 ? 'Retake' : (
    progress > 0 ? 'Resume' : 'Start'
  )
)

export const getButtonPerProgress = (progress, itemName, btnProps) => {
  const icon = progress === 100 ? <CachedIcon /> : <PlayArrowIcon />;
  const action = getActionPerProgress(progress);
  const title = action + (itemName ? ` ${itemName}` : '');
  return (
    <Button
      aria-label={title}
      variant='outlined'
      startIcon={icon}
      size='small'
      sx={{
        fontWeight: 'bold',
      }}
      {...btnProps}
    >
      {title}
    </Button>
  )
}

export const getStatusPerProgress = (progress, inProgressFormatter) => (
  progress === 100
    ? <Stack direction='row' alignItems='center'>
        <CheckIcon sx={{color: 'success.main'}}/>
        <Typography
          variant='body2'
          sx={{color: 'success.main', fontWeight: 'bold'}}
        >
          Completed
        </Typography>
      </Stack>
    : (progress > 0
        ?
            <Typography
              variant='body2'
              sx={{fontWeight: 'bold', alignSelf: 'center'}}
            >
              {inProgressFormatter ? inProgressFormatter(progress) : `${progress}% Completed`}
            </Typography>
        : ''
    )
)

export const getCourseDetailsUrl = (courseUid, action) => {
   let url = `/course/${courseUid}/`;
   if (action) {
     url += `?${action.toLowerCase()}`;
   }
   return url;
}
