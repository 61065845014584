/**
 * @generated SignedSource<<f471cc9ca9e496cbfb29715cfa7ed70f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

import VideoDataWrapperProgressQuery_graphql from './VideoDataWrapperProgressQuery.graphql';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": VideoDataWrapperProgressQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "VideoDataWrapperProgressFragment_progress",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoProgress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progressPercent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
};

node.hash = "5468aead2eb225432aedab4f9b24d25d";

export default node;
