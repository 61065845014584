import {isEmpty} from 'lodash';
import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

export const DisplayMessageContext = React.createContext(null);

const DisplayMessageProvider = (props) => {
  const {
    children,
  } = props;
  const [message, setMessage] = React.useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(!isEmpty(message));
  const [bgColor, setBgColor] = React.useState(null);
  const displayMessage = (msg, bgColor) => {
    setMessage(msg);
    setBgColor(bgColor);
    setIsSnackbarOpen(true);
  }
  return (
    <DisplayMessageContext.Provider value={displayMessage}>
      {children}
      {isSnackbarOpen &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={(e) => setIsSnackbarOpen(false)}
        >
          <Alert
            onClose={(e) => setIsSnackbarOpen(false)}
            severity={message?.error ? 'error' : 'success'}
            variant='filled'
            sx={{ width: '100%', bgcolor: bgColor ?? undefined }}
          >
            {message?.error ?? message.info}
          </Alert>
        </Snackbar>
      }
    </DisplayMessageContext.Provider>
  )
}

DisplayMessageProvider.propTypes = {
  children: PropTypes.node,
}

export default DisplayMessageProvider;
