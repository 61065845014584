import React from 'react';
import Box from '@mui/material/Box';
import {moduleBoxStaticStyles} from './ModuleCard';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const ModuleCardNoContent = (
  {
    sectionColor,
    name='No modules found',
    cardSx = {},
  }) => {
  return (
    <Box sx={{
      ...moduleBoxStaticStyles,
      ...cardSx,
      backgroundColor: (theme) => {
        return theme.palette[sectionColor].header;
      },
      cursor: 'not-allowed',
    }}>
      <Typography
        align='center'
        fontWeight='bold'
        fontSize={{xs: 14, md: 16}}
        sx={{
          m: {xs: '4px', md: '12px'},
          color: (theme) => {
            return theme.palette[sectionColor].tabInactive;
          },
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

ModuleCardNoContent.propTypes = {
  sectionColor: PropTypes.string.isRequired,
  name: PropTypes.string,
  cardSx: PropTypes.object,
}

export default ModuleCardNoContent;