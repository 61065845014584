import React, {useCallback, useContext, useState} from 'react';
import {graphql, useMutation} from 'react-relay';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import DeleteUniversity from './DeleteUniversity';
import {DisplayMessageContext} from '../../DisplayMessageProvider';
import {useUniversities} from '../hooks/useUniversities';


const DeleteUniversitiesMutation = graphql`
  mutation Universities_DeleteUniversitiesMutation(
    $universityToDeleteUid: String!,
    $universityToReplaceWithUid: String!,
  ) {
    deleteUniversity(
      universityToDeleteUid: $universityToDeleteUid,
      universityToReplaceWithUid: $universityToReplaceWithUid,
    ) {
      id
      uid
      name
    }
  }
`;

const Universities = () => {
  const displayMessage = useContext(DisplayMessageContext);

  const {universities: universitiesData, deleteUniversity} = useUniversities();
  const [universities, setUniversities] = useState(universitiesData)

  const [searchText, setSearchText] = useState('');
  const onSearchTextChanged = useCallback(
    (event) => {
      setSearchText(event.target.value);
    }, []
  );

  const universitiesToShow = universities.filter(
    (u) => {
      if (!searchText) return true;
      const st = searchText.toLowerCase();
      return u.name.toLowerCase().includes(st);
    }
  );
  const universitiesMap = universitiesToShow.reduce((map, university) => {
    map[university.name] = university;
    return map;
  }, {});

  const [universityToDelete, setUniversityToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onModalClose = () => {
    setUniversityToDelete(null);
    setUniversityReplaceWith(null);
    setIsDeleteModalOpen(false);
  }
  const onDeleteClick = (university) => {
    setUniversityToDelete(university);
    setIsDeleteModalOpen(true);
  }

  const [universityReplaceWith, setUniversityReplaceWith] = useState(null);
  const onValueReplaceWithChanged = (event, universityName) => {
    setUniversityReplaceWith(universitiesMap[universityName]);
  }

  const [commitMutation, isMutationInProgress] = useMutation(DeleteUniversitiesMutation);
  const onSubmitClick = () => {
    const variables = {
      universityToDeleteUid: universityToDelete?.uid,
      universityToReplaceWithUid: universityReplaceWith?.uid,
    };
    commitMutation({
      variables,
      onCompleted: (data) => {
        const {deleteUniversity} = data;
        setUniversities(deleteUniversity);
        setUniversityToDelete(null);
        setUniversityReplaceWith(null);
        setSearchText('');
        setIsDeleteModalOpen(false)
        displayMessage({info: 'Universities have been updated.'});
      },
      onError: (err) => {
        console.error("Error performing mutation deleteUniversity:", err);
        displayMessage({error: 'Error updating Universities. Please try again later.'});
      },
      updater: (store, data) => {
        console.log('Universities::DeleteMutation::updater: ', JSON.stringify(universityToDelete))
        deleteUniversity(store, universityToDelete);
      },
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: '5px',
        minHeight: '80vh',
      }}
    >
      <Box>
        <TextField
          id='outlined-basic'
          label='Search'
          variant='outlined'
          size='small'
          placeholder='Search topic'
          value={searchText}
          onChange={onSearchTextChanged}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        {
          universitiesToShow
          .sort((u1, u2) => u1.name.toLowerCase().localeCompare(u2.name.toLowerCase()))
          .map((university) => (
            <Chip
              key={university.name}
              label={university.name}
              onDelete={() => onDeleteClick(university)}
            />
          ))
        }
      </Box>
      <DeleteUniversity
        isDeleteModalOpen={isDeleteModalOpen}
        onModalClose={onModalClose}
        universityToDelete={universityToDelete}
        universityReplaceWith={universityReplaceWith}
        onValueReplaceWithChanged={onValueReplaceWithChanged}
        universities={universitiesToShow}
        onSubmitClick={onSubmitClick}
        isLoading={isMutationInProgress}
        disabled={!universityToDelete?.uid || !universityReplaceWith?.uid}
      />
    </Box>
  );
};

export default Universities;