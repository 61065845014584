import {difference, differenceBy, isEmpty, isEqual} from 'lodash';
import React, {lazy} from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {latestCourseVersion} from '@/utils/courses';
import {AlertBgColorNs, AlertBgColorStanford} from '@/style/constants/courses';
import {CURR_SITE, SITE_NS} from '@/constants';
import {useTags} from '../hooks/useTags';

const CourseBuilder = lazy(() => import('./CourseBuilder'));

const CreateCourseMutation = graphql`
  mutation CourseDetailsDialog_CreateMutation(
    $name: String!,
    $description: String!,
    $synopsis: String!,
    $learningObjectives: [String!]!,
    $bannerImg: String!,
    $coverImg: String!,
    $coverImgDesc: String!,
    $tags: [String!]!,
    $accessPlanUids: [Uid!]!,
    $sections: [CourseSectionCreateInput!]!,
  ) {
    createCourse(
      data: {
        name: $name,
        description: $description,
        synopsis: $synopsis,
        learningObjectives: $learningObjectives,
        bannerImg: $bannerImg,
        coverImg: $coverImg,
        coverImgDesc: $coverImgDesc,
        tags: $tags,
        accessPlanUids: $accessPlanUids,
        sections: $sections,
      }
    ) {
      ... on Course {
        id
        ...CoursesTable_course @relay(mask: false)
      }
    }
  }
`;

const UpdateCourseMutation = graphql`
  mutation CourseDetailsDialog_UpdateMutation(
    $id: GlobalID!,
    $name: String = null,
    $description: String = null,
    $synopsis: String = null,
    $status: String = null,
    $learningObjectives: [String!] = null,
    $bannerImg: String = null,
    $coverImg: String = null,
    $coverImgDesc: String = null,
    $tags: [String!] = null,
    $accessPlanUids: [Uid!] = null,
    $sections: [CourseSectionInputPartial!] = null,
  ) {
    updateCourse(
      data: {
        id: $id,
        name: $name,
        description: $description,
        synopsis: $synopsis,
        status: $status,
        learningObjectives: $learningObjectives,
        bannerImg: $bannerImg,
        coverImg: $coverImg,
        coverImgDesc: $coverImgDesc,
        tags: $tags,
        accessPlanUids: $accessPlanUids,
        sections: $sections,
      }
    ) {
      ... on Course {
        id
        ...CoursesTable_course @relay(mask: false)
      }
    }
  }
`;

const PublishCourseMutation = graphql`
  mutation CourseDetailsDialog_PublishMutation(
    $id: GlobalID!,
    $publish: Boolean!,
    $addToHomepage: Boolean = null,
  ) {
    publishCourse(
      data: {
        id: $id,
        publish: $publish,
        addToHomepage: $addToHomepage,
      }
    ) {
      ... on Course {
        id
        ...CoursesTable_course @relay(mask: false)
        courseCatalogItem {
          ...CourseList_CourseCatalogItem
        }
      }
    }
  }
`;

const AlertBgColor = CURR_SITE.name === SITE_NS ? AlertBgColorNs : AlertBgColorStanford;


export default function CourseDetailsDialog(props) {
  const {
    isOpen,
    course: courseInitial,
    displayMessage,
    onClose
  } = props;
  const [course, setCourse] = React.useState(courseInitial);
  const [createMutation, mutatingCreate] = useMutation(CreateCourseMutation);
  const [updateMutation, mutatingUpdate] = useMutation(UpdateCourseMutation);
  const [publishMutation, mutatingPublish] = useMutation(PublishCourseMutation);
  const {updateTags} = useTags();

  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  const handleSubmit = (courseData) => {
    if (isEmpty(course)) {
      handleCourseCreate(courseData);
    }
    else {
      handleCourseUpdate(courseData);
    }
  }

  const handleCourseCreate = (data) => {
    console.log('In handleCourseCreate. data=', JSON.stringify(data));
    const variables = {
      ...data,
      tags: data.tags.map((tag) => tag.text),
      accessPlanUids: data.accessPlans.map((ap) => ap.uid),
      bannerImg: data.bannerImg.name,
      coverImg: data.coverImg.name,
      sections: data.sections.map((section) => ({
          ...section,
          chapters: section.chapters.map((chapter) => ({
            ...chapter,
            modules: chapter.modules.map((module) => module.uid),
          }))
      }))
    }
    console.log('In handleCourseCreate. variables=', JSON.stringify(variables));
    createMutation({
      variables,
      onCompleted: (data) => {
        console.log('handleCourseCreate::onCompleted: data=', data);
        setCourse(data.createCourse);
        const cn = data.createCourse.name;
        displayMessage({info: `Course "${cn}" has been created.`});
      },
      onError: (errData) => {
        console.error("Error performing mutation createCourse:", errData);
        const err = errData.source?.errors?.[0]?.message
          ?? 'Error saving course. Please try again later.'
        displayMessage({error: err});
      },
      updater: (store, updaterData) => {
        console.log('handleCourseCreate::updater: updaterData=', updaterData);
        const newCourse = store.getRootField('createCourse');
        const id = newCourse.getDataID();
        const coursesConnection = ConnectionHandler.getConnection(
          store.getRoot(),
          'CoursesTable_courses',
          {filters: {accessPlans: {site: {current: true}}}}
        );
        const edge = ConnectionHandler.createEdge(
          store,
          coursesConnection,
          newCourse,
          'Course'
        )
        ConnectionHandler.insertEdgeAfter(coursesConnection, edge);
        const newCourseTags =  newCourse.getLinkedRecords('tags');
        updateTags(store, newCourseTags);
      },
    })
  }

  const handleCourseUpdate = (data) => {
    console.log('In handleCourseUpdate: course=', JSON.stringify(course))
    console.log('In handleCourseUpdate: data=', JSON.stringify(data))
    const variables = {
      id: course.id,
      uid: course.uid,
    };
    ['name', 'description', 'synopsis', 'status', 'coverImgDesc'].forEach(
      (field) => {
        if (!isEqual(data[field], course[field])) {
          variables[field] = data[field];
        }
      }
    );
    const learningObjectives = course.learningObjectives.map((lo) => lo.text);
    if (learningObjectives.length !== data.learningObjectives.length ||
      !isEmpty(difference(learningObjectives, data?.learningObjectives))) {
      variables.learningObjectives = data.learningObjectives;
    }
    if (course.tags.length !== data.tags.length ||
      !isEmpty(differenceBy(course.tags, data?.tags, 'text'))) {
      variables.tags = data.tags.map((tag) => tag.text);
    }
    if (course.accessPlans.length !== data.accessPlans.length ||
      !isEmpty(differenceBy(course.accessPlans, data?.accessPlans, 'uid'))) {
      variables.accessPlanUids = data.accessPlans.map((ap) => ap.uid);
    }
    if (!isEqual(data?.bannerImg?.name, course?.bannerImg?.name)) {
      variables.bannerImg = data.bannerImg.name;
    }
    if (!isEqual(data?.coverImg?.name, course?.coverImg?.name)) {
      variables.coverImg = data.coverImg.name;
    }
    const normalizeSections = (sectionsData) => sectionsData.map((section) => ({
      ...section,
      chapters: section.chapters.map((chapter) => ({
        ...chapter,
        modules: chapter.modules.map((module) => module.uid),
      }))
    }))
    const version = latestCourseVersion(course);
    const currSections = normalizeSections(version.sections);
    const updatedSections = normalizeSections(data.sections);
    console.log('curr sections:', JSON.stringify(currSections))
    console.log('upd sections:', JSON.stringify(updatedSections))

    if (currSections.length !== updatedSections.length ||
      !isEmpty(difference(currSections, updatedSections))) {
      variables.sections = updatedSections;
    }
    console.log('In handleCourseUpdate. vars=', JSON.stringify(variables));
    updateMutation({
      variables,
      onCompleted: (data) => {
        console.log('In handleCourseUpdate::onCompleted. data=', JSON.stringify(data));
        const updatedCourse = data.updateCourse;
        setCourse(updatedCourse);
        displayMessage({info: 'Course has been updated.'});
      },
      onError: (errData) => {
        console.error("Error performing mutation updateCourse:", errData);
        const err = errData.source?.errors?.[0]?.message
          ?? 'Error updating course. Please try again later.'
        displayMessage({error: err});
      },
      updater: (store, updaterData) => {
        console.log('handleCourseUpdate::updater: updaterData=', updaterData);
        const updatedCourse = store.getRootField('updateCourse');
        const updatedCourseTags = updatedCourse.getLinkedRecords('tags');
        updateTags(store, updatedCourseTags);
      }
    })
  }


  const handleCoursePublish = (courseData, publishedValue, addToHomepage) => {
    if (courseData?.uid === undefined) {
      displayMessage({error: "Course needs to be saved before publishing"});
      return;
    }
    const variables = {
      id: courseData.id,
      publish: publishedValue,
    };
    if (publishedValue) {
      variables.addToHomepage = addToHomepage;
    }

    publishMutation({
      variables,
      onCompleted: (data) => {
        console.log('In handleCoursePublish::onCompleted. data=', JSON.stringify(data));
        const updatedCourse = data.publishCourse;
        const cn = updatedCourse.name;
        displayMessage(
          {
            info: (publishedValue
              ? `Course "${cn}" has been published.`
              : `Course "${cn}" has been unpublished.`
            )
          },
          AlertBgColor,
        );
        onClose();
      },
      updater: (store, data) => {
        console.log('In handleCoursePublish::updater: ', JSON.stringify(data));
        const courseCatalogConnection = ConnectionHandler.getConnection(
          store.getRoot(),
          'CourseList_courseCatalog',
          {filters: {course: {accessPlans: {site: {current: true}}}}}
        );
        if (courseCatalogConnection) {
          if (publishedValue && addToHomepage) {
            const publishedCourse = store.getRootField('publishCourse');
            const courseCatalogItem = publishedCourse.getLinkedRecord('courseCatalogItem');
            if (courseCatalogItem) {
              const edge = ConnectionHandler.createEdge(
                store,
                courseCatalogConnection,
                courseCatalogItem,
                'CourseCatalogItem'
              )
              ConnectionHandler.insertEdgeAfter(courseCatalogConnection, edge);
            }
          }
          else if (publishedValue === false) {
            const edges = courseCatalogConnection.getLinkedRecords('edges');
            edges.forEach((nodeProxy) => {
              const node = nodeProxy.getLinkedRecord('node');
              const course = node.getLinkedRecord('course');
              const courseUid = course.getValue('uid');
              if (courseUid === courseData.uid) {
                console.log('In handleCoursePublish::updater. CourseCatalogItem nodeToRemove:', node.getDataID());
                ConnectionHandler.deleteNode(courseCatalogConnection, node.getDataID());
              }
            });
          }
        }
      },
      onError: (err) => {
        console.error("Error performing mutation updateCourse:", err);
        displayMessage({error: 'Error publishing course. Please try again later.'});
      },
    })
  }

  const title = course?.id ? 'Edit Course' : 'Create Course';
  const verb = course?.id ? 'editing' : 'creating';
  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogContent sx={{p: 0}}>
        <CourseBuilder
          title={title}
          verb={verb}
          course={course}
          isMutationInProgress={mutatingCreate || mutatingUpdate || mutatingPublish}
          displayMessage={displayMessage}
          onPublish={handleCoursePublish}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}

CourseDetailsDialog.propTypes = {
  isOpen: PropTypes.bool,
  course: PropTypes.object,
  displayMessage: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

CourseDetailsDialog.defaultProps = {
  isOpen: false,
  course: null,
}
