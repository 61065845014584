const pad = (value) => {
  let val = String(value);
  if (val.length < 2) {
    val = '0' + val;
  }
  return val;
}

const getHourAmPm = (hour) => {
  return hour < 12 ? [hour, 'AM'] : [hour === 12 ? hour : hour-12, 'PM'];
}

export const formatDate = (dateValue, dateOnly=false) => {
  const date = new Date(dateValue);
  if (isNaN(date.valueOf())) {
    return '';
  }
  const [month, day, year] = [
    date.getMonth(), // months are 0-indexed
    date.getDate(),
    date.getFullYear(),
  ];
  const [hours, minutes] = [
    date.getHours(),
    date.getMinutes(),
  ];
  let formatted = `${pad(month+1)}/${pad(day)}/${pad(year % 100)}`;
  if (!dateOnly) {
    const [hour, AmPm] = getHourAmPm(hours);
    formatted += ` ${pad(hour)}:${pad(minutes)}${AmPm}`;
  }
  return formatted;
}
