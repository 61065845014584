import React from 'react';
import ModulesTable from '../content/ModulesTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ManageModules = () => {
  const [moduleName, setModuleName] = React.useState('');
  const [url, setUrl] = React.useState('');

  const onModuleNameChange = (evt) => {
    setModuleName(evt.target.value);
    setUrl(`${window.location.origin}/lms/m/module_link?module=${encodeURIComponent(evt.target.value)}`)
  }
  const onContentCopy = (evt) => {
    navigator.clipboard.writeText(url);
  }

  return (
    <Stack direction='column' spacing={5}>
      <ModulesTable />
      <Stack direction='column' spacing={2}>
        <Typography variant='h6'>Module Interlinking</Typography>
        <Typography variant='body2'>Enter a module name below to generate a URL to that module to be used in Rise 360</Typography>
        <TextField
          margin='dense'
          label='Module Name'
          id='module_name'
          size='small'
          type='text'
          value={moduleName}
          variant='outlined'
          onChange={onModuleNameChange}
        />
        <TextField
          readOnly
          label='Encoded URL'
          margin='dense'
          id='module_name'
          size='small'
          type='text'
          value={url}
          variant='outlined'
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                title='Copy to clipboard'
                aria-label="copy to clipboard"
                onClick={onContentCopy}
                edge="end"
              >
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default ManageModules;
