import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../../themes/userUi';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import NavBar from '@/components/common/navbar/NavBar';
import Typography from '@mui/material/Typography';

const AccessError = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        minHeight: '100vh',
        backgroundColor: 'background.accent',
        }}
      >
        <NavBar
          showLinks={false}
          showWelcomeText={false}
          theme={theme}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '17vh',
            gap: 1,
            px: 1,
          }}
        >
          <LockIcon
            color='primary'
            sx={{
              fontSize: {xs: '48px', md: '86px'},
            }}
          />
          <Typography
            color='primary'
            sx={{
              fontSize: {xs: '48px', md: '96px'},
            }}
          >
            Access Error
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '28px', md: '48px'},
              textAlign: 'center',
            }}
          >
            You are not currently logged in under an admin account
          </Typography>

          <Typography
            sx={{
              textAlign: 'center',
              fontSize: {xs: '14px', sm: '20px'},
            }}
          >
            Please check your login information and make sure you are logged in as an admin to access this page.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default AccessError;
