import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextFieldWithLengthDisplay from '../content/TextFieldWithLengthDisplay';
import ReadonlyTextfield from '../common/ReadonlyTextfield';


export default function AdminProfilePanel(props) {
  const {
    editMode,
    formik,
  } = props;

  return (
    <Stack direction='column' gap={2} alignContent='start' width='100%'>
      {editMode
        ?
          <TextFieldWithLengthDisplay
            maxLength={50}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={(formik.touched.firstName && formik.errors.firstName) ?? ' '}
            fullWidth
            id="firstName"
            label="First Name"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('firstName')}
          />
        :
          <ReadonlyTextfield label="First Name" text={formik.values.firstName} />
      }
      {editMode
        ?
          <TextFieldWithLengthDisplay
            maxLength={50}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={(formik.touched.lastName && formik.errors.lastName) ?? ' '}
            fullWidth
            id="lastName"
            label="Last Name"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('lastName')}
          />
        :
          <ReadonlyTextfield label="Last Name" text={formik.values.lastName} />
      }
      {editMode
        ?
          <TextFieldWithLengthDisplay
            maxLength={100}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) ?? ' '}
            fullWidth
            id="email"
            label="Email"
            margin="dense"
            variant="outlined"
            size='small'
            InputProps={{ readOnly: !editMode }}
            {...formik.getFieldProps('email')}
          />
        :
          <ReadonlyTextfield label="Email" text={formik.values.email} />
      }
    </Stack>
  );
}

AdminProfilePanel.propTypes = {
  formik: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
}

AdminProfilePanel.defaultProps = {
  editMode: false,
}
