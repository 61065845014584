import {isEmpty, split, values} from 'lodash';
import React from 'react';
import {graphql} from 'react-relay';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {VisuallyHiddenInput} from '../common/VisuallyHiddenInput';
import {formatFileSize} from '@/utils/common';
import {useMutationWithAuthCheck} from '../hooks/useMutationWithAuthCheck';


const LOADING = 'Loading';
const COMPLETE = 'Complete';
const FAILED = 'Failed';

export const UploadFileTypes = {
  CMI5_ZIP: 'cmi5_zip',
  COVER_IMG: 'cover_img',
  CSV: 'csv',
}

const PrepareUploadMutation = graphql`
  mutation FileUpload_PrepareUploadMutation(
      $fileName: String!,
      $fileType: String!,
  ) {
      prepareUpload(fileName: $fileName, fileType: $fileType)
  }
`;

const CompleteUploadMutation = graphql`
  mutation FileUpload_CompleteUploadMutation(
      $fileId: Int!
  ) {
      completeUpload(fileId: $fileId) {
          originalFileName
          file {
              url
              size
              name
          }
          url
      }
  }
`;

const fileName = (filePath) => {
  if (isEmpty(filePath)) {
    return filePath;
  }
  const elems = split(`${filePath}`, '/');
  if (elems.length > 0) {
    return elems[elems.length-1];
  }
  return filePath;
}

export default function FileUpload(props) {
  const {
    btnLabel,
    initial,
    helperText,
    acceptFileTypes,
    maxFileSize,
    fileType,
    refCallback,
    showPreview,
    sx,
    validationError,
    onUploadCleared,
    onUploadSuccess
  } = props;
  const [commitPrepareMutation, isPrepareMutationInFlight] = useMutationWithAuthCheck(PrepareUploadMutation);
  const [commitCompleteMutation, isCompleteMutationInFlight] = useMutationWithAuthCheck(CompleteUploadMutation);
  const [uploadProgress, setUploadProgress] = React.useState(initial ? 100 : 0);
  const [uploadStatus, setUploadStatus] = React.useState(initial ? COMPLETE : null);
  const [selectedFile, setSelectedFile] = React.useState(fileName(initial?.originalFileName ?? initial?.name ?? null));
  const [bytesUploaded, setBytesUploaded] = React.useState(initial?.size ?? 0);
  const [uploadedFileUrl, setUploadedFileUrl] = React.useState(initial?.url ?? null);
  const [generalError, setGeneralError] = React.useState('');
  const [uploadError, setUploadError] = React.useState('');
  const uploadRef = React.useRef();

  React.useEffect(() => {
      setGeneralError(validationError);
    },
    [validationError]
  );
  const updateProgress = (e) => {
    const percent = (e.loaded / e.total) * 100;
    setUploadProgress(Math.round(percent));
    setBytesUploaded(e.loaded);
  }
  const handleUploadSuccess = (fileId) => {
    return (e) => {
      console.log('Upload complete:', e);
      completeFileUpload(fileId);
    }
  }
  const handleUploadError = (evt, error) => {
    console.error('Upload failed');
    setUploadError('Error uploading file. Please try again later.');
    setUploadStatus(FAILED);
    if (evt) {
      Sentry.captureEvent(evt);
    }
    if (error) {
      Sentry.captureMessage(error)
    }
  }
  const handleUploadAbort = () => {
    console.log('Upload aborted');
    setUploadStatus(FAILED);
  }

  const handleFileUpload = () => {
    setGeneralError(null);
    const file = uploadRef.current.files[0];
    setUploadStatus(LOADING);
    setSelectedFile(file.name);
    if (maxFileSize !== null && file.size > maxFileSize) {
      setUploadError('File too large');
      setUploadStatus(FAILED);
      return;
    }
    setUploadError('');
    startFileUpload(file);
  }

  const startFileUpload = (file) => {
    console.log('In FileUpload::startFileUpload. file=', JSON.stringify(file));
    const variables = {
      fileName: file.name,
      fileType,
    }
    commitPrepareMutation({
      variables,
      onCompleted: (data) => {
        console.log('In FileUpload::startFileUpload::onCompleted. data=', JSON.stringify(data));
        directUpload(file, data.prepareUpload);
      },
      onError: (errData) => {
        console.error("Error performing mutation startFileUpload:", errData);
        const err = errData.source?.errors?.[0]?.message
          ?? 'Error uploading file. Please try again later.'
        setUploadError(err);
      },
    })
  }

  const completeFileUpload = (fileId) => {
    console.log('In FileUpload::completeFileUpload. file=', JSON.stringify(fileId));
    const variables = {
      fileId,
    }
    commitCompleteMutation({
      variables,
      onCompleted: (data, errors) => {
        console.log('In FileUpload::completeFileUpload::onCompleted. data=', JSON.stringify(data));
        if (errors) {
          console.error('In FileUpload::completeFileUpload::onCompleted. errors=', JSON.stringify(errors));
          handleUploadError(null, errors);
          return;
        }
        const uploadInfo = data.completeUpload;
        const file = uploadInfo.file;
        const savedPath = file?.name;
        const uploadedUrl = uploadInfo.url
        console.log('Upload complete:', {savedPath, uploadedUrl});
        setUploadProgress(100);
        setUploadStatus(COMPLETE);
        setUploadedFileUrl(uploadedUrl);
        if (typeof onUploadSuccess === 'function') {
          onUploadSuccess(savedPath, uploadedUrl, file.size);
        }
      },
      onError: (errData) => {
        console.error("Error performing mutation completeFileUpload:", JSON.stringify(errData));
        handleUploadError(null, errData);
      },
    })
  }
  const directUpload = (file, presignedData) => {
    console.log('In FileUpload::directUpload. presignedData=', JSON.stringify(presignedData));
    const postData = new FormData();
    for (const key in presignedData.fields) {
      postData.append(key, presignedData.fields[key]);
    }
    postData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", updateProgress, false);
    xhr.addEventListener("load", handleUploadSuccess(presignedData.id), false);
    xhr.addEventListener("error", handleUploadError, false);
    xhr.addEventListener("abort", handleUploadAbort, false);
    xhr.open("POST", presignedData.url);
    for (const hdr in (presignedData?.headers ?? [])) {
      xhr.setRequestHeader(hdr, presignedData.headers[hdr]);
    }
    xhr.send(postData);
  }

  const handleRemoveUploadedFile = () => {
    setSelectedFile(null);
    setUploadError('');
    setUploadStatus(null);
    setUploadedFileUrl(null);
    setBytesUploaded(0);
    setUploadProgress(0);
    uploadRef.current.value = '';
    if (typeof onUploadCleared === 'function') {
      onUploadCleared();
    }
  }
  return (
    <Box>
    <Stack direction='column'>
      <Stack direction='row' gap={1} sx={{ display: 'flex' }}>
        <Button
          ref={refCallback}
          aria-label={btnLabel}
          component="label"
          variant="contained"
          size='small'
          disabled={uploadStatus===LOADING || isPrepareMutationInFlight || isCompleteMutationInFlight}
          sx={{
            ...sx,
            bgcolor: 'primary.submit',
            '&:hover': {
              bgcolor: 'primary.submitDark',
            },
          }}
        >
          {btnLabel}
          <VisuallyHiddenInput
            aria-hidden='true'
            type="file"
            name="file"
            ref={uploadRef}
            accept={acceptFileTypes}
            onChange={handleFileUpload}
          />
        </Button>
        <Typography variant="caption" display="block" gutterBottom sx={{alignSelf: 'center'}} >
          {helperText}
        </Typography>
      </Stack>
      {selectedFile === null ?
        <Typography variant="caption" display="block" gutterBottom color='error' sx={{marginLeft: '15px'}}>
          {generalError || '\u00A0'}
        </Typography>
        :
        <Card raised elevation={1} sx={{width: '50%', marginBottom: '20px'}}>
          <CardContent style={{paddingBottom: '10px'}}>
            <Stack direction='row' gap={2} justifyContent="space-between">
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', alignSelf: 'center', marginRight: '10px', width: 60, height: 60}}>
                {showPreview && uploadedFileUrl
                  ? <Avatar
                      alt={uploadedFileUrl}
                      src={uploadedFileUrl}
                      variant='square'
                      sx={{height: '100%', width: '100%'}}
                  />
                  : <UploadFileIcon
                    color={isEmpty(uploadError) ? 'primary' : 'error'}
                    sx={{alignSelf: 'center'}}
                  />
                }
                </Box>
                <Stack direction='column'>
                  <Typography
                    variant="body2"
                    gutterBottom
                    color={isEmpty(uploadError) ? 'primary' : 'error'}
                  >
                    {isEmpty(uploadError) ? selectedFile : 'Upload failed'}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    color={isEmpty(uploadError) ? 'disabled' : 'error'}
                  >
                    {isEmpty(uploadError) ? `${formatFileSize(bytesUploaded, 1)}` : uploadError} {'\u00B7'} {uploadStatus}
                  </Typography>
                  <LinearProgress
                    aria-label='File upload progress'
                    variant="determinate"
                    value={uploadProgress}
                    color={isEmpty(uploadError) ? 'primary' : 'error'}
                  />
                </Stack>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  aria-label="remove"
                  disabled={isPrepareMutationInFlight || isCompleteMutationInFlight}
                  onClick={handleRemoveUploadedFile}
                  sx={{alignSelf: 'center'}}
                >
                  <DeleteIcon />
                </IconButton>
                {uploadStatus === COMPLETE && <CheckCircleIcon color='success' sx={{alignSelf: 'center'}} />}
              </Box>
            </Stack>
          </CardContent>
        </Card>
      }
    </Stack>
    </Box>
  )
}

FileUpload.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  initial: PropTypes.object,
  helperText: PropTypes.node,
  acceptFileTypes: PropTypes.string,
  fileType: PropTypes.oneOf(
    values(UploadFileTypes)
  ).isRequired,
  maxFileSize: PropTypes.number,
  refCallback: PropTypes.func,
  showPreview: PropTypes.bool,
  validationError: PropTypes.string,
  sx: PropTypes.object,
  onUploadSuccess: PropTypes.func,
  onUploadCleared: PropTypes.func,
}

FileUpload.defaultProps = {
  initial: null,
  helperText: '',
  acceptFileTypes: '',
  maxFileSize: null,
  refCallback: () => {},
  showPreview: false,
  sx: {},
  validationError: null,
}