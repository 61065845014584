import React from 'react';
import SearchEntityCard from './SearchEntityCard';
import PropTypes from 'prop-types';

const SearchModuleCard = (props) => {

  const {
    moduleData,
    progressValue,
    progressBarVariant,
    isProgressLoading,
    onClick,
  } = props;

  return (
    <>
      <SearchEntityCard
        url={moduleData.coverImg.url}
        name={moduleData.name}
        description={moduleData.description}
        progressValue={progressValue}
        handleClick={onClick}
      />
    </>
  );
};

SearchModuleCard.propTypes = {
  moduleData: PropTypes.object.isRequired,
  progressValue: PropTypes.number.isRequired,
  progressBarVariant: PropTypes.string,
  isProgressLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default SearchModuleCard;