import {isEmpty, trim} from 'lodash';
import React, { useState } from 'react';
import {graphql, useMutation} from 'react-relay';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {postLoginPage} from '@/utils/auth';


const LoginMutation = graphql`
  mutation LoginMutation(
    $email: String!,
    $password: String!
  ) {
    login(
      username: $email,
      password: $password
    ) {
        ... on User {
            uid
            firstName
            lastName
            email
            groups {
                name
            }
        }
        ... on OperationInfo {
            messages  {
                code
                field
                kind
                message
            }
        }
      }
  }
`;


const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [agreeToTerms, setAgreeToTerms] = useState(true);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [commitMutation, isMutationInFlight] = useMutation(LoginMutation);

  const setFormFieldError = (field, errMsg) => {
    setFieldErrors((fe) => ({
      ...fe,
        [field]: errMsg,
    }))
  }

  const checkFormField = (field, errMsg) => {
    let valid = true;
    const val = trim(formData[field]);
    if (isEmpty(val)) {
      setFormFieldError(field, errMsg);
      valid = false;
    }
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldErrors({});
    let valid = true;
    valid = checkFormField('email', 'Please enter an email address') && valid;
    valid = checkFormField('password', 'Please enter a password') && valid;
    if (!agreeToTerms) {
      setFormFieldError('agree', 'Please agree to Terms and Conditions');
      valid = false;
    }
    if (!valid) {
      return;
    }
    setError(null);
    setLoading(true);

    commitMutation({
      variables: {
        email: formData.email,
        password: formData.password
      },
      onCompleted: (data) => {
        console.log('In Login::onCompleted:', data);
        if (data.login?.messages) {
          setError(data.login.messages[0].message)
          setLoading(false);
        }
        else {
          const dest = postLoginPage(data.login);
          navigate(dest, {replace: true});
        }
      },
      onError: (error) => {
        setError(error.source.errors[0].message);
        setLoading(false);
      }
    })
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant='body1' style={{textAlign: 'center'}}>
        Log in to your account
      </Typography>

      {error && (
        <Typography color='error' variant='body2' sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin='normal'
          fullWidth
          name='email'
          label='Email'
          type='email'
          id='email'
          value={formData.email}
          error={!isEmpty(fieldErrors?.email)}
          helperText={fieldErrors?.email}
          onChange={handleInputChange}
        />
        <TextField
          margin='normal'
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          value={formData.password}
          error={!isEmpty(fieldErrors?.password)}
          helperText={fieldErrors?.password}
          onChange={handleInputChange}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{
            mt: 3,
            mb: 2,
          }}
          disabled={loading}
          aria-label='Log In'
        >
          {loading ? 'Loading...' : 'Log In'}
        </Button>
        <Typography variant='body1' style={{textAlign: 'center'}}>
          <RouterLink
            to='/request-password-reset/'
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography sx={{
              color: 'primary.accent',
            }}>
              Forgot password?
            </Typography>
          </RouterLink>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
