import {useEffect} from 'react';

export const useUpdateNextModule = (
  selectedVersionedModule,
  versionedModuleData,
  handleNextVersionedModuleChanged,
) => {
  useEffect(() => {
    if (
      selectedVersionedModule &&
      selectedVersionedModule?.versionUidOfNextModule === versionedModuleData?.uid
    ) {
      handleNextVersionedModuleChanged(versionedModuleData)
    }
  }, [selectedVersionedModule, versionedModuleData])
}