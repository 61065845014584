import PropTypes from 'prop-types';

export const moduleParentPropTypes = {
  selectedVersionedModule: PropTypes.object,
  handleSelectedVersionedModuleChanged: PropTypes.func.isRequired,
  handleNextVersionedModuleChanged: PropTypes.func.isRequired,
  launchMvUid: PropTypes.string,
};

export const videoParentPropTypes = {
  selectedVideo: PropTypes.object,
  handleSelectVideoChanged: PropTypes.func.isRequired,
  handleNextVideoChanged: PropTypes.func.isRequired,
  handlePreviousVideoChanged: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
};