import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {featuredTopicsSx} from '../../../style/constants/homepage';

const indexes = [0, 1, 2, 3, 4, 5, 6, 7];

const FeaturedTopicModulesSkeleton = () => {

  const elements = (
    indexes.map(index => (
      <Skeleton
        key={index}
        variant='rectangular'
        sx={{
          mr: 1,
          p: 0,
          flexShrink: 0,
          ...featuredTopicsSx,
        }}
      />
    ))
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: {xs: 'auto', md: 'hidden'},
      }}
    >
      {elements}
    </Box>
  );
};

export default FeaturedTopicModulesSkeleton;