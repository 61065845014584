/**
 * @generated SignedSource<<4f2d799358e6af40cee0a1b758c1119b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filters",
  "value": {
    "accessPlans": {
      "site": {
        "current": true
      }
    }
  }
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "originalFileName",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CourseEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "synopsis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearningObjective",
            "kind": "LinkedField",
            "name": "learningObjectives",
            "plural": true,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "bannerImg",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "coverImg",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImgDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedTs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseChapter",
                    "kind": "LinkedField",
                    "name": "chapters",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModuleVersion",
                        "kind": "LinkedField",
                        "name": "modules",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Module",
                            "kind": "LinkedField",
                            "name": "module",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessPlan",
            "kind": "LinkedField",
            "name": "accessPlans",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addToHomepage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoursesTableQuery",
    "selections": [
      {
        "alias": "courses",
        "args": [
          (v0/*: any*/)
        ],
        "concreteType": "CourseConnection",
        "kind": "LinkedField",
        "name": "__CoursesTable_courses_connection",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": "__CoursesTable_courses_connection(filters:{\"accessPlans\":{\"site\":{\"current\":true}}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoursesTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "CourseConnection",
        "kind": "LinkedField",
        "name": "courses",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": "courses(filters:{\"accessPlans\":{\"site\":{\"current\":true}}},first:10)"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "CoursesTable_courses",
        "kind": "LinkedHandle",
        "name": "courses"
      }
    ]
  },
  "params": {
    "cacheID": "125b3da24be3d49d6108558b8c875198",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "courses"
          ]
        }
      ]
    },
    "name": "CoursesTableQuery",
    "operationKind": "query",
    "text": "query CoursesTableQuery {\n  courses(first: 10, filters: {accessPlans: {site: {current: true}}}) {\n    edges {\n      node {\n        id\n        uid\n        name\n        description\n        synopsis\n        learningObjectives {\n          text\n        }\n        bannerImg {\n          originalFileName\n          name\n          url\n          size\n        }\n        coverImg {\n          originalFileName\n          name\n          url\n          size\n        }\n        coverImgDesc\n        lastUpdatedTs\n        versions {\n          uid\n          version\n          status\n          author {\n            uid\n            firstName\n            lastName\n            email\n          }\n          sections {\n            uid\n            name\n            description\n            rank\n            chapters {\n              uid\n              name\n              description\n              modules {\n                uid\n                version\n                status\n                module {\n                  name\n                }\n              }\n            }\n          }\n        }\n        accessPlans {\n          uid\n          name\n        }\n        tags {\n          id\n          text\n        }\n        addToHomepage\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "a2cd0d0f4d465007d72851b9eefd938a";

export default node;
