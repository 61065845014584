/**
 * @generated SignedSource<<daa19177eb1828ff98f8ee6ae997374a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uid"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "password2",
        "variableName": "password2"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "userUid64b",
        "variableName": "uid"
      }
    ],
    "concreteType": "ResetPasswordResult",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "msg",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ResetPasswordMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d32eb25eaf6f68a374d008d242325891",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordMutation(\n  $uid: String!\n  $token: String!\n  $password: String!\n  $password2: String!\n) {\n  resetPassword(userUid64b: $uid, token: $token, password: $password, password2: $password2) {\n    status\n    msg\n  }\n}\n"
  }
};
})();

node.hash = "12522f6f2376e92f53af505befabf2c4";

export default node;
