/**
 * @generated SignedSource<<c600ca7b2b51e19c2a5da0c3fcbf09f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTemplate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "videoSearchTemplate",
    "variableName": "searchTemplate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ModuleVersion",
  "kind": "LinkedField",
  "name": "moduleVersion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadedFile",
          "kind": "LinkedField",
          "name": "coverImg",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchQueryRunner_SearchVideosListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "filterVideos",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoDataWrapperFragment_defaultVersionedModulesVideo"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchQueryRunner_SearchVideosListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Video",
        "kind": "LinkedField",
        "name": "filterVideos",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnailUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nextVideoUid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previousVideoUid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoProgress",
            "kind": "LinkedField",
            "name": "progress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "progressPercent",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba7bbcdabdc549adcba282a7bfcaa2a9",
    "id": null,
    "metadata": {},
    "name": "SearchQueryRunner_SearchVideosListQuery",
    "operationKind": "query",
    "text": "query SearchQueryRunner_SearchVideosListQuery(\n  $searchTemplate: String\n) {\n  filterVideos(videoSearchTemplate: $searchTemplate) {\n    uid\n    ...VideoDataWrapperFragment_defaultVersionedModulesVideo\n    moduleVersion {\n      module {\n        coverImg {\n          url\n        }\n        tags {\n          text\n        }\n      }\n    }\n  }\n}\n\nfragment VideoDataWrapperFragment_defaultVersionedModulesVideo on Video {\n  id\n  uid\n  title\n  description\n  fullUrl\n  thumbnailUrl\n  nextVideoUid\n  previousVideoUid\n  ...VideoDataWrapperProgressFragment_progress\n}\n\nfragment VideoDataWrapperProgressFragment_progress on Video {\n  progress {\n    progressPercent\n  }\n  id\n}\n"
  }
};
})();

node.hash = "f48e8b414060f389abc78080f1178d86";

export default node;
