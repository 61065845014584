import React from 'react';
import PropTypes from "prop-types";
import SearchEntityCard from "./SearchEntityCard";

const SearchCourseCard = (props) => {
  const {
    versionedCourseData,
    progressValue,
    onNameClick,
    onButtonClick,
  } = props;

  const {course} = versionedCourseData;

  return (
    <>
      <SearchEntityCard
        url={course.coverImg.url}
        name={course.name}
        description={course.synopsis}
        progressValue={progressValue}
        handleClick={onButtonClick}
      />
    </>
  );
};

SearchCourseCard.propTypes = {
  versionedCourseData: PropTypes.object.isRequired,
  progressValue: PropTypes.number.isRequired,
  onNameClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

export default SearchCourseCard;