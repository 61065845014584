import { deepmerge } from '@mui/utils';
import {createTheme} from "@mui/material/styles";
import {theme as baseTheme} from './main';
import {CURR_SITE, SITE_NS, SITE_STANFORD} from "../constants";


const params = {
  [SITE_NS]: {
    'color-primary': '#22917D',
    'color-action': '#F0532A',
    'color-action-dark': '#dc4c25',
    'color-accent': '#0A4A63',
    'color-submit': '#F0532A',
    'color-submit-dark': '#dc4c25',
  },
  [SITE_STANFORD]: {
    'color-primary': '#197F89',
    'color-action': '#B13050',
    'color-action-dark': '#7b2138',
    'color-accent': '#4C6F8D',
    'color-submit': '#197F89',
    'color-submit-dark': '#1e6d77',
  }
}

const site_params = params[CURR_SITE.name] ?? params[SITE_STANFORD];

export const theme = createTheme(
  deepmerge(
    baseTheme,
    {
      palette: {
        primary: baseTheme.palette.augmentColor({
          color: {
            main: site_params['color-primary'],
            action: site_params['color-action'],
            actionDark: site_params['color-action-dark'],
            accent: site_params['color-accent'],
            submit: site_params['color-submit'],
            submitDark: site_params['color-submit-dark'],
          },
          name: 'primary',
        }),
      }
    }
  )
);
