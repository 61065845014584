import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const buttonHeight = {
  xs: '48px',
  md: '56px',
}

const buttonFontSize = {
  xs: '16px',
}

const defaultPlaceholder = 'Search Content';

const SearchTextField = (props) => {
  const {
    filterText,
    updateFilterText,
    placeholder,
    initiallyFocused,
  } = props;

  const [filterTextTyped, setFilterTextTyped] = useState(filterText);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && initiallyFocused) {
      inputRef.current.focus()
    }
  }, [inputRef.current]);

  const handleFieldChanged = (event) => {
    event.preventDefault();
    setFilterTextTyped(event.target.value);
  }

  const handleClick = (event) => {
    updateFilterText(filterTextTyped);
  }

  useEffect(() => {
    if (filterTextTyped !== filterText) {
      setFilterTextTyped(filterText)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      justifyContent: {xs: 'center'},
      alignItems: {xs: 'center'},
      height: '100%',
      width: {xs: '100%', lg: 'auto'},
    }}>
      <TextField
        inputRef={inputRef}
        placeholder={placeholder ?? defaultPlaceholder}
        value={filterTextTyped}
        onChange={handleFieldChanged}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleClick(event);
          }
        }}
        inputProps={{maxLength: 100}}
        InputProps={{
          startAdornment: (
            <SearchIcon
              sx={{
                fontSize: '26px',
                stroke: '#ffffff',
                strokeWidth: '1px',
                pr: 1,
              }}
            />
          ),
        }}
        sx={{
          '&.MuiFormControl-root': {
            '& .MuiInputBase-root': {
              height: '100%',
              fontSize: {...buttonFontSize},
            },
            width: {xs: '100%'},
            minWidth: {lg: '360px'},
            height: {...buttonHeight},
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'black',
              borderWidth: '1px',
            },
          },
          flex: {xs: 1},
          mr: {md: '15px'},
          mb: {xs: '15px', md: '0px'}
        }}>
      </TextField>

      <Button
        aria-label='search'
        variant='contained'
        color='accent'
        sx={{
          textTransform: 'none',
          height: {...buttonHeight},
          width: {xs: '100%', md: 'auto'},
          backgroundColor: 'accentDark.main',
          '&:hover': {
            backgroundColor: 'accentDark.main',
          },
        }}
        onClick={handleClick}
      >
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {...buttonFontSize},
          color: 'background.light',
        }}>
          Search
        </Typography>
      </Button>
    </Box>
  );
};

SearchTextField.propTypes = {
  filterText: PropTypes.string.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  initiallyFocused: PropTypes.bool,
}

export default SearchTextField;