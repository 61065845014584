
export const SITE_NS = 'ns';
export const SITE_STANFORD = 'stanford';
export const MODULE_CHAPTER_TYPE = 'module';
export const VIDEO_CHAPTER_TYPE = 'video';

export const MODULE_SEARCH_TYPE = 'module';
export const COURSE_SEARCH_TYPE = 'course';
export const VIDEO_SEARCH_TYPE = 'video';

export const SEARCH_PLACEHOLDER = 'Search Content';

export const SITES = {
  [SITE_NS]: {
    name: SITE_NS,
    title: 'NS',
    domain: 'sparknslearning.org',
  },
  [SITE_STANFORD]: {
    name: SITE_STANFORD,
    title: 'Stanford',
    domain: 'sparkmedlearning.com',
  }
}

export const CURR_SITE = window.location.hostname.endsWith(SITES[SITE_NS].domain)
  ? SITES[SITE_NS]
  : SITES[SITE_STANFORD];

export const HOMEPAGE_TITLE = CURR_SITE.name === SITE_STANFORD ? 'Directed Learning' : 'Home';
export const HOMEPAGE_URL = CURR_SITE.name === SITE_STANFORD ? '/directed-learning/' : '/';

export const FEATURED_TOPIC_HDR = CURR_SITE.name === SITE_NS
  ? 'Learning Path'
  : 'Featured Topic';

export const MAX_TAG_LENGTH = 40;
