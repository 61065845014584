import React from 'react';

const Cmi5ViewerExit = () => {
  React.useEffect(() => {
    console.log('Cmi5ViewerExit sending message to parent with origin: ', window.location.origin);
    window.parent.postMessage("Exited", window.location.origin);
  }, []);

  return (
    <>
      <b>Exiting...</b>
    </>
  )
}

export default Cmi5ViewerExit;
