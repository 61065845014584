export const navBarHeight= {
  xs: '60px', md: '70px',
}

export const PublishStatusColors = {
  draft: 'primary.action',
  published: 'primary.accent',
  readyToPublish: 'primary.main',
}

export const contentPt = {
  xs: '24px',
}

export const contentPb = {
  xs: '16px',
}

