import React, {useEffect} from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import PropTypes from 'prop-types';

const ModulesSectionsListQuery = graphql`
  query ModulesSectionsListQuery {
    sections {
      ...SectionFragment_section
      chapters {
        id
        uid
        ...ModuleChapterFragment_chapter
      }  
    }
  }
`;

const ModulesSectionsList = (props) => {
  const {
    dataLoadedCb,
    renderSectionsList,
  } = props;

  const {sections} = useLazyLoadQuery(
    ModulesSectionsListQuery,
    {},
    {fetchPolicy: 'network-only'},
  );

  useEffect(() => {
    if (sections) {
      dataLoadedCb();
    }
  }, [sections])

  return (
    renderSectionsList(sections)
  );
};

ModulesSectionsList.propTypes = {
  dataLoadedCb: PropTypes.func.isRequired,
  renderSectionsList: PropTypes.func.isRequired,
}

export default ModulesSectionsList;