/**
 * @generated SignedSource<<6e0421e25f82ec908e7a7429f37c3651>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoursesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseVersion",
        "kind": "LinkedField",
        "name": "versionedCourses",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CourseCardDataWrapperFragment_courseVersion"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoursesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseVersion",
        "kind": "LinkedField",
        "name": "versionedCourses",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "synopsis",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadedFile",
                "kind": "LinkedField",
                "name": "coverImg",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverImgDesc",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseVersionProgress",
            "kind": "LinkedField",
            "name": "progress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "progressPercent",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a2ca66d1e279c5356edcfd5d5977670",
    "id": null,
    "metadata": {},
    "name": "CoursesQuery",
    "operationKind": "query",
    "text": "query CoursesQuery {\n  versionedCourses {\n    id\n    uid\n    ...CourseCardDataWrapperFragment_courseVersion\n  }\n}\n\nfragment CourseCardDataWrapperFragment_courseVersion on CourseVersion {\n  uid\n  version\n  course {\n    id\n    uid\n    name\n    synopsis\n    coverImg {\n      url\n    }\n    coverImgDesc\n  }\n  progress {\n    progressPercent\n  }\n}\n"
  }
};
})();

node.hash = "94a22f6e7e3a2de4d54c98f3e0c2648e";

export default node;
