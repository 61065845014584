import {useState} from 'react';

export const useVideos = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
    const handleSelectVideoChanged = (video) => {
      setSelectedVideo(prev => {
        return video;
      });
    };

    const [nextVideo, setNextVideo] = useState(null);
    const [previousVideo, setPreviousVideo] = useState(null);

    const handleNextVideoChanged = (video) => {
      setNextVideo(prev => {
        return video;
      });
    };

    const handlePreviousVideoChanged = (video) => {
      setPreviousVideo(prev => {
        return video;
      });
    };

    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

    const handleOpenDialog = () => {
      setIsViewDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setIsViewDialogOpen(false);
      setSelectedVideo(prev => {
        return null;
      })
      setPreviousVideo(null);
      setNextVideo(null);
    };

    return [
      isViewDialogOpen,
      handleOpenDialog,
      handleCloseDialog,
      selectedVideo,
      handleSelectVideoChanged,
      nextVideo,
      handleNextVideoChanged,
      previousVideo,
      handlePreviousVideoChanged,
    ]
}