import React from 'react';
import {isEmpty} from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import ModulesTable from "./ModulesTable";


export default function ModuleSelectionDialog(props) {
  const {
    initialSelection,
    initialSearchText,
    isOpen,
    title,
    isRowSelectable,
    onClose
  } = props;

  const [selectedModules, setSelectedModules] = React.useState(initialSelection ?? []);

  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose(null);
    },
  [onClose],
  );

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onClose(selectedModules);
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ModulesTable
          initialSelection={selectedModules}
          initialSearchText={initialSearchText}
          editable
          selectable
          isRowSelectable={isRowSelectable}
          onModulesSelected={setSelectedModules}
        />
      </DialogContent>
      <DialogActions>
        <Button
          aria-label='Cancel'
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          aria-label='Submit'
          disabled={isEmpty(selectedModules)}
          type="button"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModuleSelectionDialog.propTypes = {
  initialSelection: PropTypes.array,
  initialSearchText: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isRowSelectable: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

ModuleSelectionDialog.defaultProps = {
  initialSelection: null,
  initialSearchText: null,
  isOpen: false,
  isRowSelectable: (row) => true,
}
