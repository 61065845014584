import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import StickyHeader from './StickyHeader';
import SectionsList from './SectionsList';
import SectionsTabs from "./SectionsTabs";
import {useCallback, useRef, useState} from "react";
import scrollbar from './scrollbar';


const SectionsContainer = (props) => {
  const {
    sections,
    createStickyHeaderComponent,
    createSectionContentsComponent,
    stickyHeaderSx,
  } = props;
  const stickyHeaderRef = React.useRef(null);
  const sectionsRefMap = useRef({});
  const [sectionValue, setSectionValue] = useState(0);

  const sectionsRefMapCb = useCallback((index, node) => {
    sectionsRefMap.current[index] = node;
  }, [sectionsRefMap]);

  const handleSectionTabChanged = React.useCallback(
  (event, value) => {
    event.preventDefault()
    if (stickyHeaderRef.current) {
      const sectionRef = sectionsRefMap.current[value];
      if (sectionRef) {
        scrollbar.disable();
        const sectionPosition = Math.round(
          sectionRef.getBoundingClientRect().top
        );
        const positionOffset = getStickyHeaderBottom() ?? 0;
        const currentScrollY = Math.round(window.scrollY);
        const stickyHdrStyleTopStr= (window.getComputedStyle ?
            window.getComputedStyle(stickyHeaderRef.current, null).getPropertyValue('top') :
            stickyHeaderRef.current.currentStyle ? stickyHeaderRef.current.currentStyle.top : '0'
        );
        const stickyHdrStyleTop = parseInt(stickyHdrStyleTopStr, 10);
        const stickyHdrBoundRectTop = stickyHeaderRef.current.getBoundingClientRect().top;
        const scrollTo = currentScrollY + sectionPosition - positionOffset + stickyHdrBoundRectTop - stickyHdrStyleTop;
        window.scroll({top: scrollTo, behavior: 'smooth'});
        setSectionValue(value);
      }
    }
  }, []);

  const onScrollToSection = useCallback(
  (event, value) => {
    if (event) {
      event.preventDefault();
    }
    setSectionValue(value)
  },[]);

  const getStickyHeaderBottom = () => {
    if (stickyHeaderRef.current) {
      return Math.round(
        stickyHeaderRef.current.getBoundingClientRect().bottom
      );
    }
    return null;
  }

  const getBottomFillHeight = () => {
    /*
    If the last section's height is small, it will not reach the bottom of the sticky header
    when scrolling vertically to the bottom of the page.
    As a workaround, we add a "filler" space which is high enough to ensure than the last
    section's top edge can be scrolled to the bottom of the sticky header.
     */
    const sectionRef = sectionsRefMap.current[sections[sections.length-1].rank];
    if (sectionRef) {
      const br = sectionRef.getBoundingClientRect();
      const sectionHeight = Math.round(br.bottom - br.top);
      return Math.max(window.innerHeight - getStickyHeaderBottom() - sectionHeight, 0);
    }
    return 0;
  }

  return (
    <Box>
      <StickyHeader ref={stickyHeaderRef} sx={stickyHeaderSx}>
        <Box sx={{
          mb: {xs: '6px'},
        }}>
          {
            typeof createStickyHeaderComponent === 'function'
              ? createStickyHeaderComponent(sections, sectionValue, handleSectionTabChanged)
              : (
                  <SectionsTabs
                    sections={sections}
                    sectionValue={sectionValue}
                    handleSectionTabChanged={handleSectionTabChanged}
                    getSectionRank={(section) => section.rank}
                  />
              )
          }
        </Box>
      </StickyHeader>
      <SectionsList
        sectionsRefMap={sectionsRefMap}
        getStickyHeaderBottom={getStickyHeaderBottom}
        onScrollToSection={onScrollToSection}
      >
        {sections.map((section, idx) => (
          <Box
            key={section.uid}
            ref={(node) => sectionsRefMapCb(section.rank, node)}
          >
            <React.Fragment key={section.uid}>
              {createSectionContentsComponent(section, idx)}
            </React.Fragment>
          </Box>
        ))}
      </SectionsList>
      <Box
        sx={{
          height: `${getBottomFillHeight()}px`,
        }}
      />
    </Box>
  )
}

SectionsContainer.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  createSectionContentsComponent: PropTypes.func.isRequired,
  createStickyHeaderComponent: PropTypes.func,
  stickyHeaderSx: PropTypes.object,
}

SectionsContainer.defaultProps = {
  stickyHeaderSx: {},
}
export default SectionsContainer;
