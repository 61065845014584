import React, {useCallback, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeNs from '/static/ns/icons/navigate_before.svg';
import NavigateAfterNs from '/static/ns/icons/navigate_after.svg';
import NavigateBeforeStanford from '/static/stanford/icons/navigate_before.svg';
import NavigateAfterStanford from '/static/stanford/icons/navigate_after.svg';
import PropTypes from 'prop-types';
import {CURR_SITE, SITE_STANFORD} from '../../constants';

const site = CURR_SITE.name;
const NavigateBefore = site === SITE_STANFORD ? NavigateBeforeStanford : NavigateBeforeNs;
const NavigateAfter = site === SITE_STANFORD ? NavigateAfterStanford : NavigateAfterNs;

const ScrollableXContainer = (props) => {
  const {elements, containerSx, navigationSx} = props;
  const defaultScrollAmount = 100;
  const containerRef = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const elementsRefMap = useRef({});
  const elementsRefMapCb = useCallback((node, key) => {
    if (!!node) {
      elementsRefMap.current[key] = node;
    }
  }, [])

  const checkModulesOverflow = useCallback(
    () => {
      if (containerRef.current) {
        const {scrollLeft, scrollWidth, clientWidth} = containerRef.current;
        const roundedScrollLeft = Math.floor(scrollLeft);
        const roundedScrollRight = Math.floor(scrollWidth - scrollLeft);
        const roundedClientWidth = Math.floor(clientWidth);
        setShowLeft(roundedScrollLeft > 0);
        setShowRight(roundedScrollRight > roundedClientWidth);
      }
    }, [])

  useEffect(() => {
    checkModulesOverflow();
  }, [elements, checkModulesOverflow])

  useEffect(() => {
    const checkResizing = (e) => {
      checkModulesOverflow();
    }
    window.removeEventListener('resize', checkResizing);
    window.addEventListener('resize', checkResizing);
    return () => window.removeEventListener('resize', checkResizing);
  }, [checkModulesOverflow])

  const handleScrollArrowClick = (directionIndicator = 1) => {
    const container = containerRef.current;
    if (container) {
      const containerRect = containerRef.current.getBoundingClientRect();
      let scrollOffset = containerRect.width;
      let scroll = 0;
      for (let i = 0; i < elements.length; i++) {
        const elementNode = elementsRefMap.current[elements[i].key]
        const elementRect = elementNode.getBoundingClientRect();
        const elementWidth = elementRect.width;
        const elementEnd = elementRect.left + elementWidth;

        // Check if the element is the last visible element
        if (
          elementRect.left < containerRect.right &&
          elementEnd >= containerRect.right
        ) {
          const hiddenElementPart = elementEnd - containerRect.right;

          // Retrieve from the scroll offset the last element`s hidden part
          scroll += hiddenElementPart;
          scrollOffset -= elementRect.width;

          // Find the maximum number of elements that can fit
          scroll += Math.floor(scrollOffset / elementWidth) * elementWidth;
          break;
        }
      }

      // Added for safety
      if (scroll <= 0) {
        scroll = defaultScrollAmount;
      }

      container.scrollTo({
        left: container.scrollLeft + directionIndicator * scroll,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = (e) => {
    checkModulesOverflow();
  };

  const navigationBeforeElement = (
    (showLeft || showRight) && (
      <IconButton
        aria-label='left'
        disabled={!showLeft}
        sx={{
          opacity: showLeft ? 'none' : '0.3',
        }}
        onClick={() => handleScrollArrowClick(-1)}
      >
        <img
          style={{
            width: '22px',
            height: '22px',
            opacity: '0.85',
          }}
          alt=''
          src={NavigateBefore}/>
      </IconButton>
    )
  );

  const navigationAfterElement = (
    (showLeft || showRight) && (
      <IconButton
        aria-label='right'
        disabled={!showRight}
        sx={{
          opacity: showRight ? 'none' : '0.3',
        }}
        onClick={() => handleScrollArrowClick(1)}
      >
        <img
          style={{
            width: '22px',
            height: '22px',
            opacity: '0.85',
          }}
          alt=''
          src={NavigateAfter}
        />
      </IconButton>
    )
  );

  return (
    <>
      <Box
        onScroll={(e) => handleScroll(e)}
        sx={{
          display: 'flex',
          overflowX: {xs: 'auto', md: 'hidden'},
          justifyContent: 'flex-start',
          width: '100%',
          ...containerSx,
        }}
        ref={containerRef}
      >
        {
          elements.map((element, index) => {
            return (
              <Box
                key={element.key}
                ref={(node) => elementsRefMapCb(node, element.key)}
              >
                {element}
              </Box>
            );
          })
        }
      </Box>
      <Box sx={{
        display: {xs: 'none', md: 'block'},
        position: 'absolute',
        bottom: '-27%',
        right: '2%',
        ...navigationSx,
        height: '22px',
      }}>
        {navigationBeforeElement}
        {navigationAfterElement}
      </Box>
    </>
  );
}

ScrollableXContainer.propTypes = {
  elements: PropTypes.array.isRequired,
  containerSx: PropTypes.object,
  navigationSx: PropTypes.object,
}

export default ScrollableXContainer;