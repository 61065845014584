import { createBrowserHistory } from 'history';
import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

function locationString(locationLike) {
  if (typeof locationLike === 'string') {
    return locationLike;
  }
  const { pathname, search, hash } = locationLike;
  let result = pathname;

  if (search) {
    result += search;
  }
  if (hash) {
    result += hash;
  }
  return result;
}

function createHistory() {
  const history = createBrowserHistory();
  const origPush = history.push;
  history.push = (path, state) => {
    if (window.buildNumbersMismatch) {
      // make an HTTP request to the backend
      window.location.href = locationString(path);
    } else {
      origPush(path, state);
    }
  };
  return history;
}

export default class SmartRouter extends React.Component {
  constructor(props) {
    super(props);
    this.history = createHistory();
  }

  render() {
    return <HistoryRouter history={this.history} {...this.props} />;
  }
}
