import {graphql, useLazyLoadQuery} from "react-relay";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from 'prop-types';


const CoursesWithModuleQuery = graphql`
  query CoursesWithModuleQuery(
    $moduleUid: Uid!
  ) {
      coursesWithModule(moduleUid: $moduleUid) {
        name
      }
  }
`;


function CoursesWithModule(props) {
  const {
    moduleUid,
  } = props;
  const data = useLazyLoadQuery(
    CoursesWithModuleQuery,
    {moduleUid},
  );
  return (
    <React.Suspense fallback={<CircularProgress aria-label='Data loading progress'/>}>
      <List dense>
        {data.coursesWithModule.length === 0
          ?
            (<ListItem>
              <ListItemText primary='No courses contain this module' />
            </ListItem>)
          :
            (data.coursesWithModule.map((course) =>
              <ListItem key={course.name}>
                <ListItemText primary={course.name} />
              </ListItem>
            ))
        }
      </List>
    </React.Suspense>
  )
}

CoursesWithModule.propTypes = {
  moduleUid: PropTypes.string.isRequired,
}

export default CoursesWithModule;
