
const dummyScrollId = 'dummy-scroll';

class Scrollbar {
  #scrollBarLength;
  #isScrollDisabled;
  #scrollDisabledTimer;
  constructor() {
    this.#scrollBarLength = 0;
    this.#isScrollDisabled = false;
    this.#scrollDisabledTimer = null;
  }

  createDummyScroll = () => {
    const dummyScroll = document.getElementById(dummyScrollId);
    if (!dummyScroll && this.#scrollBarLength && this.#scrollBarLength > 0) {
      const textarea = document.createElement('textarea');
      textarea.id = dummyScrollId;
      textarea.style.overflowY = 'scroll';
      textarea.style.overflowX = 'hidden';
      textarea.style.padding = '0px';
      textarea.style.width = `${this.#scrollBarLength}px`;
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.position = 'fixed';
      textarea.style.top = '0';
      textarea.style.right = '0';
      textarea.style.bottom = '0';
      textarea.style.border = '0';
      textarea.style.zIndex = '9999';
      document.body.appendChild(textarea);
    }
  }

  removeDummyScroll = () => {
    const dummyScroll = document.getElementById(dummyScrollId);
    if (dummyScroll) {
      dummyScroll.remove();
    }
  }

  isScrollDisabled = () => {
    return this.#isScrollDisabled;
  }

  disable = () => {
    if (this.#isScrollDisabled === false) {
      let marginRight = 0;
      if (window.getComputedStyle) {
        const bodyStyle = window.getComputedStyle(document.body);
        if (bodyStyle) {
          marginRight = parseInt(bodyStyle.marginRight, 10);
        }
      }
      this.#scrollBarLength = window.innerWidth - document.body.clientWidth;
      this.createDummyScroll();
      Object.assign(document.body.style, {
        overflow: 'hidden',
        marginRight: `${marginRight + this.#scrollBarLength}px`
      });
      this.#isScrollDisabled = true;
    }
    // It was found during the test
    // that if the user scrolls very fast and chaotically (not typical user behavior),
    // the window.scroll event might not finish as expected.
    // Therefore, we added the ability to cancel the disabled state of scrolling as a precaution.
    if (this.#scrollDisabledTimer !== null) {
      clearTimeout(this.#scrollDisabledTimer);
    }
    const self = this;
    this.#scrollDisabledTimer = setTimeout(function() {
      self.enable()
    }, 1500);
  }

  enable = () => {
    if (this.#isScrollDisabled === true) {
      let marginRight = 0;
      if (window.getComputedStyle) {
        const bodyStyle = window.getComputedStyle(document.body);
        if (bodyStyle) {
          marginRight = parseInt(bodyStyle.marginRight, 10);
        }
      }
      this.removeDummyScroll()
      Object.assign(document.body.style, {
        overflow: 'auto',
        marginRight: `${marginRight - this.#scrollBarLength}px`
      });
      this.#isScrollDisabled = false;
    }
  }
}

const scrollbar = new Scrollbar();
export default scrollbar;
