import React, {useCallback, useRef} from 'react';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
import {createStyles, makeStyles} from '@mui/styles';
import {useDragAndDrop} from '../hooks/useDragAndDrop';
import {draggableChildrenStyle, dummyBorderStyle} from '@/constants/dragAndDrop';

export const useAccordionStyles = makeStyles(() => createStyles({
  accordionSummaryContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ChapterAccordion = (props) => {
  const {
    chapterData,
    expanded,
    onExpanded,
    chapterState,
    handleChapterStateChanged,
    renderDraggableContainer,
  } = props;

  const accordionSummaryContentClasses = useAccordionStyles();

  const handleValuesChanged = (values) => {
    handleChapterStateChanged(chapterData.uid, values);
  };

  const handleAccordionExpanded = (evt, isExpanded) => {
    if (!evt.isDefaultPrevented()) {
      onExpanded(chapterData.uid, isExpanded);
    }
  }

  const modulesRefMap = useRef({});

  const modulesRefMapCb = useCallback((moduleUid, node) => {
    modulesRefMap.current[moduleUid] = node;
   }, [modulesRefMap]);

  const [
    handleDragStart,
    handleDragEnd,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  ] = useDragAndDrop(
    chapterState.modules,
    handleValuesChanged,
    modulesRefMap,
  );

  const getModuleData = (uid) => {
    return chapterData.defaultModules.find((m) => m.uid === uid);
  };

  const accordionSummaryElement = !!chapterData && (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      aria-controls={`chapter${chapterData.uid}-content`}
      id={`chapter${chapterData.uid}-header`}
      classes={{
        'content': accordionSummaryContentClasses.accordionSummaryContent,
      }}
      sx={{
        backgroundColor: '#ECEFF1',
          '& .MuiAccordionSummary-content': {
            ...draggableChildrenStyle,
          }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ...draggableChildrenStyle,
        }}
      >
        <DragIndicatorIcon
          sx={{
            fontSize: 15,
            marginRight: '10px',
          }}
        />
        <Typography
          sx={{
            ...draggableChildrenStyle,
          }}
        >
          {chapterData.name}
        </Typography>
      </Box>
    </AccordionSummary>
  );

  return (
    <>
      <Box>
        <Accordion
          expanded={expanded}
          onChange={handleAccordionExpanded}
          sx={{
            '& .MuiAccordionSummary-content': {
              my: '20px',
            },
            '&.MuiAccordion-root': {
              '&.Mui-expanded': {
                m: 0,
              }
            },
          }}
        >
          {renderDraggableContainer(chapterData.uid, accordionSummaryElement)}
          <AccordionDetails>
            <Stack
              direction='column'
              gap={1}
            >
              <Box
                sx={{
                  mt: 3
                }}
              >
                {
                  chapterState.modules.map((moduleUid) => {
                    const moduleData = getModuleData(moduleUid);
                    return (
                      <Paper
                        ref={(node) => modulesRefMapCb(moduleUid, node)}
                        key={`module-${moduleUid}`}
                        draggable="true"
                        elevation={0}
                        sx={{
                          backgroundColor: '#ECEFF1',
                          py: '10px',
                          mb: '10px',
                          border: dummyBorderStyle,
                        }}
                        onDragStart={(evt) => handleDragStart(evt, moduleUid)}
                        onDragEnd={(evt) => handleDragEnd(evt, moduleUid)}
                        onDrop={(evt) => handleDrop(evt, moduleUid)}
                        onDragOver={(evt) => handleDragOver(evt, moduleUid)}
                        onDragLeave={(evt) => handleDragLeave(evt, moduleUid)}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          sx={{
                            ...draggableChildrenStyle,
                          }}
                        >
                          <Stack
                            direction='row'
                            gap={1}
                            alignItems='center'
                            sx={{
                              ml: 2,
                              ...draggableChildrenStyle,
                            }}
                          >
                            <DragIndicatorIcon
                              sx={{
                                fontSize: 15,
                                ...draggableChildrenStyle,
                              }}
                            />
                            <Typography
                              sx={{
                                ...draggableChildrenStyle,
                              }}
                            >
                              {moduleData.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Paper>
                    );
                  })
                }
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

ChapterAccordion.propTypes = {
  chapterData: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onExpanded: PropTypes.func.isRequired,
  chapterState: PropTypes.object,
  handleChapterStateChanged: PropTypes.func.isRequired,
  renderDraggableContainer: PropTypes.func.isRequired,
}

ChapterAccordion.defaultProps = {
  expanded: false,
}

export default ChapterAccordion;
