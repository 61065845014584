import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import {graphql, useLazyLoadQuery} from 'react-relay';
import PropTypes from 'prop-types';

const FeaturedTopicsChipsQuery = graphql`
  query FeaturedTopicsChipsQuery {
    featuredTopics(withModulesOnly: true) {
      uid
      id
      name
      synopsis
    }  
  }
`;

const FeaturedTopicsChips = (props) => {

  const {
    selectedTopic,
    onSelectedTopicChanged,
  } = props;

  const {featuredTopics} = useLazyLoadQuery(
    FeaturedTopicsChipsQuery,
    {},
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!selectedTopic) {
      onSelectedTopicChanged(featuredTopics[0])
    }
  }, [featuredTopics])

  return (
    <Grid container spacing={1} sx={{
      mb: '32px',
    }}>
      {
        featuredTopics.map((topic, index) => (
          <Grid item key={index}>
            <Chip
              onClick={() => onSelectedTopicChanged(topic)}
              label={topic.name}
              color='primary'
              variant={selectedTopic?.name === topic.name ? 'filled' : 'outlined'}
              sx={{
                cursor: 'pointer',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'primary.main',
                  color: 'white',
                }
              }}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

FeaturedTopicsChips.propTypes = {
  selectedTopic: PropTypes.object,
  onSelectedTopicChanged: PropTypes.func.isRequired,
}


export default FeaturedTopicsChips;