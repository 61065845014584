import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ScrollableXContainer from '../common/ScrollableXContainer';
import CourseCard from './CourseCard';
import {
  pageContentPaddingX,
  pageContentPaddingY, scrollableXContainerPosition,
  sectionDescriptionFont,
  sectionHeaderMb,
  sectionNameFont,
  sectionNameMb,
} from '../../style/constants/homepage';
import {CURR_SITE, SITE_STANFORD} from '../../constants';
import CourseCardDataWrapper from '../common/data-wrappers/CourseCardDataWrapper';
import {graphql, useLazyLoadQuery} from 'react-relay';


export const courseSectionDescription = `
Explore Courses for a more focused learning experience.
`

const site = CURR_SITE.name;
const backgroundImage = site === SITE_STANFORD
  ? ''
  : 'url(/static/ns/img/background_homepage_courses.svg)';

const CoursesQuery = graphql`
  query CoursesQuery {
    versionedCourses {
      id
      uid
      ...CourseCardDataWrapperFragment_courseVersion,
    }
  }
`;

const Courses = (props) => {

  const {versionedCourses} = useLazyLoadQuery(
    CoursesQuery,
    {},
    {
      fetchPolicy: 'network-only',
    },
  );

  const renderCourseCard = (
    {
      versionedCourseData,
      progressValue,
      onNameClick,
      onButtonClick,
    }
  ) => {

    return (
      <CourseCard
        key={versionedCourseData.uid}
        versionedCourseData={versionedCourseData}
        progressValue={progressValue}
        onNameClick={onNameClick}
        onButtonClick={onButtonClick}
      />
    )
  }

  const coursesElement = (
    versionedCourses
      .map(versionedCourse => (
        <CourseCardDataWrapper
          key={versionedCourse.uid}
          versionedCourse={versionedCourse}
          renderChildren={renderCourseCard}
        />
      ))
  );

  return (
    <Box sx={{
      backgroundColor: 'background.main',
      backgroundImage: backgroundImage,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
      position: 'relative',
      px: {...pageContentPaddingX},
      py: {...pageContentPaddingY}
    }}>
      <Typography sx={{
        mb: {...sectionNameMb},
        fontSize: {...sectionNameFont},
      }}>
        Courses
      </Typography>

      <Typography sx={{
        mb: {...sectionHeaderMb},
        fontSize: {...sectionDescriptionFont},
      }}>
        {courseSectionDescription}
      </Typography>

      <ScrollableXContainer
        containerSx={{
          pb: {xs: '20px'},
        }}
        navigationSx={{
          bottom: {xs: '10px', lg: '30px'},
          ...scrollableXContainerPosition,
        }}
        elements={coursesElement}
      />
    </Box>
  );
};

export default Courses;