import { deepmerge } from '@mui/utils';
import {createTheme} from '@mui/material/styles';
import {theme as baseTheme} from './main';
import {CURR_SITE, SITE_NS, SITE_STANFORD} from '../constants';

const site = CURR_SITE.name;

const palette = {
  primary: {
    [SITE_NS]: {
      main: '#F0532A',
      dark: '#dc4c25',
    },
    [SITE_STANFORD]: {
      main: '#B13050',
      dark: '#7b2138',
    }
  },

  accent: {
    [SITE_NS]: {
      main: '#7F9891',
    },
    [SITE_STANFORD]: {
      main: '#4C6F8D',
    }
  },

  accentDark: {
    [SITE_NS]: {
      main: '#0A4A63',
    },
    [SITE_STANFORD]: {
      main: '#4C6F8D',
    }
  },

  background: {
    [SITE_NS]: {
      light: '#ffffff',
      accent: '#F5F5F5',
      dark: '#3C434E',
      footer: '#40474F',
    },
    [SITE_STANFORD]: {
      light: '#ffffff',
      accent: '#F5F8F9',
      dark: '#000000',
      footer: '#F5F8F9',
    }
  },

  text: {
    [SITE_NS]: {
      menuItem: '#40474F',
      menuItemSelected: '#F0532A',
      menuItemBackground: '#F5F5F5',
      footerItem: '#FFFFFF',
      copyright: '#FFFFFF',
    },
    [SITE_STANFORD]: {
      menuItem: '#B13050',
      menuItemSelected: '#B13050',
      menuItemBackground: '#2196F314',
      footerItem: '#B13050',
      copyright: '#000000',
    }
  },
  search: {
    [SITE_NS]: {
      tabBackground: 'rgba(156,186,197,0.1)',
      tabInactive: 'rgba(10, 74, 99, 0.3)',
      tabActive: '#0A4A63',
    },
    [SITE_STANFORD]: {
      tabBackground: 'rgba(156,186,197,0.1)',
      tabInactive: 'rgba(76, 111, 141, 0.3)',
      tabActive: '#4C6F8D',
    }
  }
}

export const theme = createTheme(
  deepmerge(
    baseTheme,
    {
      palette: {
        primary: {...palette.primary[site]},
        accent: {...palette.accent[site]},
        accentDark: {...palette.accentDark[site]},
        background: {...palette.background[site]},
        text: {...palette.text[site]},
        search: {...palette.search[site]}
      },
    }
  )
);