import React from 'react';
import { graphql, useMutation } from 'react-relay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {DisplayMessageContext} from '../../DisplayMessageProvider';
import {useTags} from '../hooks/useTags';


const DeleteTagsMutation = graphql`
  mutation Tags_DeleteTagsMutation(
    $tagsToDelete: [String!]!,
  ) {
    deleteTags(
        tagsToDelete: $tagsToDelete,
    ) {
        id
        text
    }
  }
`;

const Tags = (props) => {
  const {tags: initialTags, deleteTags} = useTags();
  const [tags, setTags] = React.useState(initialTags ?? [])
  const [commitMutation, isMutationInProgress] = useMutation(DeleteTagsMutation);
  const [deletedTags, setDeletedTags] = React.useState([]);
  const [isDirty, setIsDirty] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const displayMessage = React.useContext(DisplayMessageContext);

  const onSave = () => {
    if (deletedTags.length < 1) return;
    console.log('In Tags::onSave. vars=', JSON.stringify(deletedTags));
    const variables = {tagsToDelete: deletedTags.map((dt) => dt.text)};
    commitMutation({
      variables,
      onCompleted: (data) => {
        console.log('In Tags::onSave::onCompleted. data=', JSON.stringify(data));
        setIsDirty(false);
        setDeletedTags([]);
        setSearchText('');
        const updatedTags = data.deleteTags;
        console.log("Setting tags to: ", JSON.stringify(updatedTags))
        setTags(updatedTags);
        displayMessage({info: 'Tags have been updated.'});
      },
      onError: (err) => {
        console.error("Error performing mutation deleteTags:", err);
        displayMessage({error: 'Error updating Tags. Please try again later.'});
      },
      updater: (store, updaterData) => {
        console.log('In Tags::onSave::updater. updaterData=', updaterData);
        deleteTags(store, deletedTags);
      }
    })
  }

  const onReset = () => {
    setDeletedTags([]);
    setSearchText('');
    setIsDirty(false);
  }

  const onDeleteTag = (tag) => {
    setIsDirty(true);
    setDeletedTags((prevDelTags) => [...prevDelTags, tag]);
  }

  const onSearchTextChanged = React.useCallback(
    (evt) => {
      const val = evt.target.value;
      setSearchText(val);
    },
    []
  )

  return (
    <Stack direction='column' spacing={2} sx={{mt: '5px'}}>
      <Box>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size='small'
          placeholder='Search topic'
          value={searchText}
          onChange={onSearchTextChanged}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'block',
        }}
      >
        {tags
        .filter(
          (tag) => deletedTags.find((delTag) => delTag.text === tag.text) === undefined
        )
        .filter(
          (tag) => {
            if (!searchText) return true;
            const st = searchText.toLowerCase();
            return tag.text.toLowerCase().includes(st);
          }
        )
        .sort((t1, t2) => t1.text.toLowerCase().localeCompare(t2.text.toLowerCase()))
        .map((tag) => (
          <Chip
            key={tag.text}
            label={tag.text}
            onDelete={(evt) => onDeleteTag(tag)}
            sx={{
              mb: 2,
              mr: 2,
            }}
          />
        ))}
      </Box>
        <Stack
          direction='row'
          gap={1}
          alignSelf='flex-end'
        >
          {isDirty &&
            <Button
              aria-label='Reset'
              variant='outlined'
              onClick={onReset}
            >
              Reset
            </Button>
          }
          <Button
            aria-label='Save'
            variant='contained'
            disabled={!isDirty || isMutationInProgress}
            onClick={onSave}
          >
            Save
          </Button>
        </Stack>
    </Stack>
  )
}

export default Tags;
