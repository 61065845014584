import React from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


const CONFIRMATION_TEXT = 'DELETE';

const DeleteCourseMutation = graphql`
  mutation DeleteCourseDialogMutation(
    $id: GlobalID!,
  ) {
      deleteCourse(
        data: {
          id: $id
        }
      ) {
          ... on Course {
            id
            uid
          }
        }
  }
`;

export default function DeleteCourseDialog(props) {
  const {
    course,
    isOpen,
    displayMessage,
    onClose
  } = props;

  const [error, setError] = React.useState(null);
  const [commitMutation, isMutationInFlight] = useMutation(DeleteCourseMutation);
  const [confirmText, setConfirmText] = React.useState('');

  const onCloseDeleteDialog = (confirmDelete) => {
    if (confirmDelete === true) {
      if (confirmText !== CONFIRMATION_TEXT) {
        setError('Please enter the confirmation text above');
        return;
      }
      const variables = {id: course.id};
      commitMutation({
        variables,
        onCompleted: (data) => {
          displayMessage({info: `Course "${course.name}" has been deleted`});
          onClose(true);
        },
        onError: (err) => {
          console.error("Error performing mutation deleteCourse:", err);
          setError('Error deleting course. Please try again later.');
        },
        updater: (store, data) => {
          console.log('DeleteCourse::CommitMutation::updater: data=', data);
          const coursesConnection = ConnectionHandler.getConnection(
            store.getRoot(),
            'CoursesTable_courses',
            {filters: {accessPlans: {site: {current: true}}}}
          );
          ConnectionHandler.deleteNode(coursesConnection, course.id);
          console.log('DeleteCourse::CommitMutation::updater: deleted node:', course.id);
        },
      })
    }
    else {
      onClose();
    }
  }
  return (
    <>
    {isOpen &&
      <Dialog
        open={isOpen}
        onClose={onCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this course?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Removing a course will also remove all related CMI5 content and may impact the progress of students
            who are taking this course.
          </DialogContentText>
          <DialogContentText id="alert-delete-dialog-instruction">
            Please enter <b>{CONFIRMATION_TEXT}</b> in the textbox to confirm.
          </DialogContentText>
          <TextField
            margin='dense'
            id='confirm_delete'
            size='small'
            type='text'
            value={confirmText}
            variant='outlined'
            onChange={(evt) => {setConfirmText(evt.target.value)}}
          />
          {error && (
            <Typography color='error' variant='body2'>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Delete'
            color='error'
            disabled={isMutationInFlight}
            variant='contained'
            onClick={() => onCloseDeleteDialog(true)}
          >
            Delete
          </Button>
          <Button
            aria-label='Cancel'
            onClick={onCloseDeleteDialog}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    }
    </>
  )
}

DeleteCourseDialog.propTypes = {
  course: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  displayMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

DeleteCourseDialog.defaultProps = {
  isOpen: false,
}
