export const GROUP_LEARNERS = 'learners';
export const GROUP_INSTRUCTORS = 'instructors';
export const GROUP_ADMINS = 'admins';
export const GROUP_PENDING_USERS = 'pending_users';

export const DURATION_UNITS = [
  {name: 'Days', value: 'D', days: 1, max: 90},
  {name: 'Months', value: 'M', days: 30, max: 18},
  {name: 'Years', value: 'Y', days: 365, max: 3},
];

export const MAX_CSV_SIZE = 1024*1024;

export const SUBSCRIPTION_TYPES = [
  {name: 'Subsidized', value: 'subsidized'},
  {name: 'Free Access', value: 'free'},
  {name: 'Paid Access', value: 'paid'},
]

export const STUDY_UNIT_MODULE = 'module';
export const STUDY_UNIT_COURSE = 'course';
