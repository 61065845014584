import React from 'react';
import {graphql, useFragment} from 'react-relay';
import {useNavigate} from 'react-router-dom';
import {getCourseDetailsUrl} from '../../course-details/utils';
import PropTypes from "prop-types";


const CourseCardDataWrapperFragment = graphql`
  fragment CourseCardDataWrapperFragment_courseVersion on CourseVersion {
    uid
    version
    course {
      id
      uid
      name
      synopsis
      coverImg {
        url
      }
      coverImgDesc
    }
    progress {
      progressPercent
    }
  }
`;

const CourseCardDataWrapper = (props) => {
  const {
    versionedCourse,
    renderChildren,
  } = props;

  const versionedCourseData = useFragment(
    CourseCardDataWrapperFragment,
    versionedCourse,
  );

  const navigate = useNavigate();

  const {course} = versionedCourseData;

  const progressValue = parseInt(versionedCourseData.progress.progressPercent) || 0;

  const navigateToCourse = React.useCallback((courseUid, action) => {
     const url = getCourseDetailsUrl(courseUid, action);
     navigate(url);
  }, [navigate]);

  const onNameClick = () => {
    navigateToCourse(course.uid, null)
  }

  const onButtonClick = () => {
    navigateToCourse(course.uid)
  }

  const renderChildrenProps = {
    versionedCourseData,
    progressValue,
    onNameClick,
    onButtonClick,
  }

  return renderChildren(renderChildrenProps);
};

CourseCardDataWrapper.propTypes = {
  versionedCourse: PropTypes.object.isRequired,
  renderChildren: PropTypes.func.isRequired,
}

export default CourseCardDataWrapper;