import React, {lazy, useEffect} from "react";
import {graphql, useLazyLoadQuery} from "react-relay";
import {Route, Routes, useLocation} from "react-router-dom";
import PrivateRoutes from "./components/common/PrivateRoutes";
import ProfileQuestionsWrapper from "./ProfileQuestionsWrapper";
import SelfGuidedModulesPage from "./pages/SelfGuidedModulesPage";
import DashboardPage from "./pages/DashboardPage";
import {
  ConfirmInvitationPage,
  LoginPage,
  RequestPasswordResetPage,
  ResetPasswordPage,
} from "./pages/AuthPage";
import Homepage from "./pages/Homepage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import Videos from "./pages/Videos";
import DisplayMessageProvider from "./DisplayMessageProvider";
import {getPageTitle} from "./utils/titles";
import SearchPage from "./pages/SearchPage";
import Page404 from "./pages/Page404";
import Cmi5ViewerExit from "./components/cmi5-viewer/Cmi5ViewerExit";
import LoadingSpinner from "./LoadingSpinner";

const CurrentUserQuery = graphql`
  query AppRoutesQuery {
    me {
      id
      ...UsersTable_user @relay(mask: false)
    }
  }
`;

const About = lazy(() => import ("./pages/About"));
const Contacts = lazy(() => import ("./pages/Contacts"));
const FAQ = lazy(() => import ("./pages/FAQ"));
const PrivacyPolicy = lazy(() => import ("./pages/PrivacyPolicy"));
const Terms = lazy(() => import ("./pages/Terms"));

export const UserContext = React.createContext(null);

const AppRoutes = (props) => {
  const { pathname } = useLocation();

  const currUserData = useLazyLoadQuery(
    CurrentUserQuery,
    {},
    {
      fetchKey: pathname,
      fetchPolicy: 'network-only'
    },
  );

  useEffect(() => {
    document.title = getPageTitle(pathname);
  }, [pathname]);


  const withProfileQuestionsCheck = (component) => (
    <ProfileQuestionsWrapper user={currUserData.me}>
      {component}
    </ProfileQuestionsWrapper>
  )
  return (
    <UserContext.Provider value={currUserData.me}>
      <DisplayMessageProvider>
        <Routes>
          <Route element={<PrivateRoutes user={currUserData.me} />}>
            <Route exact path='/self-guided-learning/' element={
              withProfileQuestionsCheck(
                <SelfGuidedModulesPage/>
              )
            }/>
            <Route exact path='/directed-learning/' element={
              withProfileQuestionsCheck(
                <Homepage/>
              )
            }/>
            <Route exact path='/video-library/' element={
              withProfileQuestionsCheck(
                <Videos/>
              )
            }/>
            <Route exact path='/course/:courseUid' element={
              withProfileQuestionsCheck(
                <CourseDetailsPage/>
              )
            }/>
            <Route exact path='/dashboard/:page?/' element={
              withProfileQuestionsCheck(
                <DashboardPage user={currUserData.me}/>
              )
            }/>
            <Route exact path='/search/' element={
              withProfileQuestionsCheck(
                <SearchPage/>
              )
            }/>
            <Route exact path='/' element={
              withProfileQuestionsCheck(
                <Homepage/>
              )
            }/>
            <Route exact path='/module-exit/:moduleVersionUid' element={
              withProfileQuestionsCheck(
                <React.Suspense fallback={<LoadingSpinner/>}>
                  <Cmi5ViewerExit/>
                </React.Suspense>
              )
            }/>
            <Route exact path='/module-link/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <SelfGuidedModulesPage/>
              </React.Suspense>
            }/>
            <Route exact path='/privacy-policy/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <PrivacyPolicy/>
              </React.Suspense>
            }/>
            <Route exact path='/terms/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <Terms/>
              </React.Suspense>
            }/>
            <Route exact path='/faqs/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <FAQ/>
              </React.Suspense>
            }/>
            <Route exact path='/about-us/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <About/>
              </React.Suspense>
            }/>
            <Route exact path='/contact-us/' element={
              <React.Suspense fallback={<LoadingSpinner/>}>
                <Contacts/>
              </React.Suspense>
            }/>
          </Route>
          <Route exact path='/login/' element={
              <LoginPage/>
          }/>
          <Route exact path='/request-password-reset/' element={
              <RequestPasswordResetPage/>
          }/>
          <Route exact path='/reset-password/:uid/:token/' element={
              <ResetPasswordPage/>
          }/>
          <Route exact path='/confirm-invitation/:uid/:token/' element={
              <ConfirmInvitationPage/>
          }/>
          <Route path='*' element={<Page404/>} />
        </Routes>
      </DisplayMessageProvider>
    </UserContext.Provider>
  )
}

export default AppRoutes;
