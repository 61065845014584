import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Cmi5Viewer from './Cmi5Viewer';


export default function Cmi5ViewerDialog(props) {
  const {
    isOpen,
    moduleVersion,
    preview,
    getNextModule,
    onClose,
    handleSelectedVersionedModuleChanged,
  } = props;

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={onClose}
      sx={{width: '100%'}}
      PaperProps={{
        sx: {
          height: '100%',
          width: '100%',
          mx: 0,
        }
      }}
    >
      <Cmi5Viewer
        preview={preview}
        moduleVersion={moduleVersion}
        getNextModule={getNextModule}
        onClose={onClose}
        handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
      />
    </Dialog>

  )
}

Cmi5ViewerDialog.propTypes = {
  isOpen: PropTypes.bool,
  moduleVersion: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    version: PropTypes.number,
    module: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  preview: PropTypes.bool,
  getNextModule: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  handleSelectedVersionedModuleChanged: PropTypes.func,
}

Cmi5ViewerDialog.defaultProps = {
  isOpen: false,
  preview: false,
  getNextModule: null,
}
