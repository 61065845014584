import {pick} from 'lodash';
import React, {useState} from 'react';
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import {Modal} from '@mui/material';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import {UserAnswersFragment} from '../user-profile/UserProfile';
import {QuestionnairePanel} from '../questionnaire/QuestionnairePanel';
import {userQuestionnaireSchema} from '../user-profile/validation';

const QuestionnaireQuery = graphql`
  query QuestionnaireQuery{
    profileQuestions {
        uid
        questionText
        questionType
        options {
            uid
            optionText
            isDefault
            allowCustomAnswer
        }
    }
  }
`;

const QuestionnaireMutation = graphql`
  mutation QuestionnaireMutation(
    $data: [UserAnswerInput!]!,
  ) {
    saveUserAnswers(data: $data){
      ... on SaveUserAnswersResult {
        allQuestionsAnswered
      }
    }
  }
`;

const questionnaireFormTheme = createTheme({
  palette: {
    primary: {
      main: '#197F89',
    },
  },
});

const style = {
  position: 'absolute',
  outline: 'none',
  borderRadius: '5px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: {
    sm: '80%',
    md: '50%',
  },
  minWidth: '300px',
  maxWidth: '80%',
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto',
};


const Questionnaire = (props) => {
  const {
    user
  } = props;
  const answers = useFragment(UserAnswersFragment, user.answers);

  const { profileQuestions } = useLazyLoadQuery(QuestionnaireQuery);
  const [commitMutation, isMutationInFlight] = useMutation(QuestionnaireMutation);
  const [error, setError] = useState('')
  const [successText, setSuccessText] = useState('')
  const navigate = useNavigate()
  const initialValues = {
    answers: profileQuestions.map((question) => answers.find(
      (answer) => answer.question.uid === question.uid
    )) ?? null,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: userQuestionnaireSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      formik.setTouched({});
    },
  });

  const onSave = (evt) => {
    setSuccessText('');
    setError('');
    formik.handleSubmit();
    formik.setSubmitting(false);
  }

  const handleSubmit = () => {
    const filteredData = formik.values.answers.filter(answer => {
      return answer?.chosenOption || (
        answer?.chosenOptions && answer?.chosenOptions.length !== 0
      )
    }).map((answer) => ({
      ...answer,
      chosenOption: pick(answer?.chosenOption, ['uid']),
    }));
    console.log('OnSubmit. answers:', JSON.stringify(filteredData))

    commitMutation({
      variables: {
        data: filteredData,
      },
      onCompleted: (data) => {
        const res = data.saveUserAnswers;
        if (res.allQuestionsAnswered) {
          setSuccessText('Your answers have been saved. Redirecting')
          navigate(0)
        }
        else {
          setSuccessText('Your answers have been saved. Complete all questions to continue.')
        }
      },
      onError: (error) => {
        const err = error.source.errors[0].message;
        setError(err);
      }
    });
  };

  const welcomingMessage = (
    !user?.firstName || user?.firstName === ''
      ? 'Welcome!'
      : `Welcome, ${user?.firstName}!`
  );

  return (
    <ThemeProvider theme={questionnaireFormTheme}>
      <Modal
        disableEnforceFocus
        fullWidth
        maxWidth={'md'}
        open={true}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>

          <Typography sx={{
            mb: 3
          }}>
            {welcomingMessage}
          </Typography>

          <Typography sx={{
            mb: 2,
          }}>
            {'Please answer the following to continue: '}
          </Typography>
          <QuestionnairePanel
            questions={profileQuestions}
            formik={formik}
          />
          <LoadingButton
            aria-label='Save'
            sx={{
              width: '100%',
              my: 2,
            }}
            variant='contained'
            loading={isMutationInFlight}
            onClick={onSave}
          >
            Save
          </LoadingButton>
          {
            successText !== '' && (
              <Typography color='success.main'>
                {successText}
              </Typography>
            )
          }
          {
            error !== '' && (
              <Typography color='error'>
                {error}
              </Typography>
            )
          }
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

Questionnaire.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    answers: PropTypes.object,
  })
}

export default Questionnaire;
