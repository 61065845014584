/**
 * @generated SignedSource<<1c46200308b74505614155b71e17007b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "progress"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoUid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "progress",
            "variableName": "progress"
          },
          {
            "kind": "Variable",
            "name": "videoUid",
            "variableName": "videoUid"
          }
        ],
        "kind": "ObjectValue",
        "name": "data"
      }
    ],
    "kind": "ScalarField",
    "name": "updateVideoProgress",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoModal_ProgressUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VideoModal_ProgressUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d9057b20cdb28a8d399f79fee2cd5dd2",
    "id": null,
    "metadata": {},
    "name": "VideoModal_ProgressUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation VideoModal_ProgressUpdateMutation(\n  $videoUid: Uid!\n  $progress: Int!\n) {\n  updateVideoProgress(data: {videoUid: $videoUid, progress: $progress})\n}\n"
  }
};
})();

node.hash = "f9c69f343a5fd9fa7a57f3d53bbd7088";

export default node;
