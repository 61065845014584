/**
 * @generated SignedSource<<63e3c68b9905f99a4bb72c4f1e7024f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseUids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "originalFileName",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "items",
        "variableName": "courseUids"
      }
    ],
    "concreteType": "CourseCatalogItem",
    "kind": "LinkedField",
    "name": "setCourseCatalogItems",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "synopsis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearningObjective",
            "kind": "LinkedField",
            "name": "learningObjectives",
            "plural": true,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "bannerImg",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "coverImg",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImgDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedTs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseChapter",
                    "kind": "LinkedField",
                    "name": "chapters",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModuleVersion",
                        "kind": "LinkedField",
                        "name": "modules",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Module",
                            "kind": "LinkedField",
                            "name": "module",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessPlan",
            "kind": "LinkedField",
            "name": "accessPlans",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addToHomepage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseList_SetCourseCatalogItemsMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseList_SetCourseCatalogItemsMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "ea8e82635a10dfd875c28df75b3c097d",
    "id": null,
    "metadata": {},
    "name": "CourseList_SetCourseCatalogItemsMutation",
    "operationKind": "mutation",
    "text": "mutation CourseList_SetCourseCatalogItemsMutation(\n  $courseUids: [Uid!]!\n) {\n  setCourseCatalogItems(items: $courseUids) {\n    id\n    rank\n    course {\n      uid\n      name\n      description\n      synopsis\n      learningObjectives {\n        text\n      }\n      bannerImg {\n        originalFileName\n        name\n        url\n        size\n      }\n      coverImg {\n        originalFileName\n        name\n        url\n        size\n      }\n      coverImgDesc\n      lastUpdatedTs\n      versions {\n        uid\n        version\n        status\n        author {\n          uid\n          firstName\n          lastName\n          email\n        }\n        sections {\n          uid\n          name\n          description\n          rank\n          chapters {\n            uid\n            name\n            description\n            modules {\n              uid\n              version\n              status\n              module {\n                name\n              }\n            }\n          }\n        }\n      }\n      accessPlans {\n        uid\n        name\n      }\n      tags {\n        id\n        text\n      }\n      addToHomepage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "6d15f3394e4e6d95f85f0327d8d2b7da";

export default node;
