import {createTheme} from '@mui/material/styles';
import {CURR_SITE, SITE_STANFORD} from '../constants';

const site = CURR_SITE.name;

const linkColor = site === SITE_STANFORD ? '#0066CC' : '#F0532A';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      // a large mobile
      lm: 450,
      sm: 600,
      // a large tablet
      lt: 750,
      md: 900,
      lg: 1200,
      xlg: 1400,
      xl: 1536,
      xxl: 2000,
      giant: 2400,
    },
  },
  palette: {
    link: {
      main: linkColor,
    },
    sectionFirstOption: {
      main: 'rgba(49, 123, 43, 0.05)',
      header: 'rgba(49, 123, 43, 0.1)',
      tabInactive: 'rgba(49, 123, 43, 0.2)',
      dark: 'rgb(19,73,15)',
      contrastText: '#317B2B',
    },
    sectionSecondOption: {
      main: 'rgba(134, 77, 160, 0.05)',
      header: 'rgba(134, 77, 160, 0.1)',
      tabInactive: 'rgba(134, 77, 160, 0.2)',
      dark: 'rgb(75,42,89)',
      contrastText: '#864DA0',
    },
    sectionThirdOption: {
      main: 'rgba(1, 113, 187, 0.05)',
      header: 'rgba(1, 113, 187, 0.1)',
      tabInactive: 'rgba(1, 113, 187, 0.2)',
      dark: 'rgb(4,62,101)',
      contrastText: '#0171BB',
    },
  },
  typography: {
     fontFamily: `"Open Sans", sans-serif`,
  },
});
