import {capitalize, findIndex, isEmpty, isNumber, reduce} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {theme} from '@/themes/userUi';
import NavBar from '@/components/common/navbar/NavBar';
import Footer from '../components/common/Footer';
import {ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {pageContentPadding} from '@/style/constants/self-guided';
import {contentPt} from '@/style/constants/main';
import {ErrorBoundary} from '@/ErrorBoundary';
import ProjectTabs from '../components/ui/ProjectTabs';
import ProjectTab from '../components/ui/ProjectTab';
import {
  HOMEPAGE_TITLE,
  HOMEPAGE_URL,
  MODULE_SEARCH_TYPE,
  COURSE_SEARCH_TYPE,
  VIDEO_SEARCH_TYPE,
} from '@/constants';
import SearchTextField from '../components/common/search/SearchTextField';
import SearchModulesList from '../components/search-page/SearchModulesList';
import SearchCoursesList from '../components/search-page/SearchCoursesList';
import SearchVideosList from '../components/search-page/SearchVideosList';
import SearchQueryRunner from '../components/search-page/SearchQueryRunner';
import SearchResultSkeletonsList
  from '../components/skeletons/search/SearchResultSkeletonsList';

const CONTENT_TYPES = [MODULE_SEARCH_TYPE, COURSE_SEARCH_TYPE, VIDEO_SEARCH_TYPE];
const RESULTS_STARTING_TAB= {
  [MODULE_SEARCH_TYPE]: MODULE_SEARCH_TYPE,
  [COURSE_SEARCH_TYPE]: MODULE_SEARCH_TYPE,
  [VIDEO_SEARCH_TYPE]: VIDEO_SEARCH_TYPE
}

const urls = {
  [MODULE_SEARCH_TYPE]: {
    url: '/self-guided-learning/',
    name: 'Return to Self-Guided Learning',
  },
  [COURSE_SEARCH_TYPE]: {
    url: HOMEPAGE_URL,
    name: `Return to ${HOMEPAGE_TITLE}`,
  },
  [VIDEO_SEARCH_TYPE]: {
    url: '/video-library/',
    name: 'Return to Videos',
  },
}

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = searchParams.get('query');
  const contentTypeParam = searchParams.get('type') ?? CONTENT_TYPES[0];
  const [searchText, setSearchText] = useState(query ?? '');
  const [searchResults, setSearchResults] = useState({});

  const [
    selectedContentType, setSelectedContentType,
  ] = useState(contentTypeParam);

  const [numResultsByType, setNumResultsByType] = useState({});
  const [totalResults, setTotalResults] = useState(0);

  const handleSearchResultsUpdated = useCallback(
    (newSearchResults) => {
      setSearchResults(newSearchResults);
      const numResults = reduce(
        newSearchResults,
        (acc, value, key) => {
          acc[key] = (value?.count ?? 0);
          return acc;
        },
        {}
      );
      const totalResults = reduce(numResults, (total, value) => total + value, 0);
      setNumResultsByType(numResults);
      setTotalResults(totalResults);
      const startingContentType = RESULTS_STARTING_TAB[selectedContentType];
      let idx = findIndex(CONTENT_TYPES, (ct) => ct === startingContentType);
      for (let i=0; i<CONTENT_TYPES.length; ++i) {
        const ct = CONTENT_TYPES[idx];
        if (numResults[ct] > 0) break;
        idx = (idx + 1) % CONTENT_TYPES.length;
      }
      if (CONTENT_TYPES[idx] !== selectedContentType) {
        setSelectedContentType(CONTENT_TYPES[idx]);
      }
    },
    [selectedContentType]
  );

  const changeTypeValue = (value) => {
    setSelectedContentType(value);
  };

  const changeFilterText = (value) => {
    if (value.toLowerCase() === searchText.toLowerCase()) return;
    setNumResultsByType({});
    setTotalResults(0);
    setSearchResults({});
    setSearchText(value);
  };

  const handleGoBack = () => {
    navigate(urls[contentTypeParam].url);
  };

  useEffect(() => {
    setSearchParams({
      query: searchText,
      type: contentTypeParam,
    });
  }, [searchText, contentTypeParam, setSearchParams]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar/>
        <ErrorBoundary>
          {
            !!urls[contentTypeParam] && (
              <Box
                sx={{
                  backgroundColor: 'search.tabBackground',
                  width: '100%',
                  pt: 2,
                  pb: 2,
                }}
              >
                <Typography
                  variant='body2'
                  onClick={handleGoBack}
                  sx={{
                    ml: 4,
                    cursor: 'pointer',
                  }}
                >
                  {'<'} {urls[contentTypeParam]?.name}
                </Typography>
              </Box>
            )
          }

          <Box sx={{
            px: {...pageContentPadding},
            pt: {...contentPt},
            minHeight: {xs: '100vh'}
          }}>
            <Box
              sx={{
                mt: {xs: '20px'},
                mb: {xs: '20px'},
              }}
            >
              <SearchTextField
                filterText={searchText}
                updateFilterText={changeFilterText}
                initiallyFocused={false}
              />
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              mb: '40px'
            }}>
              {
                !!searchText
                  ?
                    <Typography sx={{
                      fontSize: {
                        xs: '16px',
                      },
                    }}>
                      {isEmpty(searchResults)
                        ?  <CircularProgress aria-label="Loading search results" size='1rem'/>
                        : `${totalResults} search results for "${searchText}"`
                      }
                    </Typography>
                  :
                    <Typography sx={{
                      fontSize: {
                        xs: '16px',
                      },
                    }}>
                      {'Type a query and search'}
                    </Typography>
              }
            </Box>

            <Box
              sx={{
                mb: {xs: '30px'},
              }}
            >
              <ProjectTabs
                tabValue={selectedContentType}
                changeTabValue={changeTypeValue}
              >
                {
                  CONTENT_TYPES.map((typeValue) => {
                    const tabName = capitalize(typeValue) + (
                      isNumber(numResultsByType?.[typeValue]) ? ` (${numResultsByType?.[typeValue] ?? 0})` : ''
                    );
                    return (
                      <ProjectTab
                        key={typeValue}
                        value={typeValue}
                        isSelected={typeValue === selectedContentType}
                        tabName={tabName}
                        indicatorColor={'search.tabInactive'}
                        selectedIndicatorColor={'search.tabActive'}
                        tabBgColor={'search.tabBackground'}
                        tabColor={'search.tabActive'}
                      />
                    )
                  })
                }
              </ProjectTabs>
            </Box>

            {/* The content */}
            <Box>
              {
                !!searchText && (
                  <React.Suspense fallback={<SearchResultSkeletonsList/>}>
                    <SearchQueryRunner
                      searchText={searchText}
                      updateSearchResults={handleSearchResultsUpdated}
                    />
                  </React.Suspense>
                )
              }
              {
                !!searchText && selectedContentType === MODULE_SEARCH_TYPE && searchResults[MODULE_SEARCH_TYPE]?.results &&
                (
                  <SearchModulesList
                    searchResults={searchResults[MODULE_SEARCH_TYPE].results}
                  />
                )
              }

              {
                !!searchText && selectedContentType === COURSE_SEARCH_TYPE && searchResults[COURSE_SEARCH_TYPE]?.results &&
                (
                  <SearchCoursesList
                    searchResults={searchResults[COURSE_SEARCH_TYPE].results}
                  />
                )
              }

              {
                !!searchText && selectedContentType === VIDEO_SEARCH_TYPE && searchResults[VIDEO_SEARCH_TYPE]?.results &&
                (
                  <SearchVideosList
                    searchResults={searchResults[VIDEO_SEARCH_TYPE].results}
                  />
                )
              }
            </Box>
          </Box>
        </ErrorBoundary>
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default SearchPage;