import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {theme} from '@/themes/userUi';
import {ThemeProvider} from '@mui/material/styles';
import NavBar from '@/components/common/navbar/NavBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SectionsTabs from '../components/content/SectionsTabs';
import {navBarHeight} from '@/style/constants/main';
import VideoModal from '../components/videos/VideoModal';
import Footer from '../components/common/Footer';
import {contentPb, contentPt} from '@/style/constants/main';
import {pageContentPadding} from '@/style/constants/self-guided';
import {ErrorBoundary} from '@/ErrorBoundary';
import {useTabNavigation} from '@/components/hooks/useTabNavigation';
import SectionsList from '../components/content/SectionsList';
import {
  SEARCH_PLACEHOLDER,
  VIDEO_CHAPTER_TYPE,
  VIDEO_SEARCH_TYPE,
} from '@/constants';
import FocusPageSearchField from '../components/common/search/FocusPageSearchField';
import ModalSearch from '../components/common/search/ModalSearch';
import {useModalSearch} from '@/components/hooks/useModalSearch';
import {staticPageMbHeader} from '@/style/constants/static-pages';
import {useVideos} from '@/components/hooks/useVideos';
import ModulesSectionsListSkeleton
  from '../components/skeletons/content/ModulesSectionsListSkeleton';
import VideosSectionsList from '../components/videos/VideosSectionsList';

const Videos = () => {

  const [
    stickyPartRef,
    headerPartRef,
    sectionsRefMap,
    sectionValue,
    handleSectionChanged,
    handleSectionTabChanged,
  ] = useTabNavigation();

  const [filterText, setFilterText] = useState('')

  const changeFilterText = (value) => {
    setFilterText(value);
  };

  const navigate = useNavigate();

  const [
    isModalSearchOpen,
    onModalSearchClose,
    onSearchFocused,
  ] = useModalSearch();

  useEffect(() => {
    if (isModalSearchOpen && !!filterText) {
      onModalSearchClose();
      navigate(`/search?query=${filterText}&type=${VIDEO_SEARCH_TYPE}`);
    }
  }, [filterText, isModalSearchOpen, onModalSearchClose, navigate]);

  const [
    isViewDialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    selectedVideo,
    handleSelectVideoChanged,
    nextVideo,
    handleNextVideoChanged,
    previousVideo,
    handlePreviousVideoChanged,
  ] = useVideos();

  const childrenProps = {
    selectedVideo,
    handleSelectVideoChanged,
    handleNextVideoChanged,
    handlePreviousVideoChanged,
    handleOpenDialog,
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dataLoadedCb = () => {
    setIsDataLoaded(true)
  }

  const renderSectionsList = (sections) => {
    return sections && sections.length > 0 && (
      <SectionsList
        key={'videos'}
        sections={sections}
        handleSectionChanged={handleSectionChanged}
        stickyPartRef={stickyPartRef}
        sectionsRefMap={sectionsRefMap}
        entityType={VIDEO_CHAPTER_TYPE}
        childrenProps={childrenProps}
      />
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar/>
        <ErrorBoundary>
          <ModalSearch
            open={isModalSearchOpen}
            onClose={onModalSearchClose}
            filterText={filterText}
            changeFilterText={changeFilterText}
            placeholder={SEARCH_PLACEHOLDER}
          />

          <Box
            className="page-header"
            ref={headerPartRef}
            sx={{
              px: {...pageContentPadding},
              pt: {...contentPt},
            }}>
            <Typography sx={{
              fontSize: {xs: '24px', md: '32px'},
              fontWeight: 'bold',
            }}>
              Video Library
            </Typography>

            <Box sx={{
              backgroundColor: 'primary.main',
              width: '100%',
              height: '4px',
            }}/>
          </Box>

          <Box
            ref={stickyPartRef}
            sx={{
              position: 'sticky',
              top: navBarHeight,
              zIndex: 100,
              px: {...pageContentPadding},
              pb: {...contentPb},
              pt: {...staticPageMbHeader},
              backgroundColor: 'background.light',
            }}
          >
            {/* Search Section */}
            <FocusPageSearchField
              onSearchFocused={onSearchFocused}
              isVideo={true}
            />

            <SectionsTabs
              sectionValue={sectionValue}
              handleSectionTabChanged={handleSectionTabChanged}
              getSectionRank={(section) => section.frontendId}
              isDataLoaded={isDataLoaded}
            />
          </Box>

          <React.Suspense fallback={<ModulesSectionsListSkeleton isVideo={true}/>}>
            <VideosSectionsList
              dataLoadedCb={dataLoadedCb}
              renderSectionsList={renderSectionsList}
            />
          </React.Suspense>

          <VideoModal
            open={isViewDialogOpen}
            handleModalClose={handleCloseDialog}
            selectedVideo={selectedVideo}
            handleSelectVideoChanged={handleSelectVideoChanged}
            nextVideo={nextVideo}
            handleNextVideoChanged={handleNextVideoChanged}
            previousVideo={previousVideo}
            handlePreviousVideoChanged={handlePreviousVideoChanged}
          />
        </ErrorBoundary>
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default Videos;