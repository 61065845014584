import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ReportPeriodSelect from '@/components/reports/ReportPeriodSelect';

const REPORT_URL = '/api/reports/search_terms/';


const SearchTerms = (props) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);

  const handleDownloadClick = (evt) => {
    evt.target.href = `${REPORT_URL}?period=${selectedPeriod.value}`
  }

  return (
    <Stack direction='column' spacing={3}>
      <Typography variant='h6'>Select report period:</Typography>
      <Stack
        direction='column'
        spacing={3}
        sx={{
          width: {
            xs: '100%',
            md: '50%',
            lg: '30%',
          }
        }}
      >
        <ReportPeriodSelect
          onChange={(period) => setSelectedPeriod(period)}
        />
        <Button
          aria-label='Download CSV'
          href={REPORT_URL}
          disabled={selectedPeriod === null}
          variant='outlined'
          sx={{height: '100%'}}
          onClick={handleDownloadClick}
        >
          DOWNLOAD CSV
        </Button>
      </Stack>
    </Stack>
  );
}

export default SearchTerms;
