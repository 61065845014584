import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChapterAccordion from './ChapterAccordion';
import SectionsContainer from '../sections-navigation/SectionsContainer';
import {sectionsColorsByName} from '../sections-navigation/constants';


const CourseContents = (props) => {
  const {
    courseVersion,
    navigateToModule,
    onLaunchChapter,
    onLaunchModule,
  } = props;

  const chapterContRefs = React.useRef({});
  React.useEffect(() => {
    if (!navigateToModule) return;
    for (let sec of courseVersion.sections) {
      const chpt = sec.chapters.find((ch) => (
        ch.modules.find((mv) => mv.uid === navigateToModule.uid)
      ))
      if (chpt) {
        const el = chapterContRefs.current[chpt.uid];
        if (el) {
          const elTop = Math.round(el.getBoundingClientRect().top);
          window.scrollTo({top: elTop, behavior: 'smooth'})
          break;
        }
      }
    }
  }, [])
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <SectionsContainer
        sections={courseVersion.sections}
        stickyHeaderSx={{p: 1}}
        createSectionContentsComponent={(section) => {
          const sectionsColorsKey = section.name.toLowerCase();
          return (
            <Box
              sx={{
                mx: 1,
                mb: 4,
                px: {xs: '5px', md: '10px', lg: '20px'},
                py: {xs: '5px', md: '10px', lg: '20px'},
                backgroundColor: `${sectionsColorsByName[sectionsColorsKey]}.main`,
              }}
            >
              <Stack direction='column' spacing={3}>
                <Typography variant='h5'>Chapters in This Section</Typography>
                {section.chapters.map((chapter, i) => (
                  <Box
                    key={chapter.uid}
                    ref={(node) => { chapterContRefs.current[chapter.uid] = node }}
                    sx={{
                      p: 0,
                      backgroundColor: 'white',
                    }}
                  >
                    <ChapterAccordion
                      chapter={chapter}
                      modulesProgress={courseVersion.progress.modulesProgress}
                      onLaunchModule={onLaunchModule}
                      onLaunchChapter={onLaunchChapter}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
        )}}
      />
    </Box>
  )
}

CourseContents.propTypes = {
  courseVersion: PropTypes.shape({
    description: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    progress: PropTypes.shape({
      modulesProgress: PropTypes.arrayOf(PropTypes.object),
      progressPercent: PropTypes.number,
    })
  }).isRequired,
  navigateToModule: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }),
  onLaunchChapter: PropTypes.func.isRequired,
  onLaunchModule: PropTypes.func.isRequired,
}

export default CourseContents;
