export const staticPageContentPaddingX = {
  xs: '20px',
  sm: '40px',
};

export const staticPageContentPaddingY = {
  xs: '24px',
};


export const staticPageMbHeader = {
  xs: '28px',
}


export const staticPageBodyFont = {
  fontSize: {
    xs: '16px',
  }
}
