import React from 'react';
import {graphql, useMutation} from 'react-relay';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import Typography from '@mui/material/Typography';
import {useMediaQuery, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import {isAdmin} from '@/utils/auth';

const LogoutMutation = graphql`
  mutation AccountMenuLogoutMutation {
    logout
  }
`;

const AccountMenu = (props) => {
  const {
    user,
    anchorEl,
    pages,
    xsPages,
    displayMessage,
    handleMenuItemClick,
    handleClose,
  } = props;
  const [commitMutation, isMutationInFlight] = useMutation(LogoutMutation);
  const currentTheme = useTheme();
  const isMdBreakpointDown = useMediaQuery(currentTheme.breakpoints.down('md'));

  const handleProfileClick = (evt) => {
    handleMenuItemClick('profile');
  }
  const handleMyProgressClick = (evt) => {
    handleMenuItemClick('progress');
  }

  const handleDashboardClick = (evt) => {
    if ((location?.pathname ?? '').startsWith('/dashboard/')) {
      return;
    }
    handleMenuItemClick('dashboard', {target: '/dashboard/'});
  }

  const handleLogout = () => {
    commitMutation({
      variables: {},
      onCompleted: (data) => {
        handleMenuItemClick('logout', {target: '/login/', params: {replace: true}});
      },
      onError: (error) => {
        displayMessage({error: 'Error logging out.'});
      }
    })
  }

    const hamburgerMenuItems = isMdBreakpointDown === false
    ? []
    : pages.concat(xsPages).map(
      (page) => {
        const isActive = location?.pathname === page.link;
        return (
          <MenuItem
            key={page.label}
            onClick={
              (e) => {
                handleMenuItemClick(page.label, {target: page.link});
              }
            }
          >
            <ListItemIcon>
              {page.icon}
            </ListItemIcon>
            <Link
              underline='none'
              key={page.label}
              component='button'
              variant='body1'
              sx={{
                padding: '0px',
                mr: {xs: 0, lg: 4},
                color: 'primary.main',
                textTransform: 'none',
              }}
            >
              <Typography
                sx={{
                  color: isActive ? 'text.menuItemSelected' : 'black',
                }}
              >
                {page.label}
              </Typography>
            </Link>
          </MenuItem>
        )
      }
    );

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl !== null}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: {xs: 0, md: 1.5},
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: {xs: 7, md: 14},
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {
        isMdBreakpointDown && (
          [
            ...hamburgerMenuItems,
            <Divider key='menu-items-divider' />
          ]
        )
      }
      <MenuItem onClick={handleProfileClick}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small"/>
        </ListItemIcon>
        Profile
      </MenuItem>
      { isAdmin(user)
        ? (
          <MenuItem onClick={handleDashboardClick}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            Dashboard
          </MenuItem>
        )
        : (
          <MenuItem onClick={handleMyProgressClick}>
            <ListItemIcon>
              <TimelapseIcon fontSize="small" />
            </ListItemIcon>
            My Progress
          </MenuItem>
        )
      }
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )
}

AccountMenu.propTypes = {
  user: PropTypes.object.isRequired,
  anchorEl: PropTypes.node,
  pages: PropTypes.array,
  xsPages: PropTypes.array,
  displayMessage: PropTypes.func.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

AccountMenu.defaultProps = {
  pages: [],
  xsPages: [],
}

export default AccountMenu;
