import {isEmpty} from 'lodash';
import React, { useState } from "react";
import {graphql, useMutation} from "react-relay";
import {Link as RouterLink, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


const ResetPasswordMutation = graphql`
  mutation ResetPasswordMutation(
    $uid: String!,
    $token: String!,
    $password: String!,
    $password2: String!
  ) {
    resetPassword(
      userUid64b: $uid,
      token: $token, 
      password: $password,
      password2: $password2,
    ) {
         status
         msg
      }
  }
`;


const ResetPassword = () => {
  const { uid, token } = useParams();

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [resetResult, setResetResult] = useState(null);
  const [commitMutation, isMutationInFlight] = useMutation(ResetPasswordMutation);

  const setFormFieldError = (field, errMsg) => {
    setFieldErrors((fe) => ({
      ...fe,
        [field]: errMsg,
    }))
  }

  const checkFormField = (field, errMsg) => {
    let valid = true;
    const val = formData[field];
    if (isEmpty(val)) {
      setFormFieldError(field, errMsg);
      valid = false;
    }
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldErrors({});
    let valid = true;
    valid = checkFormField('password', 'Please enter a password') && valid;
    valid = checkFormField('password2', 'Please confirm the password') && valid;
    if (valid && formData.password !== formData.password2) {
      setFormFieldError('password2', 'Passwords do not match');
      valid = false;
    }
    if (!valid) {
      return;
    }

    commitMutation({
      variables: {
        uid: uid,
        token: token,
        password: formData.password,
        password2: formData.password2,
      },
      onCompleted: (data) => {
        console.log('In ResetPassword::onCompleted:', data);
        const res = data.resetPassword
        setResetResult(res);
      },
      onError: (error) => {
        const err = error.source.errors[0].message;
        setResetResult({msg: err, status: false});
      }
    })
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {resetResult?.status === false && (
          <Typography color='error' variant='body2' sx={{ mb: 2 }}>
            {resetResult?.msg || 'Password reset error. Please try again later.'}
          </Typography>
        )}
        {resetResult?.status === true && (
          <Typography variant='body1' style={{textAlign: 'center'}}>
            Your password has been reset successfully. Please&nbsp;
            <RouterLink
              to='/login/'
              style={{
                color: '#0066CC',
                textDecoration: 'none',
              }}
            >
              sign in
            </RouterLink>
            &nbsp;with your new password.
          </Typography>
        )}
        {resetResult?.status !== true && (
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography variant='body1' style={{textAlign: 'center'}}>
              Please enter a new password below.
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={formData.password}
              error={!isEmpty(fieldErrors?.password)}
              helperText={fieldErrors?.password ?? ' '}
              onChange={handleInputChange}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password2'
              label='Confirm Password'
              type='password'
              id='password2'
              value={formData.password2}
              error={!isEmpty(fieldErrors?.password2)}
              helperText={fieldErrors?.password2 ?? ' '}
              onChange={handleInputChange}
            />
            <Button
              aria-label='Submit'
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={isMutationInFlight}
            >
              {isMutationInFlight ? 'Loading...' : 'Submit'}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResetPassword;
