import React from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import {CURR_SITE, HOMEPAGE_URL, SITE_STANFORD} from '@/constants';

const site = CURR_SITE.name;
const SiteLogo = site === SITE_STANFORD ? "/static/stanford/img/logo.svg" : "/static/ns/img/logo.svg";

const Logo = (props) => {
    const navigate = useNavigate();

  return (
    <Box
      component='img'
      onClick={
        (e) => navigate(HOMEPAGE_URL)
      }
      sx={{
        height: 48,
        width: 108,
        maxHeight: {xs: 233, md: 167},
        maxWidth: {xs: 350, md: 250},
        mr: {xs: 2, md: 4, lr: 10},
        cursor: 'pointer',
      }}
      src={SiteLogo}
      alt='Logo of SPARK at Stanford'
    />
  )
}

export default Logo;
