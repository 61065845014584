/**
 * @generated SignedSource<<8f5ba26ad552b63d5dfb2d03c9478b2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "withModulesOnly",
        "value": true
      }
    ],
    "concreteType": "FeaturedTopic",
    "kind": "LinkedField",
    "name": "featuredTopics",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "synopsis",
        "storageKey": null
      }
    ],
    "storageKey": "featuredTopics(withModulesOnly:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedTopicsChipsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeaturedTopicsChipsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "edaa64252e96fc7604073f5c60bef13e",
    "id": null,
    "metadata": {},
    "name": "FeaturedTopicsChipsQuery",
    "operationKind": "query",
    "text": "query FeaturedTopicsChipsQuery {\n  featuredTopics(withModulesOnly: true) {\n    uid\n    id\n    name\n    synopsis\n  }\n}\n"
  }
};
})();

node.hash = "834213901b4c95ea79d192625f8353a9";

export default node;
