import {isEmpty} from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import {CardMedia, LinearProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {contentElementNameFont} from '@/style/constants/homepage';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';

const VideoCard = (props) => {
  const {
    videoData,
    progressValue,
    progressBarVariant,
    onClick,
  } = props;
  const imgUrl = isEmpty(videoData.thumbnailUrl) ? videoData.module.coverImg.url : videoData.thumbnailUrl;
  let buttonActionName = 'Watch Video';
  buttonActionName = progressValue === 100 ? 'Watch Again' : buttonActionName;
  buttonActionName = progressValue > 0 && progressValue < 100 ? 'Resume Video' : buttonActionName;

  return (
    <Box
      className="video-card"
      sx={{
        width: '276px',
        height: '100%',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
        zIndex: '100',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        mr: '32px',
      }}
    >
      <CardMedia
        image={imgUrl}
        component='img'
        sx={{
          mb: {xs: '16px'},
          width: '276px',
          height: '184px',
        }}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        px: '16px',
      }}>
        <Box>
          <Typography
            className="video-title"
            sx={{
              fontWeight: 'bold',
              fontSize: {...contentElementNameFont},
              mb: '8px',
              cursor: 'pointer',
            }}
          >
            {videoData.title}
          </Typography>
          {!isEmpty(videoData.description) &&
            <Typography
              className="video-description"
              sx={{
                fontSize: {
                  xs: '14px', md: '16px',
                },
                mb: '8px',
              }}
            >
              {videoData.description}
            </Typography>
          }
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {
            progressValue > 0
              ? <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: {xs: '16px'},
                width: '100%',
              }}>
                <LinearProgress
                  aria-label='Video completion progress of user'
                  sx={{
                    width: '80%',
                  }}
                  variant={progressBarVariant}
                  value={progressValue}
                />
                <Typography
                  variant='body2'
                >
                  {`${progressValue} %`}
                </Typography>
              </Box>
              : null
          }
          <Button
            aria-label={buttonActionName}
            variant='outlined'
            onClick={onClick}
            startIcon={<PlayArrowIcon/>}
            size='small'
            sx={{
              width: '100%',
              mb: '16px',
              borderColor: '#bebebe',
            }}
          >
            <Typography sx={{
              fontWeight: 'bold',
              fontSize: '12px',
            }}>
              {buttonActionName}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

VideoCard.propTypes = {
  videoData: PropTypes.object.isRequired,
  progressValue: PropTypes.number.isRequired,
  progressBarVariant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default VideoCard;