import React from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import ModuleDetailsDialog from './ModuleDetailsDialog';
import {DisplayMessageContext} from '../../DisplayMessageProvider';
import {useTags} from '../hooks/useTags';


const CreateModuleMutation = graphql`
  mutation CreateModuleDialogMutation(
    $name: String!,
    $description: String,
    $status: String!,
    $inSelfStudy: Boolean!,
    $chapterUid: Uid,
    $cmi5Zip: String!,
    $coverImg: String!,
    $coverImgDesc: String!,
    $tags: [String!]!,
    $accessPlanUids: [Uid!]!,
  ) {
    createModule(
      data: {
        name: $name, 
        description: $description,
        status: $status,
        inSelfStudy: $inSelfStudy,
        chapterUid: $chapterUid,
        cmi5Zip: $cmi5Zip,
        tags: $tags,
        coverImg: $coverImg,
        coverImgDesc: $coverImgDesc,
        accessPlanUids: $accessPlanUids
      }
    ) {
      ... on Module {
        id
        ...ModulesTable_ModuleFragment
      }
    }
  }
`;


export default function CreateModuleDialog(props) {
  const {
    onClose
  } = props;

  const [commitMutation, isMutationInFlight] = useMutation(CreateModuleMutation);
  const displayMessage = React.useContext(DisplayMessageContext);
  const {updateTags} = useTags();

  const mutate = (data) => {
    console.log('In CreateModule::mutate. data=', JSON.stringify(data));
    const variables = {
      ...data,
      coverImg: data.coverImg.name,
      cmi5Zip: data.cmi5Zip.name,
    }
    commitMutation({
      variables,
      onCompleted: (data) => {
        displayMessage({info: 'Module has been saved.'});
        onClose(data.createModule);
      },
      onError: (errData) => {
        console.error("Error performing mutation createModule:", errData);
        const err = errData.source?.errors?.[0]?.message
          ?? 'Error saving module. Please try again later.'
        displayMessage({error: err});
      },
      updater: (store, data) => {
        console.log('CreateModule::CommitMutation::updater: data=', data);
        const newModule = store.getRootField('createModule')
        console.log('CreateModule::CommitMutation::updater: newModule=', newModule);
        const modulesConnection = ConnectionHandler.getConnection(
          store.getRoot(),
          'ModulesTable_modules',
          {filters: {accessPlans: {site: {current: true}}}}
        );
        console.log('CreateModule::CommitMutation::updater: modulesConnection=', modulesConnection);
        const edge = ConnectionHandler.createEdge(
          store,
          modulesConnection,
          newModule,
          'Module'
        )
        console.log('CreateModule::CommitMutation::updater: edge=', edge);
        ConnectionHandler.insertEdgeAfter(modulesConnection, edge);
        const newModuleTags = newModule.getLinkedRecords('tags');
        updateTags(store, newModuleTags);

      },
    })
  }

  return (
    <ModuleDetailsDialog
      title='Create Module'
      isMutationInProgress={isMutationInFlight}
      mutate={mutate}
      {...props}
    />
  );
}

CreateModuleDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

CreateModuleDialog.defaultProps = {
  isOpen: false,
}
