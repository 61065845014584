import {
  Store,
  RecordSource,
  Environment,
  Network,
  Observable,
} from 'relay-runtime';
import * as Sentry from '@sentry/react';

export const ENDPOINT = '/graphql/';
const frontendBuildNumber = window.BUILD_NUMBER;

const fetchFn = (params, variables) => {
  const response = fetch(ENDPOINT, {
    method: 'POST',
    headers: [
      ['Content-Type', 'application/json'],
      ['X-CSRFToken', window.Settings.csrfToken],
    ],
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  }).then((response) => {
    const backendBuildNumber = response.headers.get('BUILD_NUMBER');
    if (frontendBuildNumber && frontendBuildNumber !== backendBuildNumber) {
      window.buildNumbersMismatch = true;
    }
    return response;
  })
  ;

  return Observable.from(response.then((data) => data.json()));
};

function createEnvironment() {
  const network = Network.create(fetchFn);
  const store = new Store(new RecordSource());
  return new Environment({ store, network });
}

export const environment = createEnvironment();


function isCsrfError(error) {
  const { message } = error?.source?.errors?.[0] || {};
  return message === 'CSRF failure';
}

export function isLoginApiError(error) {
  const { message } = error?.source?.errors?.[0] || {};
  return message === 'Login required.' || message === 'User is not logged in.' || isCsrfError(error);
}

export function redirectToSignInIfNeeded(queryResult) {
  if (queryResult?.type !== 'error') return null;
  const currentPath = window.location.pathname;
  const signInUrl = '/login';
  if (
    isLoginApiError(queryResult)
    && !currentPath.startsWith(signInUrl)
  ) {
    const next = encodeURI(window.location);
    window.location.href = `${signInUrl}/?next=${next}`;
    return true;
  }
  if (isCsrfError(queryResult)) {
    // We are on the error/login page, but the CSRF token is not working.
    // Reload the page to get a new token from the backend.
    window.location.reload();
    return true;
  }
  // 'Login required' & 'CSRF' outside of Login and Error pages are
  // expected errors and we don't capture them.
  // Everything else should go to Sentry:
  Sentry.captureException(queryResult);
  return false;
}
