import React, {useCallback, useEffect, useRef, useState} from 'react';

export const useModuleLaunch = () => {
  const [selectedVersionedModule, setSelectedVersionedModule] = useState(null);
  const [nextVersionedModule, setNextVersionedModule] = useState(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const nextModuleVersionCalculator = useRef(null);

  const handleSelectedVersionedModuleChanged = useCallback(
    (versionedModule) => {
      setSelectedVersionedModule(versionedModule);
      if (!!versionedModule && isViewDialogOpen === false) {
        setIsViewDialogOpen(true)
      }
    }, []);

  const handleNextVersionedModuleChanged = useCallback(
    (versionedModule) => {
      setNextVersionedModule(versionedModule);
    }, [])

  useEffect(() => {
    if (
      !!selectedVersionedModule &&
      !selectedVersionedModule?.versionUidOfNextModule
    ) {
      setNextVersionedModule(null)
    }
  }, [selectedVersionedModule])

  const handleNextModuleVersionCalculatorChanged = useCallback(
    (calculatorFn) => {
      nextModuleVersionCalculator.current = calculatorFn;
    },
    []
  )

  const handleCloseDialog = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick') {
        event.preventDefault();
        return;
      }
      setIsViewDialogOpen(false);
      setSelectedVersionedModule(null);
      setNextVersionedModule(null);
    }, []);

  return [
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    isViewDialogOpen,
    handleCloseDialog,
    nextVersionedModule,
    handleNextVersionedModuleChanged,
    nextModuleVersionCalculator.current,
    handleNextModuleVersionCalculatorChanged,
  ]
}
