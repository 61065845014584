import {concat, findIndex, reduce} from 'lodash';
import React from 'react';
import {graphql, useRefetchableFragment, useLazyLoadQuery} from 'react-relay';
import {useParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CourseContents from './CourseContents';
import CourseHeader from './CourseHeader';
import CourseSummary from './CourseSummary';
import {navBarHeight} from '@/style/constants/main';
import Cmi5ViewerDialog from '../cmi5-viewer/Cmi5ViewerDialog';

const CourseProgressFragment = graphql`
  fragment CourseDetails_courseProgress on CourseVersion
    @refetchable(queryName: "CourseProgressRefetchQuery")
  {
    progress {
      progressPercent
      modulesProgress {
        moduleVersion {
          uid
        }
        progressPercent
      }
    }
  }
`;

const CourseQuery = graphql`
  query CourseDetailsQuery(
      $courseUid: Uid!
    ) {
    courses(first: 1, filters: {uid: $courseUid})
    {
      edges {
        node {
          id
          ...CoursesTable_course @relay(mask: false)
          version(which: USER_CURRENT) {
            ...CoursesTable_courseVersion @relay(mask: false)
            ...CourseDetails_courseProgress
          }
        }
      }
    }
  }
`;

const CourseDetails = (props) => {
  const routeParams = useParams();
  const courseUid = routeParams.courseUid;
  const courseData = useLazyLoadQuery(
    CourseQuery,
    {courseUid},
  );
  const course = courseData.courses.edges?.[0]?.node;
  const [progressData, refetchCourseProgress] = useRefetchableFragment(CourseProgressFragment, course?.version)
  const [isViewDialogOpen, setIsViewDialogOpen] = React.useState(false);
  const [selectedModuleVersion, setSelectedModuleVersion] = React.useState(null);

  const allChapters = React.useMemo(() => {
    return course?.version
      ? reduce(
          course?.version?.sections,
          (result, section) => concat(result, section.chapters),
          []
        )
      : []
  }, [course])
  const allModules = React.useMemo(() => {
    return reduce(
      allChapters,
      (result, chapter) => concat(result, chapter.modules),
      []
    )
  }, [allChapters])

  const onCloseViewDialog = () => {
    refetchCourseProgress({}, { fetchPolicy: 'network-only' })
    setIsViewDialogOpen(false);
  }

  const getFirstModuleBy = (moduleVersions, predicate) => (
    moduleVersions.find((mv) => {
      const mvProgressInfo = progressData.progress.modulesProgress.find((mvProgressInfo) => (
        mvProgressInfo.moduleVersion.uid === mv.uid
      ))
      return predicate(mvProgressInfo);
    })
  )

  const getFirstInProgressModule = (moduleVersions) => (
    getFirstModuleBy(moduleVersions,
      (mvProgInfo) => mvProgInfo?.progressPercent > 0 && mvProgInfo?.progressPercent < 100
    )
  )

  const getFirstCompletedModule = (moduleVersions) => (
    getFirstModuleBy(moduleVersions,
      (mvProgInfo) => mvProgInfo?.progressPercent === 100
    )
  )

  const getFirstModuleToLaunch = (moduleVersions) => {
    return getFirstInProgressModule() ?? getFirstCompletedModule() ?? moduleVersions?.[0];
  }

  const getNextModule = React.useCallback(
    (forModule) => {
      const idx = findIndex(
        allModules, (module) => module.uid === forModule.uid);
      if (idx >=0 && idx < allModules.length-1) {
        console.log('Next module for ', forModule, ' is: ', JSON.stringify(allModules[idx+1]))
        return allModules[idx+1];
      }
      return null;
    },
    [allModules]
  )
  const onLaunchCourse = () => {
    setSelectedModuleVersion(getFirstModuleToLaunch(allModules));
    setIsViewDialogOpen(true);
  }
  const onLaunchChapter = (chapterUid) => {
    const chapter = allChapters.find((ch) => ch.uid === chapterUid);
    if (chapter) {
      const mv = getFirstModuleToLaunch(chapter.modules);
      if (mv) {
        setSelectedModuleVersion(mv);
        setIsViewDialogOpen(true);
      }
    }
  }
  const onLaunchModule = (moduleVersionUid) => {
    const moduleVersion = allModules.find((mv) => mv.uid === moduleVersionUid);
    if (moduleVersion) {
      setSelectedModuleVersion(moduleVersion);
      setIsViewDialogOpen(true);
    }
  }

  return (
    <>
      <CourseHeader course={course} />
      {course &&
        <Grid container>
          <Grid xs={12} md={9} order={{xs: 2, md: 1}}>
            <Box>
              <CourseContents
                courseVersion={{...course.version, progress: progressData.progress}}
                navigateToModule={getFirstModuleToLaunch(allModules)}
                onLaunchChapter={onLaunchChapter}
                onLaunchModule={onLaunchModule}
              />
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={3}
            order={{xs: 1, md: 2}}
            sx={{
              position: 'relative',
              top: {xs: 0, md: '-40px'},
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: navBarHeight,
                zIndex: 100,
                backgroundColor: 'background.light',
              }}
            >
              <CourseSummary
                course={course}
                courseProgress={progressData.progress}
                onLaunchCourse={onLaunchCourse}
              />
            </Box>
          </Grid>
        </Grid>
      }
      {isViewDialogOpen &&
        <Cmi5ViewerDialog
          isOpen={isViewDialogOpen}
          moduleVersion={selectedModuleVersion}
          getNextModule={getNextModule}
          onClose={onCloseViewDialog}
        />
      }
    </>
  )
}

export default CourseDetails;
