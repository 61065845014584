import {useFormik} from 'formik';
import {cloneDeep, concat, take, takeRight, without} from 'lodash';
import React from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FeaturedTopicAccordion from './FeaturedTopicAccordion';
import {validationSchema} from './validation';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import {FEATURED_TOPIC_HDR} from '@/constants';


const MAX_FEATURED_TOPICS = 15;

const FeaturedTopicFragment = graphql`
  fragment FeaturedTopics_topic on FeaturedTopic {
    uid
    name
    synopsis
    modules {
      uid
      name
    }
  }
`;

export const FeaturedTopicsQuery = graphql`
  query FeaturedTopicsQuery {
    featuredTopics {
      ...FeaturedTopics_topic @relay(mask: false)
    }
  }
`;

const SetFeaturedTopicsMutation = graphql`
  mutation FeaturedTopics_SetFeaturedTopicsMutation(
    $topics: [FeaturedTopicInputPartial!]!,
  ) {
    setFeaturedTopics(
      data: {
        topics: $topics,
      }
    ) {
      topics {
        ...FeaturedTopics_topic @relay(mask: false)
      }
    }
  }
`;


const FeaturedTopics = (props) => {
  const data = useLazyLoadQuery(FeaturedTopicsQuery, {});
  const [updateMutation, isMutationInProgress] = useMutation(SetFeaturedTopicsMutation);
  const displayMessage = React.useContext(DisplayMessageContext);
  const [isDirty, setIsDirty] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({topics: data.featuredTopics });
  const formik = useFormik({
    initialValues: cloneDeep(initialValues),
    validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      onSubmit(values);
      formik.setTouched({});
    },
  });
  const [draggingTopicIdx, setDraggingTopicIdx] = React.useState(null);
  const [topicExpanded, setTopicExpanded] = React.useState(
    Array(formik.values.topics.length).fill(true)
  );


  const onSave = () => {
    formik.handleSubmit();
    formik.setSubmitting(false);
  }
  const onReset = () => {
    formik.setValues(cloneDeep(initialValues));
    setTopicExpanded(Array(initialValues.topics.length).fill(true));
    setIsDirty(false);
  }
  const onSubmit = (values) => {
    const topics = values.topics.map((ft) => ({
      ...ft,
      modules: ft.modules.map((mv) => mv.uid),
    }));
    console.log('In FeaturedTopics::onSubmit. vars=', JSON.stringify(topics));
    const variables = {topics};
    updateMutation({
      variables,
      onCompleted: (data) => {
        console.log('In FeaturedTopics::onSubmit::onCompleted. data=', JSON.stringify(data));
        setIsDirty(false);
        const updatedFeaturedTopics = data.setFeaturedTopics;
        setInitialValues(updatedFeaturedTopics);
        displayMessage({info: `${FEATURED_TOPIC_HDR}s have been updated.`});
      },
      onError: (err) => {
        console.error("Error performing mutation setFeaturedTopics:", err);
        displayMessage({error: `Error updating ${FEATURED_TOPIC_HDR}s. Please try again later.`});
      },
    })

  }
  const onAddTopic = (evt) => {
    setIsDirty(true);
    const n = formik.values.topics.length;
    formik.setFieldValue(
      'topics',
      concat(formik.values.topics,
        {name: null, synopsis: '', modules: []}
      )
    );
    setTopicExpanded((prev) => concat(prev, true));
    formik.setFieldTouched(`topics[${n}].name`, false);
    formik.setFieldTouched(`topics[${n}].synopsis`, false);
  }
  const onRemoveTopic = (idx) => {
    setIsDirty(true);
    formik.setFieldValue(
      'topics',
      formik.values.topics.filter((sec, i) => i !== idx)
    );
    setTopicExpanded((prev) => prev.filter((e, i) => i !== idx));
  }
  const handleTopicExpanded = (idx, isExpanded) => {
    setTopicExpanded((prev) => prev.map((e, i) => (i === idx ? isExpanded : e)));
  }
  const handleChangeField = (idx, field, val) => {
    setIsDirty(true);
    formik.setFieldValue(
      'topics',
      formik.values.topics.map((topic, i) => (
        i === idx ? {...topic, [field]: val} : topic
      ))
    );
  }

  const handleDragStart = (evt, idx) => {
    setDraggingTopicIdx(idx);
  }
  const handleDragEnd = (evt) => {
    evt.target.style.boxShadow = 'none';
  }
  const handleDrop = (evt, idx) => {
    evt.preventDefault();
    const topics = formik.values.topics;
    if (idx === draggingTopicIdx) return;
    const topicToDrop = topics[draggingTopicIdx];
    const withoutDropped = without(formik.values.topics, topicToDrop);
    formik.setFieldValue(
      'topics',
      concat(
        take(withoutDropped, idx),
        topicToDrop,
        takeRight(withoutDropped, withoutDropped.length-idx)
      )
    )
    setIsDirty(true);
  }
  const handleDragOver = (evt) => {
    evt.preventDefault();
    if (evt.target.className === 'module') {
      evt.target.style.boxShadow = '0 4px 3px grey';
    }
  }
  const handleDragLeave = (evt) => {
    evt.target.style.boxShadow = 'none';
  }

  return (
    <Box
      id='panel-featured-topics'
      role='tabpanel'
      aria-labelledby='tab-featured-topics'
      sx={{ px: 3 }}
    >
      <Stack direction='column' gap={3} alignContent='start'>
        {formik.values.topics.map((topic, idx) => (
          <div
            draggable
            key={idx}
            onDragStart={(evt) => handleDragStart(evt, idx)}
            onDragEnd={(evt) => handleDragEnd(evt, idx)}
            onDrop={(evt) => handleDrop(evt, idx)}
            onDragOver={(evt) => handleDragOver(evt, idx)}
            onDragLeave={(evt) => handleDragLeave(evt, idx)}
          >
            <FeaturedTopicAccordion
              index={idx}
              data={topic}
              expanded={topicExpanded[idx]}
              formik={formik}
              showRemoveButton={formik.values.topics.length > 1}
              onChangeField={(field, value) => handleChangeField(idx, field, value)}
              onExpanded={handleTopicExpanded}
              onRemoveTopic={onRemoveTopic}
            />
          </div>
        ))}
        <Button
          aria-label={`Add ${FEATURED_TOPIC_HDR}`}
          disabled={formik.values.topics.length >= MAX_FEATURED_TOPICS}
          variant='text'
          onClick={onAddTopic}
          sx={{alignSelf: 'start', mt: -1}}
        >
          + Add {FEATURED_TOPIC_HDR}
        </Button>
        {typeof(formik.errors?.topics) === 'string' &&
          <Typography variant='body2' color='error'>{formik.errors?.topics}</Typography>
        }
        <Stack
          direction='row'
          gap={1}
          alignSelf='flex-end'
        >
          {isDirty &&
            <Button
              aria-label='Reset'
              variant='outlined'
              onClick={onReset}
            >
              Reset
            </Button>
          }
          <Button
            aria-label='Save'
            variant='contained'
            disabled={!isDirty || formik.isSubmitting || isMutationInProgress}
            onClick={onSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default FeaturedTopics;
