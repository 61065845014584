import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {CURR_SITE, SITE_STANFORD} from '../../constants';
import {
  moduleBoxStaticStylesNs,
  moduleBoxStaticStylesStanford
} from '../../style/constants/modules';
import {featuredTopicsSx} from '../../style/constants/homepage';

const site = CURR_SITE.name;

export const moduleBoxStaticStyles = site === SITE_STANFORD
  ? moduleBoxStaticStylesStanford
  : moduleBoxStaticStylesNs;


const TopicModuleCard = (props) => {
  const {
    moduleData,
    onClick,
  } = props;

  const imgUrl = moduleData.coverImg.url;
  const moduleBoxDynamicStyles = site === SITE_STANFORD
    ? {
      background: `linear-gradient(to bottom, #000000B0, #000000B0), url(${imgUrl})`,
    }
    : {
      background: `url(${imgUrl})`,
    }

  return (
    <Box
      title={moduleData?.coverImgDesc ?? ''}
      onClick={onClick}
      style={{
        backgroundSize: 'cover',
      }}
      sx={{
        ...moduleBoxStaticStyles,
        ...moduleBoxDynamicStyles,
        ...featuredTopicsSx,
        p: 2,
      }}
    >
      <Typography sx={{
        fontSize: '16px',
        fontWeight: 'bold',
      }}>
        {moduleData.name}
      </Typography>
    </Box>
  );
};

TopicModuleCard.propTypes = {
  moduleData: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TopicModuleCard;