import React from 'react';
import Box from '@mui/material/Box';
import {pageContentPadding} from '../../style/constants/self-guided';
import {sectionsColors} from './SectionsNavigation';
import Typography from '@mui/material/Typography';
import PropTypes, {shape} from 'prop-types';
import {graphql, useFragment} from 'react-relay';
import {MODULE_CHAPTER_TYPE, VIDEO_CHAPTER_TYPE} from '../../constants';
import {moduleParentPropTypes, videoParentPropTypes} from '../../constants/propTypesShapes';
import ModuleChapter from '../self-guided/ModuleChapter';
import VideoChapter from '../videos/VideoChapter';
import {useMediaQuery} from '@mui/material';

export const sectionDescriptions = {
  0:
    `This section covers foundational knowledge: from evaluating medical need and defining essential product characteristics to selecting a therapeutic approach and identifying a lead candidate.`,
  1:
    `This section covers the intricacies of candidate development: from preclinical studies to preparing for clinical trials, including making the decision to license or commercialize.`,
  2:
    `This section covers advancing a lead candidate to humans: from regulatory requirements and clinical trial design, to first-in-human and late-stage clinical trials, fundraising, and commercialization.`,
};

const SectionFragment = graphql`
  fragment SectionFragment_section on Section {
    id
    uid
    name
    description
    frontendId
  }
`;

const Section = (props) => {
  const {
    section,
    sectionsRefMapCb,
    entityType,
    childrenProps,
  } = props;

  const sectionData = useFragment(
    SectionFragment,
    section,
  );

  const renderChapterElement = (chapter, index) => {
    if (entityType === MODULE_CHAPTER_TYPE) {
      return (
        <ModuleChapter
          key={index}
          chapter={chapter}
          sectionColor={sectionsColors[sectionData.frontendId]}
          childrenProps={childrenProps}
        />
      )
    } else if (entityType === VIDEO_CHAPTER_TYPE) {
      return (
        <VideoChapter
          key={index}
          chapter={chapter}
          sectionColor={sectionsColors[sectionData.frontendId]}
          childrenProps={childrenProps}
        />
      )
    }
  }
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Box
      key={sectionData.uid}
      ref={(node) => sectionsRefMapCb(sectionData.frontendId, node)}
    >
      {/* Section Header */}
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        alignItems: {xs: 'flex-start', md: 'center'},
        px: {...pageContentPadding},
        py: {xs: '8px', md: '12px'},
        backgroundColor: `${sectionsColors[sectionData.frontendId]}.header`,
      }}>
        {
          isMd && (
            <Typography sx={{
              color: `${sectionsColors[sectionData.frontendId]}.contrastText`,
              fontSize: {xs: 19, md: 28},
              mr: '24px',
              mb: {xs: '8px', md: '0px'},
            }}
            >
              {sectionData.name}
            </Typography>
          )
        }

        <Typography sx={{
          color: `${sectionsColors[sectionData.frontendId]}.contrastText`,
          fontSize: {xs: 14},
        }}
        >
          {sectionDescriptions[sectionData.frontendId]}
        </Typography>
      </Box>

      {/* All Chapters */}
      <Box
        key={sectionData.frontendId}
        sx={{
          px: {...pageContentPadding},
          pt: {xs: '18px', md: '24px'},
          pb: {xs: '20px', md: '40px'},
          backgroundColor: `${sectionsColors[sectionData.frontendId]}.main`,
          minHeight: '250px',
        }}
      >
        {/* Section Chapters */}
        {
          !!section.chapters && (
            section.chapters.map((chapter, index) => renderChapterElement(chapter, index))
          )
        }
      </Box>
    </Box>
  );
};

Section.propTypes = {
  section: PropTypes.object.isRequired,
  sectionsRefMapCb: PropTypes.func,
  entityType: PropTypes.string.isRequired,
  childrenProps: PropTypes.oneOfType([
    shape(moduleParentPropTypes),
    shape(videoParentPropTypes),
  ]),
}

export default Section;