import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../themes/userUi';
import NavBar from '@/components/common/navbar/NavBar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {CURR_SITE, SITE_STANFORD} from '../constants';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';

const site = CURR_SITE.name;
const image = site === SITE_STANFORD
  ? '/static/stanford/img/404.svg'
  : '/static/ns/img/404.svg';

const Page404 = (props) => {
  const {
    showLinks,
    showWelcomeText,
  } = props;

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        minHeight: '100vh',
        backgroundColor: 'background.accent',
        }}
      >
        <NavBar
          showLinks={showLinks}
          showWelcomeText={showWelcomeText}
          theme={theme}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '17vh',
            gap: 1,
            px: 1,
          }}
        >
          <Box
            component='img'
            alt='crossed file icon'
            src={image}
            sx={{
              width: {xs: '70px', md: '70px'},
            }}
          />
          <Typography
            sx={{
              fontSize: {xs: '48px', md: '96px'},
            }}
          >
            404
          </Typography>

          <Typography
            sx={{
              fontSize: {xs: '28px', md: '48px'},
            }}
          >
            Page Not Found
          </Typography>

          <Typography
            sx={{
              textAlign: 'center',
              fontSize: {xs: '14px', sm: '20px'},
            }}
          >
            Sorry, the page you are looking for doesn’t exist.
          </Typography>

          <Button
            variant='contained'
            aria-label='Go back'
            onClick={() => navigate(-1)}
            sx={{
              '&:hover': {
                backgroundColor: 'accentDark.main',
              },
              backgroundColor: 'accentDark.main',
              minWidth: '160px',
              mt: 2,
              height: {xs: '48px', md: '56px'},
              textTransform: 'none',
            }}
          >
            Back
          </Button>

        </Box>
      </Box>
    </ThemeProvider>
  )
}

Page404.propTypes = {
  showLinks: PropTypes.bool,
  showWelcomeText: PropTypes.bool,
}

Page404.defaultProps = {
  showLinks: true,
  showWelcomeText: true,
}

export default Page404;
