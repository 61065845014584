import React, {useEffect} from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import {MODULE_SEARCH_TYPE, COURSE_SEARCH_TYPE, VIDEO_SEARCH_TYPE} from '../../constants';
import PropTypes from 'prop-types';

const SearchVideosListQuery = graphql`
  query SearchQueryRunner_SearchVideosListQuery($searchTemplate: String) {
    filterVideos(
        videoSearchTemplate: $searchTemplate,
    )
    {
        uid
        ...VideoDataWrapperFragment_defaultVersionedModulesVideo
        moduleVersion {
            module {
                coverImg {
                  url
                }
                tags {
                    text
                }
            }
        }
    }
  }
`;

const SearchCoursesListQuery = graphql`
  query SearchQueryRunner_SearchCoursesListQuery($searchTemplate: String) {
    versionedCourses(searchTemplate: $searchTemplate) {
      id
      uid
      ...CourseCardDataWrapperFragment_courseVersion
    }
  }
`;

const SearchModulesListQuery = graphql`
  query SearchQueryRunner_ModulesListQuery($searchTemplate: String!) {
    searchModules(moduleSearchTemplate: $searchTemplate) {
      id
      uid
      ...ModuleDataWrapperFragment_defaultModules
    }
  }
`;

const SearchQueryRunner = (props) => {
  const {
    searchText,
    updateSearchResults,
  } = props;

  const videoResults = useLazyLoadQuery(
    SearchVideosListQuery,
    {searchTemplate: searchText},
    {fetchKey: searchText},
  );
  const courseResults = useLazyLoadQuery(
    SearchCoursesListQuery,
    {searchTemplate: searchText},
    {fetchKey: searchText},
  );
  const moduleResults = useLazyLoadQuery(
    SearchModulesListQuery,
    {searchTemplate: searchText},
    {fetchKey: searchText},
  );

  useEffect(() => {
    updateSearchResults({
      [MODULE_SEARCH_TYPE]: {
        results: moduleResults.searchModules,
        count: moduleResults.searchModules.length,
      },
      [COURSE_SEARCH_TYPE]: {
        results: courseResults.versionedCourses,
        count: courseResults.versionedCourses.length,
      },
      [VIDEO_SEARCH_TYPE]: {
        results: videoResults.filterVideos,
        count: videoResults.filterVideos.length,
      },
    });
  }, [moduleResults, courseResults, videoResults]);

  return null;
}

SearchQueryRunner.propTypes = {
  searchText: PropTypes.string,
  updateSearchResults: PropTypes.func.isRequired,
}

SearchQueryRunner.defaultProps = {
  searchText: '',
}

export default SearchQueryRunner;
