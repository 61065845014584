/**
 * @generated SignedSource<<e06b1623bd073836a1760cf1e980f35b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProfileQuestion",
    "kind": "LinkedField",
    "name": "profileQuestions",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "questionText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "questionType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileQuestionOption",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowCustomAnswer",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionnaireQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QuestionnaireQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a945f721d0d26c9726311bcd10fc0e86",
    "id": null,
    "metadata": {},
    "name": "QuestionnaireQuery",
    "operationKind": "query",
    "text": "query QuestionnaireQuery {\n  profileQuestions {\n    uid\n    questionText\n    questionType\n    options {\n      uid\n      optionText\n      isDefault\n      allowCustomAnswer\n    }\n  }\n}\n"
  }
};
})();

node.hash = "5923776bfda7a2a62dc6bf86d64cdc16";

export default node;
