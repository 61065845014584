import React from 'react';
import {graphql, useFragment} from 'react-relay';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ScrollableXContainer from '../common/ScrollableXContainer';
import ModuleCard from './ModuleCard';
import PropTypes, {shape} from 'prop-types';
import {moduleParentPropTypes} from '../../constants/propTypesShapes';
import ModuleCardNoContent from './ModuleCardNoContent';
import ModuleDataWrapper from '../common/data-wrappers/ModuleDataWrapper';

const ModuleChapterFragment = graphql`
  fragment ModuleChapterFragment_chapter on Chapter {
    uid
    name
    defaultModules {
      id
      uid
      ...ModuleDataWrapperFragment_defaultModules
    }
  }
`;

const ModuleChapter = (
  {
    chapter,
    sectionColor,
    childrenProps,
  }
) => {

  const {
    selectedVersionedModule,
    handleSelectedVersionedModuleChanged,
    handleNextVersionedModuleChanged,
    launchMvUid,
  } = childrenProps;

  const chapterData = useFragment(
    ModuleChapterFragment,
    chapter,
  );

  const modules = chapterData.defaultModules;

  const renderModuleCard = React.useCallback((
    {
      moduleData,
      progressValue,
      progressBarVariant,
      isProgressLoading,
      onClick,
    }
  ) => (
      <ModuleCard
        key={moduleData.uid}
        moduleData={moduleData}
        sectionColor={sectionColor}
        progressValue={progressValue}
        progressBarVariant={progressBarVariant}
        isProgressLoading={isProgressLoading}
        onClick={onClick}
      />
  ), [sectionColor]);

  const modulesElement = React.useMemo(() => (
    modules.length === 0
      ? [
        <ModuleCardNoContent
          key={'no-modules-found-card'}
          sectionColor={sectionColor}
        />
      ]
      : modules.map((module) => {
        return (
          <ModuleDataWrapper
            key={module.uid}
            module={module}
            selectedVersionedModule={selectedVersionedModule}
            handleNextVersionedModuleChanged={handleNextVersionedModuleChanged}
            handleSelectedVersionedModuleChanged={handleSelectedVersionedModuleChanged}
            renderChildren={renderModuleCard}
            launchMvUid={launchMvUid}
          />
        );
      })
  ),
    [modules, selectedVersionedModule?.uid, launchMvUid, sectionColor, renderModuleCard,
    handleNextVersionedModuleChanged, handleSelectedVersionedModuleChanged]
  );

  return (
    <Box sx={{
      mb: {xs: '13px'},
      '&:last-child': {mb: 0},
    }}>

      {/* Chapter Name */}
      <Typography
        color={`${sectionColor}.contrastText`}
        fontWeight='bold'
        fontSize={{xs: 14, md: 20}}
        sx={{
          mb: {xs: '13px'},
        }}
      >
        {chapterData.name}
      </Typography>

      {/* Chapter Content */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}>
        {/* Modules */}
        <ScrollableXContainer
          elements={modulesElement}
          navigationSx={{
            top: '-26%',
          }}
        />
      </Box>
    </Box>
  );
};

ModuleChapter.propTypes = {
  chapter: PropTypes.object.isRequired,
  sectionColor: PropTypes.string.isRequired,
  childrenProps: shape(moduleParentPropTypes),
}

export default ModuleChapter;