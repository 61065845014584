import React from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {buttonFontSize, buttonHeight} from '../../style/constants/homepage';

const selfGuidedDescriptionText = `
Self-Guided Learning is designed with the academic researcher in mind. All modules are available on demand, 
organized by stage of development, so you can find relevant information on your journey. 
`;

const SelfGuidedDescription = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      alignItems: {xs: 'center'},
      mb: {xs: '20px', md: '40px'},
    }}>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: {md: '20px'},
        mb: {xs: '24px', md: '0px'}
      }}>
        <Typography sx={{
          fontSize: {xs: '18px', md: '22px'},
          mb: {xs: '4px', md: '6px'},
          fontWeight: {xs: 700},
        }}>
          {'Explore Self-Guided Learning'}
        </Typography>
        <Typography sx={{
          fontSize: {xs: '14px', md: '16px'},
        }}>
          {selfGuidedDescriptionText}
        </Typography>
      </Box>

      <Box
        sx={{
          width: {xs: '100%', md: 'auto'}
        }}
      >
        <Button
          aria-label='Start Learning'
          onClick={(e) => navigate('/self-guided-learning/')}
          variant='contained'
          color='primary'
          sx={{
            '&:hover': {
              backgroundColor: 'primary.main',
            },
            height: {...buttonHeight},
            minWidth: '180px',
            width: {xs: '100%'},
            textTransform: 'none',
          }}
        >
          <Typography sx={{
            fontWeight: {xs: 700},
            fontSize: {...buttonFontSize},
          }}>
            Start Learning
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SelfGuidedDescription;