import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';

const cardShapePolygon = 'polygon(89% 0, 100% 50%, 89% 100%, 0% 100%, 11% 50%, 0% 0%)';

const imgSize = {
  lg: '64px',
  xl: '68px',
  xxl: '72px',
  giant: '77px',
};

const cardFontSize = {
  lg: 14,
  xl: 16,
  xxl: 18,
  giant: 22,
}

export const cardSize = {
  width: {
    lg: '127.2px', xl: '135px', xxl: '144px', giant: '154px',
  },
  height: {
    lg: '80px', xl: '85px', xxl: '90px', giant: '96px',
  },
}

const SectionContentCard = (
  {sectionContentCard, sectionContentCardColor, handleSectionTabChanged, isSelected, isDataLoaded}
) => {
  return (
    // This Box is a wrapper to apply shadow for clipPath
    <Box
      className="pipeline-element"
      sx={{
      // Props as item of parent Box
      ...cardSize,
      filter: isSelected ? 'drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.20))': 'none',
      '&:first-of-type': { marginLeft: 0 },
      marginLeft: 'calc(-0.75%)',
      // Props as container for children elements
      display: 'flex',
      flexGrow: '1',
      flexShrink: '1',
    }}>
    <Card
      onClick={
        (e) => {
          e.preventDefault();
          handleSectionTabChanged(sectionContentCard.section);
        }
      }
      sx={{
        '&:hover': {
          cursor: isDataLoaded ? 'pointer' : 'wait',
        },
        // Props as item of parent Box
        clipPath: cardShapePolygon,
        backgroundColor: sectionContentCardColor,
        borderRadius: '0px',
        transition: 'background-color 0.3s',
        paddingX: {
          xs: '15px',
          xl: '20px',
        },
        flexGrow: '1',
        flexShrink: '1',

        // Props as container for children elements
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        component='img'
        sx={{
          height: {
            ...imgSize,
          },
          width: {
            ...imgSize,
          },
        }}
        alt=''
        src={sectionContentCard.iconPath}
      />
    </Card>
    </Box>
  );
};

SectionContentCard.propTypes = {
  sectionContentCard: PropTypes.shape({
    section: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    iconPath: PropTypes.string.isRequired,
  }),
  sectionContentCardColor: PropTypes.string,
  handleSectionTabChanged: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  isDataLoaded: PropTypes.bool.isRequired,
};

export default SectionContentCard;