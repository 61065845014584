import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const PERIODS = [
  {value: '7days', label: 'Last 7 days'},
  {value: 'this_month', label: 'This Month'},
  {value: 'last_month', label: 'Last Month'},
  {value: 'this_quarter', label: 'This Quarter'},
  {value: 'this_year', label: 'This Year'},
  {value: 'all_time', label: 'All Time'},
];

const ReportPeriodSelect = (props) => {
  const {
    onChange,
  }  = props;
  const [selectedPeriod, setSelectedPeriod] = React.useState(null);

  const handlePeriodChanged = (event, newValue) => {
    setSelectedPeriod(newValue)
    onChange(newValue);
  }
  return (
    <Autocomplete
      blurOnSelect
      id="ac-report-period"
      options={PERIODS}
      value={selectedPeriod}
      getOptionLabel={(option) => option.label}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label='Report Period'
        />
      )}
      size='small'
      onChange={handlePeriodChanged}
    />
  )
}

ReportPeriodSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default ReportPeriodSelect;
