import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuIcon from '@mui/icons-material/Menu';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import SchoolIcon from '@mui/icons-material/School';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';
import PropTypes from 'prop-types';
import {navBarHeight} from '@/style/constants/main';
import {pageContentPadding} from '@/style/constants/self-guided';
import {theme as navbarTheme} from '@/themes/navbar';
import {UserContext} from '@/AppRoutes';
import LoadingSpinner from '@/LoadingSpinner';
import UserDetailsDialog from '@/components/user-profile/UserDetailsDialog';
import UserProgressDialog from '@/components/user-profile/UserProgressDialog';
import {DisplayMessageContext} from '@/DisplayMessageProvider';
import {HOMEPAGE_TITLE, HOMEPAGE_URL} from '@/constants';
import Logo from './Logo';
import AccountMenu from "./AccountMenu";
import MainLinks from './MainLinks';
import UserSection from './UserSection';


const showOnlySmallSx = {
  display: {xs: 'none', md: 'flex'},
}

const pages = [
  {label: HOMEPAGE_TITLE, link: HOMEPAGE_URL, icon: <SchoolIcon fontSize="small"/>},
  {label: 'Self-Guided Learning', link: '/self-guided-learning/', icon: <LightbulbIcon fontSize="small"/>},
  {label: 'Videos', link: '/video-library/', icon: <PersonalVideoIcon fontSize="small"/>},
  {label: 'About', link: '/about-us/', icon: <InfoIcon fontSize="small"/>},
];


const xsPages = [
  {label: 'FAQ', link: '/faqs/', icon: <LiveHelpIcon fontSize="small"/>},
]

const NavBar = (props) => {
  const {
    showLinks,
    showWelcomeText,
    theme,
  } = props;
  const themeToUse = theme ? createTheme(deepmerge(navbarTheme, theme)) : navbarTheme;
  const currUser = React.useContext(UserContext);
  const displayMessage = React.useContext(DisplayMessageContext);
  const navigate = useNavigate();
  const [isUserDialogOpen, setIsUserDialogOpen] = React.useState(false);
  const [isUserProgressDialogOpen, setIsUserProgressDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [batchedAction, setBatchedAction] = React.useState(null);

  React.useEffect(() => {
    if (batchedAction) {
      const {item, navigateInfo} = batchedAction;
      setBatchedAction(null);
      if (item === 'profile') {
        setIsUserDialogOpen(true);
      }
      else if (item === 'progress') {
        setIsUserProgressDialogOpen(true);
      }
      else if (navigateInfo?.target) {
        navigate(navigateInfo.target, navigateInfo.params);
      }
    }
  }, [batchedAction, navigate, setIsUserDialogOpen, setIsUserProgressDialogOpen]);

  const handleAccountMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item, navigateInfo) => {
    setAnchorEl(null);
    setBatchedAction({item, navigateInfo});
  }


  const renderHamburgerMenu = (
    <IconButton
      aria-label='menu'
      disableRipple
      onClick={handleAccountMenuOpen}
      edge='start'
      color='inherit'
      sx={{
        display: {xs: 'block', md: 'none'},
        p: 0
      }}
    >
      <MenuIcon sx={{ color: 'black'}}>
      </MenuIcon>
    </IconButton>
  )


  return (
    <ThemeProvider theme={themeToUse}>
      <AppBar position='sticky' sx={{backgroundColor: 'panelBackground.main'}}>
        <Box
          sx={{
            minHeight: navBarHeight,
            maxHeight: '120px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: {...pageContentPadding},
          }}
        >
          {/* Logo Section */}
          <Logo />

          {/* Menu Section */}
          {showLinks &&
            <MainLinks
              pages={pages}
              smallScreensSx={showOnlySmallSx}
            />
          }

          {/* User Section */}
          <UserSection
            user={currUser}
            showLinks={showLinks}
            showWelcomeText={showWelcomeText}
            smallScreensSx={showOnlySmallSx}
            onUserAvatarClick={handleAccountMenuOpen}
          />

          {/* Hamburger Icon */}
          {renderHamburgerMenu}
        </Box>
        <AccountMenu
          user={currUser}
          anchorEl={anchorEl}
          pages={pages}
          xsPages={xsPages}
          displayMessage={displayMessage}
          handleMenuItemClick={handleMenuItemClick}
          handleClose={handleCloseAccountMenu}
        />
      </AppBar>
      {isUserDialogOpen &&
        <React.Suspense fallback={<LoadingSpinner />}>
          <UserDetailsDialog
            isOpen={isUserDialogOpen}
            user={currUser}
            showChangePasswordLink
            displayMessage={displayMessage}
            fieldParams={{
              accessPlan: {hidden: true},
              subscriptionType: {hidden: true},
              university: {readOnly: true},
            }}
            onClose={(e) => setIsUserDialogOpen(false)}
          />
        </React.Suspense>
      }
      {isUserProgressDialogOpen &&
        <UserProgressDialog
          isOpen={isUserProgressDialogOpen}
          onClose={(e) => setIsUserProgressDialogOpen(false)}
        />
      }
    </ThemeProvider>
  );
};

NavBar.propTypes = {
  showLinks: PropTypes.bool,
  showWelcomeText: PropTypes.bool,
  theme: PropTypes.object,
}

NavBar.defaultProps = {
  showLinks: true,
  showWelcomeText: true,
  theme: null,
}

export default NavBar;
