import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';

const TextFieldWithLengthDisplay = (props) => {
  const {
    maxLength,
    refCallback,
    onChange: onChangeOrig,
    ...textFieldProps
  } = props;
  const [size, setSize] = React.useState(textFieldProps?.value?.length ?? 0);
  return (
    <Stack direction='column'>
      <TextField
        ref={refCallback}
        inputProps={{maxLength: maxLength}}
        onChange={(evt) => {
          setSize(evt.target.value.length);
          if (typeof onChangeOrig === 'function') {
            onChangeOrig(evt);
          }
        }}
        {...textFieldProps}
      />
      <Typography
        variant='caption'
        sx={{textAlign: 'right', fontSize: 'xx-small', paddingRight: '10px', marginTop: '-25px'}}
      >
        {size} / {maxLength}
      </Typography>
    </Stack>
  )
}

TextFieldWithLengthDisplay.propTypes = {
  maxLength: PropTypes.number.isRequired,
  refCallback: PropTypes.func,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
}

TextFieldWithLengthDisplay.defaultProps = {
  refCallback: () => {},
}

export default TextFieldWithLengthDisplay;
