import {differenceWith, isEmpty, isEqual, pick} from 'lodash';
import React from 'react';
import {graphql, useFragment, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AdminProfilePanel from './AdminProfilePanel';
import LearnerProfilePanel from './LearnerProfilePanel';
import {GROUP_ADMINS, SUBSCRIPTION_TYPES} from './constants';
import {useFormik} from 'formik';
import {userSchema} from './validation';
import ChangePasswordDialog from './ChangePasswordDialog';
import {useUniversities} from '../hooks/useUniversities';

export const UserAnswersFragment = graphql`
  fragment UserProfile_UserAnswers on UserAnswer @relay(plural: true) {
    uid
    question {
      uid
    }
    chosenOption {
      uid
      allowCustomAnswer
    }
    chosenOptions {
      uid
    }
    customAnswer
  }
`;

const UpdateUserMutation = graphql`
  mutation UserProfile_UpdateMutation(
    $id: GlobalID!,
    $firstName: String = null,
    $lastName: String = null,
    $email: String = null,
    $university: String = null,
    $accessPlanUid: Uid = null,
    $subscriptionType: String = null,
    $answers: [UserAnswerInput!] = null,
  ) {
    updateUser(
      data: {
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        university: $university,
        accessPlanUid: $accessPlanUid,
        subscriptionType: $subscriptionType,
        answers: $answers,
      }
    ) {
      ... on User {
        id
        ...UsersTable_user @relay(mask: false)
      }
    }
  }
`;

const UserProfile = (props) => {
  const {
    user: userInitial,
    showProgress,
    extraButtons,
    showChangePasswordLink,
    displayMessage,
    fieldParams,
  } = props;
  const [user, setUser] = React.useState(userInitial);
  const [editMode, setEditMode] = React.useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState(false);
  const [updateMutation, mutatingUpdate] = useMutation(UpdateUserMutation);
  const userAnswers = useFragment(UserAnswersFragment, userInitial.answers);
  const {updateUniversities} = useUniversities();
  const initialValues = {
    ...user,
    accessPlan: user.subscription?.accessPlan,
    subscriptionType: SUBSCRIPTION_TYPES.find((t) => t.value === user.subscription?.type),
    answers: [...userAnswers],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      handleSubmit(values);
      formik.setTouched({});
    },
  });

  const isAdmin = React.useMemo(() => (
     Boolean((user.groups ?? []).find((g) => g.name === GROUP_ADMINS))
  ), [user]);

  const onSave = (evt) => {
    formik.handleSubmit();
    formik.setSubmitting(false);
  }

  const handleChangePasswordClick = (evt) => {
    evt.preventDefault();
    setShowChangePasswordDialog(true);
  }
  const handleCancelEdit = () => {
    formik.setValues(initialValues);
    setEditMode(false);
  }

  const handleSubmit = (userData) => {
    handleUserUpdate(userData);
  }

  const handleUserUpdate = (data) => {
    console.log('In handleUserUpdate: user=', JSON.stringify(user))
    console.log('In handleUserUpdate: data=', JSON.stringify(data))
    const variables = {
      id: user.id,
      uid: user.uid,
    };
    ['firstName', 'lastName', 'email'].forEach(
      (field) => {
        if (!isEqual(data[field], user[field])) {
          variables[field] = data[field];
        }
      }
    );
    if (user.university.name !== data.university.name) {
      variables.university = data.university.name
    }
    if (user.subscription?.accessPlan?.uid !== data.accessPlan.uid) {
      variables.accessPlanUid = data.accessPlan.uid;
    }
    if (user.subscription?.type !== data.subscriptionType.value) {
      variables.subscriptionType = data.subscriptionType.value;
    }
    if (user.answers.length !== data.answers.length ||
      !isEmpty(differenceWith(user.answers, data?.answers, isEqual))) {
      variables.answers = data.answers.filter(
        (ans) => !isEmpty(ans)
      ).map((ans) => ({
          ...ans,
          chosenOption: pick(ans?.chosenOption, ['uid']),
        }
      ));
    }

    console.log('In handleUserUpdate. vars=', JSON.stringify(variables));
    updateMutation({
      variables,
      onCompleted: (data) => {
        console.log('In handleUserUpdate::onCompleted. data=', JSON.stringify(data));
        const updatedUser = data.updateUser;
        setUser((prevUser) => ({...prevUser, ...updatedUser}));
        displayMessage({info: 'User has been updated.'});
        setEditMode(false);
      },
      onError: (err) => {
        console.error("Error performing mutation updateUser:", err);
        displayMessage({error: 'Error updating user. Please try again later.'});
      },
      updater: (store, data) => {
        console.log('handleUserUpdate::updater: data=', data);
        const user = store.getRootField('updateUser');
        const userUniversity = user.getLinkedRecord('university');
        updateUniversities(store, userUniversity);
      }
    })
  }


  return (
    <Stack direction='column' spacing={3}>
      <Paper sx={{ p: 2}} elevation={2}>
        {isAdmin
          ? <AdminProfilePanel formik={formik} editMode={editMode} />
          : <LearnerProfilePanel
              formik={formik}
              editMode={editMode}
              user={user}
              showProgress={showProgress}
              fieldParams={fieldParams}
          />
        }
      </Paper>
      <Paper
        role="dialog"
        aria-modal="false"
        square
        variant="outlined"
        tabIndex={-1}
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          m: 0,
          p: 2,
          borderWidth: 0,
          borderTopWidth: 1,
          zIndex: 101,
        }}
      >
        <Stack direction='row' spacing={2}>
        {editMode ? (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              aria-label='Cancel'
              variant='outlined'
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
            <Button
              aria-label='Save'
              disabled={formik.isSubmitting || mutatingUpdate}
              variant='contained'
              onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        ) : (
          <Stack
            direction='row'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {showChangePasswordLink
              ? <Link
                  href='#'
                  component='button'
                  variant='body1'
                  onClick={handleChangePasswordClick}
                >
                  Change Password
                </Link>
              : <Box />
            }
            <Stack direction='row' spacing={2}>
              {extraButtons}
              <Button
                aria-label='Edit'
                disabled={formik.isSubmitting || mutatingUpdate}
                variant='contained'
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            </Stack>
          </Stack>
        )}
        </Stack>
      </Paper>
      {showChangePasswordDialog &&
        <ChangePasswordDialog
          isOpen
          onClose={() => setShowChangePasswordDialog(false)}
          displayMessage={displayMessage}
        />
      }
    </Stack>
  )
}

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
  extraButtons: PropTypes.node,
  showChangePasswordLink: PropTypes.bool,
  fieldParams: PropTypes.object,
  displayMessage: PropTypes.func.isRequired,
}

UserProfile.defaultProps = {
  showProgress: false,
  extraButtons: null,
  showChangePasswordLink: false,
  fieldParams: {},
}

export default UserProfile;
