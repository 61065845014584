import {isEmpty} from 'lodash';
import React from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import {useFormik} from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import MultiUserInvitationPanel from './MultiUserInvitationPanel';
import SingleUserInvitationPanel from './SingleUserInvitationPanel';
import {DURATION_UNITS} from './constants';
import {invitationSchemaSingleUser, invitationSchemaMultiUser} from './validation';
import {ENDPOINT} from '@/api';
import {useUniversities} from '../hooks/useUniversities';


const InviteSingleUserMutation = graphql`
  mutation UserInvitationDialog_InviteSingleUserMutation(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $accessPlanUid: Uid,
    $university: String!,
    $isAdmin: Boolean!,
    $autoRenew: Boolean,
    $subscriptionDuration: Int,
  ) {
    inviteUser(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        accessPlanUid: $accessPlanUid,
        university: $university,
        isAdmin: $isAdmin,
        autoRenew: $autoRenew,
        subscriptionDuration: $subscriptionDuration,
      }
    ) {
        invitation {
          recipient {
            id
            ...UsersTable_user @relay(mask: false)
          }
        }
        status
        msg
    }
  }
`;

const InviteMultiUserMutation = graphql`
  mutation UserInvitationDialog_InviteMultiUserMutation(
    $csvPath: String!,
  ) {
    inviteUsers(
      data: {
        csvPath: $csvPath,
      }
    ) {
        status
        msg
    }
  }
`;

const CsvUploadMutationName = 'uploadCsv';

export default function UserInvitationDialog(props) {
  const {
    isOpen,
    displayMessage,
    onClose
  } = props;
  const [isSingleUserInvite, setIsSingleUserInvite] = React.useState(true);
  const [inviteSingleUserMutation, mutationSingleInProgress] = useMutation(InviteSingleUserMutation);
  const [inviteMultiUserMutation, mutationMultiInProgress] = useMutation(InviteMultiUserMutation);
  const {updateUniversities} = useUniversities();
  const formikSingleUser = useFormik({
    initialValues: {
      ...invitationSchemaSingleUser.default(),
      subscriptionDuration: { unit: 'Y', duration: 1},
    },
    validationSchema: invitationSchemaSingleUser,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      handleSubmit(values);
      formikSingleUser.setTouched({});
    },
  });
  const formikMultiUser = useFormik({
    initialValues: invitationSchemaMultiUser.default(),
    validationSchema: invitationSchemaMultiUser,
    onSubmit: (values) => {
      handleSubmit(values);
      formikMultiUser.setTouched({});
    },
  });


  const handleClose = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      onClose();
    },
  [onClose],
  );

  const onSave = (evt) => {
    const formik = isSingleUserInvite ? formikSingleUser : formikMultiUser;
    formik.handleSubmit();
    if (!isEmpty(formik.errors)) {
      console.log('In UserInvitationDialog::onSave. values: ', JSON.stringify(formik.values))
      console.log('In UserInvitationDialog::onSave. errors: ', JSON.stringify(formik.errors))
    }
    formik.setSubmitting(false);
  }

  const handleSubmit = (invitationData) => {
    if (isSingleUserInvite) {
      handleSingleUserInvite(invitationData);
    }
    else {
      handleMultiUserInvite(invitationData);
    }
  }

  const handleSingleUserInvite = (data) => {
    console.log('In handleSingleUserInvite: data=', JSON.stringify(data))
    let durationDays = 0;
    const autoRenew = data.autoRenew;
    if (autoRenew === false) { // 'false' means a selection was made and it's not 'auto-renew'
      const sd = data.subscriptionDuration;
      const unitInfo = DURATION_UNITS.find((elem) => elem.value === sd.unit);
      durationDays = sd.duration * unitInfo.days;
    }
    const variables = {
      ...data,
      accessPlanUid: data.accessPlan?.uid,
      university: data.university.name,
      subscriptionDuration: autoRenew === false ? undefined : durationDays,
      autoRenew: autoRenew ? true : undefined,
    };

    console.log('In handleSingleUserInvite. vars=', JSON.stringify(variables));
    inviteSingleUserMutation({
      variables,
      onCompleted: (data) => {
        console.log('In handleSingleUserInvite::onCompleted. data=', JSON.stringify(data));
        const invResult = data.inviteUser;
        const success = invResult?.status === true;
        displayMessage(
          success ? {info: invResult.msg} : {error: invResult.msg}
        );
        if (success) {
          onClose();
        }
      },
      onError: (err) => {
        console.error("Error performing mutation inviteUser:", err);
        displayMessage({error: 'Error inviting user. Please try again later.'});
      },
      updater: (store, data) => {
        if (data.inviteUser.status !== true) return;
        console.log('handleSingleUserInvite::updater: data=', data);
        const invData = store.getRootField('inviteUser');
        const invitation = invData.getOrCreateLinkedRecord('invitation', 'Invitation');
        const invitee = invitation.getOrCreateLinkedRecord('recipient', 'User');
        const usersConnection = ConnectionHandler.getConnection(
          store.getRoot(),
          'UsersTableFragment_users',
          {filters: {forCurrentSite: true}}
        );
        console.log('handleSingleUserInvite::updater: usersConnection=', usersConnection);
        const edge = ConnectionHandler.createEdge(
          store,
          usersConnection,
          invitee,
          'User'
        )
        ConnectionHandler.insertEdgeAfter(usersConnection, edge);
        const inviteeUniversity = invitee.getLinkedRecord('university');
        updateUniversities(store, inviteeUniversity);
      },
    })
  }

  const handleMultiUserInvite = (data) => {
    const file = formikMultiUser.values.csvFile;
    console.log('In handleMultiUserInvite. file=', JSON.stringify(file));
    uploadCsvFile(file);
  }
  const uploadCsvFile = (file) => {
    const formData = new FormData();
    formData.append('operations',
      `{ "query": "mutation($file: Upload!){${CsvUploadMutationName}(file: $file) {savedPath, url} }"`+
      ', "variables": { "file": null } }');
    formData.append('map', '{ "file": ["variables.file"] }');
    formData.append("file", file);
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', handleCsvUploadCompleted, false);
    xhr.addEventListener(
      'error',
      () => displayMessage({error: 'Error uploading file. Please try again later.'}),
      false
    );
    xhr.addEventListener(
      'abort',
      () => displayMessage({error: 'File uploading aborted. Please try again later.'}),
      false
    );
    xhr.open('POST', ENDPOINT);
    xhr.send(formData);
  }
  const handleCsvUploadCompleted = (e) => {
    console.log('CSV Upload complete:', e);
    const respBody = e.target.responseText;
    let respJson;
    try {
      respJson = JSON.parse(respBody);
    }
    catch (e) {
      console.error("Error parsing server response:", e);
      displayMessage({error: 'Error uploading file. Please try again later.'});
      return;
    }
    console.log('Upload complete:', {respBody}, respJson);
    if (respJson?.errors) {
      console.error("Received Errors in response: ", respJson.errors);
      displayMessage({error: 'Error uploading file. Please try again later.'});
      return;
    }
    const uploadInfo = respJson.data[CsvUploadMutationName];
    console.log('Uploaded CSV file info:', uploadInfo );
    const variables = {
      csvPath: uploadInfo.savedPath,
    };
    inviteMultiUserMutation({
      variables,
      onCompleted: (data) => {
        console.log('In handleCsvUploadCompleted::onCompleted. data=', JSON.stringify(data));
        const invResult = data.inviteUsers;
        const success = invResult?.status === true;
        displayMessage(
          success ? {info: invResult.msg} : {error: invResult.msg}
        );
        if (success) {
          onClose();
        }
      },
      onError: (err) => {
        console.error("Error performing mutation inviteUsers:", err);
        displayMessage({error: 'Error inviting users. Please try again later.'});
      },
    })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>Invite User</DialogTitle>
      <FormGroup
        sx={{
          position: 'absolute',
          top: 15,
          right: 5,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={!isSingleUserInvite}
              disabled={formikSingleUser.isSubmitting || mutationSingleInProgress || mutationMultiInProgress}
              onChange={(evt) => setIsSingleUserInvite(!evt.target.checked)}
            />
          }
          label="Multiple Users"
        />
      </FormGroup>
      <DialogContent sx={{p: 2}}>
        <Paper sx={{ p: 2 }} elevation={2}>
          {isSingleUserInvite
            ? <SingleUserInvitationPanel formik={formikSingleUser} />
            : <MultiUserInvitationPanel formik={formikMultiUser} />
          }
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label='Cancel'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          aria-label='Invite'
          disabled={formikSingleUser.isSubmitting || mutationSingleInProgress || mutationMultiInProgress}
          variant='contained'
          onClick={onSave}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserInvitationDialog.propTypes = {
  isOpen: PropTypes.bool,
  displayMessage: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

UserInvitationDialog.defaultProps = {
  isOpen: false,
}
