import React from 'react';
import {ConnectionHandler, graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {DisplayMessageContext} from '../../DisplayMessageProvider';


const CONFIRMATION_TEXT = 'DELETE';

const DeleteModuleMutation = graphql`
  mutation DeleteModuleDialogMutation(
    $id: GlobalID!,
  ) {
      deleteModule(
        data: {
          id: $id
        }
      ) {
          ... on Module {
            id
            uid
          }
        }
  }
`;

export default function DeleteModuleDialog(props) {
  const {
    module,
    isOpen,
    onClose
  } = props;

  const [error, setError] = React.useState(null);
  const [commitMutation, isMutationInFlight] = useMutation(DeleteModuleMutation);
  const [confirmText, setConfirmText] = React.useState('');
  const displayMessage = React.useContext(DisplayMessageContext);

  const onCloseDeleteDialog = (confirmDelete) => {
    if (confirmDelete === true) {
      if (confirmText !== CONFIRMATION_TEXT) {
        setError('Please enter the confirmation text above');
        return;
      }
      const variables = {id: module.id};
      commitMutation({
        variables,
        onCompleted: (data) => {
          displayMessage({info: `Module "${module.name}" has been deleted`});
          onClose(true);
        },
        onError: (errData) => {
          console.error("Error performing mutation deleteModule:", errData);
          const err = errData.source?.errors?.[0]?.message
            ?? 'Error deleting module. Please try again later.'
          displayMessage({error: err});
        },
        updater: (store, data) => {
          console.log('DeleteModule::CommitMutation::updater: data=', data);
          const modulesConnection = ConnectionHandler.getConnection(
            store.getRoot(),
            'ModulesTable_modules',
            {filters: {accessPlans: {site: {current: true}}}}
          );
          ConnectionHandler.deleteNode(modulesConnection, module.id);
          console.log('DeleteModule::CommitMutation::updater: deleted node:', module.id);
        },
      })
    }
    else {
      onClose();
    }
  }
  return (
    <>
    {isOpen &&
      <Dialog
        open={isOpen}
        onClose={onCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this module?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            Removing a module will also remove all related CMI5 content and may impact the progress of students
            who are taking this module.
          </DialogContentText>
          <DialogContentText id="alert-delete-dialog-instruction">
            Please enter <b>{CONFIRMATION_TEXT}</b> in the textbox to confirm.
          </DialogContentText>
          <TextField
            margin='dense'
            id='confirm_delete'
            size='small'
            type='text'
            value={confirmText}
            variant='outlined'
            onChange={(evt) => {setConfirmText(evt.target.value)}}
          />
          {error && (
            <Typography color='error' variant='body2'>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Delete'
            disabled={isMutationInFlight}
            variant='contained'
            sx={{
              backgroundColor: 'primary.action',
              '&:hover': {
                backgroundColor: 'primary.actionDark',
              },
            }}
            onClick={() => onCloseDeleteDialog(true)}
          >
            Delete
          </Button>
          <Button
            aria-label='Cancel'
            onClick={onCloseDeleteDialog}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    }
    </>
  )
}

DeleteModuleDialog.propTypes = {
  module: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

DeleteModuleDialog.defaultProps = {
  isOpen: false,
}