/**
 * @generated SignedSource<<61074153c05051f02b890dafa0388b17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoDataWrapperProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoDataWrapperProgressFragment_progress"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoDataWrapperProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isNode"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoProgress",
                "kind": "LinkedField",
                "name": "progress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progressPercent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Video",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a52022c33e441dbec3839c0c9b3380d1",
    "id": null,
    "metadata": {},
    "name": "VideoDataWrapperProgressQuery",
    "operationKind": "query",
    "text": "query VideoDataWrapperProgressQuery(\n  $id: GlobalID!\n) {\n  node(id: $id) {\n    __typename\n    ...VideoDataWrapperProgressFragment_progress\n    __isNode: __typename\n    id\n  }\n}\n\nfragment VideoDataWrapperProgressFragment_progress on Video {\n  progress {\n    progressPercent\n  }\n  id\n}\n"
  }
};
})();

node.hash = "5468aead2eb225432aedab4f9b24d25d";

export default node;
