/**
 * @generated SignedSource<<21a3808bb923542e92083507a4755c00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTemplate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "moduleSearchTemplate",
    "variableName": "searchTemplate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchQueryRunner_ModulesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "searchModules",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModuleDataWrapperFragment_defaultModules"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchQueryRunner_ModulesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "searchModules",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImgDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "coverImg",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleVersion",
            "kind": "LinkedField",
            "name": "latestVersion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "versionUidOfNextModule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleVersionProgress",
                "kind": "LinkedField",
                "name": "progress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progressPercent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16c264c64dce3f641f0415363c2dc7b0",
    "id": null,
    "metadata": {},
    "name": "SearchQueryRunner_ModulesListQuery",
    "operationKind": "query",
    "text": "query SearchQueryRunner_ModulesListQuery(\n  $searchTemplate: String!\n) {\n  searchModules(moduleSearchTemplate: $searchTemplate) {\n    id\n    uid\n    ...ModuleDataWrapperFragment_defaultModules\n  }\n}\n\nfragment ModuleDataWrapperFragment_defaultModules on Module {\n  id\n  uid\n  name\n  description\n  coverImgDesc\n  coverImg {\n    url\n  }\n  tags {\n    text\n  }\n  latestVersion {\n    id\n    uid\n    versionUidOfNextModule\n    ...ModuleDataWrapperProgressFragment_progress\n  }\n}\n\nfragment ModuleDataWrapperProgressFragment_progress on ModuleVersion {\n  progress {\n    progressPercent\n  }\n  id\n}\n"
  }
};
})();

node.hash = "faabe29c403089c991fc1275461129a2";

export default node;
