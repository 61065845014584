import React from 'react';
import {upperFirst} from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { graphql, useLazyLoadQuery } from 'react-relay';
import CoursesWithModule from './CoursesWithModule';
import CreateModuleDialog from './CreateModuleDialog';
import DeleteModuleDialog from './DeleteModuleDialog';
import UpdateModuleDialog from './UpdateModuleDialog';
import LoadingSpinner from '../../LoadingSpinner';
import TagList, {compareTagLists} from './TagList';
import PropTypes from 'prop-types';
import { formatDate } from '@/utils/date';
import { latestModuleVersion } from '@/utils/modules';
import {PublishStatusColors} from '@/style/constants/main';
import Cmi5ViewerDialog from '../cmi5-viewer/Cmi5ViewerDialog';

const ModuleFragment = graphql`
  fragment ModulesTable_ModuleFragment on Module {
    uid
    name
    description
    inSelfStudy
    defaultChapter {
      uid
      name
      section {
        uid
        name
      }
    }
    coverImg {
      originalFileName
      name
      url
      size
    }
    coverImgDesc
    lastUpdatedTs
    versions {
      uid
      version
      status
      author {
        uid
        firstName
        lastName
        email
      }
      cmi5 {
        uid
        status
        zipPath {
          originalFileName
          name
          size
        }
      }
      module {
        uid
        name
      }
    }
    accessPlans {
      uid
      name
    }
    tags {
      id
      text
    }
  }
`;

const ModulesQuery = graphql`
  query ModulesTableQuery {
    modules(
      first: 100,
      filters: {accessPlans: {site: {current: true}}}
    )
    @connection(key: "ModulesTable_modules")
    {
      edges {
        node {
          id
          ...ModulesTable_ModuleFragment @relay(mask: false)
        }
      }
    }
  }
`;


function ModulesTable(props) {
  const {
    initialSelection,
    initialSearchText,
    editable,
    selectable,
    isRowSelectable,
    onModulesSelected,
  } = props;
  const data = useLazyLoadQuery(
    ModulesQuery,
    {},
  );
  const [isViewDialogOpen, setIsViewDialogOpen] = React.useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [selectedModule, setSelectedModule] = React.useState(null);
  const [searchText, setSearchText] = React.useState(initialSearchText ?? '');
  const [rowSelectionModel, setRowSelectionModel] = React.useState(initialSelection ?? []);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const previewModule = React.useCallback(
    (module) => () => {
      setSelectedModule(module);
      setIsViewDialogOpen(true);
    },
  [],
  );
  const editModule = React.useCallback(
    (module) => () => {
      setSelectedModule(module);
      setIsCreateDialogOpen(true);
    },
  [],
  );
  const deleteModule = React.useCallback(
    (module) => () => {
      setSelectedModule(module);
      setIsDeleteDialogOpen(true);
    },
    [],
  );
  const showCoursesForModule = React.useCallback(
    (module) => (evt) => {
      evt.preventDefault();
      setSelectedModule(module);
      setAnchorEl(evt.currentTarget);
    },
    [],
  );
  const handleCmi5ViewerClosed = React.useCallback(
    (evt, reason) => {
      if (reason === 'backdropClick') {
        evt.preventDefault();
        return;
      }
      setIsViewDialogOpen(false);
    },
  [],
  );
  const handleCreateModuleDialogClosed = React.useCallback(
    (moduleData) => {
      setIsCreateDialogOpen(false);
      setSelectedModule(null);
    },
    [],
  );
  const onCloseDeleteDialog = React.useCallback(
    () => {
      setIsDeleteDialogOpen(false);
      setSelectedModule(null);
    },
    [],
  );
  const onSearchTextChanged = React.useCallback(
    (evt) => {
      const val = evt.target.value;
      setSearchText(val);
    },
    []
  )
  const onRowSelectionChanged = React.useCallback(
    (newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
      const versions = data.modules.edges.map((item) => latestModuleVersion(item.node));
      const selectedModules = versions.filter((mv) => (
        newRowSelectionModel.find((uid) => uid === mv.uid)
      ));
      onModulesSelected(selectedModules);
    },
    [data.modules.edges, onModulesSelected]
  )
  const columns = React.useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.5,
        minWidth: 200,
        width: 220,
        renderCell: ({value}) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value}
          </Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        width: 100,
        renderCell: (params) => (
          <Chip
            label={upperFirst(params.value)}
            size="small"
            sx={{
              bgcolor: PublishStatusColors[params.value] ?? 'default',
              color: 'white',
            }}
          />
        )
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 200,
        width: 220,
        renderCell: (params) => (
          <TagList tags={params.value} maxTagsToDisplay={4} />
        ),
        sortComparator: compareTagLists,
      },
      { field: 'courses',
        headerName: 'Courses',
        headerAlign: 'center',
        align: 'center',
        minWidth: 70,
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <Link
            href="#"
            underline='none'
            onClick={showCoursesForModule(params.row.module)}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: 'author',
        headerName: 'Author',
        minWidth: 120,
        width: 120,
        valueGetter: ({value}) => {
          if (!value) return '';
          let n = value.firstName;
          if (value.lastName) {
            n += ` ${value.lastName[0]}.`;
          }
          return n;
        },
        renderCell: ({value}) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value}
          </Typography>
        ),
      },
      {
        field: 'versionNumber',
        headerAlign: 'center',
        headerName: 'Version',
        minWidth: 40,
        align: 'center',
        valueFormatter: (params) => {
          return params.value ? `v${params.value}` : '';
        },
      },
      {
        field: 'last_updated',
        headerName: 'Last Updated',
        renderHeader: (params) => (
          <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
            Last Updated
          </Typography>
        ),
        type: 'dateTime',
        valueGetter: ({ value }) => value && new Date(value),
        minWidth: 90,
        width: 135,
        renderCell: ({ value }) => (
          <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
            {value ? formatDate(value) : ''}
          </Typography>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 30,
        getActions: (params) => {
          const actions = [];
          if (editable) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Edit" arrow><EditIcon/></Tooltip>}
                key='action_edit_module'
                label="Edit"
                onClick={editModule(params.row.module)}
              />
            )
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>}
                key='action_delete_module'
                label="Remove"
                onClick={deleteModule(params.row.module)}
              />
            )
          }
          actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Preview" arrow><PlayCircleOutlineIcon/></Tooltip>}
                key='action_preview_module'
                label="Preview"
                onClick={previewModule(params.row.module)}
              />
          );
          return actions;
        }
      }
    ],
     [deleteModule, editable, editModule, previewModule, showCoursesForModule],
  );

  const rows = data.modules.edges.map((item) => {
    const m = item.node;
    const version = latestModuleVersion(m);
    return {
      id: version.uid,
      name: m.name,
      status: version.status,
      tags: m.tags
        .map((tag) => tag.text)
        .sort((t1, t2) => t1.toLowerCase().localeCompare(t2.toLowerCase())),
      courses: 'View',
      author: version.author,
      versionNumber: version.version,
      version: version,
      last_updated: m.lastUpdatedTs,
      module: m,
    }
  }).filter((item) => {
    if (!searchText) return true;
    const st = searchText.toLowerCase();
    return item.name.toLowerCase().includes(st) || (
      item.tags.filter((tag) => (tag.toLowerCase().includes((st))))
    ).length > 0;
  })
  return (
    <Box sx={{mt: '5px'}}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            size='small'
            placeholder='Search topic or name'
            value={searchText}
            onChange={onSearchTextChanged}
          />
          <Button
            aria-label='Create module'
            variant="outlined"
            size='small'
            onClick={() => {setIsCreateDialogOpen(true)}}
          >
            CREATE MODULE
          </Button>
        </Stack>
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          rowHeight={70}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [
                {
                  field: 'name',
                  sort: 'asc',
                },
              ],
            },
          }}
          sortingOrder={['asc', 'desc']}
          pageSizeOptions={[10, 20]}
          checkboxSelection={selectable}
          isRowSelectable={(params) => selectable && isRowSelectable(params.row)}
          onRowSelectionModelChange={onRowSelectionChanged}
          rowSelectionModel={rowSelectionModel}

          sx={{
            minHeight: '60vh',
          }}
        />
      </Stack>
      <Cmi5ViewerDialog
        isOpen={isViewDialogOpen}
        moduleVersion={latestModuleVersion(selectedModule)}
        preview
        onClose={handleCmi5ViewerClosed}
      />
      {isCreateDialogOpen &&
        <React.Suspense fallback={<LoadingSpinner />}>
          {(
            selectedModule === null ?
              <CreateModuleDialog
                isOpen={isCreateDialogOpen}
                onClose={handleCreateModuleDialogClosed}
              />
              :
              <UpdateModuleDialog
                isOpen={isCreateDialogOpen}
                module={selectedModule}
                onClose={handleCreateModuleDialogClosed}
              />
          )}
        </React.Suspense>
      }
      {isDeleteDialogOpen && (
        <DeleteModuleDialog
          module={selectedModule}
          isOpen={isDeleteDialogOpen}
          onClose={onCloseDeleteDialog}
        />
      )}
      {selectedModule &&
        <Popover
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={(e) => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <React.Suspense fallback={<CircularProgress aria-label='Data loading progress'/>}>
            <CoursesWithModule moduleUid={selectedModule.uid} />
          </React.Suspense>
        </Popover>
      }
    </Box>
  );
}

ModulesTable.propTypes = {
  initialSelection: PropTypes.array,
  initialSearchText: PropTypes.string,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  onModulesSelected: PropTypes.func,
}

ModulesTable.defaultProps = {
  initialSelection: null,
  initialSearchText: null,
  editable: true,
  selectable: false,
  isRowSelectable: (row) => true,
}

export default ModulesTable;
