import React from 'react';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {sectionsColorsByName} from './constants';
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';

const tabIndicatorHeight = {xs: '4px'};

const TabsOverflowVisible = styled(Tabs)(({theme}) => ({
  '&.MuiTabs-root': {
    overflow: 'visible',
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible !important',
  },
  '& .MuiButtonBase-root': {
    padding: '8px',
    marginRight: '4px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
}));

const tabsShadow = `
  0px 3px 5px -1px rgba(0, 0, 0, 0.20), 
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 18px 0px rgba(0, 0, 0, 0.12)
`

const SectionsTabs = (
  {
    sections,
    sectionValue,
    handleSectionTabChanged,
    getSectionRank,
  }
) => {
  return (
    <TabsOverflowVisible
      textColor='inherit'
      value={sectionValue}
      onChange={handleSectionTabChanged}
      variant='fullWidth'
      TabIndicatorProps={{
        sx: {
          display: 'none',
        }
      }}
    >
      {
        sections.map((section, idx) => {
          const sectionRank = getSectionRank(section);
          const sectionsColorsKey = section.name.toLowerCase();
          return (
            <Tab
              key={sectionRank}
              value={sectionRank}
              label={
                <>
                  <Typography
                    sx={{
                      mb: tabIndicatorHeight,
                    }}
                    fontSize={{ xs: 16 }}
                    fontWeight={sectionRank === sectionValue ? 'bold' : 'normal'}
                  >
                    {section.name}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      height: tabIndicatorHeight,
                      width: '100%',
                      backgroundColor: (
                        sectionValue === sectionRank
                          ? `${sectionsColorsByName[sectionsColorsKey]}.contrastText`
                          : `${sectionsColorsByName[sectionsColorsKey]}.tabInactive`
                      ),
                      zIndex: 1000,
                      transition: 'background-color 500ms linear',
                    }}
                  />
                </>
              }
              sx={{
                height: {xs: '38px'},
                justifyContent: 'center',
                backgroundColor: `${sectionsColorsByName[sectionsColorsKey]}.main`,
                color: `${sectionsColorsByName[sectionsColorsKey]}.contrastText`,
                opacity: 1,
                textTransform: 'none',
                fontWeight: sectionRank === sectionValue ? 'bold' : 'normal',
                boxShadow: sectionRank === sectionValue ? tabsShadow : 'none',
              }}
            >
            </Tab>
          )
        })
      }
    </TabsOverflowVisible>
  )
};

SectionsTabs.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  sectionValue: PropTypes.number.isRequired,
  handleSectionTabChanged: PropTypes.func.isRequired,
  getSectionRank: PropTypes.func.isRequired,
};

export default SectionsTabs;