import {useState} from 'react';
import {createDummyScroll, removeDummyScroll} from '../../utils/common';

export const useModalSearch = () => {
  const [isModalSearchOpen, setIsModalSearchOpen] = useState(false);

  const onModalSearchClose = () => {
    setIsModalSearchOpen(false);
    removeDummyScroll();
  };

  const onSearchFocused = () => {
    createDummyScroll();
    setIsModalSearchOpen(true);
  };

  return [
    isModalSearchOpen,
    onModalSearchClose,
    onSearchFocused,
  ]
}