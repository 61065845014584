import {truncate} from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {VisuallyHiddenInput} from '../common/VisuallyHiddenInput';
import {DisplayMessageContext} from '@/DisplayMessageProvider';


export default function MultiUserInvitationPanel(props) {
  const {
    formik,
  } = props;
  const uploadRef = React.useRef();
  const displayMessage = React.useContext(DisplayMessageContext);

  const handleFileSelected = () => {
    const file = uploadRef.current.files[0];
    formik.setFieldValue('csvFile', file);
  }
  const handleDrop = (evt, uid) => {
    evt.preventDefault();
    const droppedFiles = evt.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = Array.from(droppedFiles)[0];
      if (file.type.toLowerCase() !== 'text/csv') {
        displayMessage({error: "Please select a CSV file"});
        return;
      }
      formik.setFieldValue('csvFile', file);
    }
  }
  const handleDragOver = (evt) => {
    evt.preventDefault();
  }

  return (
    <Stack direction='column' gap={2} alignContent='start'>
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          py: 1,
          px: 3,
          borderColor: 'lightgray',
          borderStyle: 'dashed'
        }}
      >
        <Stack direction='column' gap={1} alignItems='center' justifyContent='center'>
          {formik.values.csvFile?.name &&
            <Stack direction={'row'} spacing={1} alignContent='center'>
              <DescriptionIcon />
              <Typography variant='caption' textAlign='center'>
                {truncate(formik.values.csvFile.name, {length: 40})}
              </Typography>
            </Stack>
          }
          <Typography variant='body2' textAlign='center'>
            <Link
              onClick={(evt) => uploadRef.current.click()}
              sx={{
                cursor: 'pointer',
              }}
            >
              Click to upload
              <VisuallyHiddenInput
                type='file'
                name='file'
                accept='.csv'
                ref={uploadRef}
                onChange={handleFileSelected}
              />
            </Link> or drag and drop
          </Typography>
          <Typography variant='caption' component='p' textAlign='center'>
            CSV file only
          </Typography>
        </Stack>
      </Box>
      {formik.errors?.csvFile &&
        <Typography variant='caption' component='p' color='error' textAlign='center'>
          {formik.errors.csvFile}
        </Typography>
      }
      <Typography variant='caption' textAlign='center'>
        Don&apos;t have a file yet?{' '}
        <Link href='/download/invite_csv/'>Download CSV template</Link>
      </Typography>
    </Stack>
  );
}

MultiUserInvitationPanel.propTypes = {
  formik: PropTypes.object.isRequired,
}
