import React from 'react';
import VideoModal from '../videos/VideoModal';
import {moduleSearchCardsContainerSx} from '../../style/constants/search';
import Box from '@mui/material/Box';
import SearchVideoCard from './SearchVideoCard';
import {useVideos} from '../hooks/useVideos';
import VideoDataWrapper from '../common/data-wrappers/VideoDataWrapper';
import PropTypes from 'prop-types';

const SearchVideosList = (props) => {
  const { searchResults: videos } = props;
  const [
    isViewDialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    selectedVideo,
    handleSelectVideoChanged,
    nextVideo,
    handleNextVideoChanged,
    previousVideo,
    handlePreviousVideoChanged,
  ] = useVideos();

  const renderVideoCard = (
    {
      videoData,
      progressValue,
      progressBarVariant,
      isProgressLoading,
      onClick,
    }
  ) => {

    return (
      <SearchVideoCard
        key={videoData.uid}
        videoData={videoData}
        progressValue={progressValue}
        progressBarVariant={progressBarVariant}
        isProgressLoading={isProgressLoading}
        onClick={onClick}
      />
    )
  }

  return (
    <Box
      sx={{
        ...moduleSearchCardsContainerSx,
        mb: 5,
      }}
    >
      {
        videos.map(video => {
          return (
            <VideoDataWrapper
              key={video.uid}
              video={video}
              module={video.moduleVersion.module}
              selectedVideo={selectedVideo}
              handleSelectVideoChanged={handleSelectVideoChanged}
              handleNextVideoChanged={handleNextVideoChanged}
              handlePreviousVideoChanged={handlePreviousVideoChanged}
              handleOpenDialog={handleOpenDialog}
              renderChildren={renderVideoCard}
            />
          );
        })
      }

      <VideoModal
        open={isViewDialogOpen}
        handleModalClose={handleCloseDialog}
        selectedVideo={selectedVideo}
        handleSelectVideoChanged={handleSelectVideoChanged}
        nextVideo={nextVideo}
        handleNextVideoChanged={handleNextVideoChanged}
        previousVideo={previousVideo}
        handlePreviousVideoChanged={handlePreviousVideoChanged}
      />
    </Box>
  );
};

SearchVideosList.propTypes = {
  searchResults: PropTypes.array,
}

export default SearchVideosList;