import {sortBy} from 'lodash';
import React from 'react';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {iconCheckBox, iconCheckBoxChecked} from './icons';
import {useUniversities} from '../hooks/useUniversities';


const filter = createFilterOptions({trim: true});


const UniversitySelect = (props) => {
  const {
    checkboxes,
    error,
    helperText,
    label,
    multiple,
    name,
    value,
    onChange,
    onBlur,
    ...autoselectFieldProps
  } = props;
  const {universities} = useUniversities();
  const universitiesSorted = React.useMemo(() => {
    return sortBy(universities, (univ) => univ.name.toLowerCase());
  }, [universities]);
  return (
    <React.Suspense fallback={<Skeleton variant="rounded" />}>
      <Autocomplete
        multiple={multiple}
        id="ac-university"
        name={name}
        freeSolo
        options={universitiesSorted}
        disableCloseOnSelect={multiple}
        value={value}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.name === value?.name;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const inputValue = params.inputValue.trim();
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue.toLowerCase() === option.name.toLowerCase()
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Create "${inputValue}"`
            });
          }
          return filtered;
        }}
        renderOption={(props, option, { selected }) => (
          checkboxes
          ?
            <li {...props}>
              <Checkbox
                icon={iconCheckBox}
                checkedIcon={iconCheckBoxChecked}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          :
            <li {...props}>{option?.name ?? option}</li>
        )}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? 'Institution'}
            error={error}
            helperText={helperText ?? ' '}
          />
        )}
        size='small'
        onChange={(event, newValue) => {
          let univ = '';
          if (typeof newValue === 'string') {
            univ = {name: newValue};
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            univ = {uid: '', name: newValue.inputValue};
          }
          else {
            univ = newValue;
          }
          onChange(event, univ);
        }}
        onBlur={onBlur}
        {...autoselectFieldProps}
      />
    </React.Suspense>
  )
}

UniversitySelect.propTypes = {
  checkboxes: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  autoselectFieldProps: PropTypes.object,
}

UniversitySelect.defaultProps = {
  checkboxes: false,
  error: false,
  helperText: null,
  label: null,
  multiple: false,
  value: null,
}

export default UniversitySelect;
