import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {QuestionsContext} from '../questionnaire/QuestionsContext';
import {QuestionnairePanel} from '../questionnaire/QuestionnairePanel';


const UserQuestionnairePanel = (props) => {
  const {
    formik,
    disabled,
  } = props;
  const questions = React.useContext(QuestionsContext);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    const userAnswers = formik.values.answers;
    const mappedAnswers = questions.map((question) => userAnswers.find(
      (answer) => answer?.question?.uid === question.uid
    ));
    formik.setFieldValue('answers', mappedAnswers);
    setInitialized(true);
  }, []);

  return (
    <>
      <Typography variant='h6'>Questionnaire Answers</Typography>
      {initialized &&
        <QuestionnairePanel
          compact
          disabled={disabled}
          formik={formik}
          questions={questions}
        />
      }
    </>
  )
};

UserQuestionnairePanel.propTypes = {
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

UserQuestionnairePanel.defaultProps = {
  disabled: false,
}

export default UserQuestionnairePanel;
