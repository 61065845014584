import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import scrollbar from './scrollbar';

const SectionsList = (
  {
    onScrollToSection,
    getStickyHeaderBottom,
    sectionsRefMap,
    children,
  }
) => {
  useEffect(() => {
    const onScroll = (e) => {
      const stickyHeaderBottom = getStickyHeaderBottom();
      if (stickyHeaderBottom !== null) {
        const sections = Object.keys(sectionsRefMap.current)
          .sort((a, b) => b - a);
        const scrollSum = (
          Math.round(window.scrollY) + Math.round(window.innerHeight)
        );
        const isTheScreenEnd = scrollSum >= document.documentElement.scrollHeight;

        for (const sectionToCheck of sections) {
          const sectionTop = Math.round(
            sectionsRefMap.current[sectionToCheck].getBoundingClientRect().top
          );
          if (
            (sectionTop === stickyHeaderBottom || isTheScreenEnd) &&
            scrollbar.isScrollDisabled()
          ) {
            scrollbar.enable();
            break;
          }
          else if (
            (sectionTop <= stickyHeaderBottom || isTheScreenEnd) &&
            !scrollbar.isScrollDisabled()
          ) {
            onScrollToSection(undefined, parseInt(sectionToCheck))
            break;
          }
        }
      }
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, {passive: true});
    return () => window.removeEventListener('scroll', onScroll);
  }, [getStickyHeaderBottom, sectionsRefMap, onScrollToSection]);

  return (
    <>
      {children}
    </>
  );
};

SectionsList.propTypes = {
  children: PropTypes.node,
  onScrollToSection: PropTypes.func.isRequired,
  getStickyHeaderBottom: PropTypes.func.isRequired,
  sectionsRefMap: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
}

export default SectionsList;
