import {debounce, isEmpty} from 'lodash';
import React from 'react';
import {fetchQuery, graphql, useLazyLoadQuery, useRelayEnvironment} from 'react-relay';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Cmi5ViewingComplete from './Cmi5ViewingComplete';
import LoadingSpinner from '../../LoadingSpinner';


const AuLaunchSessionCheckQuery = graphql`
  query Cmi5ViewerLaunchSessionCheckQuery($auLaunchSessionUid: String!) {
    auLaunchSessionCheck(auLaunchSessionUid: $auLaunchSessionUid) {
      status
      error
    }
  }
`;

const ModuleLaunchInfoQuery = graphql`
    query Cmi5ViewerQuery(
        $mvuid: String!,
        $preview: Boolean!,
    ) {
        moduleLaunchInfo(moduleVersionUid: $mvuid, preview: $preview) {
            error
            launchUrl
            auLaunchSessionUid
            returnUrl
        }
    }
`;

function Cmi5ContentIframe(props) {
  const {
    moduleVersionUid,
    preview,
    onIFrameLoaded,
    onBeforeIFrameUnload,
    onModuleLaunchError,
  } = props;
  const iFrameRef = React.useRef();
  const [isPending, setIsPending] = React.useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
  const environment = useRelayEnvironment();
  const data = useLazyLoadQuery(
    ModuleLaunchInfoQuery,
    {
      mvuid: moduleVersionUid,
      preview: preview ?? false,
    },
    {fetchPolicy: 'network-only'},
  );
  const [isAuLaunchSessionActive, setIsAuLaunchSessionActive] = React.useState(
    !isEmpty(data?.moduleLaunchInfo?.auLaunchSessionUid)
  );

  React.useEffect(() => {
    if (!preview && data.moduleLaunchInfo.launchUrl) {
      setIsAuLaunchSessionActive(true);
      const recheckAuLaunchSession = debounce(
        () => {
          fetchQuery(
            environment,
            AuLaunchSessionCheckQuery,
            {auLaunchSessionUid: data.moduleLaunchInfo.auLaunchSessionUid},
          )
          .subscribe({
            start: () => setIsPending(true),
            complete: () => {
              setIsPending(false);
            },
            next: (data) => {
              if (data?.auLaunchSessionCheck?.status !== true) {
                setIsAuLaunchSessionActive(false);
              }
            }
          })
        },
        300,
        {trailing: true}
      );

      const handleMessage = (event) => {
        const {origin, data} = event;
        if (origin === window.location.origin && data === 'Exited') {
          console.log('CMI5Viewer received message: ', event, JSON.stringify(event.data))
          onBeforeIFrameUnload();
        }
      }

      const handleVisibilityChange = (evt) => {
        if (!document.hidden){
            console.log("Browser tab with cmi5 viewer is visible")
            recheckAuLaunchSession();
        }
      }

      window.addEventListener("message", handleMessage, false);
      document.addEventListener("visibilitychange",handleVisibilityChange, false);

      return (() => {
        window.removeEventListener("message", handleMessage);
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      })
    }
  }, []);

  React.useEffect(() => {
    if (!preview && !isAuLaunchSessionActive) {
      console.log('!! AU Launch session expired!');
      setIsAlertDialogOpen(true);
    }
  }, [preview, isAuLaunchSessionActive]);

  const handleDialogClose = (evt, reason) => {
    if (reason === 'backdropClick') {
      evt.preventDefault();
      return;
    }
    setIsAlertDialogOpen(false);
    iFrameRef.current.src = data.moduleLaunchInfo.returnUrl;
  }

  const handleIFrameLoaded = (evt) => {
    onIFrameLoaded();
  }

  if (data.moduleLaunchInfo.error) {
    onModuleLaunchError(data.moduleLaunchInfo.error);
    return null;
  }

  return (
    <>
      <iframe
        ref={iFrameRef}
        src={data.moduleLaunchInfo.launchUrl}
        id='cmi5PreviewFrame'
        title='CMI5 content'
        frameBorder={0}
        scrolling='no'
        style={{width: '100%', height: '100%'}}
        onLoad={handleIFrameLoaded}
      />
      {isPending && <LoadingSpinner />}
      {isAlertDialogOpen &&
        <Dialog
          disableEscapeKeyDown
          open={true}
          onClose={handleDialogClose}
          aria-labelledby="cmi5-viewer-alert-title"
          aria-describedby="cmi5-viewer-alert-description"
        >
          <DialogTitle id="cmi5-viewer-alert-title">
            Module Session Closed
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="cmi5-viewer-alert-description">
              The session for this launched module has ended.<br/>
              This may happen if the module has been inactive for too long, or if the module is opened in another window.<br/>
              You will need to re-launch this module.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>OK</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

Cmi5ContentIframe.propTypes = {
  moduleVersionUid: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  onIFrameLoaded: PropTypes.func.isRequired,
  onBeforeIFrameUnload: PropTypes.func.isRequired,
  onModuleLaunchError: PropTypes.func.isRequired,
}

Cmi5ContentIframe.defaultProps = {
  preview: false,
}


export default function Cmi5Viewer(props) {
  const {
    moduleVersion,
    preview,
    getNextModule,
    onClose,
    handleSelectedVersionedModuleChanged,
  } = props;
  const [moduleVersionToView, setModuleVersionToView] = React.useState(moduleVersion);
  const [iFrameLoaded, setIFrameLoaded] = React.useState(false);
  const [viewingComplete, setViewingComplete] = React.useState(false);
  const [moduleLaunchError, setModuleLaunchError] = React.useState(null);
  const showPostViewingDialog = typeof getNextModule === 'function';

  const onIFrameLoaded = () => {
    setIFrameLoaded(true);
  }
  const onBeforeIFrameUnload = () => {
    setViewingComplete(true);
    setIFrameLoaded(false);
    if (!showPostViewingDialog) {
      onClose();
    }
  }

  const handleNextAction = (action) => {
    if (action === 'next') {
      const nextVersionedModule = getNextModule(moduleVersionToView);
      if (nextVersionedModule !== null) {
        setModuleVersionToView(nextVersionedModule);
        setViewingComplete(false);
        if (typeof handleSelectedVersionedModuleChanged === 'function') {
          handleSelectedVersionedModuleChanged(nextVersionedModule);
        }
      }
    } else if (action === 'restart') {
      setViewingComplete(false);
    } else {
      onClose();
    }
  }

  return (
    <div id='cmi5viewer_cont' style={{width: '100%', height: '100%', overflow: 'hidden'}}>
      {viewingComplete && showPostViewingDialog &&
        <Cmi5ViewingComplete
          hasNextModule={getNextModule(moduleVersionToView) !== null}
          handleSelection={handleNextAction}
        />
      }
      {(!iFrameLoaded && !viewingComplete) &&
        <Stack direction='column' gap={5}>
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon/>
          </IconButton>
          {!iFrameLoaded && (
            <Alert severity="info">
              <AlertTitle sx={{overflow: 'hidden'}}>
                Loading module: {moduleVersionToView?.module?.name ?? ''}
                <CircularProgress aria-label='Content loading progress' size='1rem' sx={{ml: 2}}/>
              </AlertTitle>
            </Alert>
          )}
          {viewingComplete &&
            <Alert severity="success">Completed viewing module: {moduleVersionToView?.module?.name ?? ''}</Alert>
          }
          {moduleLaunchError && <Alert severity="error">Error: {moduleLaunchError}</Alert>}
        </Stack>
      }
      {!viewingComplete &&
        <Stack
          direction='column'
          spacing={0}
          sx={{
            height: '100%',
          }}
        >
          {preview && iFrameLoaded &&
            <Box
              sx={{
                display: 'flex',
                backgroundColor: 'darkgray',
                justifyContent: 'end',
              }}
            >
              <IconButton
                aria-label="close"
                size='small'
                onClick={onBeforeIFrameUnload}
              >
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          }
          <Cmi5ContentIframe
            moduleVersionUid={moduleVersionToView.uid}
            preview={preview}
            onIFrameLoaded={onIFrameLoaded}
            onBeforeIFrameUnload={onBeforeIFrameUnload}
            onModuleLaunchError={(err) => setModuleLaunchError(err)}
          />
        </Stack>
      }
    </div>
  )
}

Cmi5Viewer.propTypes = {
  moduleVersion: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    version: PropTypes.number,
    module: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  preview: PropTypes.bool,
  getNextModule: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  handleSelectedVersionedModuleChanged: PropTypes.func,
}

Cmi5Viewer.defaultProps = {
  preview: false,
  getNextModule: null,
}
