/**
 * @generated SignedSource<<3314066bc116fb1e42a0487e5339f6bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "userUid64b",
        "variableName": "uid"
      }
    ],
    "concreteType": "InvitedUserInfo",
    "kind": "LinkedField",
    "name": "invitedUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGroup",
            "kind": "LinkedField",
            "name": "groups",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "msg",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmInvitation_InvitedUserInfoQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConfirmInvitation_InvitedUserInfoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "23375794e82071fc529fc850c9970dfd",
    "id": null,
    "metadata": {},
    "name": "ConfirmInvitation_InvitedUserInfoQuery",
    "operationKind": "query",
    "text": "query ConfirmInvitation_InvitedUserInfoQuery(\n  $uid: String!\n  $token: String!\n) {\n  invitedUser(userUid64b: $uid, token: $token) {\n    user {\n      firstName\n      lastName\n      email\n      groups {\n        name\n      }\n    }\n    msg\n  }\n}\n"
  }
};
})();

node.hash = "816fe90b119b85e6bf926c23f390c1d7";

export default node;
