import {concat, upperFirst} from 'lodash';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {formatDate} from '@/utils/date';


const BASE_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.2,
    minWidth: 180,
    width: 200,
    renderCell: ({value}) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {value}
      </Typography>
    ),
  },
  {
    field: 'signedUp',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Signed Up
      </Typography>
    ),
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    minWidth: 90,
    width: 115,
    renderCell: ({ value }) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {value ? formatDate(value) : ''}
      </Typography>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 0.2,
    minWidth: 130,
    width: 140,
  },
  {
    field: 'modulesCompleted',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Completed Modules
      </Typography>
    ),
    minWidth: 100,
    width: 120,
    align: 'center',
  },
  {
    field: 'coursesCompleted',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Completed Courses
      </Typography>
    ),
    minWidth: 100,
    width: 120,
    align: 'center',
  },
  {
    field: 'accessPlan',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Access Plan Group
      </Typography>
    ),
    minWidth: 130,
    width: 130,
    sortable: false,
    renderCell: (params) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {params.value?.name ?? ''}
      </Typography>
    ),
  },
  {
    field: 'subscriptionType',
    renderHeader: (params) => (
      <Typography variant='subtitle2' sx={{whiteSpace: 'normal'}}>
        Subscription Type
      </Typography>
    ),
    minWidth: 130,
    width: 130,
    renderCell: (params) => (
      <Typography variant='body2' sx={{whiteSpace: 'normal'}}>
        {upperFirst(params.value)}
      </Typography>
    ),
  },
];

export const EmptyDataGrid = () => (
  <DataGrid
    disableColumnFilter
    disableColumnMenu
    disableRowSelectionOnClick
    rowHeight={70}
    rows={[{id: 0}]}
    columns={BASE_COLUMNS}
    loading={true}
    hideFooterPagination={true}
  />
)

const ActiveUsersTable = (props) => {
  const {
    rows,
    editable,
    paginationModel,
    handlePaginationModelChange,
    handleUserDelete,
    handleUserEdit,
    loading,
  } = props;

  const columns = React.useMemo(
    () => concat(
      BASE_COLUMNS,
      {
        field: 'actions',
        type: 'actions',
        minWidth: 30,
        getActions: (params) => {
          const actions = [];
          actions.push(
            <GridActionsCellItem
              icon={<Tooltip title="Edit" arrow><EditIcon/></Tooltip>}
              key='action_edit_user'
              label="Edit"
              onClick={handleUserEdit(params.id)}
            />
          )
          if (editable) {
            actions.push(
              <GridActionsCellItem
                icon={<Tooltip title="Remove" arrow><DeleteIcon/></Tooltip>}
                key='action_delete_user'
                label="Delete"
                onClick={handleUserDelete(params.id)}
              />
            )
          }
          return actions;
        }
      }
    ),
     [handleUserDelete, editable, handleUserEdit],
  );

  return (
    <>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        rowHeight={70}
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'name',
                sort: 'asc',
              },
            ],
          },
        }}
        loading={loading}
        sortingOrder={['asc', 'desc']}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 20]}
        sx={{
          minHeight: '60vh'
        }}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </>
  );
}

ActiveUsersTable.propTypes = {
  editable: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  paginationModel: PropTypes.object,
  handlePaginationModelChange: PropTypes.func.isRequired,
  handleUserDelete: PropTypes.func.isRequired,
  handleUserEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

ActiveUsersTable.defaultProps = {
  editable: true,
}

export default ActiveUsersTable;
