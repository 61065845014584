/**
 * @generated SignedSource<<87561b0c75212aa352ce183af9b62322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

import CourseProgressRefetchQuery_graphql from './CourseProgressRefetchQuery.graphql';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progressPercent",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": CourseProgressRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CourseDetails_courseProgress",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseVersionProgress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleVersionProgress",
          "kind": "LinkedField",
          "name": "modulesProgress",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ModuleVersion",
              "kind": "LinkedField",
              "name": "moduleVersion",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CourseVersion",
  "abstractKey": null
};
})();

node.hash = "734ca0a0557a6c5f64ab6b479d447fc2";

export default node;
