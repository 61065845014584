import React, {useEffect} from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import PropTypes from 'prop-types';

const VideosSectionsListQuery = graphql`
  query VideosSectionsListQuery {
    sections {
      ...SectionFragment_section
      chapters {
        id
        uid
        ...VideoChapterFragment_chapter
      }  
    }
  }
`;

const VideosSectionsList = (props) => {
  const {
    dataLoadedCb,
    renderSectionsList,
  } = props;

  const {sections} = useLazyLoadQuery(
    VideosSectionsListQuery,
    {},
    {fetchPolicy: 'network-only'},
  );

  useEffect(() => {
    if (sections) {
      dataLoadedCb();
    }
  }, [sections])

  return (
    renderSectionsList(sections)
  );
};

VideosSectionsList.propTypes = {
  dataLoadedCb: PropTypes.func.isRequired,
  renderSectionsList: PropTypes.func.isRequired,
}

export default VideosSectionsList;