import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchTerms from '@/components/reports/SearchTerms';


const REPORT_SEARCH_TERMS = 'Search Terms Report';

const Reports = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedReportType, setSelectedReportType] = React.useState(REPORT_SEARCH_TERMS);

  const handleReportTypeChange = React.useCallback(
    (evt, newValue) => {
      setSelectedReportType(newValue);
    },
    [],
  )

  return (
    <Box sx={{mt: '5px'}}>
      <Stack direction='column' spacing={2}>
        <Tabs
          value={selectedReportType}
          onChange={handleReportTypeChange}
          aria-label="report types"
        >
          <Tab label={REPORT_SEARCH_TERMS} value={REPORT_SEARCH_TERMS} disabled={isLoading}/>
        </Tabs>
        {selectedReportType === REPORT_SEARCH_TERMS &&
          <SearchTerms />
        }
      </Stack>
    </Box>
  )
}

export default Reports;
