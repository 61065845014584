import React from 'react';
import Questionnaire from './components/common/Questionnaire';
import PropTypes from 'prop-types';
import {isAdmin} from './utils/auth';

const ProfileQuestionsWrapper = (props) => {
  const {
    user,
    children
  } = props;

  const showQuestionnaire = (
    user?.uid
    && user?.allQuestionsAnswered === false
    && !isAdmin(user)
  )
  return (
    <>
      {
        showQuestionnaire
        ?
          <>
            <Questionnaire user={user}/>
            {children}
          </>
        :
        <>
          {children}
        </>
      }
    </>
  );
};

ProfileQuestionsWrapper.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProfileQuestionsWrapper;
