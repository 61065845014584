import React from 'react';
import PropTypes from 'prop-types';
import SearchCourseCard from './SearchCourseCard';
import Box from '@mui/material/Box';
import {moduleSearchCardsContainerSx} from '../../style/constants/search';
import CourseCardDataWrapper from '../common/data-wrappers/CourseCardDataWrapper';


const SearchCoursesList = (props) => {
  const { searchResults: versionedCourses } = props;

  const renderCourseCard = (
    {
      versionedCourseData,
      progressValue,
      onNameClick,
      onButtonClick,
    }
  ) => {

    return (
      <SearchCourseCard
        key={versionedCourseData.uid}
        versionedCourseData={versionedCourseData}
        progressValue={progressValue}
        onNameClick={onNameClick}
        onButtonClick={onButtonClick}
      />
    )
  }

  return (
    <Box
      sx={{
        ...moduleSearchCardsContainerSx,
        mb: 5,
      }}
    >
      {
        versionedCourses.map(versionedCourse => {
          return (
            <CourseCardDataWrapper
              key={versionedCourse.uid}
              versionedCourse={versionedCourse}
              renderChildren={renderCourseCard}
            />
          );
        })
      }
    </Box>
  );
};

SearchCoursesList.propTypes = {
  searchResults: PropTypes.array,
}

export default SearchCoursesList;