export const pageContentPaddingX = {
  xs: '15px',
  sm: '20px',
  md: '40px',
  lg: '80px',
}

export const pageContentPaddingY = {
  xs: '25px',
  sm: '30px',
  md: '40px',
  lg: '80px',
}

export const buttonHeight = {
  xs: '48px',
  md: '56px',
}

export const buttonFontSize = {
  xs: '14px',
  md: '16px',
}

export const sectionNameFont = {
  xs: '24px', md: '32px',
}

export const sectionHeaderMb = {
  xs: '24px', md: '32px',
}

export const sectionDescriptionFont = {
  xs: '14px', md: '16px',
}

export const sectionNameMb = {
  xs: '4px', md: '6px',
}

export const contentElementNameFont = {
  xs: '16px',
  md: '18px',
}

export const contentElementDescriptionFont = {
  xs: '14px',
  md: '16px',
}

export const scrollableXContainerPosition = {
  right: '30px',
}

export const featuredTopicsSx = {
  height: {xs: '143px', md: '207px'},
  width: {xs: '190px', md: '276px'},
}