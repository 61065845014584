import React from 'react';
import SearchEntityCard from './SearchEntityCard';
import PropTypes from 'prop-types';

const SearchVideoCard = (props) => {
  const {
    videoData,
    progressValue,
    progressBarVariant,
    isProgressLoading,
    onClick,
  } = props;

  return (
    <>
      <SearchEntityCard
        url={videoData.thumbnailUrl || videoData.module.coverImg.url}
        name={videoData.title}
        description={videoData.description}
        progressValue={progressValue}
        handleClick={onClick}
      />
    </>
  );
};

SearchVideoCard.propTypes = {
  videoData: PropTypes.object.isRequired,
  progressValue: PropTypes.number.isRequired,
  progressBarVariant: PropTypes.string.isRequired,
  isProgressLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SearchVideoCard;