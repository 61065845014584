import {useState} from 'react';
import {
  activeBorderStyle,
  dummyBorderStyle
} from '../../constants/dragAndDrop';

export const useDragAndDrop = (
  values,
  handleValuesChanged,
  refMap,
  passedDummyBorderStyle = dummyBorderStyle,
) => {

  const [draggingUid, setDraggingUid] = useState(null);

  const handleDragStart = (evt, uid) => {
    setDraggingUid(uid);
  }
  const handleDragEnd = (evt, uid) => {
    const hoveredElement = refMap.current[uid]
    hoveredElement.style.border = passedDummyBorderStyle;
    setDraggingUid(null);
  }

  const handleDrop = (evt, uid) => {
    evt.preventDefault();
    const hoveredElement = refMap.current[uid]
    hoveredElement.style.border = passedDummyBorderStyle;
    const draggingIndex = values.indexOf(draggingUid);
    const dropIndex = values.indexOf(uid);
    if (
      draggingIndex === -1 ||
      dropIndex === -1 ||
      uid === draggingUid
    ) {
      setDraggingUid(null);
      return
    }
    const newValues = [...values];
    newValues[draggingIndex] = uid;
    newValues[dropIndex] = draggingUid;
    handleValuesChanged(newValues);
    setDraggingUid(null);
  }
  const handleDragOver = (evt, uid) => {
    evt.preventDefault();
    const draggingIndex = values.indexOf(draggingUid);
    const dropIndex = values.indexOf(uid);
    if (
      draggingIndex === -1 ||
      dropIndex === -1
    ) return;

    const hoveredElement = refMap.current[uid];
    hoveredElement.style.border = activeBorderStyle;
  }
  const handleDragLeave = (evt, uid) => {
    const hoveredElement = refMap.current[uid];
    hoveredElement.style.border = passedDummyBorderStyle;
  }

  return [
    handleDragStart,
    handleDragEnd,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  ]
}