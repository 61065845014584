import {GROUP_ADMINS, GROUP_INSTRUCTORS} from '../user-profile/constants';

export const PAGES = {
  REPORTS:  'reports',
  MANAGE_USERS: 'users',
  MANAGE_COURSES: 'courses',
  MANAGE_MODULES: 'modules',
  MANAGE_LANDING_PAGE: 'landing-page',
  MANAGE_SELF_GUIDED_MODULES: 'self-guided-manager',
  MANAGE_TAGS: 'tags',
  MANAGE_INSTITUTIONS: 'institutions',
}

export const PAGE_INFO = {
  [PAGES.REPORTS]: {
    title:  'Reports',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
  [PAGES.MANAGE_USERS]: {
    title: 'Manage Users',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
  [PAGES.MANAGE_COURSES]: {
    title: 'Manage Courses',
    enabled: true,
    allowedGroups: [GROUP_ADMINS, GROUP_INSTRUCTORS],
  },
  [PAGES.MANAGE_MODULES]: {
    title: 'Manage Modules',
    enabled: true,
    allowedGroups: [GROUP_ADMINS, GROUP_INSTRUCTORS],
  },
  [PAGES.MANAGE_LANDING_PAGE]: {
    title: 'Manage Home Page',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
  [PAGES.MANAGE_SELF_GUIDED_MODULES]: {
    title: 'Manage Self-Guided Learning Page',
    menuItemTitle: 'Manage Self-Guided Page',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
  [PAGES.MANAGE_TAGS]: {
    title: 'Manage Tags',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
  [PAGES.MANAGE_INSTITUTIONS]: {
    title: 'Manage Institutions',
    enabled: true,
    allowedGroups: [GROUP_ADMINS],
  },
}

export const DEFAULT_PAGE= PAGES.MANAGE_MODULES;
