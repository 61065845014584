/**
 * @generated SignedSource<<0567ef60fbd611cdfe6defd07692b620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "requestPasswordReset",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestPasswordResetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestPasswordResetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8750f8f7ee103d98457790701f1ce65",
    "id": null,
    "metadata": {},
    "name": "RequestPasswordResetMutation",
    "operationKind": "mutation",
    "text": "mutation RequestPasswordResetMutation(\n  $email: String!\n) {\n  requestPasswordReset(email: $email)\n}\n"
  }
};
})();

node.hash = "1bdcb387e280a269c7daf6ba729526a8";

export default node;
