/**
 * @generated SignedSource<<ae13e45dfb244b2f5ea55cc1c0057b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filters",
  "value": {
    "accessPlans": {
      "site": {
        "current": true
      }
    }
  }
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalFileName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ModuleEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inSelfStudy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Chapter",
            "kind": "LinkedField",
            "name": "defaultChapter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Section",
                "kind": "LinkedField",
                "name": "section",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadedFile",
            "kind": "LinkedField",
            "name": "coverImg",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImgDesc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedTs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleVersion",
            "kind": "LinkedField",
            "name": "versions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CMI5Package",
                "kind": "LinkedField",
                "name": "cmi5",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadedFile",
                    "kind": "LinkedField",
                    "name": "zipPath",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessPlan",
            "kind": "LinkedField",
            "name": "accessPlans",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModulesTableQuery",
    "selections": [
      {
        "alias": "modules",
        "args": [
          (v0/*: any*/)
        ],
        "concreteType": "ModuleConnection",
        "kind": "LinkedField",
        "name": "__ModulesTable_modules_connection",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": "__ModulesTable_modules_connection(filters:{\"accessPlans\":{\"site\":{\"current\":true}}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModulesTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "ModuleConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": "modules(filters:{\"accessPlans\":{\"site\":{\"current\":true}}},first:100)"
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "ModulesTable_modules",
        "kind": "LinkedHandle",
        "name": "modules"
      }
    ]
  },
  "params": {
    "cacheID": "7f8064992010f96562e052c86eaa929f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "modules"
          ]
        }
      ]
    },
    "name": "ModulesTableQuery",
    "operationKind": "query",
    "text": "query ModulesTableQuery {\n  modules(first: 100, filters: {accessPlans: {site: {current: true}}}) {\n    edges {\n      node {\n        id\n        uid\n        name\n        description\n        inSelfStudy\n        defaultChapter {\n          uid\n          name\n          section {\n            uid\n            name\n          }\n        }\n        coverImg {\n          originalFileName\n          name\n          url\n          size\n        }\n        coverImgDesc\n        lastUpdatedTs\n        versions {\n          uid\n          version\n          status\n          author {\n            uid\n            firstName\n            lastName\n            email\n          }\n          cmi5 {\n            uid\n            status\n            zipPath {\n              originalFileName\n              name\n              size\n            }\n          }\n          module {\n            uid\n            name\n          }\n        }\n        accessPlans {\n          uid\n          name\n        }\n        tags {\n          id\n          text\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "89aa8d81af9eef43147717d998774545";

export default node;
