import {debounce} from 'lodash';
import React from 'react';
import {useQueryLoader} from 'react-relay';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {GROUP_ADMINS, GROUP_PENDING_USERS, GROUP_LEARNERS} from '../user-profile/constants';
import LoadingSpinner from '../../LoadingSpinner';
import UserInvitationDialog from '../user-profile/UserInvitationDialog';
import UserReportDownloadDialog from '../user-profile/UserReportDownloadDialog';
import UsersTable, {UsersQuery} from '../user-profile/UsersTable';
import {EmptyDataGrid} from '../user-profile/ActiveUsersTable';
import {DisplayMessageContext} from '@/DisplayMessageProvider';


const ManageUsers = (props) => {
  const displayMessage = React.useContext(DisplayMessageContext);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = React.useState(false);
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [selectedUserType, setSelectedUserType] = React.useState(GROUP_LEARNERS);
  const [queryReference, loadQuery] = useQueryLoader(UsersQuery);
  const extraButtonsContainerRef = React.useRef(null);

  React.useEffect(() => {
    loadQuery({});
  }, []);

  const handleUserTypeChange = React.useCallback(
    (evt, newValue) => {
      setSelectedUserType(newValue);
    },
    [],
  )
  const handleInvitationDialogClosed = React.useCallback(
    (msg) => {
      setIsInviteDialogOpen(false);
    },
    [],
  );

  const onSearchTextChanged = React.useCallback(
    debounce(
      (evt) => {
        const val = evt.target.value;
        setSearchText(val);
      },
      300,
      {trailing: true}
    ),
    []
  )

  return (
    <Box sx={{mt: '5px'}}>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <TextField
            id='outlined-basic'
            disabled={isLoading}
            label='Search'
            variant='outlined'
            size='small'
            placeholder='Search name or email'
            onChange={onSearchTextChanged}
          />
          <Stack direction='row' spacing={2} justifyContent='flex-end'>
            <Box ref={extraButtonsContainerRef}>
              {selectedUserType !== GROUP_PENDING_USERS &&
                <Button
                  aria-label='Download CSV'
                  disabled={isLoading}
                  variant='outlined'
                  size='small'
                  sx={{height: '100%'}}
                  onClick={() => setIsDownloadDialogOpen(true)}
                >
                  DOWNLOAD CSV
                </Button>
              }
            </Box>
            <Button
              aria-label='Invite user'
              disabled={isLoading}
              variant='contained'
              size='small'
              onClick={() => {setIsInviteDialogOpen(true)}}
            >
              INVITE USER
            </Button>
          </Stack>
        </Stack>
        <Tabs
          value={selectedUserType}
          onChange={handleUserTypeChange}
          aria-label="user types"
        >
          <Tab label="Learners" value={GROUP_LEARNERS} disabled={isLoading} />
          <Tab label="Admins" value={GROUP_ADMINS} disabled={isLoading} />
          <Tab label="Pending Users" value={GROUP_PENDING_USERS} disabled={isLoading} />
        </Tabs>
        <React.Suspense fallback={<EmptyDataGrid />}>
          {queryReference !== null
            ?
              <UsersTable
                extraButtonsContainerRef={extraButtonsContainerRef}
                searchText={searchText}
                userType={selectedUserType}
                queryReference={queryReference}
                setIsDataLoading={setIsLoading}
              />
            : null
          }
        </React.Suspense>
      </Stack>
      {isInviteDialogOpen &&
        <React.Suspense fallback={<LoadingSpinner />}>
          <UserInvitationDialog
            isOpen={isInviteDialogOpen}
            displayMessage={displayMessage}
            onClose={handleInvitationDialogClosed}
          />
        </React.Suspense>
      }
      {isDownloadDialogOpen &&
        <UserReportDownloadDialog
          isOpen={isDownloadDialogOpen}
          onClose={() => setIsDownloadDialogOpen(false)}
        />
      }
    </Box>

  )
}

export default ManageUsers;
