/**
 * @generated SignedSource<<9ca62441492dba0f58306963ee89df75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountMenuLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountMenuLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "418526c5901f7fecc4fa4cec1e296335",
    "id": null,
    "metadata": {},
    "name": "AccountMenuLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation AccountMenuLogoutMutation {\n  logout\n}\n"
  }
};
})();

node.hash = "e9c66f8d9d9050df379fafbe7c2d4295";

export default node;
