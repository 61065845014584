export const moduleSearchCardsContainerSx = {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridTemplateRows: 'auto',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '20px',
}

export const moduleSearchCardSx = {
  gridColumn: {
    xs: 'span 12',
    lt: 'span 6',
    md: 'span 4',
    lg: 'span 3',
    xl: 'span 2',
    xxl: 'span 2',
    giant: 'span 1',
  },
}