import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import {CURR_SITE, SITE_STANFORD} from '@/constants';
import PropTypes from 'prop-types';

const site = CURR_SITE.name;
const backgroundColor = site === SITE_STANFORD
  ? '#4C6F8D'
  : '#0A4A63';

const UserAvatar = (props) => {
  const {
    user,
    iconProps,
    onClick,
  } = props;
  const userInitials = React.useMemo(() => {
    const userInfo = user || {};
    const firstName = userInfo?.firstName || '';
    const lastName = userInfo?.lastName || '';
    let initials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
    if (!initials) {
      initials = (userInfo?.email || 'X')[0];
    }
    return initials.toUpperCase();
  }, [user]);

  return (
    !user
      ?
        <Skeleton variant='circular'>
          <Avatar />
        </Skeleton>
      :
        <IconButton
          disableFocusRipple
          disableRipple
          edge='end'
          aria-label='account of current user'
          aria-haspopup='true'
          sx={{
            ...iconProps,
            p: 0,
            m: 0,
          }}
          onClick={onClick}
        >
          <Avatar
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: backgroundColor,
            }}
          >
            {userInitials}
          </Avatar>
        </IconButton>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  iconProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

UserAvatar.defaultProps = {
  iconProps: {},
}

export default UserAvatar;
