import {isEmpty, isArray, join} from 'lodash';
import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {MenuItem, Select} from '@mui/material';
import {MULTIPLE_CHOICE, SINGLE_CHOICE} from './constants';
import ReadonlyTextfield from '../common/ReadonlyTextfield';
import PropTypes from 'prop-types';


const NBSP = '\u00A0'; // unicode value to use in place of "&nbsp;"

const getUserSelectedOptionUids = (question, answer) => {
  if (question.questionType === SINGLE_CHOICE) {
    return answer.chosenOption?.uid;
  } else if (question.questionType === MULTIPLE_CHOICE) {
    return answer.chosenOptions.map((chosenOption) => chosenOption.uid)
  }
}

const getQuestionDefaultOptionUids = (question) => {
  if (question.questionType === SINGLE_CHOICE) {
    const defaultOption =  question.options.find((option) => option.isDefault);
    return defaultOption?.uid;
  } else if (question.questionType === MULTIPLE_CHOICE) {
    const defaultOptions =  question.options.filter((option) => option.isDefault);
    return defaultOptions.map((option) => option.uid);
  }
};

const getQuestionSelectValue = (question, answers) => {
  const answer = answers.find((ans) => ans?.question?.uid === question.uid);
  if (answer) {
    return getUserSelectedOptionUids(question, answer);
  }
  return getQuestionDefaultOptionUids(question);
}

const getQuestionSelectedOptions = (question, selectedAnswerValues) => {
  const answersArray = isArray(selectedAnswerValues) ? selectedAnswerValues : [selectedAnswerValues]
  return question.options.filter(
    (opt) => answersArray.find((ansUid) => ansUid === opt.uid)
  );
}

const getQuestionSelectedOptionText = (question, selectedAnswerValues) => {
  const selectedOptions = getQuestionSelectedOptions(question, selectedAnswerValues);
  return join(
    selectedOptions.map((opt) => opt.optionText),
    ', '
  );
}

const getQuestionCustomAnswer = (question, answers) => {
  if (question.questionType === SINGLE_CHOICE) {
    const answer = answers.find((ans) => ans?.question.uid === question.uid);
    if (answer) {
      return answer.customAnswer;
    }
  }
  return null;
}

export const QuestionnairePanel = (props) => {
  const {
    compact,
    disabled,
    formik,
    questions,
  } = props;
  const controlsSize = compact ? 'small' : undefined;
  const questionTextVariant = compact ? 'subtitle2' : undefined;
  const userAnswers = formik.values.answers;

  const onAnswerChange = React.useCallback(
    (event, question, questionIdx, isCustomAnswer) => {
      const answers = [...formik.values.answers];
      let selectedValues = event.target.value;
      if (!isArray(selectedValues)) {
        selectedValues = [event.target.value];
      }
      const customAnswerSelected = question.options.filter(
        (option) => selectedValues.find((val) => val === option.uid)
      ).find((option) => option?.allowCustomAnswer) !== undefined;
      let fieldToUpdate = {};
      if (question.questionType === MULTIPLE_CHOICE) {
        fieldToUpdate = {
          chosenOptions: event.target.value.map((value) => ({uid: value}))
        }
      }
      else if (isCustomAnswer) {
        fieldToUpdate = {
          customAnswer: event.target.value
        };
      }
      else {
        fieldToUpdate = {
          chosenOption: {
            uid: event.target.value,
            allowCustomAnswer: customAnswerSelected,
          }
        }
      }
      if (!isEmpty(answers[questionIdx])) {
        answers[questionIdx] = {
          ...answers[questionIdx],
          ...fieldToUpdate,
        };
      }
      else {
        answers[questionIdx] = {
          question: {uid: question.uid},
          ...fieldToUpdate,
          customAnswer: null,
        }
      }
      formik.setFieldValue('answers', answers);
    },
    [formik],
  )

  return (
    <Stack direction='column' spacing={2}>
      {
        questions.map((question, questionIdx) => {
          const multiple = question.questionType === MULTIPLE_CHOICE;
          const selectedValues = getQuestionSelectValue(question, userAnswers);
          const selectedOptions = getQuestionSelectedOptions(question, selectedValues);
          const showCustomAnswer = selectedOptions.length === 1 && selectedOptions[0].allowCustomAnswer;
          return (
            <Box
              key={question.uid}
            >
              <Typography
                variant={questionTextVariant}
                sx={{
                  mb: '5px',
                }}
              >
                {question.questionText}
              </Typography>
              {disabled
                ?
                  <ReadonlyTextfield
                    key={question.uid}
                    text={getQuestionSelectedOptionText(question, selectedValues) || NBSP}
                  />
                :
                  <Select
                    disabled={disabled}
                    id={question.uid}
                    key={question.uid}
                    fullWidth
                    multiple={multiple}
                    name={`answers[${questionIdx}].chosenOption`}
                    size={controlsSize}
                    value={selectedValues || ''}
                    renderValue={(selected) => getQuestionSelectedOptionText(question, selected)}
                    onChange={(event) => {
                      onAnswerChange(event, question, questionIdx);
                    }}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {
                      question.options.map((option) => {
                        let isChecked = false;
                        if (multiple) {
                          isChecked = selectedValues.find(
                            (item) => item === option.uid
                          ) !== undefined;
                        }
                        return (
                          <MenuItem
                            key={option.uid}
                            value={option.uid}
                          >
                            {multiple &&
                              <Checkbox checked={isChecked} />
                            }
                            <ListItemText primary={option.optionText} />
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
              }
              {showCustomAnswer && (
                disabled
                  ?
                    <ReadonlyTextfield
                      key={`${question.uid}_custom_answer_ro`}
                      text={getQuestionCustomAnswer(question, userAnswers) || NBSP}
                    />
                  :
                    <TextField
                      key={`${question.uid}_custom_answer`}
                      fullWidth
                      size={controlsSize}
                      value={getQuestionCustomAnswer(question, userAnswers) || ''}
                      name={`answers[${questionIdx}].customAnswer`}
                      error={
                        formik.touched?.answers?.[questionIdx]?.customAnswer &&
                        Boolean(formik.errors?.answers?.[questionIdx]?.customAnswer)
                      }
                      helperText={(
                          formik.touched?.answers?.[questionIdx]?.customAnswer &&
                          formik.errors?.answers?.[questionIdx]?.customAnswer
                        ) ?? ' '
                      }
                      sx={{
                        mt: "3px",
                      }}
                      onChange={(evt) => {
                        onAnswerChange(evt, question, questionIdx, true);
                      }}
                      onBlur={formik.handleBlur}
                    />
              )}
            </Box>
          );
        })
      }
    </Stack>
  )
}

QuestionnairePanel.propTypes = {
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  formik: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      questionText: PropTypes.string.isRequired,
      questionType: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string.isRequired,
          optionText: PropTypes.string.isRequired,
          isDefault: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

QuestionnairePanel.defaultProps = {
  compact: false,
  disabled: false,
}
