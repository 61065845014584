import React from 'react';
import {graphql, useMutation} from 'react-relay';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";


const ResendInvitationsMutation = graphql`
  mutation ResendInvitationsDialogMutation(
    $inviteeUids: [Uid!]!,
  ) {
      resendUserInvitations(
         inviteeUids: $inviteeUids,
      ) {
            status
            msg
        }
  }
`;


export default function ResendInvitationsDialog(props) {
  const {
    users,
    isOpen,
    displayMessage,
    onClose
  } = props;

  const [error, setError] = React.useState(null);
  const [commitMutation, isMutationInFlight] = useMutation(ResendInvitationsMutation);

  const onCloseDialog = (confirm) => {
    if (confirm === true) {
      const variables = {inviteeUids: users};
      commitMutation({
        variables,
        onCompleted: (data) => {
          const {status, msg} = data.resendUserInvitations;
          console.log('ResendInvitations::CommitMutation::onCompleted: data=', data);
          const success = status === true;
          displayMessage(
            success ? {info: msg} : {error: msg}
          );
          if (status) {
            onClose(true);
          }
        },
        onError: (err) => {
          console.error("Error performing mutation resendUserInvitations:", err);
          setError('Error resending user invitations. Please try again later.');
        },
      })
    }
    else {
      onClose();
    }
  }
  return (
    <>
    {isOpen &&
      <Dialog
        open={isOpen}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Resend Invitations
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-resend-invites-dialog-description">
            {`Are you sure you want to resend invites to ${users.length ?? ''} user(s)?`}
          </DialogContentText>

          {error && (
            <Typography color='error' variant='body2' sx={{mt: 2}}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Resend'
            disabled={isMutationInFlight}
            variant='contained'
            onClick={() => onCloseDialog(true)}
          >
            Resend
          </Button>
          <Button
            aria-label='Cancel'
            onClick={onCloseDialog}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    }
    </>
  )
}

ResendInvitationsDialog.propTypes = {
  users: PropTypes.array,
  isOpen: PropTypes.bool,
  displayMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

ResendInvitationsDialog.defaultProps = {
  isOpen: false,
}
