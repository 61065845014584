import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CourseList from './CourseList';
import FeaturedCourses from './FeaturedCourses';
import FeaturedTopics from './FeaturedTopics';
import {FEATURED_TOPIC_HDR} from '../../constants';

const TAB_FEAT_COURSES = 0;
const TAB_COURSE_LIST = 1;
const TAB_FEAT_TOPICS = 2;

const LandingPageManager = (props) => {
  const [activeTab, setActiveTab] = React.useState(TAB_FEAT_TOPICS);

  const handleTabChange = React.useCallback(
    (evt, newValue) => {
      setActiveTab(newValue);
    },
    [],
  )

  return (
    <Box sx={{mt: '5px'}}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Landing page manager tabs"
      >
        <Tab
          id='tab-course-list'
          label="Course List"
          value={TAB_COURSE_LIST}
          aria-controls='panel-course-list'
        />
        <Tab
          id='tab-featured-topics'
          label={`${FEATURED_TOPIC_HDR}s`}
          value={TAB_FEAT_TOPICS}
          aria-controls='panel-featured-topics'
        />
        <Tab
          id='tab-featured-courses'
          label="Featured Courses"
          value={TAB_FEAT_COURSES}
          aria-controls='panel-featured-courses'
        />
      </Tabs>
      <Box sx={{mt: 3}}>
        {activeTab === TAB_FEAT_TOPICS &&
          <FeaturedTopics />
        }
        {activeTab === TAB_COURSE_LIST &&
          <CourseList />
        }
        {activeTab === TAB_FEAT_COURSES &&
          <FeaturedCourses />
        }
      </Box>
    </Box>
  )
}

export default LandingPageManager;
