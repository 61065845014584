/**
 * @generated SignedSource<<aca1e0b0535bb27f069dd8b2b8ed1567>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeaturedTopic",
    "kind": "LinkedField",
    "name": "featuredTopics",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "synopsis",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "modules",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedTopicsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeaturedTopicsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0e5f3f1171079b7daba0170101b03314",
    "id": null,
    "metadata": {},
    "name": "FeaturedTopicsQuery",
    "operationKind": "query",
    "text": "query FeaturedTopicsQuery {\n  featuredTopics {\n    uid\n    name\n    synopsis\n    modules {\n      uid\n      name\n    }\n  }\n}\n"
  }
};
})();

node.hash = "0def17cab697284decee3c18c1ebd6d9";

export default node;
