import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const FeaturedCourses = (props) => {
  return (
    <Box
      id='tabpanel-featured-courses'
      role='tabpanel'
      aria-labelledby='tab-featured-courses'
      sx={{ p: 3 }}
    >
      <Alert severity="info">
        Coming soon!
      </Alert>
    </Box>
  )
}

export default FeaturedCourses;
