import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';


export default function Cmi5ViewingComplete(props) {
  const {
    hasNextModule,
    handleSelection
  } = props;

  const onNextModule = () => {
    handleSelection('next');
  }
  const onCloseModule = () => {
    handleSelection('close');
  }
  const onRestartModule = () => {
    handleSelection('restart');
  }

  const btnWidth = {
    lg: '20%',
    md: '30%',
    sm: '50%',
    xs: '70%',
  }
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%'}}>
      <IconButton
        aria-label="close"
        onClick={onCloseModule}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        gap={3}
        sx={{
          width: '100%',
          alignSelf: 'center'
        }}
      >
        <Typography
          variant='h5'
          gutterBottom
          sx={{
            textAlign: 'center',
          }}
        >
          Where would you like to go now?
        </Typography>
        <Button
          aria-label='Go to next module'
          variant='contained'
          onClick={onNextModule}
          disabled={!hasNextModule}
          sx={{width: btnWidth}}
        >
          GO TO NEXT MODULE <ArrowForwardIcon/>
        </Button>
        <Button
          aria-label='Close module'
          variant='outlined'
          onClick={onCloseModule}
          sx={{width: btnWidth}}
        >
          CLOSE MODULE
        </Button>
        <Button
          aria-label='Restart this module'
          variant='outlined'
          onClick={onRestartModule}
          sx={{width: btnWidth}}
        >
          RESTART THIS MODULE
        </Button>
      </Stack>
    </Box>
  )
}

Cmi5ViewingComplete.propTypes = {
  hasNextModule: PropTypes.bool,
  handleSelection: PropTypes.func.isRequired
}

Cmi5ViewingComplete.defaultProps = {
  hasNextModule: true,
}
