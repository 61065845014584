import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import NavBar from '@/components/common/navbar/NavBar';
import Box from '@mui/material/Box';
import {theme} from '@/themes/userUi';
import {ThemeProvider} from '@mui/material/styles';
import SectionsTabs from '../components/content/SectionsTabs';
import SectionContentCards from '../components/content/SectionContentCards';
import Welcome from '../components/homepage/Welcome';
import SelfGuidedDescription from '../components/homepage/SelfGuidedDescription';
import SectionDescription from '../components/homepage/SectionDescription';
import Courses from '../components/homepage/Courses';
import FullAccessVideoBanner from '../components/homepage/FullAccessVideoBanner';
import FeaturedTopics from '../components/homepage/FeaturedTopics';
import Footer from '../components/common/Footer';
import {pageContentPaddingX, pageContentPaddingY} from '@/style/constants/homepage';
import {COURSE_SEARCH_TYPE, CURR_SITE, SITE_STANFORD} from '@/constants';
import SearchContainer from '../components/homepage/SearchContainer';
import CoursesSkeleton from '../components/skeletons/homepage/CoursesSkeleton';
import {ErrorBoundary} from '@/ErrorBoundary';

const site = CURR_SITE.name;
const backgroundImage = site === SITE_STANFORD
  ? ''
  : 'url(/static/ns/img/sections_background.svg)';

const Homepage = () => {
  const [sectionValue, setSectionValue] = useState(0);
  const navigate = useNavigate();

  const handleSectionTabChanged = useCallback(
    (value) => {
      setSectionValue(value)
    }, [],
  );

  const [filterText, setFilterText] = useState('')

  const changeFilterText = (value) => {
    setFilterText(value);
  };

  useEffect(() => {
    if (!!filterText) {
      navigate(`/search?query=${filterText}&type=${COURSE_SEARCH_TYPE}`);
    }
  }, [filterText, navigate])

  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar/>
        <ErrorBoundary>
          <Welcome/>
          <SearchContainer
            filterText={filterText}
            updateFilterText={changeFilterText}
          />

          {/* Self-guided part */}
          <Box sx={{
            py: {...pageContentPaddingY},
            px: {...pageContentPaddingX},
            backgroundColor: 'background.accent',
            backgroundImage: backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
          }}>
            <SelfGuidedDescription/>
            <Box sx={{
              mb: {xs: '21px'},
            }}>
              <SectionsTabs
                sectionValue={sectionValue}
                handleSectionTabChanged={handleSectionTabChanged}
                getSectionRank={(section) => section.frontendId}
              />
            </Box>
            <Box sx={{
              mb: '40px',
            }}>
              <SectionContentCards
                sectionValue={sectionValue}
                handleSectionTabChanged={handleSectionTabChanged}
              />
            </Box>
            <SectionDescription
              selectedSection={sectionValue}
            />
          </Box>

          {/* Courses */}
          <React.Suspense fallback={<CoursesSkeleton/>}>
            <Courses/>
          </React.Suspense>

          {/* The full Video Access Banner */}
          <FullAccessVideoBanner/>

          {/* Topics */}
          <FeaturedTopics/>
        </ErrorBoundary>
        <Footer/>
      </ThemeProvider>
    </>
  );
};

export default Homepage;