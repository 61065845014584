import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay';
import PropTypes from 'prop-types';

const  QuestionsQuery = graphql`
  query QuestionsContextQuestionsQuery {
    profileQuestions {
      uid
      questionText
      questionType
      options {
        uid
        optionText
        isDefault
        allowCustomAnswer
      }
    }
  }
`;


export const QuestionsContext = React.createContext([]);

export const QuestionsProvider = ({children}) => {
  const data = useLazyLoadQuery(QuestionsQuery, {});
  return (
    <QuestionsContext.Provider value={data.profileQuestions}>
      {children}
    </QuestionsContext.Provider>
  )
}

QuestionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}