import {pick} from 'lodash';
import React from 'react';
import AccessPlanSelect from '../common/AccessPlanSelect';
import UniversitySelect from '../common/UniversitySelect';
import Checkbox from "@mui/material/Checkbox";
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import TextFieldWithLengthDisplay from '../content/TextFieldWithLengthDisplay';
import {DURATION_UNITS} from "./constants";


export default function SingleUserInvitationPanel(props) {
  const {
    formik,
  } = props;
  const [subscriptionOption, setSubscriptionOption] = React.useState(null);

  const onIsAdminCheckboxChange = (evt) => {
    const promise = formik.setFieldValue('isAdmin', evt.target.checked);
    if (evt.target.checked) {
      promise.then((data) => {
        formik.setFieldValue('accessPlan', null)
        formik.setFieldError('accessPlan', undefined)
      })
    }
  }
  return (
    <Stack direction='column' gap={2} alignContent='start'>
      <TextFieldWithLengthDisplay
        maxLength={50}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={(formik.touched.firstName && formik.errors.firstName) ?? ' '}
        fullWidth
        id="firstName"
        label="First Name"
        margin="dense"
        variant="outlined"
        size='small'
        {...formik.getFieldProps('firstName')}
      />
      <TextFieldWithLengthDisplay
        maxLength={50}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={(formik.touched.lastName && formik.errors.lastName) ?? ' '}
        fullWidth
        id="lastName"
        label="Last Name"
        margin="dense"
        variant="outlined"
        size='small'
        {...formik.getFieldProps('lastName')}
      />
      <TextFieldWithLengthDisplay
        maxLength={100}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={(formik.touched.email && formik.errors.email) ?? ' '}
        fullWidth
        id="email"
        label="Email"
        margin="dense"
        variant="outlined"
        size='small'
        {...formik.getFieldProps('email')}
      />
      <AccessPlanSelect
        name='accessPlan'
        value={formik.values.accessPlan}
        label="Access Plan Group"
        disabled={formik.values.isAdmin}
        disableClearable
        error={formik.touched.accessPlan && Boolean(formik.errors.accessPlan)}
        helperText={(formik.touched.accessPlan && formik.errors.accessPlan) ?? ' '}
        onChange={(event, newValue) => {
          formik.setFieldValue('accessPlan', pick(newValue, ['uid', 'name']));
        }}
        onBlur={(evt) => formik.setFieldTouched('accessPlan', true, false)}
      />
      <UniversitySelect
        name='university.name'
        value={formik.values.university}
        label="Institution"
        disableClearable
        error={formik.touched.university && Boolean(formik.errors.university)}
        helperText={(formik.touched.university && formik.errors.university) ?? ' '}
        onChange={(event, newValue) => {
          formik.setFieldValue('university', newValue);
        }}
        onBlur={(evt) => {
          formik.setFieldTouched('university', true, true)
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.isAdmin}
              onChange={onIsAdminCheckboxChange}
            />
          }
          label='Admin Access'
        />
      </FormGroup>
      <Divider variant="middle" />
      <Typography variant="subtitle1" gutterBottom>Subscription</Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="subscription-type-radio-buttons"
          name="subscription-type-radio-buttons-group"
          sx={{justifyContent: 'space-around'}}
          value={subscriptionOption}
          onChange={(evt) => {
            setSubscriptionOption(evt.target.value);
            formik.setFieldValue( 'autoRenew', evt.target.value === 'yearly');
          }}
        >
          <FormControlLabel value='yearly' control={<Radio />} label='Auto-renew yearly' />
          <FormControlLabel value='duration' control={<Radio />} label='Limit Duration' />
        </RadioGroup>
      </FormControl>
      {subscriptionOption === 'duration' && (
        <FormGroup row sx={{justifyContent: 'end'}}>
          <TextField
            label='Duration'
            size='small'
            error={formik.touched.subscriptionDuration?.duration && Boolean(formik.errors.subscriptionDuration?.duration)}
            helperText={(formik.touched.subscriptionDuration?.duration && formik.errors.subscriptionDuration?.duration) ?? ' '}
            {...formik.getFieldProps('subscriptionDuration.duration')}
            sx={{flexGrow: 1}}
          />
          <TextField
            select
            size='small'
            {...formik.getFieldProps('subscriptionDuration.unit')}
            sx={{width: 110}}
          >
            {DURATION_UNITS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
      )}
    </Stack>
  );
}

SingleUserInvitationPanel.propTypes = {
  formik: PropTypes.object.isRequired,
}
