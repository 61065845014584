import {debounce} from 'lodash';
import React, {useCallback} from 'react';
import {graphql, useMutation} from 'react-relay';
import {Modal} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Player from '@vimeo/player';
import PropTypes from 'prop-types';


const ProgressUpdateMutation = graphql`
  mutation VideoModal_ProgressUpdateMutation(
    $videoUid: Uid!,
    $progress: Int!,
  ) {
    updateVideoProgress(
      data: {
        videoUid: $videoUid,
        progress: $progress,
      }
    )
  }
`;

const VideoModal = (props) => {
  const {
    open,
    handleModalClose,
    selectedVideo,
    handleSelectVideoChanged,
    nextVideo,
    handleNextVideoChanged,
    previousVideo,
    handlePreviousVideoChanged,
  } = props;

  const iframeRef = React.useRef();
  const [progressMutation, mutationInProgress] = useMutation(ProgressUpdateMutation)
  const [videoDuration, setVideoDuration] = React.useState(null);
  const [title, setTitle] = React.useState(selectedVideo?.title ?? '');

  React.useEffect(() => {
    setTitle(selectedVideo?.title ?? '');
  }, [selectedVideo, setTitle])

  const debouncedTimeUpdate = useCallback(
    debounce(
      (evtData) => {
        const variables = {
          videoUid: selectedVideo?.uid,
          progress: Math.floor(evtData.percent * 100),
        }
        progressMutation({
          variables,
          onCompleted: (data) => {
            console.log('In progressMutation::onCompleted. data=', JSON.stringify(data));
          },
          onError: (err) => {
            console.error("Error performing mutation progressMutation:", err);
          }
        })
      },
      3000,
      {leading: true, trailing: true, maxWait: 30000}
    ), [selectedVideo])

  React.useEffect(() => {
    return () => {
      debouncedTimeUpdate.flush();
    }
  }, [selectedVideo, open])

  const handleIFrameLoaded = useCallback(() => {
    const player = new Player(iframeRef.current);
    player.getDuration().then((duration) => {
      // `duration` indicates the duration of the video in seconds
      setVideoDuration(duration);
      const currTime = (selectedVideo?.progress ?? 0) * duration / 100;
      player.setCurrentTime(currTime).then((seconds) => {
        // `seconds` indicates the actual time that the player seeks to
      }).catch(function (error) {
        console.error('Error setting current video time: ', error);
      });
    });
    player.getVideoTitle().then(videoTitle => {
      setTitle(videoTitle)
    });
    player.on('timeupdate', debouncedTimeUpdate);
  }, [selectedVideo]);

  const formatDuration = (duration) => {
    if (!duration) return '';
    let s = '';
    if (duration > 3600) {
      const hours = Math.floor(duration / 3600);
      duration = duration % 3600;
      s = hours.toString().padStart(2, '0') + ':';
    }
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    s += minutes.toString().padStart(2, '0') + ':';
    s += seconds.toString().padStart(2, '0');
    return s;
  }

  const onNextButtonClick = () => {
    setTitle('');
    handleSelectVideoChanged(nextVideo);
    handleNextVideoChanged(null);
    handlePreviousVideoChanged(null);
  };

  const onPreviousButtonClick = () => {
    setTitle('');
    handleSelectVideoChanged(previousVideo);
    handleNextVideoChanged(null);
    handlePreviousVideoChanged(null);
  };

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={handleModalClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box sx={{
        position: 'absolute',
        borderRadius: '5px',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -20%)',
        bgcolor: 'background.paper',
        minHeight: '400px',
        height: {
          xs: '80vh', md: '90vh',
        },
        width: {
          xs: '90vw',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        px: {xs: '25px', sm: '35px', md: '64px'},
        py: {xs: '20px', md: '20px'},
        boxSizing: 'border-box',
      }}>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 101,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{
          width: {
            xs: '100%',
          },
          height: {
            xs: '40%', sm: '70%',
          },
          mb: {xs: '12px', md: '24px'},
          position: 'relative',
        }}>
          <iframe
            ref={iframeRef}
            key={selectedVideo?.uid}
            src={selectedVideo?.fullUrl}
            allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
            style={{
              border: '0px',
              width: '100%',
              height: '100%',
            }}
            onLoad={handleIFrameLoaded}
          />
        </Box>
        <Box>
          <Typography sx={{
            fontSize: {xs: '16px', sm: '22px', md: '28px'},
            mb: {xs: '10px'},
          }}>
            {title}
          </Typography>

          <Typography sx={{
            fontSize: {xs: '12px', sm: '14px', md: '16px'},
            mb: {xs: '10px', md: '30px'},
          }}>
            {`Duration: ${formatDuration(videoDuration)}`}
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: {xs: 1, md: 2},
          mb: {xs: '40px', md: '56px'},
          flexWrap: 'wrap',
        }}>
          {
            selectedVideo?.module?.tags.map(tag => {
              return (
                <Chip
                  key={tag.text}
                  label={tag.text}
                />
              );
            })
          }
        </Box>

        <Box sx={{
          height: {xs: '40px', md: '56px'},
          width: '100%',
          backgroundColor: 'background.accent',
          position: 'absolute',
          left: '0',
          bottom: '0',
          boxShadow: '1px 7px 14px -8px rgba(0,0,0,0.2) inset',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>

          <Typography
            onClick={handleModalClose}
            sx={{
              color: 'primary.main',
              fontSize: {xs: '14px', md: '16px'},
              fontWeight: 'bold',
              px: '10px',
              cursor: 'pointer',
            }}
          >
            {'Exit'}
          </Typography>

          <Box sx={{
            px: '10px',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}>

            {
              !!previousVideo && (
                <Button
                  aria-label='Previous video'
                  variant='outlined'
                  size='small'
                  startIcon={<ArrowBackIcon/>}
                  onClick={onPreviousButtonClick}
                  sx={{
                    height: '50%',
                    borderColor: '#bebebe',
                  }}
                >
                  <Typography sx={{
                    fontWeight: 'bold',
                    fontSize: {xs: '10px', md: '12px'},
                  }}>
                    Previous video
                  </Typography>
                </Button>
              )
            }

            {
              !!nextVideo && (
                <Button
                  aria-label='Next video'
                  variant='outlined'
                  size='small'
                  endIcon={<ArrowForwardIcon/>}
                  onClick={onNextButtonClick}
                  sx={{
                    height: '50%',
                    borderColor: '#bebebe',
                  }}
                >
                  <Typography sx={{
                    fontWeight: 'bold',
                    fontSize: {xs: '10px', md: '12px'},
                  }}>
                    Next video
                  </Typography>
                </Button>
              )
            }

          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  selectedVideo: PropTypes.object,
  handleSelectVideoChanged: PropTypes.func.isRequired,
  nextVideo: PropTypes.object,
  handleNextVideoChanged: PropTypes.func.isRequired,
  previousVideo: PropTypes.object,
  handlePreviousVideoChanged: PropTypes.func.isRequired,
}

export default VideoModal;